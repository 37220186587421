
    <section class="cmn-innerpage-wrapper credit-note">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch()"    [(ngModel)]="searchQuery"/>
            </div>
            <button class="cmn-add-btn" (click)="openAssetsAddAssetsDialog()">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add Asset
            </button>
        </div>
        <div class="inner-content">
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms"      
                [(selectedIndex)]="activeTabIndex"
                (selectedTabChange)="onTabChange($event)" >
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Assets Overview</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                            <app-assets-overview *ngIf="activeTabIndex === 0" [searchQuery]="debouncedSearchQuery"></app-assets-overview>
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Assets Transfer</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                            <app-assets-transfer  *ngIf="activeTabIndex === 1" [searchQuery]="debouncedSearchQuery" ></app-assets-transfer>
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Three -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Assets Disposal</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                           <app-assets-disposal  *ngIf="activeTabIndex === 2" [searchQuery]="debouncedSearchQuery" ></app-assets-disposal>
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Four -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Vehicle Management</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                            <app-vehicle-management *ngIf="activeTabIndex === 3" [searchQuery]="debouncedSearchQuery" ></app-vehicle-management>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>

        <!-- <button type="button" (click)="openAssetTransferAssetDialog()">Asset Transfer</button>
        <button type="button" (click)="openAssetsDisposeAssetDialog()">Asset Disposal </button>
        <button type="button" (click)="openAssetsHistoryAssetDialog()">Asset History </button> -->
        <!-- <button type="button" (click)="openAssignVehicleDialog()">Dialog 4</button> -->

    </section>
