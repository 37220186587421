import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from 'graphql-tag';

const NEW_NOTIFICATION_SUBSCRIPTION = gql`
  subscription {
    newNotification {
      id
      title
      message
      notificationType
      createdAt
    }
  }
`;

export interface NewNotificationResponse {
    newNotification: {
      id: string;
      title: string;
      message: string;
      notificationType: string;
      createdAt: string;
    };
  }

@Injectable({
  providedIn: 'root',
})
export class NotificationServicezz {
  constructor(private apollo: Apollo) {}

  subscribeToNotifications() {
    return this.apollo.subscribe({
      query: NEW_NOTIFICATION_SUBSCRIPTION,
    });
  }
}
