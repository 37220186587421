import { Component, HostListener, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, map, tap } from 'rxjs';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ToastrService } from 'ngx-toastr';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { DialogRef } from '@angular/cdk/dialog';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as selectPermissions from '../../core/permissions/permission.selector';

@Component({
  selector: 'app-inventory',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.scss',
  providers: [Store]
})
export class InventoryComponent implements OnInit {
  public viewPortSize: boolean = false;
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();
  readonly dialog = inject(MatDialog);
  userPermission: any;
  permissions$: Observable<any>;
  columnDefs: any[] = [];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';
  canAdd$!: Observable<boolean>;
  canUpdate$!: Observable<boolean>;
  canDelete$!: Observable<boolean>;
  canView$!: Observable<boolean>;
  canUpload$!: Observable<boolean>;
  profileName: any;

  constructor(
    private router: Router,
    private itemService: InventoryItemService,
    private toastr: ToastrService,
    private store: Store
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit(): void {
    this.fetchItems();
    this.searchSubject
      .subscribe((searchTerm) => {
        this.fetchItems(searchTerm);
      });
    const permissions = {
      canAdd$: 1,
      canUpdate$: 2,
      canDelete$: 3,
      canView$: 4,
      canUpload$: 5,
    };
    // '2' for inventory module
    for (const [key, permissionId] of Object.entries(permissions)) {
      (this as any)[key] = this.store.select(selectPermissions.hasPermission("2", permissionId));
    }

    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.updateColumnDefs();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  updateColumnDefs(): void {
    this.columnDefs = [
      {
        headerName: 'Item Name',
        field: 'itemName',
        flex: this.viewPortSize ? 1 : 0,
        valueFormatter: (params: any) => {
          if (!params.value) return '';
          return (
            params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          );
        },
      },
      { headerName: 'Type', field: 'type', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'SKU', field: 'skuCode', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Purchase Rate',
        field: 'purchaseRate',
        flex: this.viewPortSize ? 1 : 0,
        valueFormatter: (params: any) => {
          const value =
            params.value === null || params.value === 0
              ? 0
              : parseFloat(params.value);
          return value === 0 ? '0.00' : `AED ${value.toFixed(2)}`;
        },
      },
      { headerName: 'Quantity', field: 'quantity', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Unit', field: 'unit', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Rate',
        field: 'rate',
        flex: this.viewPortSize ? 1 : 0,
        valueFormatter: (params: any) => {
          const value =
            params.value === null || params.value === 0
              ? 0
              : parseFloat(params.value);
          return value === 0 ? '0.00' : `AED ${value.toFixed(2)}`;
        },
      },
      // {
      //   field: 'action',
      //   headerName: 'Actions',
      //   floatingFilter: false,
      //   filter: false,
      //   flex: this.viewPortSize ? 1 : 0,
      //   cellRenderer: (params: any) => {
      //     const container = document.createElement('div');
      //     container.style.display = 'flex';
      //     container.style.alignItems = 'center';
      //     container.style.gap = '20px';

      //     const editIcon = document.createElement('img');
      //     editIcon.src = 'assets/images/icons/icon-edit.svg';
      //     editIcon.style.cursor = 'pointer';
      //     editIcon.setAttribute('data-action', 'edit');

      //     const deleteIcon = document.createElement('img');
      //     deleteIcon.src = 'assets/images/icons/icon-delete.svg';
      //     deleteIcon.style.cursor = 'pointer';
      //     deleteIcon.setAttribute('data-action', 'delete');

      //     this.canUpdate$.subscribe(canEdit => {
      //       if (canEdit) {
      //         container.appendChild(editIcon);
      //       }
      //     });

      //     // if (this.profileName === 'admin') { }
      //     this.canDelete$.subscribe(canDelete => {
      //       if (canDelete) {
      //         container.appendChild(deleteIcon);
      //       }
      //     });
      //     return container;
      //   },
      // },
    ];

    this.columnDefs.push({
      field: 'action',
      headerName: 'Actions',
      floatingFilter: false,
      filter: false,
      flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');
        // this.canUpdate$.subscribe(canUpdate => {
        //   if (canUpdate) {
            container.appendChild(editIcon);
        //   }
        // })

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        // this.canDelete$.subscribe(canDelete => {
          if (this.profileName === 'admin') {
            container.appendChild(deleteIcon);
          }
        // })

        return container;
      },
    });
  };

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchItems(search: string = '', filter: any = {}) {
    this.loading = true;
    this.itemService.fetchItems(search, filter).subscribe({
      next: (items) => {
        this.rowData = items
          .map((item: any) => ({
            id: item?.id,
            itemType: item?.itemType,
            itemName: item?.itemName,
            skuCode: item?.skuCode,
            type: item?.itemType,
            quantity: item?.quantity,
            unit: item?.unit,
            status: item?.status,
            createdAt: item?.createdAt,
            purchaseRate: item?.costPrice,
            rate: item?.sellingPrice,
          }))
          .sort((a: any, b: any) => b.createdAt - a.createdAt);

        // Update pagination options based on the rowData length
        if (this.rowData) {
          this.paginationPageSizeSelector = PaginationHelper.getPaginationPageSizeOptions(this.rowData);
        } else {
          this.paginationPageSizeSelector = [];
        }

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  openEdit(itemData: any) {
    const itemId = itemData?.id;
    if (itemId) {
      this.router.navigate([`/inventory/inventory-edit/${itemId}`]);
    }
  }

  onDeleteItem(itemData: any): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeItem(itemData?.id);
      }
    });
  }

  private removeItem(itemId: number): void {
    this.itemService.removeItemById(itemId).subscribe({
      next: () => {
        this.toastr.success('Item removed successfully.');
        this.fetchItems();
      },
      error: (error) => {
        console.log('ERROR', error);
        this.toastr.error(
          error?.message || 'Failed to remove item. Please try again.'
        );
      },
    });
  }

  onCellClicked(params: any) {
    const itemId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.openEdit(params?.data);
      } else if (action === 'delete') {
        this.onDeleteItem(params?.data);
      }
    } else {
      const itemId = params.data?.id;
      this.router.navigate([`inventory/inventory-details/${itemId}`]);
    }
  }

  navigateToAddNew() {
    this.router.navigate(['/inventory/inventory-add']);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadInventoryDialog);
    dialogRef.afterClosed().subscribe((data) => {
      this.fetchItems();
    });
  }

}







@Component({
  selector: 'upload-inventory-dialog',
  templateUrl: 'upload-inventory-dialog.html',
  styleUrls: ['./inventory.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class UploadInventoryDialog {
  file: any = null;
  url: any = null;
  loading: boolean = false;
  uploadForm: FormGroup;

  constructor(
    private toastr: ToastrService,
    private itemService: InventoryItemService,
    private dialogRef: DialogRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.uploadForm = this.fb.group({
      file: [null, Validators.required],
    });
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  uploadDocuments(csvFile: any) {
    this.loading = true;
    this.itemService.bulkCreateItem(csvFile).subscribe({
      next: (response) => {
        this.loading = false;
        if (response == true) {
          this.toastr.success('Inventory uploaded successfully!');
          this.dialogRef.close();
        } else {
          this.toastr.error(response.errors[0].message, 'Error');
          this.dialogRef.close();
        }
      },
      error: (error) => {
        this.loading = false;
        this.toastr.error(error.message, 'Bulk Inventory creation failed');
        // this.dialogRef.close();
        this.loading = false;
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  onUpload() {
    if (this.uploadForm.invalid && !this.file) {
      this.uploadForm.markAllAsTouched();
      return;
    }
    this.uploadDocuments(this.file);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
