import { gql } from 'apollo-angular';

export const GET_ACTIVITY_LOGS = gql`
query GetActivityLogs($search: String) {
  activityLogs(search: $search) {
    id
    UserID
    ActionType
    Description
    EntityID
    EntityTable
    IPAddress
    Timestamp
    AdditionalInfo
    employeeData{
      firstName
    }
  }
}

`;
