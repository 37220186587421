import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrencyAdjustmentService } from '../../../../features/currency-adjustments/services/currency-adjustment.service';
import { ToastrService } from 'ngx-toastr';
import { UploadFileService } from '../../../../shared/services/file-upload.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { nonNegativeValidator, trnValidator } from '../../../../shared/services/validations';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../../../shared/components/confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-other-details',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './other-details.component.html',
  styleUrl: '../../client-new.component.scss'
})
export class OtherDetailsComponent {
  otherDetailsForm: FormGroup;
  @Input() customerData: any = {};
  @Output() formSubmit = new EventEmitter<any>();
  search: string | undefined;
  filter: {};
  currencyData: any;
  file: any;
  fileName: any;
  fileUrl: any;
  customerId: string | null;
  fileDataList: Array<{ documentName: string; documentUrl: string }> = [];

  constructor(
    private fb: FormBuilder,
    private currencyService: CurrencyAdjustmentService,
    private documentUploadService: UploadFileService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.fetchPartnerById();
      }
    });
    this.fetchCurrencies();
  }

  initForm() {
    if (this.customerData) {
      console.log(this.customerData);

      this.otherDetailsForm = this.fb.group({
        trnNumber: [this.customerData.trnNumber || '', [trnValidator]],
        currency: [this.customerData.currency || ''],
        openingBalance: [this.customerData.openingBalance || 0, [nonNegativeValidator]],
        paymentTerm: [this.customerData.paymentTerm || ''],
        file: [this.file ? this.file : null],
      })
    } else {
      this.otherDetailsForm = this.fb.group({
        trnNumber: ['', [trnValidator]],
        currency: [''],
        openingBalance: [0, [nonNegativeValidator]],
        paymentTerm: [''],
        file: [''],
      })
    }
  }

  fetchPartnerById() {
    this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
      next: (data) => {
        this.customerData = data;
        this.initForm();
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  viewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }

  fetchCurrencies() {
    this.currencyService.fetchCurrencies(this.search, this.filter).subscribe({
      next: (currency) => {
        this.currencyData = currency;
        console.log("Currency data:", this.currencyData);

        // Check if AED exists in currencyData and set it as default
        const defaultCurrency = this.currencyData.find((c: any) => c.currencyCode === 'AED')?.currencyCode || '';
        this.otherDetailsForm.patchValue({ currency: defaultCurrency });
      },
      error: (error) => console.error(error),
    });
  }


  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
      this.fileName = this.file.name;

      // Upload file immediately if customerId exists, otherwise save for submission
      if (this.customerId) {
        this.uploadAndSaveDocument();
      }
    }
  }

  removeFile(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.file = null;
        this.fileName = '';
        this.fileUrl = null;

        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) {
          input.value = '';
        }

        this.otherDetailsForm.patchValue({
          file: null,
        });
      }
    });
  }

  onRemoveDocument(id: any): void {
    if (id) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.customerService.removeDocument(Number(id)).subscribe({
            next: (data) => {
              if (data) {
                this.toastr.success('Document removed successfully', 'Success');
                this.fetchPartnerById();
              }
            },
            error: (error) => this.toastr.error(error, 'Error'),
          });
        }
      });
    }
  }

  uploadAndSaveDocument(): void {
    if (this.file) {
      this.documentUploadService.uploadDocument(this.file, 'customers').subscribe({
        next: (response) => {
          this.fileUrl = response.url;

          // Prepare document data and save it
          const documentData = {
            partnerId: Number(this.customerId),
            documentName: this.fileName,
            documentUrl: this.fileUrl,
          };

          this.fileDataList.push(documentData); // Store uploaded document
          this.saveDocumentData(documentData);  // Save document to database
        },
        error: (error) => this.toastr.error(error, 'Error'),
      });
    }
  }

  saveDocumentData(documentData: any): void {
    this.customerService.createDocument(documentData).subscribe({
      next: (data) => {
        if (data) {
          this.customerData.documents = [
            ...this.customerData.documents,
            documentData,
          ];
          this.fetchPartnerById();
        }
      },
      error: (error) => this.toastr.error(error, 'Error'),
    });
  }

  onSubmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.otherDetailsForm.invalid) {
        this.otherDetailsForm.markAllAsTouched();
        return;
      }
      if (this.file && !this.customerId) {
        // For new customers, upload the last selected file and submit with form data
        this.documentUploadService.uploadDocument(this.file, 'customers').subscribe({
          next: (response) => {
            this.fileUrl = response.url;

            // Prepare the last document data and add it to the fileDataList
            const lastFileData = {
              documentName: this.fileName,
              documentUrl: this.fileUrl,
            };
            this.fileDataList.push(lastFileData); // Add the last file to list
            console.log('this.fileDataList', this.fileDataList);

            // Emit the form data with all document data
            this.formSubmit.emit({
              ...this.otherDetailsForm.value,
              documents: this.fileDataList, // Pass all documents
            });

            // Clear temporary file data
            this.file = null;
            this.fileName = '';
            this.fileUrl = '';
            this.fileDataList = []; // Reset list after submission

            resolve();
          },
          error: (error) => {
            this.toastr.error(error, 'Error');
            reject(error);
          },
        });
      } else {
        // For existing customers or no new file, emit with existing documents
        this.formSubmit.emit({
          ...this.otherDetailsForm.value,
          documents: this.fileDataList, // Include previously saved documents
        });
        resolve();
      }
    });
  }


  onCancel() {
    this.otherDetailsForm.reset();
    this.file = null;
    this.fileName = '';
    this.fileUrl = null;
  }

}
