import { gql } from "apollo-angular";

export const LIST_PETTY_CASH = gql`
  query pettyCashes($search: String ) {
    pettyCashes(search: $search) {
    id
    projectId
    projectData {
      id
      projectCode
      projectName
    }
    employeeId
    projectEngineerData {
      id
      firstName
      lastName
      middleName
    }
    flow
    date
    description
    amount
  }
  }
`;

export const LIST_PETTY_CASH_BY_ID = gql`
  query pettyCash($id: Int! ) {
    pettyCash(id: $id) {
    id
    projectId
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
    }
    employeeId
    projectEngineerData {
      id
      firstName
      lastName
      middleName
    }
    flow
    date
    description
    amount
    status
    createdAt
    updatedAt
  }
  }
`;

export const PETTY_CASH_DETAIL = gql`
  query getProjectBasedPettyCashes($projectId: String!, $search: String) {
    getProjectBasedPettyCashes(projectId: $projectId, search: $search) {
    pettyCashes {
      id
      projectId
      projectData{
        id
        projectCode
        projectName
        totalProjectCost
        description
      }
      employeeId
      projectEngineerData{
        id
        firstName
        lastName
      }
      flow
      date
      description
      amount
      status
      createdAt
      updatedAt
    }
    totalInFlow
    totalOutFlow
  }
  }
`;


