import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CustomerService } from '../../features/customers/services/customer.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { BillService } from '../../features/bills/services/bills.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { addDays, addMonths, endOfMonth } from 'date-fns';
import { PAYMENT_TERMS, UNITTYPES } from '../../helpers/helper-file';
import { nonNegativeValidator, positiveNumberValidator } from '../../shared/services/validations';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';
import { PreferenceService } from '../../shared/services/preference.service';
import { GRNServices } from '../../features/grn/services/grn.service';
import { PurchaseOrdereService } from '../../features/purchase-order/services/purchase-orderservice';
import { InvoiceService } from '../../features/invoice/services/invoice.service';

@Component({
  selector: 'app-bill-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  templateUrl: './bill-new.component.html',
  styleUrl: './bill-new.component.scss'
})
export class BillNewComponent {
  public arr = [1, 2, 3, 4, 5, 6, 7]
  search: any;
  vendors: any;
  filteredVendors: any = [];
  newBillForm: FormGroup;
  itemServiceForm: FormGroup;
  notesForm: FormGroup;
  vendorControl = new FormControl();
  vendorSearchControl = new FormControl();
  filter: any;
  availableItems: any;
  customers: any;
  type: any;
  accounts: any;
  addedItems: any[] = [];
  fetchedItems: any[] = [];
  file: any;
  fileUrl: any;
  billId: string | null;
  billData: any;
  itemData: any;
  subTotal: any;
  totalPrice: any;
  billAttachments: any[] = [];
  fileName: string;
  isManualDateChange = true;
  itemValue: any;
  files: any = [];
  billNumber: any;
  prefix: string;
  today: Date = new Date();
  unitTypes: any = UNITTYPES;

  // This filter disables all dates before today
  dateFilter = (d: Date | null): boolean => {
    const currentDate = new Date((d || new Date()).setHours(0, 0, 0, 0));
    const today = new Date(this.today.setHours(0, 0, 0, 0));
    return currentDate <= today;
  };
  uploadedName: string;
  grossAmount: any;
  allOrders: any;
  filteredPurchase: any[];
  purchaseControl = new FormControl();
  purchaseSearchControl = new FormControl();
  totalDiscount: number;
  vat: number;

  constructor(
    private customerService: CustomerService,
    private billService: BillService,
    private itemService: InventoryItemService,
    private chartAccountService: ChartAccountsService,
    private documentUploadService: UploadFileService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private grnServices: GRNServices,
    private invoiceService: InvoiceService,
    private purchaseOrderService: PurchaseOrdereService,
  ) { }

  ngOnInit() {
    this.initForm();
    // this.preferenceCode();
    this.fetchVendors();
    this.fetchItems();
    this.vendorSearchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchText) => {
        this.search = searchText;
        this.filterVendors(searchText);
      });

    this.items.valueChanges.subscribe(() => {
      this.calculateSubTotal(); // Recalculate subTotal whenever an item changes
    });

    this.route.paramMap.subscribe(params => {
      this.billId = params.get('id');
      if (this.billId) {
        this.fetchBillById(this.billId);
      }
    });
  }

  initForm() {
    const billDate = this.billData && this.billData.billDate ? new Date(parseInt(this.billData.billDate)) : null;
    const dueDate = this.billData && this.billData.dueDate ? new Date(parseInt(this.billData.dueDate)) : null;

    if (this.billId && this.billData) {
      this.newBillForm = this.fb.group({
        id: [this.billData.id],
        billStatus: [this.billData.billStatus],
        partnerId: [this.billData.partnerId, Validators.required],
        billNo: [this.billData.billNo, Validators.required],
        purchaseOrderId: [Number(this.billData.purchaseOrderId)],
        billDate: [billDate, Validators.required],
        dueDate: [dueDate],
        paymentTerm: [this.billData.paymentTerm],
        subject: [this.billData.subject],
        customerNote: [this.billData.customerNote],
        subTotal: [this.billData.subTotal],
        totalPrice: [this.billData.totalPrice],
        billPaymentStatus: [this.billData.billPaymentStatus],
        items: this.fb.array([]),
      });


      // Populate the items array with the billItems
      if (this.billData?.billItems?.length > 0) {
        this.billData.billItems.forEach((item: any) => {
          this.items.push(this.createItemForm(item));
        });
      }

      if (this.billData?.billAttachments?.length > 0) {
        this.fileName = this.billData?.billAttachments[0]?.fileName;
      }

      this.notesForm = this.fb.group({
        notes: [this.billData.customerNote],
        fileUrl: [this.fileName || '']
      });

      this.calculateSubTotal();
    } else {
      this.newBillForm = this.fb.group({
        billStatus: ['Open'],
        partnerId: ['', Validators.required],
        billNo: ['', Validators.required],
        purchaseOrderId: [''],
        billDate: ['', Validators.required],
        dueDate: [''],
        paymentTerm: [''],
        subject: [''],
        customerNote: [''],
        subTotal: [0],
        totalPrice: [0],
        billPaymentStatus: ['Open'],
        items: this.fb.array([]),
      });


      this.notesForm = this.fb.group({
        notes: [''],
        fileUrl: ['']
      });
    }
  }

  get items(): FormArray {
    return this.newBillForm.get('items') as FormArray;
  }

  createItemForm(item: any): FormGroup {
    let action = item?.itemId ? "create" : "edit"
    let itemId = item?.itemId || item?.itemData?.id;
    console.log('itemId', itemId);
    let remainingQuantity = item?.quantity || 0;
    if (item.billItems && item.billItems.length > 0) {
      const totalQuantityInBillItems = item.billItems.reduce((sum: number, billItem: any) => sum + parseInt(billItem.quantity || '0', 10), 0);
      remainingQuantity = item?.quantity - totalQuantityInBillItems;
    }
    const formGroup = this.fb.group({
      itemId: [{ value: Number(itemId), disabled: true }, Validators.required],
      billId: [Number(this.billId)],
      id: [Number(item?.id)],
      itemName: [item?.item?.itemName || item?.itemData?.itemName],
      purchaseItemId: [Number(item?.purchaseItemData?.id || item?.id)],
      totalQuantity: [item?.purchaseItemData?.quantity || item?.quantity],
      unit: [item?.purchaseItemData?.unit || item?.unit],
      // quantityLeft: [item?.purchaseItemData?.quantity || item?.quantity],
      quantity: [String(remainingQuantity),
      [Validators.pattern('^[1-9][0-9]*$'), Validators.maxLength(6), this.totalQuantityValidator(item, action),]],
      rate: [item?.rate || 0,  Validators.pattern('^(0(\\.[0-9]+)?|[1-9][0-9]*(\\.[0-9]+)?)$')      ],
      discount: [item?.discount || 0, Validators.pattern('^(0(\\.[0-9]+)?|[1-9][0-9]*(\\.[0-9]+)?)$')      ],
      amount: [{ value: this.calculateAmount(item), disabled: true }, Validators.required],
      tax: [{ value: this.calculateTax(item), disabled: true }, Validators.required],
      isManual: [item?.isManual || false],
      status: [item?.status || 1],
      grossAmount: [{ value: this.calculateGrossAmount(item), disabled: true }, Validators.required],
      totalAmount: [{ value: this.calculateTotalAmount(item), disabled: true }, Validators.required],
    });

    // Subscribe to changes in 'rate' and 'discount'
    ['rate', 'quantity', 'discount'].forEach(field =>
      formGroup.get(field)?.valueChanges.subscribe(() => {
        this.updateDependentFields(formGroup)
        this.calculateSubTotal();
      })
    );

    formGroup.get('discount')?.addValidators(() => {
      const grossAmount = formGroup.get('grossAmount')?.value || 0;
      const discount = formGroup.get('discount')?.value || 0;
      return discount > grossAmount ? { discountExceedsGross: true } : null;
    });

    console.log('formGroup', formGroup);
    return formGroup;

  }

  totalQuantityValidator(item: any, action: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputQuantity = Number(control.value);
      let billItemsQuantitySum = 0;
      console.log("action", action);

      // Extract billItems and calculate sum of their quantities
      if (action === "edit") {
        const billItems = item?.purchaseItemData?.billItems || [];
        const editingItemId = item?.id;
        console.log("editingItemId", editingItemId);
        // The id of the item being edited
        billItemsQuantitySum = billItems
          .filter((billItem: any) => billItem.id !== editingItemId)  // Exclude the current item being edited
          .reduce(
            (sum: number, billItem: any) => sum + Number(billItem.quantity || 0),
            0
          );
        const totalQuantity = billItemsQuantitySum + inputQuantity;
        console.log('inputQuantity', inputQuantity);
        console.log('billItemsQuantitySum', billItemsQuantitySum);
        console.log('totalQuantity', totalQuantity);
        console.log('item?.quantity', item?.purchaseItemData?.quantity);

        // Check if total exceeds item.quantity
        if (totalQuantity > Number(item?.purchaseItemData?.quantity || 0)) {
          return { totalQuantityExceeded: true }; // Validation error
        }
      }

      if (action === "create") {
        billItemsQuantitySum = item?.billItems
          .reduce(
            (sum: number, billItem: any) => sum + Number(billItem.quantity || 0),
            0
          );
        if ((billItemsQuantitySum + inputQuantity) > Number(item?.quantity)) {
          return { totalQuantityExceeded: true };
        }
      }


      return null; // No error
    };
  }

  private calculateAmount(item: any): number {
    const grossAmount = this.calculateGrossAmount(item);
    const discount = item?.discount || 0;
    return Math.max(grossAmount - discount, 0); // Ensure non-negative
  }

  private calculateTax(item: any): number {
    const amount = this.calculateAmount(item);
    return parseFloat((amount * 0.05).toFixed(2));
  }

  private calculateTotalAmount(item: any): number {
    const amount = this.calculateAmount(item);
    const tax = this.calculateTax(item);
    return parseFloat((amount + tax).toFixed(2));
  }

  private calculateGrossAmount(item: any): number {
    const rate = item.rate;
    const quantity = item.quantity;
    return Number(rate) * Number(quantity);
  }

  private updateDependentFields(formGroup: FormGroup): void {
    const rate = formGroup.get('rate')?.value || 0;
    const discount = formGroup.get('discount')?.value || 0;
    const quantity = formGroup.get('quantity')?.value || 1;

    // Calculate dependent values
    const grossAmount = rate * quantity;
    const amount = Math.max(grossAmount - discount, 0);
    const tax = parseFloat((amount * 0.05).toFixed(2));
    const totalAmount = parseFloat((amount + tax).toFixed(2));

    // Update dependent fields
    formGroup.get('amount')?.setValue(amount, { emitEvent: false });
    formGroup.get('tax')?.setValue(tax, { emitEvent: false });
    formGroup.get('grossAmount')?.setValue(grossAmount, { emitEvent: false });
    formGroup.get('totalAmount')?.setValue(totalAmount, { emitEvent: false });

    formGroup.get('discount')?.updateValueAndValidity({ emitEvent: false });

  }

  // preferenceCode() {
  //   this.preferenceService.preferenceCodes('', 'Vendor Invoice').subscribe({
  //     next: (response) => {
  //       response.forEach((prefix: any) => {
  //         this.billNumber = prefix?.prefix;
  //       })
  //       const randomSuffix = Math.floor(1000 + Math.random() * 9000);
  //       this.prefix = `${this.billNumber + randomSuffix}`;
  //       this.newBillForm.patchValue({ billNo: this.prefix });
  //     },
  //     error: (error) => {
  //       console.error(error, 'Error');
  //     },
  //   });
  // }

  onRemoveDocument(id: any): void {
    if (id) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.billService.removeBillAttachment(Number(id)).subscribe({
            next: (data) => {
              if (data) {
                this.toastr.success('Document removed successfully', 'Success');
                this.fetchBillById(this.billId);
              } else {
                this.toastr.error('Failed to remove document', 'Error');
              }
            },
            error: (error) => this.toastr.error(error, 'Error'),
          });
        }
      });
    }
  }

  // onPaymentTermChange(term: string): void {
  //   let dueDate: Date | null = null;

  //   const billDate = this.newBillForm.get('billDate')?.value;
  //   if (!billDate) {
  //     return;
  //   }

  //   const billDateObj = new Date(billDate);

  //   switch (term) {
  //     case 'Net 15':
  //       dueDate = addDays(billDate, 15);
  //       break;
  //     case 'Net 30':
  //       dueDate = addDays(billDate, 30);
  //       break;
  //     case 'Net 45':
  //       dueDate = addDays(billDate, 45);
  //       break;
  //     case 'Net 60':
  //       dueDate = addDays(billDate, 60);
  //       break;
  //     case 'Due On Receipt':
  //       dueDate = billDate;
  //       break;
  //     case 'Due end of the month':
  //       dueDate = endOfMonth(billDate);
  //       break;
  //     case 'Due end of next month':
  //       dueDate = endOfMonth(addMonths(billDate, 1));
  //       break;
  //     case 'Custom':
  //       dueDate = null;
  //       break;
  //     default:
  //       dueDate = null;
  //   }
  //   if (dueDate) {
  //     this.isManualDateChange = false;
  //     this.newBillForm.get('dueDate')?.setValue(dueDate);
  //   }

  // }

  fetchVendors() {
    this.invoiceService.fetchCustomers(this.search, 'Vendor').subscribe({
      next: (data) => {
        this.vendors = data;
        // this.vendors = data.filter((item: any) => item.partnerType === 'Vendor');
        // this.customers = data.filter((item: any) => item.partnerType === 'Customer');
        this.filteredVendors = [...this.vendors];
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  onViewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }

  fetchItems() {
    const search: any = '';
    const filter: any = {};
    const updatedFilter = { ...filter, isPurchaseActive: true };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.availableItems = items;
      },
      error: (error) => console.error(error),
    });
  }


  onVendorChange(event: any): void {
    const selectedVendorId = event.value;
    console.log('Selected Vendor ID:', selectedVendorId);
    if (selectedVendorId) {
      console.log('Selected Vendor:', selectedVendorId);
      this.vendorControl.setValue(selectedVendorId); // Update form control with the selected vendor ID
      this.vendorSearchControl.setValue('');
    }
    this.fetchAllPurchaseOrder(selectedVendorId);  
  }

  onPOChange(event: any): void {
    const selectedPO = event.value;
    console.log('Selected purchase ID:', selectedPO);
    this.fetchPurchaseById(selectedPO);
  }

  fetchPurchaseById(purchaseOrderId: any) {
    this.billService.getPurchaseOrderForBillCreation(Number(purchaseOrderId)).subscribe({
      next: (data) => {
        this.fetchedItems = data;
        this.newBillForm.patchValue({
          paymentTerm: this.fetchedItems[0]?.purchaseOrder?.paymentTerms
        })
        this.populateItems(this.fetchedItems);

        console.log('fetchedItems', this.fetchedItems);

      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  populateItems(items: any[]) {
    this.items.clear(); // Clear existing items in the FormArray
    items.forEach((item) => {
      this.items.push(this.createItemForm(item)); // Add a FormGroup for each item
    });
  }

  fetchAllPurchaseOrder(vendorId: any) {
    let purchaseStatus: any = []
    if (!this.billId) {
      purchaseStatus = ['ISSUED']
    }
    this.grnServices.fetchAllPurchaseOrder(vendorId, purchaseStatus).subscribe({
      next: (orders) => {
        this.allOrders = orders;
        this.filteredPurchase = [...this.allOrders];
      },
      error: (err) => {
        console.error('Error fetching all purchase-orders:', err);
      },
    })

  }

  filterVendors(searchText: string) {
    if (searchText) {
      this.filteredVendors = this.vendors.filter((vendor: any) =>
        vendor.displayName.toLowerCase().includes(searchText.toLowerCase())
      );
      this.fetchVendors();
    } else {
      this.filteredVendors = [...this.vendors];
    }
  }

  getVAT(): number {
    let netPrice;
    netPrice = this.totalPrice;
    const vat = netPrice * 0.05;
    return vat;
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
      this.fileName = file?.name;
      this.uploadedName = file?.name;
    }
  }

  removeFile(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.file = '';
        this.fileName = '';
        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) {
          input.value = '';
        }
      }
    })
  }


  // fetchItemById(id: any): void {
  //   console.log('inside');

  //   if (id !== null) {
  //     console.log('id', id);

  //     this.itemService.fetchItemById(Number(id)).subscribe({
  //       next: (data) => {
  //         this.itemData = data;
  //         console.log(this.itemData);

  //         if (this.billId) {
  //           console.log('inside bill');
  //           const item = {
  //             billId: Number(this.billId),
  //             itemId: this.itemData.id,
  //             quantity: this.itemServiceForm.value.quantity,
  //             rate: String(this.itemServiceForm.value.rate),
  //             status: 1,
  //             discount: this.itemServiceForm.value.discount,
  //             amount: this.itemServiceForm.value.amount,
  //             tax: Number(this.itemServiceForm.value.tax),
  //             isManual: false
  //           };

  //           this.billService.createBillItem(item).subscribe({
  //             next: (data) => {
  //               if (data) {
  //                 this.toastr.success('Bill item added successfully!', 'Success');
  //                 this.itemServiceForm.reset();
  //                 this.fetchBillById(this.billId);
  //               }
  //             },
  //             error: (error) => {
  //               console.error(error, 'Error');
  //             }
  //           })
  //         } else {
  //           this.itemServiceForm.patchValue({
  //             id: this.itemData.id,
  //             itemName: this.itemData.itemName,
  //             tax: Number(this.itemServiceForm.value.tax),
  //             // rate: String(this.itemData.sellingPrice),
  //             status: 1,
  //             isManual: false
  //           })
  //           this.addedItems.push(this.itemServiceForm.value);
  //           this.itemServiceForm.reset();
  //         }
  //         //  this.setupAutoCalculateAmount();
  //         this.calculateSubTotal();
  //         // this.calculateAmount();
  //       },
  //       error: (error) => console.error(error),
  //     });
  //   } else {
  //     console.warn('Item ID is null; cannot load item data.');
  //   }
  // }

  fetchItem(id: any) {
    this.itemService.fetchItemById(Number(id)).subscribe({
      next: (data) => {
        this.itemValue = data;
        this.itemServiceForm.patchValue({
          rate: this.itemValue.sellingPrice
        });
        // this.setupAutoCalculateAmount();
        // this.calculateAmount();
      }
    })
  }

  calculateSubTotal() {
    this.subTotal
      = this.items.controls.reduce((sum, itemForm: AbstractControl) => {
        const grossAmount = itemForm.get('grossAmount')?.value || 0;
        return sum + grossAmount;
      }, 0);
    this.totalPrice
      = this.items.controls.reduce((sum, itemForm: AbstractControl) => {
        const totalAmount = itemForm.get('totalAmount')?.value || 0;
        return sum + totalAmount;
      }, 0);
    this.totalDiscount
      = this.items.controls.reduce((sum, itemForm: AbstractControl) => {
        const discount = itemForm.get('discount')?.value || 0;
        return sum + discount;
      }, 0);
    this.vat
      = this.items.controls.reduce((sum, itemForm: AbstractControl) => {
        const tax = itemForm.get('tax')?.value || 0;
        return sum + tax;
      }, 0);

  }

  // onAddItem() {
  //   if (this.itemServiceForm.invalid) {
  //     console.log('inside add1', this.itemServiceForm.controls);
  //     this.itemServiceForm.markAllAsTouched();
  //     return;
  //   }
  //   this.itemServiceForm.patchValue({
  //     // quantity: this.itemServiceForm.value.quantity, 
  //     rate: String(this.itemServiceForm.value.rate),
  //     amount: this.itemServiceForm.value.amount,
  //     discount: Number(this.itemServiceForm.value.discount),
  //     tax: Number(this.itemServiceForm.value.tax),
  //     isManual: false
  //   });
  //   console.log('inside add');

  //   this.fetchItemById(this.itemServiceForm.value.itemId);
  //   this.calculateSubTotal();
  //   // this.calculateAmount();

  // }

  onRemoveItem(index: number, id: any) {
    console.log('index', index, 'and', 'id', id);

    if (index > -1 && index < this.addedItems.length) {
      this.addedItems.splice(index, 1);
    }
    this.billService.removeBillItem(id).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Item removed successfully', 'Success');
          this.fetchBillById(this.billId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      }
    })
  }

  onRemoveAddedItem(index: number) {
    console.log('index', index);
    
    const itemsArray = this.items;

    if (itemsArray.length <= 1) {
      // Show toastr notification
      this.toastr.warning('Cannot remove the last item in the list.', 'Warning');
      return;
    }
    if (itemsArray.length > index) {
      itemsArray.removeAt(index);
      this.calculateSubTotal();
    }
  
  }

  fetchBillById(id: any) {
    this.billService.fetchBillById(Number(id)).subscribe({
      next: (data) => {
        this.billData = data;
        if (this.billData?.billAttachments?.length > 0) {
          this.fileName = this.billData?.billAttachments[0]?.fileName;
          this.fileUrl = this.billData?.billAttachments[0]?.fileUrl;
        }
        this.calculateSubTotal();
        this.initForm();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  async onUpload() {
    if (!this.file) {
      this.toastr.error('No file selected for upload.', 'Error');
      return;
    }

    try {
      const response = await this.documentUploadService.uploadDocument(this.file, 'bills').toPromise();

      if (response && !response.errors && response.url) {
        this.fileUrl = response.url;
        this.billAttachments = [
          {
            fileUrl: this.fileUrl,
            fileName: this.fileName
          }
        ];
        if (this.billId) {
          const documentData = {
            billId: Number(this.billId),
            fileUrl: this.fileUrl,
            fileName: this.fileName
          }
          this.billService.createBillAttachment(
            documentData
          ).subscribe({
            next: (data) => {
              if (data) {
                this.fetchBillById(this.billId);
              }
            },
            error: (error) => this.toastr.error(error, 'Error'),
          });
        }
      } else if (response?.errors) {
        this.toastr.error(response.errors[0]?.message || 'Unknown error occurred', 'Error');
      }
    } catch (error: any) {
      this.toastr.error(error.message || 'Vendor invoice upload failed', 'Error');
    }
  }

  async onSave(billStatus: 'Open' | 'Draft') {
    if (this.newBillForm.invalid) {
      this.newBillForm.markAllAsTouched();
    }

    if (this.newBillForm.invalid) {
      return;
    }
    if (this.file) {
      await this.onUpload();
    }
    
    
    this.newBillForm.patchValue({
      subTotal: this.subTotal,
      totalPrice: this.grossAmount,
      billStatus: billStatus,
      billDate: this.datePipe.transform(this.newBillForm.value.billDate, 'YYYY-MM-dd'),
      dueDate: this.datePipe.transform(this.newBillForm.value.dueDate, 'YYYY-MM-dd'),
      customerNote: this.notesForm.value.notes,
      purchaseOrderId: Number(this.newBillForm.value.purchaseOrderId),
    })
    console.log('this.notesForm.value.notes',this.newBillForm.value);

    const itemValue = this.newBillForm.value;
    const { items, ...rest } = itemValue; // Destructure to exclude `items` field
    const billInput = rest;


    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;

    if (this.billId && this.billData) {
      if (this.billData) {
        if (this.billData?.billAttachments?.length > 0) {
          this.billAttachments = [
            {
              id: this.billData.billAttachments[0].id,
              fileUrl: this.fileUrl
            }
          ];
        } else {
          this.billAttachments = [];
        }
      }
      if (this.newBillForm.get('items') instanceof FormArray) {
        const billItems = (this.newBillForm.get('items') as FormArray).controls.map((item: AbstractControl) => {
          const itemValue = item.value;
          const { itemName, totalQuantity,unit, ...rest } = itemValue;
          const itemId = item.get('itemId')?.value;
          const id = item.get('id')?.value;
          const purchaseItemId = item.get('purchaseItemId')?.value;
          const description = item.get('description')?.value;
          const status = Number(item.get('status')?.value);
          // const name = item.get('itemName')?.value;
          const amount = item.get('amount')?.value;
          const tax = item.get('tax')?.value;
          // const unit = item.get('unit')?.value;
          // const grossAmount = item.get('grossAmount')?.value;
          // const totalAmount = item.get('totalAmount')?.value;


          return {
            ...rest,
            itemId,
            id,
            purchaseItemId,
            description,
            status,
            amount, // Include manually fetched values
            tax,
            // unit
            // grossAmount,
            // totalAmount
          };
        })

        this.billService.updateBill(billInput, employeeId, billItems, this.billAttachments).subscribe({
          next: (data: any) => {
            if (data) {
              this.toastr.success('Vendor invoice updated successfully!', 'Success');
              this.router.navigate([`/vendor-invoice`]);
            }
          },
          error: (error: any) => {
            this.toastr.error(error, 'Error');
          }
        })
      }
    } else {

      console.log('this.newBillForm', this.newBillForm.value);

      if (this.newBillForm.get('items') instanceof FormArray) {
        const billItems = (this.newBillForm.get('items') as FormArray).controls.map((item: AbstractControl) => {
          const itemValue = item.value;
          const { itemName, id, totalQuantity,unit, billId, ...rest } = itemValue;
          const itemId = item.get('itemId')?.value;
          const purchaseItemId = item.get('purchaseItemId')?.value;
          const description = item.get('description')?.value;
          const status = Number(item.get('status')?.value);
          // const name = item.get('itemName')?.value;
          const amount = item.get('amount')?.value;
          const tax = item.get('tax')?.value;
          // const unit = item.get('unit')?.value;
          // const grossAmount = item.get('grossAmount')?.value;
          // const totalAmount = item.get('totalAmount')?.value;


          return {
            ...rest,
            itemId,
            purchaseItemId,
            description,
            status,
            amount, // Include manually fetched values
            tax,
            // unit
            // grossAmount,
            // totalAmount
          };
        })


        console.log('this.billItems', billItems);
        if (billItems.length > 0) {
          this.billService.createBill(billInput, employeeId, billItems, this.billAttachments).subscribe({
            next: (data: any) => {
              if (data) {
                this.toastr.success('Vendor invoice created successfully!', 'Success');
                this.newBillForm.reset();
                // this.itemServiceForm.reset();
                this.notesForm.reset();
                this.addedItems = [];
                this.fileName = '';
                this.subTotal = 0;
                this.totalPrice = 0;
                this.router.navigate([`/vendor-invoice`]);
              }
            },
            error: (error: any) => {
              this.toastr.error(error, 'Error');
            }
          })
        }
        // else {
        //   this.toastr.warning('No items selected. Please add an item with quantity and details before proceeding.', 'Warning');
        // }
      }
    }
  }

  async onSaveAsOpen() {
    await this.onSave('Open');
  }
  async onSaveAsDraft() {
    await this.onSave('Draft');
  }

  onCancel() {
    this.router.navigate(['/vendor-invoice']);
  }

}
