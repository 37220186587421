import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_ACTIVITY_LOGS } from '../graphql/query/activity-log.queries';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogService {
  constructor(private apollo: Apollo) { }

  fetchActivityLogs(search: any): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ACTIVITY_LOGS,
        variables:
        {
          search
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.activityLogs || [])
      );
  }

//   fetchBillById(id: any): Observable<any> {
//     return this.apollo
//       .use('accounts')
//       .watchQuery<any>({
//         query: LIST_BILL,
//         variables:
//         {
//           id
//         },
//         fetchPolicy: 'network-only'
//       })
//       .valueChanges.pipe(
//         map((result) => result.data?.bill || [])
//       );
//   }

 


}