import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { AssetService } from '../../../../features/assets/services/asset.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AssetCurrentStatusEnum, AssetStatusEnum } from '../../../../helpers/helper-file';
import { MatDialog } from '@angular/material/dialog';
import {
  AssetTransferAssetDialog,
  AssetsAddAssetsDialog,
  AssetsDisposeAssetDialog,
  AssetsHistoryAssetDialog,
  AssignAssetsDialog,
} from '../../assets.component';

@Component({
  selector: 'app-assets-overview',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './assets-overview.html',
  styleUrl: '../../assets.component.scss',
})
export class AssetsOverview implements OnInit, OnChanges {
  
  @Input() searchQuery: string = '';
  readonly dialog = inject(MatDialog);
  
  assetStatuses = Object.values(AssetStatusEnum);
  assetTypeVehicle = 'Vehicle';
  AssetCurrentStatus= Object.values(AssetCurrentStatusEnum);

 
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  public rowData: any[] | null = null;
  public loading: boolean = true;
  columnDefs = [
    { headerName: 'Asset Name', field: 'name', flex: 1 },
    // { headerName: 'Asset Holder', field: 'holder', flex: 1 },
    // { headerName: 'Category', field: 'category', flex: 1 },
    { headerName: 'Type', field: 'type', flex: 1 },
    // { headerName: 'Branch', field: 'branch', flex: 1 },
    { headerName: 'Purchase Date', field: 'purchaseDate', flex: 1 },
    { headerName: 'Assignee', field: 'assignee', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const edit = document.createElement('img');
        edit.src = 'assets/images/icons/icon-edit.svg';
        edit.style.cursor = 'pointer';
        edit.setAttribute('data-action', 'edit');
        container.appendChild(edit);

        const assetTransfer = document.createElement('img');
        assetTransfer.src = 'assets/images/icons/icon-asset-transfer.svg';
        assetTransfer.style.cursor = 'pointer';
        assetTransfer.setAttribute('data-action', 'assetTransfer');
        container.appendChild(assetTransfer);

        const assetDisposal = document.createElement('img');
        assetDisposal.src = 'assets/images/icons/icon-assets-disposal.svg';
        assetDisposal.style.cursor = 'pointer';
        assetDisposal.setAttribute('data-action', 'assetDisposal');
        container.appendChild(assetDisposal);

        const assetHistory = document.createElement('img');
        assetHistory.src = 'assets/images/icons/icon-asset-history.svg';
        assetHistory.style.cursor = 'pointer';
        assetHistory.setAttribute('data-action', 'assetHistory');
        container.appendChild(assetHistory);


          if(params.data?.type != this.assetTypeVehicle){
            if(params.data?.assetCurrentStatus === AssetCurrentStatusEnum.Open){
              const assetAssign = document.createElement('img');
              assetAssign.src = 'assets/images/icons/icon-assign.svg';
              assetAssign.style.cursor = 'pointer';
              assetAssign.setAttribute('data-action', 'assetAssign');
              container.appendChild(assetAssign);
            }
            else{
              const unassign = document.createElement('img');
              unassign.src = 'assets/images/icons/icon-unassign.svg';
              unassign.style.cursor = 'pointer';
              unassign.setAttribute('data-action', 'unassign');
              container.appendChild(unassign);
            }

          }
  
        


        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };

  constructor(
    private router: Router,
    private assetsService: AssetService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {}

  // ngOnChanges(changes: SimpleChanges) {
  // if (changes['searchQuery']) {
  //   this.fetchAllAssets(this.searchQuery,'');
  // }
  // }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchQuery'] && changes['searchQuery'].currentValue !== changes['searchQuery'].previousValue) {
      this.fetchAllAssets(this.searchQuery, '');
    }
  }
  
  ngOnInit() {
    this.fetchAllAssets();
    this.assetsService.dataUpdated$.subscribe((updated) => {
      if (updated) {
        this.fetchAllAssets(); 
      }
    });
  }

  fetchAllAssets(search: string = '', assetType: string = '') {  
    this.assetsService.getAllAssets(search, assetType).subscribe({
      next: (assets) => {
        this.rowData = 
        assets.map((asset: any) => {
          return {
            id: asset?.id,
            name: asset?.assetName,        
            holder: asset?.assignedData?.firstName,
            branch: asset?.branch?.branchName,
            type: asset?.assetType,
            status: asset?.assetStatus,
            serialNo: asset?.serialNumber,
            condition: asset?.condition,
            purchaseCost: asset?.purchaseCost,
            branchId: asset?.branchId,
            purchaseDate: this.datePipe.transform(asset?.purchaseDate, 'dd/MM/yyyy') ||     '--',
            warantyExpiryDate: asset?.warrantyExpiryDate? this.datePipe.transform(asset?.warrantyExpiryDate, 'dd/MM/yyyy') : null,
            rcExpiryDate: asset?.rcExpiryDate ? this.datePipe.transform(asset?.rcExpiryDate, 'dd/MM/yyyy')  :  null,
            insuranceExpiryDate: asset?.insuranceExpiryDate ? this.datePipe.transform(asset?.insuranceExpiryDate, 'dd/MM/yyyy') : null,
            assignee: asset?.assignedData?.firstName,
            assetCurrentStatus: asset?.assetCurrentStatus,
            assineeId: asset?.assignedData?.id
            // polutionExpiryDate: asset?.polutionExpiryDate ? this.datePipe.transform(asset?.polutionExpiryDate, 'dd/MM/yyyy') : null,
          };
        });
      },
    });
  }

  onCellClicked(params: any) {
    if (  params.column.colId === 'action' && params.event.target.dataset.action  ) {

      let action = params.event.target.dataset.action;
    
      if (action === 'assetTransfer') {
        const rowData = params.data;
        this.dialog.open(AssetTransferAssetDialog,{
         data: {
          ...rowData,
          isEdit: false
         }
        });
      } 
      else if (action === 'assetDisposal') {
        const rowData = params.data;
        this.dialog.open(AssetsDisposeAssetDialog,{
          data: {
            ...rowData,
            isEdit: false
           }
        });
      } 
      else if( action === 'edit'){
        const rowData = params.data;
        this.dialog.open(AssetsAddAssetsDialog, {
          data: rowData
        });
      }
      else if( action === 'assetAssign'){
        const rowData = params.data;
        this.dialog.open(AssignAssetsDialog, {
          data: rowData
        });
        
      }
      else if( action === 'unassign'){
        const rowData = params.data;
        console.log(rowData);
        this.loading =true;
        if(rowData){
          const assetId = params?.data?.id;
          this.assetsService.unAssignAsset( assetId ).subscribe({
            next: (response) => {
              this.toastr.success('Un-assigned asset.');
              this.assetsService.notifyDataUpdated(true);
              this.loading=false
            },
            error: (error) => {
              this.toastr.error('Cannot unassign');
              this.loading =false;

            }
          })
        }
      }
      else {
        const id = params.data?.id;
        this.dialog.open(AssetsHistoryAssetDialog, {
          data: id
        });
      }
    }
  }

}
