import { Component, HostListener, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { BillService } from '../../features/bills/services/bills.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bills',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular
  ],
  templateUrl: './bills.component.html',
  styleUrl: './bills.component.scss'
})
export class BillsComponent {
  public viewPortSize: boolean = false;
  search: any;
  public rowData: any[] | null = null;
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  profileName: any;

  constructor(
    private router: Router,
    private billService: BillService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
  ) { 
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    this.fetchBills();

    this.checkViewportSize();
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }


  readonly dialog = inject(MatDialog);



  openDialog() {
    const dialogRef = this.dialog.open(UploadBillsDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchBills();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      {
        headerName: 'Vendor Invoice Date',
        field: 'date',
        flex: this.viewPortSize ? 1 : 0,
      },
      { headerName: 'Vendor Invoice#', field: 'billNo', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'PO No.', field: 'orderNo', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Vendor Name', field: 'vendorName', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Status', field: 'billStatus', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Due Date', field: 'dueDate', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Amount', field: 'amount', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `
          <span>AED ${params.data.amount }</span>
      `;
        }
      },
      {
        headerName: 'Balance', field: 'balance', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `
          <span>AED ${params.data.balance}</span>
      `;
        }
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');

          if(params.data.billStatus === 'Draft'){
            container.appendChild(editIcon);
          }
          if(this.profileName === 'admin'){
            container.appendChild(deleteIcon);
          }
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  fetchBills() {
    this.billService.fetchBills(this.search).subscribe({
      next: (data) => {
        this.rowData = data
          .map((data: any) => {
            return {
              id: data?.id || '--',
              date: this.datePipe.transform(data?.billDate, 'dd/MM/YYYY') || '--',
              billNo: data?.billNo || '--',
              orderNo: data?.purchaseOrderData?.purchaseOrderNo || '--',
              vendorName: data?.partnerData?.displayName || '--',
              billStatus: data?.billStatus || '--',
              dueDate: this.datePipe.transform(data?.dueDate, 'dd/MM/YYYY') || '--',
              amount: data?.totalPrice || '--',
              balance: data?.balance || '0.00',
            };
          })
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  onCellClicked(params: any) {
    const billId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(billId);
      } else if (action === 'delete') {
        this.onConfirmDelete(billId);
      }
    } else {
      this.router.navigate([`/vendor-invoice/vendor-invoice-details/${billId}`]);
    }
  }

  onConfirmDelete(id: number): void {
    console.log(id);

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }

  onDelete(id: any) {
    this.billService.removeBill(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success("Vendor invoice deleted successfully", 'Success');
          this.fetchBills();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  onEdit(id: any) {
    this.router.navigate([`/vendor-invoice/vendor-invoice-edit/${id}`]);
  }

}



@Component({
  selector: 'upload-bills-dialog',
  templateUrl: 'upload-bills-dialog.html',
  styleUrls: ['./bills.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadBillsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

}
