<mat-dialog-content>
    <section class="upload-attendance-wrapper">
        <h2 class="cmn-popup-title">Update Attendance</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="uploadForm" (ngSubmit)="onUpdate()">
        <div class="form-wrapper">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" formControlName="date"  [matDatepickerFilter]="dateFilter"  placeholder="Choose Date" (dateInput)="onDateChange($event)" readonly>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div
            *ngIf="
            uploadForm.get('date')!.invalid &&
            uploadForm.get('date')!.touched
            "
            class="error"
            >
            Select a date.
            </div>
        </div>
        <div class="cmn-upload-box" (dragover)="onDragOver($event)" 
        (drop)="onDrop($event)">
            <img src="assets/images/upload-image.png" alt="Upload Image">
            <h3>Drag and drop your file here</h3>
            <h4>or</h4>
            <input #fileInput type="file" formControlName = "file" (change)="onFileSelected($event)" accept=".csv" style="display: none;"/>
            <button class="cmn-choose-file-btn" (click)="fileInput.click()" type="button"><img src="assets/images/icons/icon-add-color.svg" alt="Icon Add">Choose
                a File
            </button>
        </div>
        <div class="uploaded-file" *ngIf="csvFile">
            <h6>{{csvFile?.name}} <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()"></h6>
        </div>
        <div class="upload-info">
            <h5>CSV Format only supported</h5>
            <a href="assets/sample-files/attendances.csv" class="download-sample" download="attendances.csv">Download Sample CSV</a>
        </div>
        <div
        *ngIf="
        (uploadForm.get('file')!.invalid &&
        uploadForm.get('file')!.touched)
       && !csvFile?.name"
        class="error"
      >
        Select a file.
      </div>
        <button class="cmn-upload-submit-btn active" type="submit">Update Attendance</button>
    </form>
    </section>
</mat-dialog-content>