<section class="bill-payment">
    <form [formGroup]="billForm" (ngSubmit)="onCreate()">
        <div class="invoice-new-form-wrapper">
            <div class="form-wrapper invoice-form-wrapper">
                <div class="cmn-Search-select-container">
                    <label for="">Vendor Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Search Vendor" #singleSelect1 formControlName="vendorName"
                            (selectionChange)="onCustomerSelected($event)">
                            <mat-option>
                                <ngx-mat-select-search   (input)="onVendorSearch($event)"
                                    placeholderLabel="Search Vendor"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let vendor of vendorList" [value]="vendor.id">
                                {{ vendor?.displayName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            billForm.get('vendorName')!.touched &&
                            billForm.get('vendorName')!.hasError('required')
                          ">Vendor Name is required.</mat-error>
                        </span>
                    </div>

                    <!-- <button>
                        <div><img src="../../../assets/images/icons/icon-add-color.svg" alt=""></div>
                        <span>Add Customer</span>
                    </button> -->
                </div>
                <div>
                    <label for="">Payment#</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter payment number" formControlName="payment" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            billForm.get('payment')!.touched &&
                            billForm.get('payment')!.hasError('required')
                          ">Payment is required.</mat-error>
                        </span>
                    </div>
                </div>
                <div>
                    <label for="">Payment Made</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter payment" formControlName="paymentMade" (input)="onPaymentMadeChange($event)" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            billForm.get('paymentMade')!.touched &&
                            billForm.get('paymentMade')!.hasError('required')
                          ">Payment Made is required.</mat-error>
                        </span>
                    </div>
                    <div class="check-box">
                        <label class="pay-full">
                            <input type="checkbox" formControlName="isFullAmount" (change)="toggleFullAmount($event)" />
                            <span>Pay Full (AED
                                {{fullAmount | number: '1.2-2'}})</span>
                        </label>
                    </div>
                </div>
                <div>
                    <label for="">Payment Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date" id="paymentDate"
                            formControlName="paymentDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #invoiceDatePicker></mat-datepicker>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            billForm.get('paymentDate')!.touched &&
                            billForm.get('paymentDate')!.hasError('required')
                          ">Payment Date is required.</mat-error>
                        </span>
                    </div>
                </div>
                <div>
                    <label for="">Payment Mode</label>
                    <mat-form-field>
                        <mat-select placeholder="Payment Mode" id="paymentMode" formControlName="paymentMode">
                            <mat-option *ngFor="let mode of paymentModeList" [value]="mode">
                                {{ mode }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            billForm.get('paymentMode')!.touched &&
                            billForm.get('paymentMode')!.hasError('required')
                          ">Payment Mode is required.</mat-error>
                        </span>
                    </div>
                </div>
                <div>
                    <label for="">Paid Through</label>
                    <mat-form-field>
                        <mat-select placeholder="Paid Through" id="depositTo" formControlName="depositTo">
                            <mat-option *ngFor="let getAccount of getAccountList" [value]="getAccount?.id">
                                {{ getAccount?.accountName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            billForm.get('depositTo')!.touched &&
                            billForm.get('depositTo')!.hasError('required')
                          ">Deposit To is required.</mat-error>
                        </span>
                    </div>
                </div>
                <div>
                    <label for="">Reference</label>
                    <mat-form-field>
                        <input matInput placeholder="Reference number" type="text" formControlName="reference" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            billForm.get('reference')!.touched &&
                            billForm.get('reference')!.hasError('required')
                          ">Reference is required.</mat-error>
                        </span>
                    </div>
                </div>
                
                <div *ngIf="billForm.get('paymentMode')?.value === 'Cheque'">
                    <label for="">Cheque No</label>
                    <mat-form-field>
                        <input matInput placeholder="Cheque No" type="text" formControlName="chequeNo" />
                    </mat-form-field>
                </div>
                <div *ngIf="billForm.get('paymentMode')?.value === 'Cheque'">
                    <label for="">Cheque Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="chequeDatePicker" placeholder="Cheque Date" type="text" formControlName="chequeDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="chequeDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #chequeDatePicker></mat-datepicker>
                    </mat-form-field>

                    
                </div>
            </div>
        </div>

        <div class="item-service-adding-table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Bill #</th>
                        <th>Bill Amount</th>
                        <th>Amount Due</th>
                        <th>Payment</th>
                    </tr>
                </thead>

                <tbody>

                    <!--here Billpayment: this.fb.array([]), *ngFor="let item of items.controls; let i = index" [formGroupName]="i"-->
                    
                    <tr *ngFor="let partnerBill of partnerBillData; let i = index">
                        <td>
                            <h2>
                                {{
                                partnerBill?.billDate
                                ? (partnerBill?.billDate | date : "MM/dd/yyyy")
                                : "---"
                                }}
                            </h2>
                            <h3 [ngClass]="{'red': isDueDatePast(partnerBill?.dueDate)}">
                                Due Date :
                                {{
                                partnerBill?.dueDate
                                ? (partnerBill?.dueDate | date : "MM/dd/yyyy")
                                : "---"
                                }}
                            </h3>
                        </td>
                        <td>{{partnerBill?.billNo || "___"}}</td>
                        <td>AED {{partnerBill?.totalPrice || "___"}}</td>
                        <td>AED {{partnerBill?.balance || "___"}}</td>
                        <td class="input-td">
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="0.00" [(ngModel)]="splitAmounts[partnerBill?.id]"
                                    (input)="onInput(partnerBill?.id, $event, partnerBill?.balance)" [ngModelOptions]="{standalone: true}" />
                                </mat-form-field>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">Total :</td>
                        <td>AED {{ totalSplitAmount | number: '1.2-2' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="notes-tatal-amount-container">
            <div class="form-container">
                <div class="form-wrapper">
                    <label for="">Customer Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Looking forward for your business.." formControlName="note"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div></div>
            <div>
                <div class="amount-table-div">
                    <div>
                        <div>
                            <h6>Total <span>: {{ totalSplitAmount | number: '1.2-2' }}</span></h6>
                            <h6>Amount used for Payments <span>: {{ usedAmount() | number: '1.2-2' }}</span></h6>
                            <!-- <h6>Amount Refunded <span>: 0.00</span></h6> -->
                            <h6>Amount in Excess <span>: AED {{ calculateExcessAmount() | number: '1.2-2' }}</span></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="upload-file-group">
            <h2>Documents</h2>
            <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none"
                accept=".pdf, .jpg, .jpeg, .png, .gif, .doc, .docx" />
            <button type="button" class="cmn-upload-btn" (click)="fileInput.click()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload File</span>
            </button>
            <div class="uploaded-file" *ngIf="documentFileName">
                <h6>
                    {{documentFileName || "___"}}
                    <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="clearDocument()" />
                </h6>
            </div>
        </div>

        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="reset">Cancel</button>
            <button class="cmn-next-btn" type="submit">Next</button>
        </div>
    </form>
</section>