import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AssetsOverview } from './Components/assets-overview/assets-overview';
import { AssetsTranfer } from './Components/assets-transfer/assets-transfer';
import { AssetsDisposal } from './Components/assets-disposal/assets-disposal';
import { VehicleManagement } from './Components/vehicle-management/vehicle-management';
import { ToastrService } from 'ngx-toastr';
import { ASSET_CONDITIONS, ASSET_TYPES, AssetCurrentStatusEnum, AssetDisposalMethodEnum, AssetStatusEnum } from '../../helpers/helper-file';
import { BranchService } from '../../features/branch/services/branch.service';
import { AssetService } from '../../features/assets/services/asset.service';
import { AuthService } from '../../core/services/auth.service';
import { Subject, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { UnassignDialog } from '../../shared/components/confirm-dialog/unassign-vehicle';
import { Observable } from '@apollo/client';

@Component({
  selector: 'app-assets',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    MatTabsModule,
    AssetsOverview,
    AssetsTranfer,
    AssetsDisposal,
    VehicleManagement,
    FormsModule
  ],
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss'
})
export class AssetsComponent implements OnInit{
  readonly dialog = inject(MatDialog);
  public searchQuery: string = ''; // Captures the search query
  activeTabIndex: number = 0; // Tracks the currently active tab
  searchResults: any[] = []; // Results for the active tab
  public debouncedSearchQuery: string = ''; // Query passed to the child
  private searchDebounceTimer: any = null;
  constructor(
    private assetsService: AssetService,
    private toastr: ToastrService
  ) { }
  
  ngOnInit(): void {
  }

  onTabChange(event: any): void {
    this.activeTabIndex = event.index;
    this.searchQuery = '';
  }

  onSearch(): void {
    // Clear the previous timeout to avoid overlapping
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }

    this.searchDebounceTimer = setTimeout(() => {
      this.debouncedSearchQuery = this.searchQuery.trim();
    }, 300); 
  }


  openAssetsAddAssetsDialog() {
    const dialogRef = this.dialog.open(AssetsAddAssetsDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.assetsService.notifyDataUpdated(true);
      }
    });
  }

  openAssetTransferAssetDialog() {
    this.dialog.open(AssetTransferAssetDialog);
  }

  openAssetsDisposeAssetDialog() {
    this.dialog.open(AssetsDisposeAssetDialog);
  }

  openAssetsHistoryAssetDialog() {
    this.dialog.open(AssetsHistoryAssetDialog);
  }

  openAssignVehicleDialog() {
    this.dialog.open(AssignVehicleDialog);
  }

}



@Component({
  selector: 'assets-add-asset-dialog',
  templateUrl: 'assets-add-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})



export class AssetsAddAssetsDialog implements OnInit {
  assetsForm: FormGroup;
  assetTypes = ASSET_TYPES;
  assetStatuses = Object.values(AssetStatusEnum);
  AssetCurrentStatus = Object.values(AssetCurrentStatusEnum);
  assetConditions = ASSET_CONDITIONS;
  allBranches: any[] = []; 
  assigneeList: any[] = []; 
  userData: any;
  selectedBranchId: any;
  assetData: any;
  assetId: any
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private assetService: AssetService,
    private branchService: BranchService,
    private dialogRef: MatDialogRef<AssetsAddAssetsDialog> ,
    private datePipe: DatePipe,
    
  ) {

    this.assetId = data?.id;
    const loggedInUser: any = localStorage.getItem('loggedInUser'); 
    this.userData = JSON.parse(loggedInUser);
    this.selectedBranchId = localStorage.getItem('selectedBranchId');
    
  }

ngOnInit(): void {
  this.initForm();
  this.fetchBranchData();
  // console.log('Received data:', this.data); 
  if (this.data) {
    this.populateForm(this.data); 
  }
}


initForm(): void{
  this.assetsForm = this.fb.group({
    id: [],
    name:['', Validators.required],
    status: [this.assetStatuses[0],Validators.required],
    type:[this.assetTypes[0], Validators.required],
    condition:[this.assetConditions[0],Validators.required],
    serialNumber:['', Validators.required],
    purchaseDate:['',Validators.required],
    branchId:[{ value: this.selectedBranchId ? Number(this.selectedBranchId) : null, disabled: true }, Validators.required],
    purchaseCost: [null,Validators.required],
    warrantyExpiryDate: [''],
    // polutionExpiryDate: [],
    rcExpiryDate: [],
    insuranceExpiryDate: []
  });
}

private fetchBranchData(search: string = '') {
  this.branchService.fetchBranches().subscribe({
    next: (resp) => {
      this.allBranches = resp;
      if (this.selectedBranchId) {
        const matchingBranch = this.allBranches.find(branch => branch.id === Number(this.selectedBranchId));
        if (matchingBranch) {
          this.assetsForm.patchValue({ branchId: matchingBranch.id });
        }
      }
    },
    error: (err) => {
      console.log(err);
    }
  })
}

onSubmit(){
  if(this.assetsForm.invalid){
    this.assetsForm.markAllAsTouched();
    this.toastr.warning('Please fill in all required fields.');
    return;
  }
  this.loading = true;
  const formValues = this.assetsForm.getRawValue();
  const createAssetInput = {
    assetName: formValues?.name,
    assetStatus:  AssetStatusEnum.Active,
    assetType: formValues?.type,
    assignedTo: this.userData?.id,
    branchId: formValues?.branchId,
    condition: formValues?.condition,
    serialNumber: formValues?.serialNumber,
    purchaseCost: formValues?.purchaseCost,
    purchaseDate: formValues?.purchaseDate ?  this.formatToLocalDate(formValues?.purchaseDate) : null,
    warrantyExpiryDate: formValues?.warrantyExpiryDate ?  this.formatToLocalDate(formValues?.warrantyExpiryDate): null,
    // polutionExpiryDate:  formValues?.polutionExpiryDate ? this.formatToLocalDate(formValues?.polutionExpiryDate) : null,
    rcExpiryDate: formValues?.rcExpiryDate ? this.formatToLocalDate(formValues?.rcExpiryDate) : null,
    insuranceExpiryDate:  formValues?.insuranceExpiryDate ? this.formatToLocalDate(formValues?.insuranceExpiryDate) : null,
    assetCurrentStatus: AssetCurrentStatusEnum.Open
  }

  if(!this.assetId){
    this.assetService.createAsset(createAssetInput).subscribe({
      next: (data) => {
        if(data){
          const newDataAdded = true; // Replace with actual condition
          this.dialogRef.close(newDataAdded);
          this.loading = false;
          this.toastr.success('Successfully created asset.');
          this.dialogRef.close(data);
        }
      },
      error: (err) => {
        this.toastr.error('Failed to create asset.');
        this.loading  = false;
      }
    })
  }
  else {
    const formValues = this.assetsForm.getRawValue();
    // console.log(formValues);
    
    const updateInput = {
      id: formValues?.id,
      assetName: formValues?.name,
      assetStatus: formValues?.status,
      assetType: formValues?.type,
      assignedTo: this.userData?.id,
      branchId: formValues?.branchId,
      condition: formValues?.condition,
      serialNumber: formValues?.serialNumber,
      purchaseCost: formValues?.purchaseCost,
      purchaseDate: formValues?.purchaseDate ?  this.formatToLocalDate(formValues?.purchaseDate) : null,
      warrantyExpiryDate: formValues?.warrantyExpiryDate ?  this.formatToLocalDate(formValues?.warrantyExpiryDate): null,
      // polutionExpiryDate:  formValues?.polutionExpiryDate ? this.formatToLocalDate(formValues?.polutionExpiryDate) : null,
      rcExpiryDate: formValues?.rcExpiryDate ? this.formatToLocalDate(formValues?.rcExpiryDate) : null,
      insuranceExpiryDate:  formValues?.insuranceExpiryDate ? this.formatToLocalDate(formValues?.insuranceExpiryDate) : null
  
    }

    this.assetService.updateAsset(updateInput).subscribe({
      next: (res)=>{
        if(res){
          this.toastr.success('Asset updated successfully.');
          this.assetService.notifyDataUpdated(true);
          this.dialogRef.close();
          this.loading = false;
        }
      },
      error: (err) => {
        this.toastr.error('Something went wrong.');
        this.loading  = false;
      }
    })
  }

  
}

populateForm(data: any): void {
  if (!data) {
    console.warn('No data provided to populate the form.');
    return;
  }

  const convertToDate = (dateStr: string) => {
    return dateStr ? new Date(dateStr.split('/').reverse().join('-')) : '';
  };
console.log(data?.status);

  this.assetsForm.patchValue({
    id: data.id || null,
    name: data.name || '',
    status: data.status || '',
    type: data.type || '',
    condition: data.condition || '',
    serialNumber: data.serialNo || '',
    purchaseDate:  convertToDate(data?.purchaseDate), 
    branchId: data.branchId || null,
    purchaseCost: data?.purchaseCost ?? null,
    warrantyExpiryDate: convertToDate( data.warantyExpiryDate) || '',
    // polutionExpiryDate: data.polutionExpiryDate ?  convertToDate(data.polutionExpiryDate) : '',
    rcExpiryDate: data.rcExpiryDate ?  convertToDate(data.rcExpiryDate) : '',
    insuranceExpiryDate: data.insuranceExpiryDate ? convertToDate(data.insuranceExpiryDate) :  ''
  });
}


private formatToLocalDate(date: any): string {
  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
  const day = String(localDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // Return in 'YYYY-MM-DD' format
}



}

@Component({
  selector: 'assets-tranfer-asset-dialog',
  templateUrl: 'assets-tranfer-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,

  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule, NgxMatSelectSearchModule],
})



export class AssetTransferAssetDialog implements OnInit{
  assetsList:  any[]=[];
  defaultAssetsList: any[] = [];
  allBranches: any[]= [];
  assetTransferForm : FormGroup;
  selectedBranchId: any;
  currentDate = new Date().toISOString().split('T')[0];
  assetId: any;
  loading = false;
  isEdit : Boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private assetsService: AssetService,
    private fb: FormBuilder,
    private branchService: BranchService,
    private toastr: ToastrService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AssetTransferAssetDialog>,
  ){
    this.selectedBranchId =  this.selectedBranchId = this.authService.getSelectedBranchId();
    this.assetId=this.data?.id;
  }
  
  ngOnInit(): void {
    this.initForm();
    this.listAssets();
    this.fetchBranchData();
    if(this.data?.isEdit){
      console.log(this.data);
      this.assetId=this.data?.id;
      this.isEdit =  this.data?.isEdit;
      this.populateForm(this.data);
  }

  }

  initForm(){

    this.assetTransferForm = this.fb.group({
      id:[],
      assetId: [ this.assetId,Validators.required],
      fromBranch: [{ value: this.selectedBranchId ? Number(this.selectedBranchId) : null, disabled: true }, Validators.required],
      toBranch: [null,Validators.required],
      transferDate: [this.formatToLocalDate(this.currentDate), Validators.required]
    });
  }

  populateForm(data: any): void {
    if (!data) {
      console.warn('No data provided to populate the form.');
      return;
    }
  
    const convertToDate = (dateStr: string) => {
      return dateStr ? new Date(dateStr.split('/').reverse().join('-')) : '';
    };
  
    this.assetTransferForm?.patchValue({
      id: data?.id,
      assetId: data?.assetID || null,
      fromBranch: data?.frombranchID  ,
      toBranch: data?.tobranchID || '',
      transferDate: convertToDate(data?.transferDate) ,
    });
    // console.log(this.assetTransferForm.value);
    
  }

  onSubmit(){
    if(this.assetTransferForm.invalid){
      this.assetTransferForm.markAllAsTouched();
      this.toastr.warning('Please fill required fields.');
      return;
    }
    const formValues = this.assetTransferForm.getRawValue();
    if(formValues?.fromBranch === formValues?.toBranch){
      this.toastr.warning('Both branches cannot be same,Choose different branches.');
      return;
    }
    this.loading = true;
    const createAssetTransferInput = {
      assetId: formValues?.assetId,
      fromBranch: formValues?.fromBranch,
      toBranch: formValues?.toBranch,
      transferDate: formValues?.transferDate
    }
    if(!this.isEdit){
      this.assetsService.transferAssets(createAssetTransferInput).subscribe({
        next: (respnse) => {
          if(respnse) {
            this.toastr.success("Asset transfered.");
            this.assetsService.notifyDataUpdated(true);
            this.dialogRef.close();
            this.loading = false;
          }
        },
        error: (err) => {
          this.toastr.error('Cannot transfer asset.')
          this.loading  = false;
        }
      });
    }
    else{
      const updateAssetTransferInput = {
        id: formValues?.id,
        assetId: formValues?.assetId,
        fromBranch: formValues?.fromBranch,
        toBranch: formValues?.toBranch,
        transferDate: formValues?.transferDate
      }
      this.assetsService.updateAssetTransfer(updateAssetTransferInput).subscribe({
        next : (response) => {
          if(response) {
            this.toastr.success('Asset transfer updated.');
            this.assetsService.notifyDataUpdated(true);
            this.dialogRef.close();
            this.loading = false;
          }
        },
        error: (error) => {
          this.toastr.error('Something went wrong.');
          this.loading  = false;
          console.log(error);
          
        }
      })
    }

  }

  preventEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      // console.log('Enter key press prevented.');
    }
  }
  listAssets(search: string = ''){
    if(!search) {
      if(this.defaultAssetsList.length === 0 ){
        this.assetsService.assetsDropdown(search).subscribe({
          next: (response) => {
            this.defaultAssetsList = [...response];
            this.assetsList = response;
          },
          error: (error) => {
            console.log(error);
            
          }
        });
      }
      else {
        this.assetsList = [...this.defaultAssetsList];
      }
    }
    else {
      this.assetsService.assetsDropdown(search).subscribe({
        next: (response) => {
          this.assetsList = response;
        },
        error: (error) => {
          console.log(error);
          
        }
      });
    }
  }

  private fetchBranchData(search: string = '') {
    this.branchService.fetchBranches().subscribe({
      next: (resp) => {
        this.allBranches = resp;
        if (this.selectedBranchId) {
          const matchingBranch = this.allBranches.find(branch => branch.id === Number(this.selectedBranchId));
          if (matchingBranch) {
            this.assetTransferForm.patchValue({ fromBranch: matchingBranch.id });
          }
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }
  private formatToLocalDate(date: any): string {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Return in 'YYYY-MM-DD' format
  }
  
  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    if (!inputValue) {
      this.assetsList = [...this.defaultAssetsList];
    } else {
      this.listAssets(inputValue);
    }
  }
}


@Component({
  selector: 'asset-dispose-asset-dialog',
  templateUrl: 'asset-dispose-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule, NgxMatSelectSearchModule],
})



export class AssetsDisposeAssetDialog implements OnInit{
  assetDisposalForm: FormGroup;
  assetList:  any[]=[];
  defaultAssetsList: any[] = [];
  loading  = false;
  disposalMethods = Object.values(AssetDisposalMethodEnum);
  isEdit: Boolean;
  assetId: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private assetsService: AssetService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AssetsDisposeAssetDialog> ,
  ){
    this.isEdit= data?.isEdit;
    this.assetId= data?.id;
  }
  ngOnInit(): void {
    this.initForm();
    this.getAllAssets();
  }

  initForm(){
    this.assetDisposalForm = this.fb.group({
      id: [],
      assetId :[this.assetId,Validators.required],
      disposalDate:[ Validators.required],
      disposalMethodId:[null, Validators.required],
      reason:['',Validators.required]
    });
   }



  onSubmit() {

    if(!this.isEdit){
      const formValues = this.assetDisposalForm?.value;
      this.loading  = true;
      const createAssetDisposalInput =  {
        assetId : formValues?.assetId,
        disposalDate :formValues?.disposalDate ? this.formatToLocalDate(formValues?.disposalDate) : null,
        disposalMethod : formValues?.disposalMethodId ,
        disposalReason :formValues?.reason
      }
      this.assetsService.disposeAsset(createAssetDisposalInput).subscribe({
        next: (result) => {
          if(result){
            this.loading  = false;
            this.assetsService.notifyDataUpdated(true);
            this.dialogRef.close();
            this.toastr.success('Asset Disposed.');
          }
        },
        error: (err) => {
          this.toastr.error('Something went wrong.'); 
          this.loading  = false;
        }
      })
    }


  }
  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    if (!inputValue) {
      this.assetList = [...this.defaultAssetsList];
    } else {
      this.getAllAssets(inputValue);
    }
  }
  getAllAssets(search: string = ''){
    this.assetsService.assetsDropdown(search).subscribe({
      next: (data) => {
        this.assetList = data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }


  private formatToLocalDate(date: any): string {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Return in 'YYYY-MM-DD' format
  }
}

@Component({
  selector: 'assign-vehicle-dialog',
  templateUrl: 'assign-vehicle-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})



export class AssignVehicleDialog implements OnInit{
  
  vehicleForm: FormGroup;
  assetName: string;
  vehicleNumber: string;
  status: string;
  assetId: any;
  userData: any; 
  assigneeList: any[]=[];
  assetVehicleTasks: any;
  currentDate = new Date().toISOString().split('T')[0];
  readonly dialog = inject(MatDialog);
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AssignVehicleDialog>,
    private assetsService: AssetService,
    private toastr: ToastrService
  )
    {
      const loggedInUser: any = localStorage.getItem('loggedInUser'); 
      this.userData = JSON.parse(loggedInUser);   
      this.assetName = data.assetName; 
      this.vehicleNumber = data.vehicleNumber; 
      this.status = data.status;
      this.assetId = data?.id
      this.assetVehicleTasks = data?.assetVehicleTasks?.id;

      
    }
    
ngOnInit(): void {
  this.initForm();
  this.listAssignees();
}

initForm(){
 this.vehicleForm = this.fb.group({
  date:[this.formatToLocalDate(this.currentDate), Validators.required],
  assignee:[],
  reason:[],
  task:['',Validators.required]
 });
}

onSubmit(){
this.loading=true;
  if(this.vehicleForm.invalid){
    this.vehicleForm.markAllAsTouched();
    this.toastr.warning('Please fill required fields.');
    return;
  }
  const formValues = this.vehicleForm.value;
  const  logId = this.userData.id;
  const actionType= 'assign';


  const createVehicleLogInput = {
    vehicleId: this.assetId,
    driverId:  formValues?.assignee,
    date: this.formatToLocalDate(formValues?.date),
    time:  new Date(formValues?.date).toLocaleTimeString('en-US', { hour12: false }),
    action: "Out"
  }

  const createVehicletaskInput = {
    assetId: this.assetId,
    employeeId: formValues?.assignee,
    date: this.formatToLocalDate(formValues?.date),
    task: formValues?.task,
    note: '',
    vehicletaskStatus: 'Open'
  }

  this.assetsService.assignVehicle(this.assetId, actionType, createVehicleLogInput, createVehicletaskInput, null ).subscribe({
    next: (data) => {
      // console.log(data);
      this.loading=false;
      const newDataAdded = true; // Replace with actual condition
      this.dialogRef.close(newDataAdded);
      this.toastr.success('Vehicle assigned successfully.');
      // this.dialogRef.close(data);
    },
    error: (err) => {
      this.loading=false;
      this.toastr.error('Something went wrong.');
    }
  })
  
}

listAssignees(){
  const status =1
  const search= 'driver'
  this.assetsService.listAssignees(search, status).subscribe({
    next: (response) => {
      this.assigneeList = response.data.designations[0]?.employees || [];
      // console.log(this.assigneeList);   
    }
  })
}

private formatToLocalDate(date: any): string {
  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
  const day = String(localDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // Return in 'YYYY-MM-DD' format
}
}

@Component({
  selector: 'asset-history-asset-dialog',
  templateUrl: 'asset-history-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [    AgGridAngular,CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})



export class AssetsHistoryAssetDialog {
  public rowData: any[] | null = null;


  columnDefs = [
    { headerName: 'Asset Name', field: 'assetName', flex: 1 },
    // { headerName: 'CurrentStatus', field: 'assetCurrentStatus', flex: 1 },
    { headerName: 'Log Message', field: 'logMessage', flex: 1 },
    { headerName: 'Assignee', field: 'assignee', flex: 1 },
    { headerName: 'Log type', field: 'logType', flex: 1 },
    { headerName: 'Action Date', field: 'actionDate', flex: 1 },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private assetService: AssetService,
    private datePipe: DatePipe,
  ){

  }
  ngOnInit(): void {
    if(this.data){
      console.log(this.data);
      const filter = {
        assetId: this.data
      }
      this.assetLogs(filter);
    }
  }

  assetLogs(filter: any){
    this.assetService.assetlogs(filter).subscribe({
      next: (logs) =>{

        this.rowData = logs.map((log: any) =>{
          return {
            assetName: log?.asset?.assetName,
            assetCurrentStatus: log?.asset?.assetCurrentStatus,
            logMessage: log?.logMessage,
            assignee: log?.actionByData?.firstName,
            logType: log?.logType,
            actionDate:  this.datePipe.transform(log?.actionDate, 'dd/MM/yyyy') ||     '--',
          }
        })
        
      }
    })

  }
}


@Component({
  selector: 'asset-vehicle-tasks-dialog',
  templateUrl: 'asset-vehicle-tasks-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [    AgGridAngular,CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})
export class VehicleTasksDialog implements OnInit {
  public rowData: any[] | null = null;
  

  columnDefs = [

    { headerName: 'Asset Name', field: 'assetName', flex: 1 },
    { headerName: 'Asset Status', field: 'assetStatus', flex: 1 },
    { headerName: 'Task', field: 'task', flex: 1 },
    { headerName: 'Note', field: 'note', flex: 1 },
    { headerName: 'Current Status', field: 'assetCurrentStatus', flex: 1 },
    { headerName: 'Employee Name', field: 'employeeName', flex: 1 },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private assetService: AssetService
  ){

  }
  ngOnInit(): void {
    if(this.data){
      console.log(this.data);
      
      this.fetchvehicletasks(this.data)
    }
  }

  fetchvehicletasks(assetId: number){
    this.assetService.vehicleTaskaLogs(assetId).subscribe({
      next: (logs) =>{
        console.log(logs);
        this.rowData = logs.map((log: any) => {
          return {
            assetName: log?.assetData?.assetName,
            serialNumber: log?.assetData?.serialNumber,
            assetStatus: log?.assetData?.assetStatus,
            task:  log?.task,
            note: log?.note,
            assetCurrentStatus: log?.assetData?.assetCurrentStatus,
            employeeName: log?.employeeData?.firstName
          }
        })
        
      }
    })
  }
}

@Component({
  selector: 'assign-asset-dialog',
  templateUrl: 'assign-assets-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
  ],
})
export class AssignAssetsDialog implements OnInit {
  assignAssetForm: FormGroup;
  allEmployees: any;
  assetsList: any[] = [];
  defaultAssetsList: any[] = [];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private assetService: AssetService,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<AssignAssetsDialog>
  ) {
    console.log(data);
  }

  ngOnInit(): void {
    this.onInitForm();
    this.listAssets();
    this.employeeList();
  }

  onInitForm() {
    this.assignAssetForm = this.fb.group({
      id: [],
      assetId: [this.data.id, Validators.required],
      employeeId: [Validators.required],
    });
  }

  employeeList(search: string = '') {
    this.assetService.listemployees(search).subscribe({
      next: (response) => {
        if (response) {
          this.allEmployees = response;
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    if (!inputValue) {
      this.assetsList = [...this.defaultAssetsList];
    } else {
      this.listAssets(inputValue);
    }
  }

  listAssets(search: string = '') {
    if (!search) {
      if (this.defaultAssetsList.length === 0) {
        this.assetService.assetsDropdown(search).subscribe({
          next: (response) => {
            this.defaultAssetsList = [...response];
            this.assetsList = response;
          },
          error: (error) => {
            console.log(error);
          },
        });
      } else {
        this.assetsList = [...this.defaultAssetsList];
      }
    } else {
      this.assetService.assetsDropdown(search).subscribe({
        next: (response) => {
          this.assetsList = response;
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  onSubmit() {
    // if (this.assignAssetForm.invalid) {
    //   this.assignAssetForm.markAllAsTouched();
    //   this.toaster.warning('Please fill in all required fields.');
    //     return;
    // }
    this.loading = true;
    const formvalues = this.assignAssetForm.value;
    const assetId = formvalues?.assetId;
    const assignedToId = formvalues?.employeeId;
    this.assetService.assignAssetToEmployee(assignedToId, assetId).subscribe({
      next: (response) => {
        this.toaster.success('Asset Assigned.');
        this.dialogRef.close();
        this.loading = false;
        this.assetService.notifyDataUpdated(true);
      },
      error: (err) => {
        this.toaster.error('Cannot assign asset.');
        this.loading = false;
      },
    });
  }
}

@Component({
  selector: 'add-vehicle-fine-dialog',
  templateUrl: 'add-vehicle-fine-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
  ],
})
export class AddVehicleFineDialog implements OnInit {
  allEmployees: any;
  vehicleFineForm: FormGroup;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private assetService: AssetService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<AddVehicleFineDialog>
  ){
    console.log(data);
    
  }
ngOnInit(): void {
  this.onInit();
  this.employeeList();
}

onInit() {
  this.vehicleFineForm = this.fb.group({
    id: [],
    assetId: [this.data.id, Validators.required],
    amount: [null,[Validators.required, Validators.min(1)]],
    employeeId: [this.data?.assigneeId],
    reason:[],
    date: []
  });
}

employeeList() {
  const status =1
  const search= 'driver'
  this.assetService.listAssignees(search,status).subscribe({
    next: (response) => {
      if (response) {
        this.allEmployees =  response.data.designations[0]?.employees || [];
      }
    },
    error: (error) => {
      console.log(error);
    },
  });
}

onSubmit(){

  if (this.vehicleFineForm.invalid) {
    this.vehicleFineForm.markAllAsTouched();
    this.toaster.warning('Please fill in all required fields.');
      return;
  }
  this.loading =true
 const formValues = this.vehicleFineForm?.value;
 const createVehicleFineInput = {
  vehicleId : this.data?.id,
  reason: formValues?.reason,
  date: this.formatToLocalDate(formValues?.date),
  employeeId: formValues.employeeId ,
  amount: formValues?.amount,
  status: 1

 }
 this.assetService.addFineToVehicle(createVehicleFineInput).subscribe({
  next: (response) => {
    if(response) {
      this.toaster.success('Fine added to vehicle.');
      this.dialogRef.close();
      this.assetService.notifyDataUpdated(true);
      this.loading = false;
    }
  },
  error: (err ) =>{
    this.toaster.error('Cannot add fine.');
    this.loading = false;
  }
 });

 
}

private formatToLocalDate(date: any): string {
  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
  const day = String(localDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // Return in 'YYYY-MM-DD' format
}

}



@Component({
  selector: 'asset-vehicle-fine-dialog',
  templateUrl: 'asset-vehicle-fine-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [    AgGridAngular,CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})
export class VehicleFineDialog implements OnInit {
  public rowData: any[] | null = null;
  

  columnDefs = [

    { headerName: 'Employee Name', field: 'employeeName', flex: 1 },
    { headerName: 'Amount', field: 'amount', flex: 1 },
    { headerName: 'Reason', field: 'reason', flex: 1 },
    { headerName: 'date', field: 'date', flex: 1 },

  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private assetService: AssetService,
    private datePipe: DatePipe,
  ){

  }
  ngOnInit(): void {
    if(this.data){
      console.log(this.data);
      const id = this.data?.id;
      this.listVehicleFine(id,'');

  }
}


listVehicleFine(vehicleId: number, search: string = ''){
  this.assetService.listFine(vehicleId, search).subscribe({
    next: (tasks) => {
      this.rowData = tasks.map((task: any) =>{
        return {
          employeeName: task?.employeeData?.firstName ?task?.employeeData?.firstName : "Munawel",
          amount: task?.amount,
          reason: task?.reason,
          date:  this.datePipe.transform(task?.date, 'dd/MM/yyyy') ||     '--',
        }
      })
    }
  })

}
}