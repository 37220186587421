import { CommonModule, DatePipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatInputModule } from "@angular/material/input";
import {  MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BankingService } from "../../features/banking/services/banking.service";
import { ToastrService } from "ngx-toastr";
import { CreateTransactionDialog } from "./create-transaction-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-uncategorized-transaction',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    // AgGridAngular,
  ],
  templateUrl: './uncategorized-transaction.component.html',
  styleUrl: './banking-details.component.scss'
})

export class UncategorizedComponent {
  statementAmount: any;
  showUncategorize = false;
  matchedData: any;
  categorizeForm: FormGroup;
  statementId: any;
  manualAmount: any;
  totalAmount: number;
  transactionIds: any[];
  pendingAmount: number;
  isCreate: boolean = false;
  statement: any;
  isSelectedAll: boolean = false;
  readonly dialog = inject(MatDialog);
  bankId: any;
  flow: any;
  categorySelection: any;
  categories: any;
  reconcilationIds: any;

  constructor(
    private bankingService: BankingService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ){}

  ngOnInit(){
    this.transactionOfChartOfAccount();
    this.fetchTrasactionCategories();
    this.initForm();
  }

  initForm(){
    
    this.categorizeForm = this.fb.group({
      category: ['', Validators.required],
      date: ['', Validators.required],
      reference: [''],
      description: [''],
    })
  }

  toggleCategorize(event?: MouseEvent, statement?: any) {
    if (event) {
      event.stopPropagation();
    }
    if (statement?.id) {
      this.statementId = statement?.id;
      this.bankId = statement?.offsetAccountId;
      this.statement = statement;
      this.manualAmount = statement?.amount
      this.totalAmount = 0;
      this.transactionIds = [];
      this.pendingAmount = 0;
      this.isCreate= false;
      if (this.statement?.flow === 'Credit') {
        this.pendingAmount += this.statement?.amount || 0;
      } else if (this.statement?.flow === 'Debit') {
        this.pendingAmount -= this.statement?.amount || 0;
      }
      this.listMatchedPaymentsForTransaction(statement?.id);
    }
    this.showUncategorize = !this.showUncategorize;
  }

  listMatchedPaymentsForTransaction(id: any) {
    this.categorizeForm.reset();
    this.bankingService.listMatchedPaymentsForTransaction(Number(id)).subscribe({
      next: (data) => {
        this.matchedData = data;
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

   // on best match
   onBestMatch(id: any) {
    const statusType = 'match';
    this.transactionIds.push(id);
    const amount = Number();
    const createTransactionInput = {
      transactionStatus: 'Matched',
      flow: '',
      // offsetAccountId: this.bankId,
      amount: amount,
    }
    this.bankingService.matchOrUnmatchStatementToTransactions(
      statusType,
      this.reconcilationIds,
      this.statementId,
      this.transactionIds,
      createTransactionInput
    ).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Transaction matched successfully');
          this.showUncategorize = !this.showUncategorize;
          this.transactionOfChartOfAccount();
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  transactionOfChartOfAccount() {
    this.bankingService.transactions('Uncategorized').subscribe({
      next: (data) => {
        // this.transactionData = data;
        // this.statements = data?.statements;
        this.statementAmount = data
        // this.statementAmount = data.map((statement: any) => ({
        //   ...statement,
        // }));

        console.log('this.statementAmount', this.statementAmount);
        

      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  selectAll() {
    if (this.isSelectedAll) {
      // Deselect all
      this.transactionIds = [];
      this.totalAmount = 0; // Reset the total amount
      this.isCreate = false;
      this.pendingAmount = this.totalAmount - this.statement?.amount;
    } else {
      // Select all
      this.transactionIds = this.matchedData?.possibleMatches?.map((data: any) => data.id) || [];
      this.totalAmount = this.matchedData?.possibleMatches?.reduce((sum: number, data: any) => sum + (data.amount || 0), 0) || 0;
      this.isCreate = true;
      this.pendingAmount = this.totalAmount - this.statement?.amount
    }
    this.isSelectedAll = !this.isSelectedAll;
  }

  toggleTransaction(data: any, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.totalAmount=0;
      
      if (data.flow === 'Credit') {
        this.totalAmount += data.amount || 0;
      } else if (data.flow === 'Debit') {
        this.totalAmount -= data.amount || 0;
      }
      this.transactionIds.push(data.id);
      console.log('pendingAmount', this.pendingAmount);
      console.log('totalAmount', this.totalAmount);
      console.log('statement?.amount', this.statement?.amount);
      
      this.pendingAmount = (this.pendingAmount - this.totalAmount)
      // this.isCreate = this.totalAmount !== this.statement?.amount && this.totalAmount !== 0;
      this.isCreate = true;

    } else {
      this.transactionIds = this.transactionIds.filter((id) => id !== data.id);
      this.totalAmount=0;
      if (data.flow === 'Credit') {
        this.totalAmount += data.amount || 0;
      } else if (data.flow === 'Debit') {
        this.totalAmount -= data.amount || 0;
      }
      this.pendingAmount = (this.pendingAmount + this.totalAmount)
      // this.isCreate = this.totalAmount !== this.statement?.amount && this.totalAmount !== 0;
    }
  }

  onCreate() {
    let applyTo = '';

    if (this.pendingAmount > 0) {
      applyTo = 'Deposits';

    } else if (this.pendingAmount < 0) {
      applyTo = 'Withdrawals';
    }

    const dialogRef = this.dialog.open(CreateTransactionDialog, {
      disableClose: true,
      data: {
        applyTo: applyTo,
        bankId: this.bankId,
        pendingAmount: Number(this.pendingAmount),
        statementDate: this.statement?.date
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.listMatchedPaymentsForTransaction(this.statement?.id);
    });
  }

   // on possible match
   onMatch() {
    console.log('totalAmount', this.totalAmount);
    console.log('statementamount', this.statement?.amount);

    if (this.pendingAmount === 0 && this.transactionIds?.length > 0) {
      const transactionIds = [...this.transactionIds];
      const statusType = 'match';
      const amount = Number();
      const createTransactionInput = {
        transactionStatus: 'Matched',
        flow: '',
        offsetAccountId: this.bankId,
        amount: amount,
      }
      this.bankingService.matchOrUnmatchStatementToTransactions(
        statusType,
        [],
        this.statementId,
        transactionIds,
        createTransactionInput
      ).subscribe({
        next: (data) => {
          if (data) {
            this.toastr.success('Transaction matched successfully', 'Success')
            this.showUncategorize = !this.showUncategorize;
            this.transactionOfChartOfAccount();
          }
        },
        error: (error) => {
          this.toastr.error(error?.message, 'Error')
        }
      })
    } else if (this.totalAmount === 0) {
      this.toastr.warning('Please select any transaction to match', 'Warning')
    } else {
      this.toastr.warning('The total sum of the matched transactions is not equal to the amount in the statement. Create an adjustment to match the pending amount', 'Balance mismatch!')
    }
  }

   // categorize manually
   onCategorizeManually() {
    if (this.categorizeForm.invalid) {
      this.categorizeForm.markAllAsTouched();
      return;
    }
    const statusType = 'categorize';
    const transactionIds = null;
    const reconcilationIds = null;
    const createTransactionInput = {
      transactionStatus: 'Categorized',
      flow: this.flow,
      offsetAccountId: this.bankId,
      amount: this.statement?.amount,
      transactionCategoryId: this.categorizeForm.value.category?.id,
      date: this.datePipe.transform(this.categorizeForm.value.date, 'yyyy-MM-dd'),
      referenceNumber: this.categorizeForm.value.reference,
      description: this.categorizeForm.value.description,
    }

    this.bankingService.uncategorizeTransactions(
      statusType,
      this.statement?.id,
      createTransactionInput,
      transactionIds,
      reconcilationIds
    ).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Transaction categorized successfully', 'Success');
          this.showUncategorize = !this.showUncategorize;
          this.transactionOfChartOfAccount();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  selectionChangeCategory(event: any) {
    const selectedTransaction = event.value;
    const flow = selectedTransaction.flow;
    this.categorySelection = selectedTransaction.id;
    console.log('Selected category status:', flow);
    if (flow === 'Out') {
      this.flow = 'Debit'
    } else if (flow === 'In') {
      this.flow = 'Credit'
    }
  }

  fetchTrasactionCategories() {
    this.bankingService.transactionCategories().subscribe({
      next: (data) => {
        if (data) {
          this.categories = data;
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }
}