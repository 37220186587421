// login.component.ts
import { Component, OnInit, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/services/auth.service';
import { CommonModule } from '@angular/common';
import { customEmailValidator } from '../../shared/services/validations';
import { EmployeeService } from '../../features/employees/services/employee.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading: boolean = false;
  hide = signal(true);
  branchData: any[];

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    private employeeService: EmployeeService,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, customEmailValidator()]],
      password: ['', Validators.required],
      rememberMe: [false],
    });
  }

  ngOnInit(): void {
    this.rememberMeCredentials();
    this.autoLogin();
    this.setupLogoutListener();
  }

  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      const { email, password, rememberMe } = this.loginForm.value;

      this.authService.login(email, password).subscribe({
        next: (response) => {
          if (response.access_token) {
            this.loadBranches(response);
            if (rememberMe) {
              localStorage.setItem('AUTH_TOKEN', response.access_token);
              localStorage.setItem('email', email);
              localStorage.setItem('rememberMe', 'true');
            } else {
              sessionStorage.setItem('AUTH_TOKEN', response.access_token);
              localStorage.removeItem('email');
              localStorage.removeItem('rememberMe');
            }

            // this.toastr.success(response.message || 'Login Successful');
            // this.router.navigate(['/dashboard']);
          }
          this.loading = false;
        },
        error: (error) => {
          const statusCode = error.status;

          if (statusCode === 400) {
            console.log("Error:", error);

            this.toastr.error(
              'Incorrect email or password',
              'Authentication Failed'
            );
          } else {
            this.toastr.error(
              'Incorrect email or password',
              'Authentication Failed'
            );
          }

          this.loading = false;
        },
      });
    }
  }

  private loadBranches(response: any) {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
        if (this.branchData) {
          localStorage.setItem('selectedBranchId', this.branchData[0]?.id);
        }
        this.router.navigate(['/dashboard']);
        this.toastr.success(response.message || 'Login Successful');
      },
      error: (error) => console.error(error),
    });
  }

  logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('loggedInUser');
    sessionStorage.removeItem('AUTH_TOKEN');
    localStorage.setItem('logout', 'true');
  }

  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  private rememberMeCredentials() {
    const rememberMe = localStorage.getItem('rememberMe');
    const email = localStorage.getItem('email');
    if (rememberMe === 'true' && email) {
      this.loginForm.patchValue({ email, rememberMe: true });
    } else {
      this.loginForm.patchValue({ rememberMe: false });
    }
  }

  private autoLogin() {
    const token =
      localStorage.getItem('AUTH_TOKEN') ||
      sessionStorage.getItem('AUTH_TOKEN');
    if (token) {
      this.router.navigate(['/dashboard']);
    }
  }

  private setupLogoutListener() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout') {
        this.router.navigate(['/login']);
      }
    });
  }
}
