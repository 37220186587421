<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <form  [formGroup]="assetTransferForm" (ngSubmit)="onSubmit()" >
        <h2 class="cmn-popup-title">{{ isEdit ? 'Edit Asset Transfer' : 'Asset Transfer'}}</h2>
        <button class="cmn-close" mat-dialog-close></button>


        <!-- <ul>
            <li>Power Drill</li>
            <li>123456789</li>
            <li>Munawel Interior Decors</li>
        </ul> -->

        <div class="form-wrapper grid-form-wrapper">




            <!-- <div class="span-2">
                <mat-form-field>
                    <textarea matInput placeholder="Enter item description"></textarea>
                </mat-form-field>
            </div> -->


            <div class="cmn-Search-select-container">

                <mat-form-field>
                    <mat-select placeholder="Search Asset" #singleSelect formControlName="assetId">
                        <mat-option>
                            <ngx-mat-select-search (input)="onSearchInput($event)"
                            [placeholderLabel]="'Search'"
                            [noEntriesFoundLabel]="'No data found'"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let asset of assetsList" [value]="asset.id">
                             {{ asset?.assetName }}
                        </mat-option>
                    </mat-select>
                    <div class="custom-error">
                        <span>
                          <mat-error *ngIf="
                          assetTransferForm.get('assetId')!.touched &&
                          assetTransferForm.get('assetId')!.hasError('required')
                          ">*Asset  is required.</mat-error>
                        </span>
                      </div>
                </mat-form-field>


            </div>


            <div class="">
                <mat-form-field>
                    <mat-select placeholder="Select from branch" formControlName="fromBranch">
                        <mat-option *ngFor="let branch of allBranches"  [value]="branch.id">
                            {{ branch.branchName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                      <mat-error *ngIf="
                      assetTransferForm.get('fromBranch')!.touched &&
                      assetTransferForm.get('fromBranch')!.hasError('required')
                      ">*From Branch is required.</mat-error>
                    </span>
                  </div>
            </div>

            <div class="">
                <mat-form-field>
                    <mat-select placeholder="Select to branch" formControlName="toBranch">
                        <mat-option *ngFor="let branch of allBranches"  [value]="branch.id">
                            {{ branch.branchName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                      <mat-error *ngIf="
                      assetTransferForm.get('toBranch')!.touched &&
                      assetTransferForm.get('toBranch')!.hasError('required')
                      ">*To Branch is required.</mat-error>
                    </span>
                  </div>
            </div>

            <div class="form-wrapper">
                <mat-form-field>
                    <input matInput [matDatepicker]="transferDate" placeholder="Select transfer date" formControlName="transferDate" />
                    <mat-datepicker-toggle matIconSuffix [for]="transferDate"></mat-datepicker-toggle>
                    <mat-datepicker #transferDate></mat-datepicker>
                  </mat-form-field>
                  <div class="custom-error">
                    <span>
                      <mat-error *ngIf="
                      assetTransferForm.get('transferDate')!.touched &&
                      assetTransferForm.get('transferDate')!.hasError('required')
                      ">*Date is required.</mat-error>
                    </span>
                  </div>
            </div>


        </div>

        <!-- <div class="selected-profile-wrapper">
            <div>
                <div class="profile-image">
                    <img src="../../../assets/images/profile.png">
                </div>

                <span>rickson wilson</span>
                <div class="close-btn"><img src="../../../assets/images/icons/icon-close.svg" alt=""></div>
            </div>
        </div> -->


        <div class="btn-wrapper">

            <button class="cmn-next-btn" [disabled]="loading" >
                <span *ngIf="!loading">Transfer Asset</span>
                <span *ngIf="loading">Loading...</span>
              </button>

        </div>
    </form>
    </section>
</mat-dialog-content>