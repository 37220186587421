import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from './notification.action';
import { Notification } from './notification.model';

export interface NotificationState {
  notifications: Notification[];
}

export const initialState: NotificationState = {
  notifications: [],
};

export const notificationReducer = createReducer(
  initialState,
  on(NotificationActions.loadNotifications, (state, { notifications }) => ({
    ...state,
    notifications,
  })),
  on(NotificationActions.addNotification, (state, { notification }) => ({
    ...state,
    notifications: [notification, ...state.notifications],
  })),
  on(NotificationActions.clearNotifications, () => ({
    ...initialState,
  })),
  on(NotificationActions.markNotificationAsRead, (state, { notificationId }) => ({
    ...state,
    notifications: state.notifications.map((notification) =>
      notification.id === notificationId ? { ...notification, readAt: new Date().toISOString() } : notification
    ),
  }))
);
