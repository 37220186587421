import { gql } from 'apollo-angular';

export const GET_INVOICES = gql`
  query invoices($search: String) {
    invoices(search: $search) {
      id
      projectModelId
      quoteId
      journalId
      journalData {
        id
        date
        journalNo
        reference
        notes
        currencyId
        inCash

        journalEnteries {
          id
          chartOfAccountId
          ChartOfAccount {
            id
            accountName
          }
          journalId
          partnerId
          description
          debit
          credit
        }
      }
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      invoiceUrl
      tax
      customerNote
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      invoiceTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
      paymentInfos {
        id
        paymentId
        refType
        refId
        amount
        createdAt
        updatedAt
      }
      invoiceAttachments {
        id
        invoiceId
        fileUrl
        fileName
        createdAt
        updatedAt
        deletedAt
      }
      bankId
      bankData {
        id
        bankType
        accountName
        accountCode
        accountNumber
        bankName
        routingNumber
        description
        isPrimary
        currencyId
        createdAt
        updatedAt
        deletedAt
        status
      }
      invoiceItems {
        id
        invoiceId
        itemId
        itemData {
          id
          itemType
          skuCode
          imageUrl
          isSalesActive
          isPurchaseActive
          isDisplay
          quantity
          unit
          itemName
          sellingPrice
          salesAccount
          salesDescription
          status
          notes
          costPrice
          purchaseAccount
          purchaseDescription
        }
        description
        quantity
        vat
        rate
        unit
        discount
        amount
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjectDropDownItems {
    getProjectDropDownItems {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      branchId
    }
  }
`;

export const GET_ACCOUNTS_DEPOSIT = gql`
  query getChartOfAccountDropDownItems($search: String) {
    getChartOfAccountDropDownItems(search: $search) {
      id
      accountType
      accountName
      accountCode
      description
      amount
      parentAccountId
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_BANKS = gql`
  query getBanksDropdown($search: String) {
    getBanksDropdown(search: $search) {
      id
      bankType
      accountName
      accountCode
      accountNumber
      bankName
      routingNumber
      description
      isPrimary
      currencyId
      createdAt
      updatedAt
      deletedAt
      status
    }
  }
`;

export const GET_CLIENTS = gql`
  query getPartnersDropDownItems(
    $search: String
    $partnerType: PartnerTypesEnum
  ) {
    getPartnersDropDownItems(search: $search, partnerType: $partnerType) {
      id
      partnerType
      customerType
      displayName
      companyName
      paymentTerm
      email
      phone
      mobile
      currency
      emirateID
      trnNumber
      openingBalance
      paymentStatus
      status
      primaryContactId
      projects {
        id
        projectCode
        projectName
        totalProjectCost
      }
      partnerProjects {
        id
        projectCode
        projectName
        totalProjectCost
        description
      }
      primaryContact {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      partnerProjects {
        id
        projectCode
        projectName
        totalProjectCost
        description
        __typename
      }
      contacts {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      addresses {
        id
        addressType
        addressLine1
        addressLine2
        attention
        city
        country
        fax
        state
        phone
      }
    }
  }
`;

export const GET_WORK_ORDER = gql`
  query getWorkOrderByPartnerAndProject($partnerId: Int!, $projectId: Int!) {
    getWorkOrderByPartnerAndProject(
      partnerId: $partnerId
      projectId: $projectId
    ) {
      id
      workOrderStatus
      workOrderNo
      partnerId
      quoteId
      addressId
      projectId
      projectManagerId
      SOW
    }
  }
`;

export const GET_INVOICE_INCREMENT_BY_QUOTE_CODE = gql`
  query getInvoiceIncrementByQuoteCode($quoteCode: String!) {
    getInvoiceIncrementByQuoteCode(quoteCode: $quoteCode)
  }
`;

export const GET_QUOTE_BY_WORK_ORDER = gql`
  query getQuoteByWorkOrder($workOrderId: Int!) {
    getQuoteByWorkOrder(workOrderId: $workOrderId) {
      id
      workOrderStatus
      workOrderNo
      partnerId
      quoteId
      quoteData {
        id
        parentQuoteId
        quoteCode
        partnerId
        TRN
        SOW
        quoteDate
        expiryDate
        approvalDate
        branchId
        newProjectName
        quoteTitle
        subject
        subTotal
        tax
        adjustment
        totalAmount
        paymentTerm
      }
      projectId
      projectData {
        id
        projectCode
        projectName
      }
      SOW
      subTotal
      tax
      totalAmount
      clientRef
      commencementDate
      completionDate
      duration
      status
      startDate
      createdAt
      updatedAt
      branchId
      branchDetails {
        id
        branchName
      }
    }
  }
`;

export const GET_WORK_ORDER_BY_PARTNER = gql`
  query getWorkOrderByPartner($partnerId: Int!, $search: String) {
    getWorkOrderByPartner(partnerId: $partnerId, search: $search) {
      id
      workOrderStatus
      workOrderNo
      partnerId
      SOW
      subTotal
      tax
      totalAmount
      clientRef
      commencementDate
      completionDate
      duration
      status
      startDate
      createdAt
      updatedAt
      branchId
    }
  }
`;

export const GET_INVOICE_BY_ID = gql`
  query invoice($id: Int!) {
    invoice(id: $id) {
      id
      projectModelId
      projectData {
        id
        projectCode
        projectName
        branchId
      }
      workOrderId
      workOrderData {
        id
        partnerId
        workOrderNo
      }
      quoteId
      quoteData {
        id
        quoteCode
        partnerId
        quoteTitle
        deletedAt
      }
      branchId
      branchData {
        id
        branchName
      }
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        email
        phone
        mobile
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      invoiceNo
      invoiceAttachments {
        id
        invoiceId
        fileUrl
        fileName
        createdAt
        updatedAt
        deletedAt
      }
      invoiceItems {
        id
        invoiceId
        itemId
        itemData {
          id
          itemType
          skuCode
          imageUrl
          isSalesActive
          isPurchaseActive
          isDisplay
          quantity
          unit
          itemName
          sellingPrice
          salesAccount
          salesDescription
          status
          notes
          costPrice
          purchaseAccount
          purchaseDescription
        }
        description
        quantity
        vat
        rate
        unit
        discount
        amount
        createdAt
        updatedAt
        deletedAt
      }
      journalData {
        id
        date
        journalNo
        reference
        notes
        currencyId
        inCash
        journalEnteries {
          id
          chartOfAccountId
          ChartOfAccount {
            id
            accountName
          }
          journalId
          partnerId
          description
          debit
          credit
        }
      }
      bankId
      bankData {
        id
        bankType
        accountName
        accountCode
        accountNumber
        bankName
        routingNumber
        description
        isPrimary
        currencyId
        createdAt
        updatedAt
        deletedAt
        status
      }
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      invoiceUrl
      tax
      customerNote
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_INVOICE_DASHBOARD = gql`
  query getInvoicesDashboard {
    getInvoicesDashboard {
      totalOutstandingReceivables
      dueToday
      dueWithin30Days
      overdueInvoice
    }
  }
`;
export const GENERATE_INVOICE_PDF = gql`
  query generateInvoicePdf($invoiceId: Int!, $employeeId: Int!) {
    generateInvoicePdf(invoiceId: $invoiceId, employeeId: $employeeId)
  }
`;
export const GET_INVOICE_DETAILS = gql`
  query getInvoiceDetails($id: Int!) {
    getInvoiceDetails(id: $id) {
      id
      projectModelId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        contacts {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
        primaryContact {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
      }
      billingAddressId
      shippingAddressId
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      invoiceUrl
      invoiceTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
    }
  }
`;

export const MARK_AS_SENT = gql`
  mutation updateInvoiceToMarkAsSent($id: Int!, $employeeId: Int) {
    updateInvoiceToMarkAsSent(id: $id, employeeId: $employeeId) {
      id
    }
  }
`;

export const GET_NOTES_BY_CUSTOMER_OR_VENDOR = gql`
  query getNotesByCustomerOrVendor($partnerId: Int!, $partnerType: String!) {
    getNotesByCustomerOrVendor(
      partnerId: $partnerId
      partnerType: $partnerType
    ) {
      id
      partnerId
      noteType
      noteNo
      noteDate
      subject
      subTotal
      tax
      adjustments
      totalPrice
      balance
      noteUrl
      noteStatus
      status
      customerNotes
      termsAndConditions
      salesPerson
      referenceId
      createdAt
      updatedAt
      shippingAddressId
      billingAddressId
      branchId
      journalId
      invoiceNo
      purchaseOrderNo
    }
  }
`;
