import {CommonModule} from "@angular/common";
import {Component, Inject, inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {CustomerService} from "../../../../features/customers/services/customer.service";
import {BillingAddressDialog} from "../add-billing-address-dialog/billing-address-dialog.component";

@Component({
    selector: 'select-address-dialog',
    templateUrl: 'list-billing-address-dialog.component.html',
    styleUrls: ['../../invoice-new.component.scss'],
    standalone: true,
    imports: [MatDialogModule, CommonModule]
})
export class ListBillingAddressDialog {
    billingAddressList : any[];
    readonly dialog = inject(MatDialog);
    selectedAddressId : number | null = null;

    constructor(private customerService : CustomerService, private dialogRef : MatDialogRef < ListBillingAddressDialog >, @Inject(MAT_DIALOG_DATA)public data : {
        selectedCustomerId: any;
        type: any
    }) {}

    ngOnInit(): void {
        this.fetchPartners();
    }

    // Set the selected address ID
    selectAddress(address : any): void {
        this.selectedAddressId = address.id;
        this.dialogRef.close(address);
    }

    openBillingAddressDialog(type : any, id: any = null): void {
        this.dialogRef.close();

        // Open the new ShippingAddressDialog
        this.dialog.open(BillingAddressDialog, {
            width: '400px',
            data: {
                clientId: this.data ?. selectedCustomerId,
                type,
                addressId: id
            }
        });
    }

    private fetchPartners() {
        const id = this.data ?. selectedCustomerId;
        console.log("listing com- selected customer-id", this.data ?. selectedCustomerId);
        
        const type = this.data ?. type;

        this.customerService.fetchPartnerById(id).subscribe({
            next: (partners) => { // this.billingAddressList = partners?.addresses;
                this.billingAddressList = partners ?. addresses ?. filter((address : any) => address.addressType == type);
            },
            error: (error) => console.error(error)
        });
    }
}
