import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
  FETCH_QUOTE_BY_ID,
  FETCH_QUOTE_WORK_SPLIT_BY_ID,
  GENERATE_QUOTATION_PDF,
  GET_PARENT_QUOTE_BY_ID,
  GET_PARENT_QUOTES,
  GET_QUOTE_SUMMARY,
  GET_QUOTES,
  LIST_PROJECTS,
} from '../graphql/quotation.queries';
import {
  CREATE_QUOTATION,
  CREATE_QUOTE_PROJECT,
  CREATE_QUOTE_WORK_SPLIT,
  GENERATE_QUOTE_PDF,
  QUOTE_MARK_AS_SENT,
  REMOVE_PARENT_QUOTE,
  REMOVE_QUOTE,
  REMOVE_QUOTE_ATTACHMENT_BY_ID,
  REMOVE_QUOTE_EXCLUSION,
  REMOVE_QUOTE_ITEM_BY_ID,
  REVISE_QUOTATION,
  REVISION_QUOTATION,
  SAVE_WORK_ORDER,
  UPDATE_QUOTATION,
  UPDATE_QUOTE_WORK_SPLIT,
} from '../graphql/quotation.mutation';

@Injectable({ providedIn: 'root' })
export class QuotationService {
  constructor(private apollo: Apollo) { }

  // fetch all quotations
  fetchQuotations(filter: {}): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_QUOTES,
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data.quotes));
  }

  //fetch parent quotations
  fetchParentQuotes(search: string = '', status: string = ''): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_PARENT_QUOTES,
        variables: {
          search,
          status
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data.parentQuotes));
  }

  //fetch parent quote by id
  fetchParentQuoteById(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_PARENT_QUOTE_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.parentQuote));
  }

  //create quote work split
  createQuotesWorkSplit(createQuotesWorkInputs: any[]): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_QUOTE_WORK_SPLIT,
      variables: {
        createQuotesWorkInputs: createQuotesWorkInputs,
      },
    });
  }

  //update quote work split
  updateQuoteWorkSplit(updateQuotesWorkInputs: any[]): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: UPDATE_QUOTE_WORK_SPLIT,
      variables: {
        updateQuotesWorkInputs: updateQuotesWorkInputs,
      },
    });
  }

  // createQuote
  createQuote(
    createQuoteInput: any,
    quoteItemsInput: any[],
    quoteExclusionInput: any[],
    quoteAttachmentsInput: any[]
  ): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_QUOTATION,
      variables: {
        createQuoteInput: createQuoteInput,
        quoteItemsInput: quoteItemsInput,
        quoteExclusionInput: quoteExclusionInput,
        quoteAttachmentsInput: quoteAttachmentsInput,
      },
    });
  }

  //remove quote exclusion
  removeQuoteExclusion(id: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_QUOTE_EXCLUSION,
      variables: {
        id,
      },
    });
  }

  //update quotation
  updateQuotation(updateQuoteInput: any, quoteItemsInput: any, quoteAttachmentsInput: any, quoteExclusionInput: any): Observable<any> {
    return this.apollo.use('project').mutate(
      {
        mutation: UPDATE_QUOTATION,
        variables: {
          updateQuoteInput: updateQuoteInput,
          quoteItemsInput: quoteItemsInput,
          quoteAttachmentsInput: quoteAttachmentsInput,
          quoteExclusionInput: quoteExclusionInput
        }
      }
    );
  }

  //quote summary
  FetchQuoteSummary(): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_QUOTE_SUMMARY,
        variables: {},
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data.quoteSummary));
  }

  //quote revision
  revisionQuote(parentQuoteId: number, quoteId: number): Observable<any> {
    return this.apollo.use('project').mutate(
      {
        mutation: REVISION_QUOTATION,
        variables: {
          parentQuoteId: parentQuoteId,
          quoteId: quoteId
        }
      }
    );
  }

  //quote variation
  reviseQuoteVariation(parentQuoteId: number, quoteId: number): Observable<any> {
    return this.apollo.use('project').mutate(
      {
        mutation: REVISE_QUOTATION,
        variables: {
          parentQuoteId: parentQuoteId,
          quoteId: quoteId
        }
      }
    );
  }

  //list projetcs
  listProjects(search: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: LIST_PROJECTS,
        variables: {
          search,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data.listProjects));
  }

  removeQuoteItemById(id: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_QUOTE_ITEM_BY_ID,
      variables: {
        id,
      },
    });
  }

  // remove invoice attachment
  removeQuoteAttachmentById(id: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_QUOTE_ATTACHMENT_BY_ID,
      variables: {
        id,
      },
    });
  }

  // fetch work split by id
  fetchWorkSplitById(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: FETCH_QUOTE_WORK_SPLIT_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.findOneQuotesWork));
  }

  //fetch quotation by id
  fetchQuoteById(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: FETCH_QUOTE_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.quote));
  }

  //generate quote pdf
  generateQuotePDF(quoteId: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: GENERATE_QUOTE_PDF,
      variables: {
        quoteId,
      },
    });
  }

  //save work order
  saveWorkOrder(quoteId: number): Observable<any> {
    const saveWorkOrder = {
      quoteId: quoteId,
    };
    return this.apollo.use('project').mutate({
      mutation: SAVE_WORK_ORDER,
      variables: {
        saveWorkOrder,
      },
    });
  }

  //save work order
  createQuoteProject(id: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_QUOTE_PROJECT,
      variables: {
        id: id,
      },
    });
  }

  //remove parent quote
  removeParentQuote(id: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_PARENT_QUOTE,
      variables: {
        id,
      },
    });
  }

  //remove quote
  removeQuote(id: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_QUOTE,
      variables: {
        id,
      },
    });
  }

  //quote mark as sent
  quoteMarkAsSent(quoteId: number, parentQuoteId: number, quoteStatus: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: QUOTE_MARK_AS_SENT,
      variables: {
        quoteId: quoteId,
        parentQuoteId: parentQuoteId,
        quoteStatus: quoteStatus,
      },
    });
  }

  generateQuotationPdf(quoteId: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GENERATE_QUOTATION_PDF,
        variables: {
          quoteId: quoteId,
        },
      })
      .valueChanges.pipe(map((response) => response.data?.getQuotePDF));
  }
}
