import { gql } from "apollo-angular";

export const LIST_ACCOUNTS_CASH_AND_BANK = gql`
  query listAllChartOfAccountsOfBankAndCash($search: String ) {
    listAllChartOfAccountsOfBankAndCash(search: $search) {
    results {
      chartOfAccount{
        id
        accountType
        accountName
      }
      uncategorized
      pending
      categorized
      amount
    }
    bankNetValue
    cashNetValue
    totalUncategorizedCount
  }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query transactions($status: TransactionStatus) {
    transactions(status: $status) {
    id
    date
    referenceNumber
    transactionStatus
    amount
    flow
    description
    paidVia
    createdAt
    updatedAt
    deletedAt
    refId
    refTableType
    status
    offsetAccountId
    offsetAccount {
      id
      accountType
      accountName
      accountCode
      description
      amount
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
    }
    transactionCategoryId
  }
  }
`;

export const LIST_MATCHED_PAYMENTS = gql`
  query listMatchedPaymentsForTransaction($statementId: Int! ) {
    listMatchedPaymentsForTransaction(statementId: $statementId)  {
    bestMatches {
      id
      date
      referenceNumber
      transactionStatus
      amount
      flow
      description
      paidVia
      createdAt
      updatedAt
      deletedAt
      status
    }
    possibleMatches {
      id
      date
      referenceNumber
      transactionStatus
      amount
      flow
      description
      paidVia
      createdAt
      updatedAt
      deletedAt
      status
      offsetAccountId
    }
  }
  }
`;

export const TRANSACTION_CATEGORIES = gql`
  query transactionCategories {
    transactionCategories{
    id
    categoryName
    categoryStatus
    createdAt
    updatedAt
    deletedAt
    status
    transactions {
      id
      date
      referenceNumber
      transactionStatus
      amount
      flow
      description
      paidVia
      createdAt
      updatedAt
      deletedAt
      status
      offsetAccountId
      # offsetAccount
      transactionCategoryId
      # transactionCategory
      # transactionReconcilations
    }
  }
  }
`;

export const GET_TRANSACTION = gql`
  query transaction($id: Int!) {
    transaction(id: $id){
    id
    date
    referenceNumber
    transactionStatus
    amount
    flow
    description
    paidVia
    offsetAccountId
    offsetAccount {
      id
      accountType
      accountName
    }
    transactionCategoryId
    transactionCategory {
      id
      categoryName
      categoryStatus
      status
    }
    transactionReconcilations {
      id
      statementId
      status
    }
  }
  }
`;

export const GET_BANK_BY_ID = gql`
  query bank($id: Int!) {
    bank(id: $id){
    id
    bankType
    accountName
    accountCode
    accountNumber
    bankName
    routingNumber
    swiftCode
    currencyId
    description
    createdAt
    updatedAt
    deletedAt
    status
  }
  }
`;

export const NET_AMOUNT_CASH_AND_BANK = gql`
  query getNetAmountOfChartOfAccountsOfBankAndCash {
    getNetAmountOfChartOfAccountsOfBankAndCash  {
    bank
    cash
    uncategorizedCount
  }
  }
`;

export const TRANSACTIONS_OF_CHART_ACCOUNTS = gql`
  query findAllTransactionsAndUncategorizedTransactions($offsetAccountId: Int!, $search: String) {
    findAllTransactionsAndUncategorizedTransactions(offsetAccountId: $offsetAccountId, search: $search)  {
    transactions {
      id
      date
      referenceNumber
      transactionStatus
      amount
      flow
      description
      paidVia
      createdAt
      updatedAt
      deletedAt
      status
      offsetAccountId
      offsetAccount{
        accountName
      }
      transactionCategoryId
      transactionCategory{
        categoryName
      }
      # transactionReconcilations
    }
    statements {
      id
      offsetAccountId
      offsetAccount{
        accountName
      }
      date
      amount
      flow
      statementRecordStatus
      referenceNumber
      payee
      description
      createdAt
      updatedAt
      deletedAt
      status
      # statementReconcilations
    }
    netValue
    # offsetAccount{
    #     accountName
    #   }
  }
  }
`;