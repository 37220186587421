<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <form [formGroup] ="assetDisposalForm" (ngSubmit)="onSubmit()">
        <h2 class="cmn-popup-title">Dispose Asset</h2>
        <button class="cmn-close" mat-dialog-close></button>


        <!-- <ul>
            <li>Power Drill</li>
            <li>123456789</li>
            <li>Munawel Interior Decors</li>
        </ul> -->

        <div class="form-wrapper grid-form-wrapper">

            <mat-form-field>
                <mat-select placeholder="Search Asset" #singleSelect formControlName="assetId">
                    <mat-option>
                        <ngx-mat-select-search (input)="onSearchInput($event)"
                        [placeholderLabel]="'Search'"
                        [noEntriesFoundLabel]="'No data found'"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let asset of assetList" [value]="asset.id">
                         {{ asset?.assetName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div>
                <mat-form-field>
                    <mat-select placeholder="disposal method" formControlName="disposalMethodId">
                        <mat-option *ngFor="let method of disposalMethods" [value]="method">
                            {{ method }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="span-2">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker2" formControlName="disposalDate" placeholder="Disposal Date Date" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>

                </mat-form-field>
            </div>

            <div class="span-2">
                <mat-form-field>
                    <textarea matInput placeholder="Enter item description"  formControlName="reason" ></textarea>
                </mat-form-field>
            </div>

        </div>



        <div class="btn-wrapper">

            <button class="cmn-next-btn" [disabled]="loading" >
                <span *ngIf="!loading">Dispose Asset</span>
                <span *ngIf="loading">Loading...</span>
              </button>

        </div>
    </form>
    </section>
</mat-dialog-content>