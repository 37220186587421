import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { filter } from 'rxjs';
import { ProjectService } from '../../../../features/projects/services/projects.service';
import { ActivatedRoute } from '@angular/router';
import { PaginationHelper } from '../../../../shared/services/pagination.service';

@Component({
    selector: 'app-projects',
    standalone: true,
    imports: [
        CommonModule,
        AgGridAngular,
    ],
    templateUrl: './projects.component.html',
    styleUrl: '../../employee-details.component.scss'
})
export class ProjectsComponent implements OnInit {
    public viewPortSize: boolean = false;

    public themeClass: string = 'ag-theme-quartz';
    public loading: boolean = false;
    public rowData: any[] | null = null;
    columnDefs: ({ headerName: string; field: string; flex?: undefined; cellRenderer?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; })[];

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkViewportSize();
    }

    checkViewportSize() {
        if (window.innerWidth > 1200) {
            this.viewPortSize = true;
        } else {
            this.viewPortSize = false;
        }
        this.setAGColumn();
    }

    setAGColumn() {
        this.columnDefs = [
            // { headerName: 'Sl. No.', field: "no", },
            { headerName: 'Project Code', field: "projectCode", flex: this.viewPortSize ? 1 : 0 },
            { headerName: 'Project Name', field: "projectName", flex: this.viewPortSize ? 1 : 0 },
            { headerName: 'Start Date', field: "startDate", flex: this.viewPortSize ? 1 : 0 },
            { headerName: 'End Date', field: "endDate", flex: this.viewPortSize ? 1 : 0 },
            // {
            //     headerName: 'Status',
            //     field: 'status',
            //     flex: this.viewPortSize ? 1 : 0,
            //     cellRenderer: (params: any) => {
            //         const status = params.data.status;
            //         const statusClass = status === "Completed" ? 'completed' : ''; // Apply class if "On Leave"
            //         return `<span class="${statusClass}">${status}</span>`;
            //     }
            // },
        ];
    }

    public defaultColDef: ColDef = {
        filter: "agTextColumnFilter",
        floatingFilter: true,
    };
    public paginationPageSize = 10;
    public paginationPageSizeSelector: number[] | boolean = [10];
    public error: any = null;

    projectData: any = {}

    constructor(
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.checkViewportSize();
        const employeeId = this.route.snapshot.paramMap.get('id');
        console.log("employee id----", employeeId);
        if (employeeId) {
            this.loadEmployeeProjects(+employeeId)
        }
    }

    loadEmployeeProjects(id: number): void {
        this.loading = true;
        this.projectService.listAllProjectsByEmployee(id).subscribe({
            next: (data) => {
                this.projectData = data;
                console.log("Project data----", this.projectData);
                this.rowData = data.map((data: any) => {
                    return {
                        projectCode: data.projectCode,
                        projectName: data.projectName,
                        startDate: this.datePipe.transform(data.startDate, 'dd/MM/yyyy'),
                        endDate: this.datePipe.transform(data.endDate, 'dd/MM/yyyy'),
                    }
                })
                if (this.rowData) {
                    this.paginationPageSizeSelector = PaginationHelper.getPaginationPageSizeOptions(this.rowData);
                } else {
                    this.paginationPageSizeSelector = [];
                }
                this.loading = false;
            },
            error: (err) => {
                console.error('Error fetching projects:', err);
                this.loading = false;
            }
        })
    }

}
