import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { Subscription } from 'rxjs';
import { NotificationServicezz, NewNotificationResponse } from '../../core/services/notification.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Notification } from '../../core/notifications/notification.model';
import { selectNotifications } from '../../core/notifications/notification.selector';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
  ],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  myNotifications$: Observable<Notification[]>;
  notifications: NewNotificationResponse['newNotification'][] = [];
  private subscription: Subscription | null = null;

  constructor(private notificationServicezz: NotificationServicezz, private store: Store) {}

  ngOnInit() {
    // this.subscription = this.notificationService.subscribeToNotifications().subscribe({
    //   next: (result) => {
    //     console.log("result", result);
    //     const data = result.data as NewNotificationResponse; // Explicitly cast result.data
    //     if (data?.newNotification) {
    //       this.notifications.unshift(data.newNotification);
    //     }
    //   },
    //   error: (err) => console.error('Error receiving notifications', err),
    // });

    this.myNotifications$ = this.store.select(selectNotifications);

    // Debug: Log notifications whenever they change
    this.myNotifications$.subscribe((notifications) => {
      // console.log('Notificationsss:', notifications);
    });
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  handleApprove(notification: Notification) {
    console.log('Approve clicked for notification:', notification);
  }
  
  handleReject(notification: Notification) {
    console.log('Reject clicked for notification:', notification);
  }
  
}
