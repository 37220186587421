import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_BANK_BY_ID, GET_TRANSACTION, LIST_ACCOUNTS_CASH_AND_BANK, LIST_MATCHED_PAYMENTS, LIST_TRANSACTIONS, NET_AMOUNT_CASH_AND_BANK, TRANSACTION_CATEGORIES, TRANSACTIONS_OF_CHART_ACCOUNTS } from '../graphql/queries/banking.query';
import { CREATE_BANK, CREATE_TRANSACTION, MATCH_OR_UNMATCH_TRANSACTION, REMOVE_TRANSACTION, UNCATEGORIZE_TRANSACTION, UPDATE_BANK, UPDATE_TRANSACTION } from '../graphql/mutation/banking.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankingService {
  constructor(private apollo: Apollo) { }

  fetchAccountsOfBankAndCash(search: any): Observable<any> {
    console.log('search', search);

    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: LIST_ACCOUNTS_CASH_AND_BANK,
        variables:
        {
          search
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.listAllChartOfAccountsOfBankAndCash || [])
      );
  }

  transactionCategories(): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: TRANSACTION_CATEGORIES,
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.transactionCategories || [])
      );
  }
  
  transactions(status: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: LIST_TRANSACTIONS,
        variables: {
          status
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.transactions || [])
      );
  }

  getTransactionDetails(id: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: GET_TRANSACTION,
        variables: {
          id
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.transaction || [])
      );
  }
  
  fetchBankById(id: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: GET_BANK_BY_ID,
        variables: {
          id
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.bank || [])
      );
  }

  transactionOfChartOfAccount(offsetAccountId: any, search: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: TRANSACTIONS_OF_CHART_ACCOUNTS,
        variables: {
          offsetAccountId,
          search
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((result) => result.data?.findAllTransactionsAndUncategorizedTransactions || [])
      );
  }

  listMatchedPaymentsForTransaction(statementId: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: LIST_MATCHED_PAYMENTS,
        variables: {
          statementId
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.listMatchedPaymentsForTransaction || [])
      );
  }

  getNetAmountOfBankAndCash(): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: NET_AMOUNT_CASH_AND_BANK,
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.getNetAmountOfChartOfAccountsOfBankAndCash || [])
      );
  }

  createBank(createBankInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_BANK,
      variables: {
        createBankInput: createBankInput,
      },
    }).pipe(
      map((result: any) => result.data.createBankInput || [])
    );
  }
  
  updateBank(updateBankInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_BANK,
      variables: {
        updateBankInput: updateBankInput,
      },
    }).pipe(
      map((result: any) => result.data.updateBankInput || [])
    );
  }

  bulkCreateTransactions(file: File, offsetAccountId: any): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN');
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
          mutation bulkCreateStatements($file: Upload!, $offsetAccountId: Int!) {
            bulkCreateStatements(file: $file, offsetAccountId: $offsetAccountId)
          }
          `,
      variables: { file: null, offsetAccountId }
    }));

    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);

    return from(
      fetch(environment.url.accountsUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'authorization': token ? `Bearer ${token}` : '',
          'x-apollo-operation-name': 'uploadFile'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(result => result.data?.bulkCreateTransactions || result)
    );
  }

  createTransaction(createTransactionInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_TRANSACTION,
      variables: {
        createTransactionInput
      },
    }).pipe(
      map((result: any) => result.data.createTransaction || [])
    );
  }

  updateTransaction(updateTransactionInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_TRANSACTION,
      variables: {
        updateTransactionInput
      },
    }).pipe(
      map((result: any) => result.data.updateTransaction || [])
    );
  }

  removeTransaction(id: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_TRANSACTION,
      variables: {
        id
      },
    }).pipe(
      map((result: any) => result.data.removeTransaction || [])
    );
  }

  // categorizeTransactions(statusType: any, statementId: any, createTransactionInput: any): Observable<any> {
  //   return this.apollo.use('accounts').mutate({
  //     mutation: CATEGORIZE_TRANSACTION,
  //     variables: {
  //       statementId,
  //       createTransactionInput
  //     },
  //   }).pipe(
  //     map((result: any) => result.data.categorizeTransactions || [])
  //   );
  // }

  uncategorizeTransactions(statusType: any, statementId: any, createTransactionInput: any, transactionId: any, reconcilationId: any,): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UNCATEGORIZE_TRANSACTION,
      variables: {
        statusType,
        statementId,
        createTransactionInput,
        transactionId,
        reconcilationId
      },
    }).pipe(
      map((result: any) => result.data.categorizeTransactions || [])
    );
  }

  matchOrUnmatchStatementToTransactions(
    statusType: any,
    reconcilationIds: any,
    statementId: any,
    transactionIds: any,
    createTransactionInput: any
  ): Observable<any> {
    const variables: any = {
      statusType,
      statementId,
      ...(reconcilationIds?.length && { reconcilationIds }),
      ...(transactionIds?.length && { transactionIds }),
      ...(createTransactionInput?.amount > 0 && { createTransactionInput }),
    };
  
    return this.apollo.use('accounts').mutate({
      mutation: MATCH_OR_UNMATCH_TRANSACTION,
      variables,
    }).pipe(
      map((result: any) => result.data.matchOrUnmatchStatementToTransactions || [])
    );
  }  

}