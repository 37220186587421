<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <h2 class="cmn-popup-title">Create New Transaction</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="createTransactionForm">
            <div class="form-wrapper grid-form-wrapper">
                <!-- <div class="span-2">
                    <mat-form-field>
                        <label class="radio-label">Apply to <span class="red">*</span></label>
                        
                        <div class="type-selection">
                            <label class="radiobtn-wrapper">
                                <input type="radio" name="applyTo" value="Deposits" formControlName="applyTo" />
                                <span class="radiomark"></span>
                                Deposits
                            </label>
                            <label class="radiobtn-wrapper">
                                <input type="radio" name="applyTo" value="Withdrawals" formControlName="applyTo" />
                                <span class="radiomark"></span>
                                Withdrawals
                            </label>
                        </div>
                    </mat-form-field>
                </div> -->
                <div class="span-2">
                    <mat-radio-group formControlName="applyTo">
                        <label class="radio-label">Apply to <span class="red">*</span></label>
                        <mat-radio-button value="Deposits">Deposits</mat-radio-button>
                        <mat-radio-button value="Withdrawals">Withdrawals</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="
                    createTransactionForm.get('applyTo')!.touched &&
                    createTransactionForm.get('applyTo')!.hasError('required')
                  ">Type is required.</mat-error>
                </div>                
                <div class="span-2">
                    <label for="">Date <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Choose date" formControlName="date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-error *ngIf="
                    createTransactionForm.get('date')!.touched &&
                    createTransactionForm.get('date')!.hasError('required')
                  ">Date is required.</mat-error>
                </div>
                <!-- <div class="span-2">
                    <label for="">Account</label>
                    <mat-form-field>
                        <mat-select placeholder="Select Account" formControlName="account">
                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                {{ account?.accountName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="
                    createTransactionForm.get('account')!.touched &&
                    createTransactionForm.get('account')!.hasError('required')
                  ">Account is required.</mat-error>
                </div> -->
                <div class="span-2">
                    <label for="">Amount <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Enter amount" formControlName="amount" maxLength="8"/>
                    </mat-form-field>
                    <mat-error *ngIf="
                    createTransactionForm.get('amount')!.touched &&
                    createTransactionForm.get('amount')!.hasError('required')
                  ">Amount is required.</mat-error>
                <!-- <h5>Pending : <span class="hyper-span-green">AED {{ pendingAmount | number: '1.2-2' }}</span></h5> -->
                </div>
                <div class="span-2">
                    <label for="">Reference#</label>
                    <mat-form-field>
                        <input matInput placeholder="REF-0023" formControlName="reference" />
                    </mat-form-field>
                </div>
            </div>
            <div class="btn-wrapper">
                <button class="cmn-next-btn" (click)="onSave()">
                    Save
                </button>
                <button class="cmn-cancel-btn ">
                    Cancel
                </button>
            </div>
        </form>
    </section>
</mat-dialog-content>
