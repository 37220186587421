<section class="cmn-innerpage-wrapper debit-note-details">
    <div class="head">
        <div class="search-wrapper">
            <input type="text" class="cmn-header-search" placeholder="Search" />
        </div>
        <button class="cmn-upload-btn" (click)="openDialog()">
            <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
            <span>Upload</span>
        </button>
        <button class="cmn-add-btn" (click)="navigateTo('/debit-note/debit-note-new')">
            <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Debit Note
        </button>
        <button class="cmn-filter-btn" (click)="toggleDropdown($event)" [ngClass]="{ 'class-clicked': isDropdownOpen }">
            <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
        </button>
        <!-- Filter -->
        <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
            <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
            <form>
                <h6 class="cmn-popup-heading">
                    Filter <span>Clear All</span>
                </h6>
                <div class="filter-inner-content">
                    <h2>Branch</h2>
                    <div class="check-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />
                                test
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <button class="cmn-apply-btn" type="submit">Apply</button>
                </div>
            </form>
        </div>
        <!-- Filter -->
    </div>
    <div class="sub-head">
        <ul>
            <li class="main">Debit Note</li>
            <li class="edit" (click)="navigateToMail()"><img src="assets/images/icons/icon-send.svg" alt="Mail"> Send to
                mail</li>
            <li class="edit" (click)="toggleComments($event)"><img src="assets/images/icons/icon-comment.svg"
                    alt="Comment"> Comments & History</li>
            <li class="edit" (click)="navigateToEdit('/debit-note/debit-note-edit')"><img
                    src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
            <li class="edit" (click)="openDebitNoteCreditApplyDialog()"><img src="assets/images/icons/icon-record.svg"
                    alt="Apply"> Apply to Bill</li>
        </ul>
        <div [ngClass]="{ show: isCommentsOpen }" class="common-dropdown filter-position" #dropdown2>
            <button class="close-dropdown" (click)="toggleComments($event)"></button>
            <form [formGroup]="commentForm" (ngSubmit)="createComment()">
                <h6 class="cmn-popup-heading">
                    Comments
                </h6>
                <div class="filter-inner-content">
                    <div class="form-wrapper">
                        <mat-form-field>
                            <textarea matInput placeholder="Add Your Comment Here..."
                                formControlName="comment"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Comment</button>
                    </div>
                    <div class="timelinne-wrapper">
                        <!-- ngFor -->
                        <div class="timeline-box">
                            <div class="timeline-head">
                                <ul>
                                    <li>test</li>
                                    <li>test</li>
                                </ul>
                                <h3>test</h3>
                            </div>
                            <div class="timeline-content">
                                <h4>test</h4>
                            </div>
                        </div>
                        <!-- ngFor -->
                    </div>
                </div>
            </form>
        </div>

        <div class="mat-menu-wrapper">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="downloadPdf()">
                    <span>PDF download</span>
                </button>
                <button mat-menu-item (click)="changeStatusNote()" type="button" *ngIf="itemData?.noteStatus === 'Draft'">
                    <span>Mark as Open</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <div class="manual-journal-details-tab-wrapper">
        <div class="tab-left">
            <div class="tab-box-main-wrapper">
                <button *ngFor="let item of allItems; let i = index" class="tab-custom-button"
                    [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                    <div class="inn-box">
                        <h2>{{ item?.partnerData?.displayName || "---" }}</h2>
                        <h3> {{ item?.noteNo || "---" }} </h3>
                    </div>
                    <div class="inn-box">
                        <h6>{{ item?.noteDate ? (item?.noteDate | date: 'MM/dd/yyyy') : "---" }}</h6>
                        <h5>AED <span>{{ item?.totalPrice || "---" }}</span></h5>
                    </div>
                    <div class="inn-box">
                        <h4 [ngClass]="{
                            'blue': item?.noteStatus === 'Open',
                            'grey': item?.noteStatus === 'Draft',
                            'green': item?.noteStatus === 'Closed'
                          }">
                            {{ item?.noteStatus || "---" }}
                        </h4>
                    </div>
                </button>
            </div>
        </div>
        <div class="manual-journal-details-content-wrapper">
            <div *ngIf="itemData && allItems.length > 0">
                <div class="chart-tab-content">
                    <div class="tab-main-container">
                        <div class="tab-inner">
                            <div class="note-header">
                                <div class="left-set">
                                    <h4>{{itemData?.billingAddressData?.attention}}</h4>
                                    <h3>{{itemData?.billingAddressData?.addressLine1}}
                                        {{itemData?.billingAddressData?.addressLine1}}
                                        {{itemData?.billingAddressData?.city}}</h3>
                                    <h3>{{itemData?.billingAddressData?.country}}
                                        {{itemData?.billingAddressData?.pinCode}}</h3>
                                </div>
                                <div class="right-set">
                                    <h2>DEBIT NOTE</h2>
                                    <h3>{{itemData?.noteNo || "---"}}</h3>
                                    <h3>{{ itemData?.noteDate ? (itemData?.noteDate | date: 'MM/dd/yyyy')
                                        : "---" }}</h3>
                                </div>
                            </div>

                            <div class="journal-details">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h4>Bill To </h4>
                                        <h5>:</h5>
                                        <h6>{{ itemData?.partnerData?.displayName || "---" }}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Subject</h4>
                                        <h5>:</h5>
                                        <h6>{{ itemData?.subject || "---" }}</h6>
                                    </div>
                                </div>
                                <div class="right-set">
                                    <div class="details-box">
                                        <h4>Reference</h4>
                                        <h5>:</h5>
                                        <h6>{{itemData?.referenceId || "---" }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="crdit-note-page-table">
                                <table>
                                    <tr>
                                        <th>Description</th>
                                        <!-- <th>Description</th> -->
                                        <th>Quantity</th>
                                        <th>Rate</th>
                                        <th>Discount</th>
                                        <th>VAT</th>
                                        <th>Amount</th>
                                    </tr>
                                    <tr *ngFor="let item of itemData?.noteItems">
                                        <td>{{ item?.itemData ?.itemName || "___" }}</td>
                                        <!-- <td>Patch 120 sq.ft</td> -->
                                        <td>{{ item?.quantity || "___" }} </td>
                                        <td>{{ item?.rate || "___" }} </td>
                                        <td>{{ item?.discount || "0.00" }} </td>
                                        <td>{{ ((item?.rate * item?.quantity - item?.discount) * 5) / 100 || "" }}</td>
                                        <td>{{ item?.amount || "___" }} </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="page-footer-content">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h4>Customer Notes :</h4>
                                        <h6>{{itemData?.customerNotes || "---" }}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Terms & Conditions :</h4>
                                        <h6>{{itemData?.termsAndConditions || "---" }}</h6>
                                    </div>
                                </div>
                                <div class="right-set">
                                    <div class="details-box">
                                        <h4>Sub total</h4>
                                        <h5>:</h5>
                                        <h6>AED {{ itemData?.subTotal  | number:'1.2-2' }}</h6>
                                    </div>
                                     <div class="details-box">
                                        <h4>Discount (-)</h4>
                                        <h5>:</h5>
                                        <h6> AED {{ ( itemData?.subTotal + itemData?.tax) - itemData?.totalPrice | number:'1.2-2'}}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>VAT</h4>
                                        <h5>:</h5>
                                        <h6>AED {{ itemData?.tax != null ? (itemData.tax | number:'1.2-2') : "____"}}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Total</h4>
                                        <h5>:</h5>
                                        <h6>AED {{ itemData?.totalPrice | number:'1.2-2' }}</h6>
                                    </div>

                                    <div class="details-box">
                                        <h4>Credits Used</h4>
                                        <h5>:</h5>
                                        <h6>{{ itemData?.noteNo }}</h6>
                                    </div>
                                    <div class="details-box bold">
                                        <h4>Credits Remaining</h4>
                                        <h5>:</h5>
                                        <h6>AED {{ itemData?.balance }}</h6>
                                    </div>
                                </div>
                            </div>

                            <p class="signature">Authorized Signature <span></span></p>


                            <span class="published-note" *ngIf="itemData?. noteStatus === 'Draft'"><img
                                    src="assets/images/img-draft.svg" alt="canceled"></span>
                            <span class="published-note" *ngIf="itemData?. noteStatus === 'Open'"><img
                                    src="assets/images/img-open.svg" alt="Draft"></span>
                        </div>
                    </div>
                    <div class="credit-note-footer-table-wrapper" *ngIf="itemData?.noteStatus === 'Open'">
                        <table>
                            <tr class="title">
                                <td>Journal</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Account</td>
                                <td>Debit</td>
                                <td>Credit</td>
                            </tr>
                            <tr *ngFor="let entry of journalData?.journalEnteries" class="bold">
                                <td>{{ entry.ChartOfAccount?.accountName || 'N/A' }}</td>
                                <td>AED {{ entry.debit | number:'1.2-2' }}</td>
                                <td>AED {{ entry.credit | number:'1.2-2' }}</td>
                            </tr>
                            <tr class="bold border">
                                <!-- <td>Personal (Current)</td>
                            <td>AED 3,500.00</td>
                            <td>AED 0.00</td> -->
                            </tr>
                            <tr class="bold">
                                <td></td>
                                <td>AED {{ calculateTotalDebit(journalData?.journalEnteries) | number:'1.2-2' }}</td>
                                <td>AED {{ calculateTotalCredit(journalData?.journalEnteries) | number:'1.2-2' }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>