import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  GENERATE_INVOICE_PDF,
  GET_ACCOUNTS_DEPOSIT,
  GET_BANKS,
  GET_CLIENTS,
  GET_INVOICES,
  GET_INVOICE_BY_ID,
  GET_INVOICE_DASHBOARD,
  GET_INVOICE_DETAILS,
  GET_INVOICE_INCREMENT_BY_QUOTE_CODE,
  GET_NOTES_BY_CUSTOMER_OR_VENDOR,
  GET_PROJECTS,
  GET_QUOTE_BY_WORK_ORDER,
  GET_WORK_ORDER,
  GET_WORK_ORDER_BY_PARTNER,
  MARK_AS_SENT,
} from '../graphql/invoice.queries';
import {
  CREATE_ADDRESS,
  CREATE_INVOICE,
  REMOVE_INVOICE_ATTACHMENT_BY_ID,
  REMOVE_INVOICE_BY_ID,
  REMOVE_INVOICE_ITEM_BY_ID,
  UPDATE_ADDRESS,
  UPDATE_INVOICE,
} from '../graphql/invoice.mutations';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  constructor(private http: HttpClient, private apollo: Apollo) { }

  createInvoice(
    createInvoiceInput: any,
    createInvoiceItemInput: any[],
    createInvoiceAttachmentInput: any[]
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_INVOICE,
      variables: {
        createInvoiceInput: createInvoiceInput,
        createInvoiceItemInput: createInvoiceItemInput,
        createInvoiceAttachmentInput: createInvoiceAttachmentInput,
      },
    });
  }

  updateInvoice(
    updateInvoiceInput: any,
    updateInvoiceItemInput: any,
    updateInvoiceAttachmentInput: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_INVOICE,
      variables: {
        updateInvoiceInput: updateInvoiceInput,
        updateInvoiceItemInput: updateInvoiceItemInput,
        updateInvoiceAttachmentInput: updateInvoiceAttachmentInput,
      },
    });
  }

  fetchInvoices(search: string = ''): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICES,
        variables: {
          search,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.invoices));
  }

  fetchInvoiceDashboard(): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_DASHBOARD,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getInvoicesDashboard)
      );
  }

  // remove invoice attachment
  removeInvoiceAttachmentById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_INVOICE_ATTACHMENT_BY_ID,
      variables: {
        id,
      },
    });
  }

  // fetch workOrders
  fetchWorkOrders(partnerId: number, search: string): Observable<any> {
    return this.apollo
      .use('project')
      .query<any>({
        query: GET_WORK_ORDER_BY_PARTNER,
        variables: {
          partnerId,
          search,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response.data.getWorkOrderByPartner));
  }

  //fetch quote by work order
  fetchQuoteByWorkOrder(workOrderId: number): Observable<any> {
    return this.apollo
      .use('project')
      .query<any>({
        query: GET_QUOTE_BY_WORK_ORDER,
        variables: {
          workOrderId
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response.data.getQuoteByWorkOrder));
  }

  // fetch partners
  fetchClients(search: string): Observable<any> {
    return this.apollo
      .use('project')
      .query<any>({
        query: GET_CLIENTS,
        variables: {
          search,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response.data.getPartnersDropDownItems));
  }

  fetchBanks(search: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: GET_BANKS,
        variables: {
          search
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response.data.getBanksDropdown));
  }

  //fetch partners based on partner type
  fetchCustomers(search: string, partnerType: string): Observable<any> {
    return this.apollo
      .use('project')
      .query<any>({
        query: GET_CLIENTS,
        variables: {
          search,
          partnerType: partnerType,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response.data.getPartnersDropDownItems));
  }

  fetchInvoiceById(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.invoice));
  }

  generateInvoicePdf(invoiceId: number, employeeId: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GENERATE_INVOICE_PDF,
        variables: {
          invoiceId: invoiceId,
          employeeId: employeeId
        },
      })
      .valueChanges.pipe(map((response) => response.data?.generateInvoicePdf));
  }

  getInvoiceIncrementByQuoteCode(quoteCode: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_INVOICE_INCREMENT_BY_QUOTE_CODE,
        variables: {
          quoteCode: quoteCode,
        },
      })
      .valueChanges.pipe(map((response) => response.data?.getInvoiceIncrementByQuoteCode));
  }

  removeInvoiceById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_INVOICE_BY_ID,
      variables: {
        id,
      },
    });
  }

  removeInvoiceItemById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_INVOICE_ITEM_BY_ID,
      variables: {
        id,
      },
    });
  }

  fetchProjects(): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({ query: GET_PROJECTS, fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map((response) => response.data.getProjectDropDownItems)
      );
  }

  fetchAccountsToDeposit(search: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_ACCOUNTS_DEPOSIT,
        variables: {
          search,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getChartOfAccountDropDownItems)
      );
  }

  getInvoiceDetails(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_DETAILS,
        variables: {
          id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getInvoiceDetails));
  }

  markAsSent(id: number, employeeId: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: MARK_AS_SENT,
      variables: {
        id: id,
        employeeId: employeeId,
      },
    });
  }

  createAddress(createAddressInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_ADDRESS,
      variables: {
        createAddressInput: createAddressInput,
      },
    });
  }

  updateAddress(updateAddressInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: UPDATE_ADDRESS,
      variables: {
        updateAddressInput: updateAddressInput,
      },
    });
  }

  fetchNotesByCustomerOrVendor(id: number, partnerType: string) {
    return this.apollo.use('accounts')
      .watchQuery<any>({
        variables: {
          partnerId: id,
          partnerType: partnerType
        },
        query: GET_NOTES_BY_CUSTOMER_OR_VENDOR,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response?.data?.getNotesByCustomerOrVendor));
  }
}