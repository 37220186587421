<mat-dialog-content>
    <section class="asset-add-assets-wrapper custom-padding">
        <h2 class="cmn-popup-title"> Asset History</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <div class="inner-parent">
        <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef" [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true" [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass" />
    </div>
    </section>
</mat-dialog-content>