import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { GRNServices } from '../../features/grn/services/grn.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../features/customers/services/customer.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-goods-received-note-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './grn-details.component.html',
  styleUrl: './grn-details.component.scss'
})
export class GrnDetailsComponent {
  selectedOption = 'today';
  selectedTabIndex = 0; // Default to the first tab
  readonly dialog = inject(MatDialog);
  search: any;
  allGrns: any;
  grnId: string | null;
  grnData: any;
  mails: any;
  mailForm: FormGroup;
  grnItemStatus: any;
  remark: any;
  itemName: any;
  balanceQuantity: any;
  orderedQuantity: any;
  subTotal: any;
  totalPrice: any;
  profileName: any;

  constructor(
    private router: Router,
    private grnService: GRNServices,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private customerService: CustomerService,
    private fb: FormBuilder,
  ) { 
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    this.fetchGrns();
    this.route.paramMap.subscribe(params => {
      this.grnId = params.get('id');
      if (this.grnId) {
        this.fetchGrns();
        this.fetchGrnById(this.grnId);
        this.fetchMails();
      }
    });
    this.mailForm = this.fb.group({
      mails: ['']
    });
    // this.filterForm = this.fb.group({
    //   status: this.fb.array([]),
    // });
  }

  fetchMails() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const conditions = {
      employeeId: employeeId,
      refId: Number(this.grnId),
      type: 'GRN',
      activityType: 'Comment'
    }
    this.customerService.comments(conditions).subscribe({
      next: (data) => {
        if (data) {
          this.mails = data;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  onMail() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const mailData = {
      employeeId: employeeId,
      refId: Number(this.grnId),
      type: 'GRN',
      activityType: 'Comment',
      note: this.mailForm.value.mails
    }
    this.customerService.createComment(mailData).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Comment added successfully!', 'Success');
          this.mailForm.reset();
          this.fetchMails();
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    const selectedBill = this.allGrns[index];
    if (selectedBill) {
      console.log('selectedBill', selectedBill);

      this.router.navigate([`/goods-received-note/grn-details/${selectedBill?.id}`]);
    }
  }

  fetchGrns() {
    this.grnService.listGoodsReceived(this.search).subscribe({
      next: (data) => {
        this.allGrns = data;
        if (this.grnId) {
          const targetId = +this.grnId;
          this.selectedTabIndex = this.allGrns.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  fetchGrnById(id: any) {
    this.grnService.goodsReceived(Number(id)).subscribe({
      next: (data) => {
        this.grnData = data;
        let subTotal = 0; // Initialize subtotal
        let totalPrice = 0;
        this.grnData?.goodsReceivedItems.map((item: any) => {
          console.log('item', item);

          this.grnItemStatus = item?.grnItemStatus;
          this.remark = item?.remark;
          this.balanceQuantity = item?.quantity;
          this.orderedQuantity = item?.purchaseItemData?.quantity;
          this.itemName = item?.purchaseItemData?.item?.itemName;
          let rate = item?.purchaseItemData?.rate;
          const itemTotal = rate * this.balanceQuantity;

          subTotal += itemTotal; // Add to subtotal
          totalPrice += itemTotal; // Add to total price
        })
        this.subTotal = subTotal;
        this.totalPrice = totalPrice;
        console.log('grnData', this.grnData);
        this.fetchGrns();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  onConfirmDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(Number(this.grnId));
      }
    });
  }

  onDelete(id: any) {
    this.grnService.deleteGoodsReceived(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success("GRN deleted successfully", 'Success');
          this.fetchGrns();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadGrnDetailsDialog);
  }

  isDropdownOpen = false;
  isCommentsOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchGrns();
  }

  onEdit() {
    this.router.navigate([`/goods-received-note/grn-edit/${this.grnId}`]);
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isCommentsOpen = !this.isCommentsOpen;
  }




}



@Component({
  selector: 'upload-grn-details-dialog',
  templateUrl: 'upload-grn-details-dialog.html',
  styleUrls: ['./grn-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadGrnDetailsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

}