import { gql } from "apollo-angular";

export const CREATE_VACATION = gql`
  mutation createVacationRequest( $createVacationRequestInput: CreateVacationRequestInput!) {
    createVacationRequest(createVacationRequestInput: $createVacationRequestInput) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        levelOne
        levelTwo
        levelThree
        levelFour
        levelFive
        levelSix
        levelSeven
      }
      departmentId
    }
    startDate
    endDate
    totalDays
    vacationStatus
    status
  }
}
`;

export const UPDATE_VACATION = gql`
  mutation updateVacationRequest( $updateVacationRequestInput: UpdateVacationRequestInput!) {
    updateVacationRequest(updateVacationRequestInput: $updateVacationRequestInput)  {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        levelOne
        levelTwo
        levelThree
        levelFour
        levelFive
        levelSix
        levelSeven
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
    }
    # requestDate
    startDate
    endDate
    totalDays
    vacationStatus
    status
    additionalSalary
  }
}
`;

export const REMOVE_VACATION = gql`
  mutation removeVacationRequest( $id: Int!) {
    removeVacationRequest(id: $id)
}
`;

export const APPROVE_VACATION = gql`
  mutation updateVacationRequestStatus( $id: Int!, $status: LeaveApproverApprovalStatusEnum!, $comment:String!) {
    updateVacationRequestStatus(id: $id, status: $status, comment: $comment)
}
`;