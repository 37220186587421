import {ApplicationConfig, LOCALE_ID, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {provideToastr} from 'ngx-toastr';
import {APOLLO_NAMED_OPTIONS, ApolloModule} from 'apollo-angular';
import {NgxPermissionsModule} from 'ngx-permissions';
import {provideHttpClient, withInterceptors, HttpClientModule} from '@angular/common/http';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {DatePipe} from '@angular/common';
import {routes} from './app.routes';
import {authInterceptorFn} from './core/interceptors/auth.interceptor-fn';
import {HttpLink} from 'apollo-angular/http';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {apolloOptions} from './core/utils/apollo-option';
import {QuillModule} from 'ngx-quill';
import { permissionReducer } from './core/permissions/permission.reducer';
import { provideStore } from '@ngrx/store';
import { notificationReducer } from './core/notifications/notification.reducer';

export const appConfig: ApplicationConfig = {

    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'en-GB'
        },
        { // Sets the locale to UK
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB'
        }, // Set the date locale
        provideRouter(routes),
        provideToastr(),
        importProvidersFrom(BrowserAnimationsModule, ApolloModule, HttpClientModule, NgxPermissionsModule.forRoot(), MatNativeDateModule, QuillModule.forRoot(),),
        provideHttpClient(withInterceptors([authInterceptorFn])), {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: apolloOptions,
            deps: [HttpLink]
        },
        DatePipe,
        AuthInterceptor,
        provideStore(
            { 
                permissions: permissionReducer,
                notifications: notificationReducer 

            }
        ),
    ]
};
