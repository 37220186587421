<section class="cmn-innerpage-wrapper quotation-new">
  <h2 class="cmn-inner-heading">
    {{ quoteId ? "Update Quotation" : "New Quotation" }}
  </h2>
  <form [formGroup]="quoteForm">
    <div class="invoice-new-form-wrapper">
      <div class="form-wrapper invoice-form-wrapper">
        <div class="cmn-Search-select-container">
          <label for="clientName">Customer Name <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Search Customer" formControlName="partnerId"
              (selectionChange)="onCustomerSelected($event)">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No options found'"
                  (input)="onSearch($event)"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let partner of partnersData" [value]="partner.id">
                {{ partner.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  quoteForm.get('partnerId')!.touched &&
                  quoteForm.get('partnerId')!.hasError('required')
                ">Customer is required.</mat-error>
            </span>
          </div>
          <button (click)="openCreateClientDialog()">
            <div>
              <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
            </div>
            <span>Add customer</span>
          </button>
        </div>
        <div>
          <label for="">Quote# <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Quote" formControlName="quoteCode" maxlength="20" readonly />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  quoteForm.get('quoteCode')!.touched &&
                  quoteForm.get('quoteCode')!.hasError('required')
                ">Quote is required.</mat-error>
            </span>
          </div>
        </div>
        <div>
          <label for="">Quote Title <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Quote Title" formControlName="quoteTitle" maxlength="100" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  quoteForm.get('quoteTitle')!.touched &&
                  quoteForm.get('quoteTitle')!.hasError('required')
                ">Quote Title is required.</mat-error>
            </span>
          </div>
        </div>
        <div>
          <label for="">Sales Incharge</label>
          <mat-form-field>
            <input matInput placeholder="Sales Incharge" formControlName="refNumber" maxlength="20" />
          </mat-form-field>
        </div>
        <div>
          <label for="">TRN No. <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="TRN No." formControlName="TRN" maxlength="15" />
          </mat-form-field>

          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  quoteForm.get('TRN')!.touched &&
                  quoteForm.get('TRN')!.hasError('required')
                ">TRN Number is required.</mat-error>
            </span>
            <span>
              <mat-error *ngIf="
                  quoteForm.get('TRN')!.touched &&
                  quoteForm.get('TRN')?.hasError('invalidTRN')
                ">Please enter a valid TRN Number.</mat-error>
            </span>
          </div>
        </div>
        <div>
          <label for="">Quote Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="quoteDatePicker1" placeholder="Select Date" formControlName="quoteDate" />
            <mat-datepicker-toggle matIconSuffix [for]="quoteDatePicker1"></mat-datepicker-toggle>
            <mat-datepicker #quoteDatePicker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <label for="">Expiry Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="quoteDatePicker2" placeholder="Select Date" formControlName="expiryDate"
              [matDatepickerFilter]="disableBeforeQuoteDate" />
            <mat-datepicker-toggle matIconSuffix [for]="quoteDatePicker2"></mat-datepicker-toggle>
            <mat-datepicker #quoteDatePicker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <label for="">Project Name</label>
          <mat-form-field>
            <mat-select placeholder="Select project" formControlName="projectId"
              (selectionChange)="onProjectChange($event.value)" #singleSelect2>
              <mat-option *ngFor="let project of projectData" [value]="project.id">
                {{ project.projectName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <label for="">Project Reference<span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Project Name" formControlName="newProjectName" maxlength="50" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  quoteForm.get('newProjectName')!.touched &&
                  quoteForm.get('newProjectName')!.hasError('required')
                ">Project Reference is required.</mat-error>
            </span>
          </div>
        </div>
        <div>
          <label for="">Branch Name <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select branch" formControlName="branchId" [disabled]="isBranchReadOnly">
              <mat-option *ngFor="let branch of branchData" [value]="branch.id">
                {{ branch.branchName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span><mat-error *ngIf="
                  quoteForm.get('branchId')!.touched &&
                  quoteForm.get('branchId')?.hasError('branchInvalid')
                ">
                Branch is required
              </mat-error>
            </span>
          </div>
        </div>

        <div>
          <label for="paymentTerm">Payment Term</label>
          <mat-form-field>
            <input matInput placeholder="Payment Term" id="paymentTerm" formControlName="paymentTerm" />
          </mat-form-field>
        </div>
        <div>
          <label for="deliveryTerm">Delivery Term</label>
          <mat-form-field>
            <input matInput placeholder="Delivery Term" id="deliveryTerm" formControlName="deliveryTerm" />
          </mat-form-field>
        </div>
        <div>
          <label for="validity">Validity</label>
          <mat-form-field>
            <input matInput [matDatepicker]="quoteDatePicker3" placeholder="Select Date" formControlName="validity" />
            <mat-datepicker-toggle matIconSuffix [for]="quoteDatePicker3"></mat-datepicker-toggle>
            <mat-datepicker #quoteDatePicker3></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <label for="termsCondition">Terms & Condition</label>
          <mat-form-field>
            <textarea matInput placeholder="Terms & condition" id="termsCondition"
              formControlName="termsCondition"></textarea>
          </mat-form-field>
        </div>
        <div>
          <label for="subject">Subject</label>
          <mat-form-field>
            <textarea matInput placeholder="Quote subject" id="subject" formControlName="subject"></textarea>
          </mat-form-field>
        </div>
        <div>
          <label for="">SOW <span class="red">*</span></label>
          <mat-form-field>
            <textarea matInput placeholder="SOW" formControlName="SOW"></textarea>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  quoteForm.get('SOW')!.touched &&
                  quoteForm.get('SOW')!.hasError('required')
                ">SOW is required.</mat-error>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="billing-shipping-address-wrapper" *ngIf="selectedCustomerId">
      <div>
        <div class="header-wrapper">
          <h5>Billing Address <span class="red">*</span></h5>
          <div (click)="openSelectBillingAddressDialog()">
            <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
          </div>
        </div>
        <div *ngIf="selectedBillingAddress">
          <h6>{{ selectedBillingAddress.attention }}</h6>
          <ul>
            <li>{{ selectedBillingAddress.addressLine1 }}</li>
            <!-- <li>{{ selectedBillingAddress.addressLine2 }}</li> -->
            <li>{{ selectedBillingAddress.city }}</li>
            <li>{{ selectedBillingAddress.state }}</li>
            <li>{{ selectedBillingAddress.country }}</li>
            <li>{{ selectedBillingAddress.phone }}</li>
          </ul>
        </div>
        <button (click)="openBillingAddressDialog('add')">
          <span>Add Address</span>
        </button>
      </div>

      <div>
        <div class="header-wrapper">
          <h5>Shipping Address <span class="red">*</span></h5>
          <div (click)="openSelectShippingAddressDialog()">
            <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
          </div>
        </div>
        <div *ngIf="selectedShippingAddress">
          <h6>{{ selectedShippingAddress.attention }}</h6>
          <ul>
            <li>{{ selectedShippingAddress.addressLine1 }}</li>
            <!-- <li>{{ selectedShippingAddress.addressLine2 }}</li> -->
            <li>{{ selectedShippingAddress.city }}</li>
            <li>{{ selectedShippingAddress.state }}</li>
            <li>{{ selectedShippingAddress.country }}</li>
            <li>{{ selectedShippingAddress.phone }}</li>
          </ul>
        </div>
        <button (click)="openShippingAddressDialog('add')">
          <span>Add Address</span>
        </button>
      </div>
    </div>

    <div class="item-service-adding-table-wrapper" formArrayName="items">
      <table>
        <thead>
          <tr>
            <th>Description <span class="red">*</span></th>
            <th>Quantity <span class="red">*</span></th>
            <th>Unit <span class="red">*</span></th>
            <th>Price <span class="red">*</span></th>
            <th>Gross Amount</th>
            <th>Discount</th>
            <th>Net Amount <span class="red">*</span></th>
            <th>VAT(5%)</th>
            <th>Total Amount</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
            <!-- Description data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="Description" formControlName="description" maxlength="100" />
                </mat-form-field>

                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('description')?.touched &&
                        item.get('description')?.hasError('required')
                      ">Description is required.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- Quantity data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="Quantity" formControlName="quantity" />
                </mat-form-field>

                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('quantity')?.touched &&
                        item.get('quantity')?.hasError('required')
                      ">Quantity is required.</mat-error>
                  </span>

                  <span>
                    <mat-error *ngIf="item.get('quantity')?.hasError('nonNegative')">Quantity cannot be
                      negative.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- unit data -->
            <td>
              <div class="form-wrapper min-width-field">
                <mat-form-field>
                  <mat-select placeholder="Choose unit" formControlName="unit" maxlength="7">
                    <mat-option *ngFor="let unit of unitTypes" [value]="unit.value">
                      {{ unit.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('unit')!.touched &&
                        item.get('unit')!.hasError('required')
                      ">Unit is required.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- price data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="0.00" formControlName="rate" type="number" />
                </mat-form-field>
                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('rate')?.touched &&
                        item.get('rate')?.hasError('required')
                      ">Price is required.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('rate')?.hasError('nonNegative')">Price cannot be negative.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- gross amount data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput [value]="getItemTotalAmount(item.value)" readonly />
                </mat-form-field>
              </div>
            </td>
            <!-- discount data -->
            <td>
              <div class="form-wrapper">
                <div class="split-parent">
                  <div class="left-set">
                    <mat-form-field>
                      <input matInput placeholder="0.00" formControlName="discount" type="number" />
                    </mat-form-field>

                    <div class="custom-error">
                      <span>
                        <mat-error *ngIf="item.get('discount')?.hasError('nonNegative')">Discount cannot be
                          negative.</mat-error>
                      </span>
                      <span>
                        <mat-error *ngIf="
                            item.get('discount')?.hasError('pattern') &&
                            item.get('discount')?.touched
                          ">Please enter a valid discount amount.</mat-error>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- net amount data -->
            <td>
              <div class="form-wrapper min-width-field">
                <mat-form-field>
                  <input matInput placeholder="0.00" formControlName="grossAmount" type="number" readonly />
                </mat-form-field>
              </div>
            </td>
            <!-- Vat tax data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="VAT" formControlName="tax" type="number" (input)="updateItemTotal(i)" />
                </mat-form-field>
                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('tax')?.touched &&
                        item.get('tax')?.hasError('required')
                      ">VAT is required.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('tax')?.hasError('nonNegative')">VAT cannot be negative.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- total amount data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="Total Amount" formControlName="amount" type="number" readonly />
                </mat-form-field>

                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('amount')?.touched &&
                        item.get('amount')?.hasError('required')
                      ">Amount is required.</mat-error>
                  </span>
                </div>
              </div>
              <span class="delete">
                <img src="../../../assets/images/icons/icon-close.svg" alt="Delete"
                  (click)="onRemoveNewlyAddedItems(i)" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <button type="button" (click)="addItem()">
        <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" />
        Add Item
      </button>
    </div>

    <div class="quotation-table-container" *ngIf="quoteData?.quoteItems.length > 0">
      <div>
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Price</th>
              <th>Gross Amount</th>
              <th>Discount</th>
              <th>Net Amount</th>
              <th>VAT(5%)</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody *ngIf="quoteData">
            <tr *ngFor="let item of getCalculatedItems(); let i = index">
              <td>{{ item.description }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.unit }}</td>
              <td>{{ item.rate }}</td>
              <td>{{ item.grossAmount }}</td>
              <td>{{ item.discount }}</td>
              <td>{{ item.netAmount }}</td>
              <td>{{ item.vat }}</td>
              <td>{{ item.netAmount + item.vat }}</td>
              <td>
                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                  (click)="onRemoveQuoteItem(i, item?.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <section class="exclusion-wrapper" formArrayName="exclusions">
      <div *ngFor="let exclusion of exclusions.controls; let i = index" [formGroupName]="i">
        <div class="form-wrapper">
          <label for="">Exclusion {{ i + 1 }}</label>
          <mat-form-field>
            <input matInput placeholder="Exclusion" formControlName="exclusion" />
          </mat-form-field>
          <button class="close-exclution" (click)="onRemoveNewlyAddedExclusions(i)">
            <img src="assets/images/icons/icon-close.svg" alt="Close Icon" />
          </button>
         
        </div>
      </div>
      <button class="add-exclution" type="button" (click)="addExclusion()">
        <img src="assets/images/icons/icon-add-color.svg" alt="Add Exclusion" />
        Add Exclusion
      </button>
    </section>

    <!-- Section to display existing exclusions -->
    <div class="excluded-file" *ngIf="quoteData?.quoteExclusions?.length">
      <h6>Existing Exclusions</h6>
      <div *ngFor="let exclusion of quoteData.quoteExclusions; let i = index">
        <h4>
          {{ exclusion.exclusion }}
          <img src="assets/images/icons/icon-close.svg" alt="Close Icon"
            (click)="onRemoveQuoteExclusion(exclusion.id, i)" />
        </h4>
      </div>
    </div>

    <div class="notes-tatal-amount-container">
      <div class="form-container">
        <div class="form-wrapper">
          <label for="customerNotes">Customer Notes</label>
          <mat-form-field>
            <textarea matInput placeholder="Enter customer notes" formControlName="customerNote"></textarea>
          </mat-form-field>
        </div>
        <!-- <div class="checkbox-container">
            <label>
              <input type="checkbox" formControlName="isPayment" />
              <span>I have received the payment</span>
            </label>
          </div> -->
      </div>
      <div class="amount-table-div">
        <div>
          <h6>
            SubTotal<span>: AED {{ getSubTotalAmount() | number : "1.2-2" }}</span>
          </h6>
          <h6>
            Total Discount(-)<span>: AED {{ getTotalDiscount() | number : "1.2-2" }}</span>
          </h6>
          <h6>
            VAT (5%)<span>: AED {{ getTotalVat() | number : "1.2-2" }}</span>
          </h6>
        </div>
        <div>
          <h4>
            Total<span>: AED {{ getTotalAmount() | number : "1.2-2" }}</span>
          </h4>
        </div>
      </div>
    </div>

    <div class="split-task-wrapper" formArrayName="branches">
      <h2>Work Splits</h2>
      <div class="split-task-form-wrapper loop-wrap" *ngFor="let branch of branches.controls; let i = index"
        [formGroupName]="i">
        <div class="form-wrapper">
          <label for="">Branch</label>
          <mat-form-field>
            <input matInput type="text" placeholder="Branch" [value]="branch.get('branchName')?.value" readonly />
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Description<span class="red">*</span></label>
          <mat-form-field>
            <textarea matInput placeholder="Enter description" formControlName="description"></textarea>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  branch.get('description')!.touched &&
                  branch.get('description')!.hasError('required')
                ">Description is required.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Amount <span class="red">*</span></label>
          <mat-form-field>
            <input matInput type="number" placeholder="Enter amount" formControlName="amount"
              [readonly]="branch.get('amount')?.disabled" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  branch.get('amount')?.touched &&
                  branch.get('amount')?.hasError('required')
                ">Amount is required.</mat-error>
              <mat-error *ngIf="
                  branch.get('amount')?.touched &&
                  branch.get('amount')?.hasError('pattern')
                ">Amount cannot be a negative value</mat-error>
              <mat-error *ngIf="
                  branch.get('amount')?.touched &&
                  branch.get('amount')?.hasError('min')
                ">Amount must be greater than or equal to 0.</mat-error>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="documents-wrapper">
      <h2>Documents</h2>
      <div class="upload-wrapper">
        <div class="cmn-upload-box">
          <h4>Upload Documents (optional)</h4>
          <input multiple #fileInput type="file" (change)="onFileSelected($event)" style="display: none"
            accept=".pdf,.jpg,.jpeg,.png,.doc" />
          <button class="cmn-choose-file-btn" (click)="fileInput.click()" type="button">
            <img src="assets/images/icons/icon-add-color.svg" alt="Icon Add" />Choose a File
          </button>
          <div class="upload-info">
            <h5>Maximum 10 files accepted</h5>
          </div>
        </div>
      </div>

      <div class="uploaded-file" *ngIf="fileDetailsArray.length > 0">
        <div *ngFor="let file of fileDetailsArray; let i = index">
          <h6>
            <span (click)="onViewDocument(file?.fileUrl)" style="cursor: pointer">
              {{ file.fileName }}
            </span>
            <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile(i)" />
          </h6>
        </div>
      </div>
    </div>

    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
        Cancel
      </button>
      <button class="cmn-draft-btn" type="button" (click)="saveAsDraft()" [disabled]="loading">
        <span *ngIf="!loading">Save As Draft</span>
        <span *ngIf="loading">Loading...</span>
      </button>
      <button class="cmn-next-btn" type="button" (click)="saveAndSend()" [disabled]="loading">
        <span *ngIf="!loading">Save and Send</span>
        <span *ngIf="loading">Loading...</span>
      </button>
    </div>
  </form>
</section>