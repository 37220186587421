import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { CreditDebitNoteService } from "../../../features/credit-debit-note/services/credit-debit-note.service";
import { ToastrService } from "ngx-toastr";
import { InvoiceService } from "../../../features/invoice/services/invoice.service";

@Component({
  selector: 'apply-credits-from-invoice-dialog',
  templateUrl: 'apply-credits-from-invoice-dialog.html',
  styleUrls: ['./apply-credits-from-invoice-dialog.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class ApplyCreditsFromInvoiceDialog implements OnInit {
  invoiceId: number;
  noteDetails: any = null;
  partnerId: any;
  allInvoices: any = [];
  invoiceForm: FormGroup;
  totalAmountToCredit: number = 0;
  invoiceBalanceDue: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: CreditDebitNoteService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ApplyCreditsFromInvoiceDialog>,
    private invoiceService: InvoiceService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.invoiceId = +this.data.noteId;
      // this.fetchNote();
      this.fetchInvoiceById();
    }
  }

  initForm(): void {
    const controls = this.allInvoices.map((_: any) => this.fb.control(0)); // Default credit = 0 for each invoice
    this.invoiceForm = this.fb.group({
      credits: this.fb.array(controls),
    });
  }
  get credits() {
    return this.invoiceForm.get('credits') as FormArray;
  }

  // Add a helper method to safely access FormControls
  getCreditControl(index: number): FormControl {
    const invoiceBalance = this.allInvoices[index]?.balance || 0;
    if (!this.credits.at(index)) {
      this.credits.insert(index, new FormControl(0, [Validators.max(invoiceBalance), Validators.min(0)]));
    }
    return this.credits.at(index) as FormControl;
  }

  get invoices() {
    return this.invoiceForm.get('invoices') as FormArray;
  }
  // Initialize form controls for each invoice
  initializeCreditControls(): void {
    this.allInvoices.forEach((invoice: any, index: number) => {
      const creditControl = this.fb.control(
        '', // Initialize with an empty string
        [Validators.max(invoice.balance)] // Use Angular's built-in max validator
      );
      this.credits.push(creditControl);
    });
  }

  fetchInvoiceById(): void {
    this.invoiceService.fetchInvoiceById(this.invoiceId).subscribe({
      next: (invoice) => {
        this.noteDetails = invoice;
        console.log("Invoice partner details----", this.noteDetails.partnerData);
        this.partnerId = this.noteDetails?.partnerData?.id;

        if(this.partnerId) {
          this.invoiceService.fetchNotesByCustomerOrVendor(this.partnerId, 'Customer').subscribe({
            next: (response) => {
              this.allInvoices = response || []
              this.initForm();
            },
            error: (error) => {
              console.error(error);
            }
          })
        }
      },
      error: (err) => {
        console.error('Error fetching invoice:', err);
      }
    })
  }



  calculateInvoiceBalanceDue(): number {
    return this.allInvoices.reduce((totalBalanceDue: number, invoice: any, index: number) => {
      const creditApplied = this.credits.at(index)?.value || 0;
      const invoiceBalance = invoice.balance || 0;

      // Ensure we don't subtract more credits than the invoice balance
      const remainingBalance = invoiceBalance - Math.min(creditApplied, invoiceBalance);

      return totalBalanceDue + remainingBalance;
    }, 0);

  }

  calculateAmountToCredit(): void {
    const availableCredits = this.noteDetails?.balance || 0;
    let totalAppliedCredits = 0;

    this.credits.controls.forEach((control, index) => {
      const invoiceBalance = this.allInvoices[index]?.balance || 0;
      const appliedCredit = control.value || 0;

      // Ensure credit does not exceed invoice balance
      if (appliedCredit > invoiceBalance) {
        control.setErrors({ ...control.errors, exceedsInvoiceBalance: true });
      } else if (control.hasError('exceedsInvoiceBalance')) {
        const { exceedsInvoiceBalance, ...errors } = control.errors || {};
        control.setErrors(Object.keys(errors).length ? errors : null);
      }

      totalAppliedCredits += appliedCredit;
    });

    // Ensure total applied credits do not exceed available credits
    if (totalAppliedCredits > availableCredits) {
      this.credits.controls.forEach((control) => {
        control.setErrors({ ...control.errors, exceedsAvailableCredits: true });
      });
    } else {
      this.credits.controls.forEach((control) => {
        if (control.hasError('exceedsAvailableCredits')) {
          const { exceedsAvailableCredits, ...errors } = control.errors || {};
          control.setErrors(Object.keys(errors).length ? errors : null);
        }
      });
    }

    // Update total amount to credit
    this.totalAmountToCredit = Math.min(totalAppliedCredits, availableCredits);

    // Update invoice balance due
    this.invoiceBalanceDue = this.calculateInvoiceBalanceDue();
  }

  applyCreditsToInvoices(): void {
    const notes = this.credits.controls.map((control, index) => {
      const appliedCredit = control.value || 0; // Default to 0 if no value is entered
      const invoice = this.allInvoices[index];

      if (appliedCredit <= 0) {
        return null;
      }

      return {
        invoiceId: invoice.id,
        refId: this.noteDetails.id, // Reference to the credit note ID
        type: 'DN',
        amount: appliedCredit,
      };
    }).filter(note => note !== null); // Remove null entries

    // Ensure there's at least one note to apply
    if (notes.length === 0) {
      console.error('No credits to apply.');
      return;
    }


    const payload = {
      notes,
    };

    this.itemService.applytoinvoice(payload).subscribe({
      next: (response) => {
        this.toastr.success('Credits applied to invoices successfully.');
        this.dialogRef.close();
      },

      error: (error) => {
        this.toastr.error('Failed to apply credits to invoices .');
        console.log(error);

      },
    });
  }

}