import { gql } from 'apollo-angular';

export const ALL_PURCHASE_ORDER = gql`
  query purchaseOrders($search: String, $isLocal: Boolean) {
    purchaseOrders(search: $search, isLocal: $isLocal) {
      id
      purchaseOrderNo
      status
      subTotal
      dueDate
      billDate
      totalPrice
      subTotal
      purchaseUrl
      reference
      subject
      vendorId
      vendor {
        displayName
        addresses {
          addressLine1
          addressLine2
        }
      }
      billStatus
      purchaseStatus
      currencyId
      currencyData {
        currencyCode
        currencySymbol
      }
      billingAddressId
      billingAddressData {
        addressLine1
        addressLine2
      }
      shippingAddressId
      shippingAddressData {
        addressLine1
        addressLine2
      }
     purchaseItems {
      id
      itemId
      item{
      itemName
      }
      quantity
      rate
      amount
      description
      status
      item{
        itemName
      }
    }
      workorderData{
      workOrderNo
      id
      }
    }

  }
`;

export const ADMIN_ADDRESS = gql`
  query adminAddress {
    adminAddress {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
  }
`;
export const FETCH_PURCHASE_ORDER = gql`
  query purchaseOrder($id: Int!) {
    purchaseOrder(id: $id) {
      id
      isVatable
      subSubjectList
      purchaseOrderNo
      createdByName
      purchaseUrl
      billStatus
      subTotal
      totalPrice
      dueDate
      tax
      reference
      customerId
      TRNno
      purchaseStatus
      orderDiscount
      isFlat
      customerNote
      paymentTerms
      shippmentInstructions
      subject
      billDate
      currencyId
      currencyData {
        currencySymbol
        currencyCode
      }
      vendorId
      vendor {
        displayName
        phone
        email
        addresses {
        addressType
          addressLine1
          addressLine2
          country
        }
      }
      billingAddressData {
      id
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
      }
      shippingAddressData {
      id
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
      }
      project {
      projectName
      }
      tax
      subTotal
      adjustment
      purchaseItems {
        id
        name
        description
        quantity
        unit
        purchaseId
        itemId
        measure
        vat
        subjectList
        item {
          itemName
          purchaseAccountId
          unit

        }
        rate
        actualRate
        discount
        amount
        isManual
        balanceQuantity
        goodsReceivedItems {
          quantity
          grnItemStatus
          remark
        }
        status
      }
      workorderData {
        workOrderNo
        id
      }
    }
  }
`;

export const SUBJECT_LIST = gql`
query getSubSubjectLists{
getSubSubjectLists
}
`;

// munawel-accounting 
export const CURRENCY_LIST = gql`
  query currencies($search: String!, $filter: CurrencyFilter) {
    currencies(search: $search, filter: $filter) {
      id
      currencyCode
      currencyName
      currencySymbol
    }
  }
`;

// munawel-accounting 
export const GENERATE_PURCHASE_ORDER_PDF = gql`
  query generatePurchasePdf($purchaseId: Int!) {
    generatePurchasePdf(purchaseId: $purchaseId)
  }
`;

// munawel-accounting 
export const LIST_PURCHASE_ORDER_COMMENTS = gql`
  query comments($conditions: CommentConditionsInput!) {
    comments(conditions: $conditions) {
 id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      nativeAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      reportingManagerId
      # reportingManager
      # comments
    }
    refId
    type
    activityType
    note
    createdAt
    updatedAt
    deletedAt
    }
  }
`;


export const GET_ALL_WORK_ORDER = gql`
  query workOrders($search: string) {
    workOrders(search: $search) {
      id
      workOrderStatus
      workOrderNo
      partnerId
      partnerData {
        id
        displayName
      }
      projectId
      projectData {
        id
        projectName
      }
      SOW
      clientRef
      duration
      commencementDate
      completionDate
      projectManagerId
      projectManagerData {
        id
        firstName
        lastName
        middleName
        status
      }
      projectInchargeId
      projectInchargeData {
        id
        firstName
        lastName
        middleName
        status
      }
      projectDesigner
      projectDesignerData {
        id
        firstName
        lastName
        middleName
        status
      }
      procurementIncharge
      procurementInchargeData {
        id
        firstName
        lastName
        middleName
        status
      }
      estimator
      estimatorData {
        id
        firstName
        lastName
        middleName
        status
      }
      quoteId
      quoteData {
        id
        quoteCode
        quoteItems {
          amount
          description
          unit
          quantity
          rate
          amount
        }
      }
      addressId
      addressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      status
      createdAt
      updatedAt
      workOrderSOWs {
        id
        workOrderId
        order
        quantity
        discount
        unit
        rate
        netPrice
        status
      }
      attachedFiles {
        id
        workOrderId
        type
        fileName
        fileUrl
      }
    }
  }
`;

export const COMMENTS = gql`
  query comments($conditions: CommentConditionsInput) {
    comments(conditions: $conditions) {
      id
      employeeId
      refId
      type
      activityType
      note
      createdAt
      employee {
        id
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  query getPartnersDropDownItems(
  $id: Int,
    $search: String
    $partnerType: PartnerTypesEnum
  ) {
    getPartnersDropDownItems(id: $id, search: $search, partnerType: $partnerType) {
      id
      partnerType
      customerType
      displayName
      companyName
      paymentTerm
      email
      phone
      mobile
      currency
      emirateID
      trnNumber
      openingBalance
      paymentStatus
      status
      primaryContactId
      projects {
        id
        projectCode
        projectName
        totalProjectCost
      }
      partnerProjects {
        id
        projectCode
        projectName
        totalProjectCost
        description
      }
      primaryContact {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      partnerProjects {
        id
        projectCode
        projectName
        totalProjectCost
        description
        __typename
      }
      contacts {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      addresses {
        id
        addressType
        addressLine1
        addressLine2
        attention
        city
        country
        fax
        state
        phone
      }
    }
  }
`;