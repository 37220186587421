<section class="cmn-innerpage-wrapper quotation-details">
  <div class="head">
    <div class="search-wrapper">
      <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
    </div>
    <!-- <button class="cmn-upload-btn" (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button> -->
    <button class="cmn-add-btn" (click)="navigateTo('/quotation/quotation-new')">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New
      Quotation
    </button>
    <!-- <button
      class="cmn-filter-btn"
      (click)="toggleDropdown($event)"
      [ngClass]="{ 'class-clicked': isDropdownOpen }"
    >
      <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
    </button> -->
    <!-- Filter -->
    <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
      <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
      <form>
        <h6 class="cmn-popup-heading">Filter <span>Clear All</span></h6>
        <div class="filter-inner-content">
          <h2>Branch</h2>
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />
                test
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button class="cmn-apply-btn" type="submit">Apply</button>
        </div>
      </form>
    </div>
    <!-- Filter -->
  </div>
  <div class="sub-head">
    <div class="flex-sub-head">
      <ul>
        <li class="main">All Quotes</li>
        <li class="edit" (click)="navigateToSendMail(selectedQuote?.id)">
          <img src="assets/images/icons/icon-send.svg" alt="Mail" /> Send to
          mail
        </li>
        <li class="edit" (click)="toggleComments($event)">
          <img src="assets/images/icons/icon-comment.svg" alt="Comment" />
          Comments & History
        </li>
        <li *ngIf="selectedQuote?.status === 'DRAFT'" class="edit" (click)="navigateToEdit(selectedQuote?.id)">
          <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
          Edit
        </li>
        <!-- <li class="edit">
          <img src="assets/images/icons/icon-record.svg" alt="Record" /> Record
          Payment
        </li> -->
        <li class="edit" (click)="onViewDocument()" [ngClass]="{ 'loading-cursor': pdfLoading }">
          <img src="assets/images/icons/icon-pdf.svg" alt="PDF" />
          PDF
        </li>
      </ul>
      <!-- Comments Dropdown -->
      <div [ngClass]="{ show: isCommentsOpen }" class="common-dropdown filter-position" #dropdown2>
        <button class="close-dropdown" (click)="toggleComments($event)"></button>
        <form [formGroup]="commentsForm" (ngSubmit)="onCommentSubmit()">
          <h6 class="cmn-popup-heading">Comments</h6>
          <div class="filter-inner-content">
            <div class="form-wrapper">
              <mat-form-field>
                <textarea matInput placeholder="Add Your Comment Here..." formControlName="comments"></textarea>
                <mat-error *ngIf="
                    commentsForm.get('comments')!.touched &&
                    commentsForm.get('comments')!.hasError('required')
                  ">
                  Comment is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="btn-wrapper">
              <button class="cmn-apply-btn" type="submit" [disabled]="loading">
                {{ loading ? "Submitting..." : "Submit" }}
              </button>
            </div>
            <div class="timelinne-wrapper">
              <!-- ngFor -->
              <div class="timeline-box" *ngFor="let comment of commentsList">
                <div class="timeline-head">
                  <ul>
                    <li>{{ comment?.employee?.firstName }}</li>
                    <li>{{ comment.createdAt | date : "dd/MM/yyyy" }}</li>
                  </ul>
                  <h3>{{ comment.createdAt | date : "hh:mm a" }}</h3>
                </div>
                <div class="timeline-content">
                  <h4>{{ comment?.note }}</h4>
                </div>
              </div>
              <!-- ngFor -->
            </div>
          </div>
        </form>
      </div>

      <div class="branch-wrapper">
        <form>
          <mat-form-field>
            <mat-select [(value)]="selectedQuote" (selectionChange)="onQuoteSelectionChange($event.value)"
              [placeholder]="parentQuoteData?.quoteData?.length ? 'Select quote' : 'No quotes available'">
              <mat-option *ngFor="let quote of parentQuoteData?.quoteData" [value]="quote">
                {{ quote.quoteCode || "--" }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

    </div>
    <div class="mat-menu-wrapper">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="selectedQuote?.status !== 'SENT'" mat-menu-item type="button" type="button"
          (click)="quoteMarkAsSent()">
          <span>Mark as Sent</span>
        </button>
        <button mat-menu-item type="button" (click)="quoteRevision()">
          <span>Revision</span>
        </button>
        <button mat-menu-item type="button" *ngIf="selectedQuote.status === 'ACCEPTED'" (click)="reviseQuote()">
          <span>Variation</span>
        </button>
        <!-- <button mat-menu-item>
          <span>Credit Available</span>
        </button>
        <button mat-menu-item>
          <span>Create Credit Note</span>
        </button>
        <button mat-menu-item>
          <span>Credit Applied</span>
        </button>
        <button mat-menu-item>
          <span>Clone</span>
        </button> -->
        <button mat-menu-item type="button" *ngIf="profileName === 'admin' && parentQuoteStatus !== 'Confirmed'"
          (click)="onDeleteQuoteById(selectedQuote?.id)">
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="manual-journal-details-tab-wrapper">
    <div class="tab-left">
      <div class="tab-box-main-wrapper">
        <button *ngFor="let parentQuote of allParentQuotes; let i = index" class="tab-custom-button"
          [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
          <div class="inn-box">
            <h2>{{ parentQuote.parentCode }}</h2>
            <h3>{{ parentQuote.quoteCode }}</h3>
          </div>
          <div class="inn-box">
            <h6>{{ parentQuote.quoteDate | date : "dd MMM yyyy" }}</h6>
            <h5>{{ parentQuote?.projectData?.projectName }}</h5>
            <!-- <h5>{{ parentQuote?.totalAmount }}</h5> -->
          </div>
          <div class="inn-box">
            <h4 [ngClass]="{
              'blue': parentQuote?.parentQuoteStatus === 'Open',
              'grey': parentQuote?.parentQuoteStatus === 'Draft',
              'green': parentQuote?.parentQuoteStatus === 'Confirmed',
            }">
              {{ parentQuote?.parentQuoteStatus || "---" }}
            </h4>
          </div>
        </button>
      </div>
    </div>
    <div class="manual-journal-details-content-wrapper">
      <div *ngIf="selectedQuote">
        <div class="chart-tab-content">
          <div class="tab-main-container">
            <div class="tab-inner">
              <div class="note-header">
                <div class="left-set">
                  <h4>To,</h4>
                  <br />
                  <h4>{{ parentQuoteData?.partnerData?.displayName }}</h4>
                  <h3 *ngIf="selectedQuote?.billingAddressData?.attention">{{
                    selectedQuote?.billingAddressData?.attention }}</h3>
                  <h3 *ngIf="selectedQuote?.billingAddressData?.addressLine1 ">{{
                    selectedQuote?.billingAddressData?.addressLine1 }}</h3>
                  <h3 class="h3-bottom">
                    {{ selectedQuote?.billingAddressData?.state }}
                    <span *ngIf="
                        selectedQuote?.billingAddressData?.state &&
                        selectedQuote?.billingAddressData?.country
                      ">,</span>
                    {{ selectedQuote?.billingAddressData?.country }}
                  </h3>
                </div>
                <div class="right-set">
                  <h2>QUOTE</h2>
                  <h3>
                    Ref No.: <b>{{ parentQuoteData.quoteCode }}</b>
                  </h3>
                  <h3>{{ selectedQuote.quoteDate | date : "dd MMM yyyy" }}</h3>
                </div>
              </div>

              <div class="journal-details">
                <div class="left-set">
                  <div class="details-box">
                    <h4>TRN No.</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.TRN }}</h6>
                  </div>
                  <!-- <div class="details-box">
                    <h4>SOW</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.SOW }}</h6>
                  </div> -->
                </div>
                <!-- <div class="right-set">
                  <div class="details-box">
                    <h4>Reference</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.refNumber }}</h6>
                  </div>
                </div> -->
              </div>
              <div class="crdit-note-page-table">
                <table>
                  <tr *ngIf="selectedQuote.SOW">
                    <td colspan="10" style="text-align: center; font-weight: 500">
                      Scope of Work: {{ selectedQuote.SOW }}
                    </td>
                  </tr>
                  <tr>
                    <th>Sl No</th>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Unit</th>
                    <th>Rate</th>
                    <th>Gross Amount</th>
                    <th>Discount</th>
                    <th>Net Amount</th>
                    <th>VAT(5%)</th>
                    <th>Amount</th>
                  </tr>
                  <tr *ngFor="
                      let item of selectedQuote?.quoteItems;
                      let i = index
                    ">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.quantity || "--" }}</td>
                    <td>{{ item.unit || "--" }}</td>
                    <td>
                      {{
                      item.rate != null
                      ? (item.rate | number : "1.2-2")
                      : "--"
                      }}
                    </td>
                    <td>
                      {{
                      item.quantity && item.rate
                      ? (item.quantity * item.rate | number : "1.2-2")
                      : "--"
                      }}
                    </td>
                    <td>
                      {{
                      item.discount != null
                      ? (item.discount | number : "1.2-2")
                      : "--"
                      }}
                    </td>
                    <td>
                      {{
                      item.quantity && item.rate && item.discount != null
                      ? (item.quantity * item.rate - item.discount
                      | number : "1.2-2")
                      : "--"
                      }}
                    </td>
                    <td>
                      {{
                      item.tax != null ? (item.tax | number : "1.2-2") : "--"
                      }}
                    </td>
                    <td>
                      {{
                      item.amount != null
                      ? (item.amount | number : "1.2-2")
                      : "--"
                      }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="page-footer-content">
                <div class="left-set">
                  <div class="details-box">
                    <h4>Exclusion :</h4>
                    <div *ngFor="let exclusion of selectedQuote?.quoteExclusions">
                      <h6>{{ exclusion?.exclusion }}</h6>
                    </div>
                  </div>
                </div>
                <div class="right-set">
                  <div class="details-box">
                    <h4>Total Amount</h4>
                    <h5>:</h5>
                    <h6>
                      AED
                      {{ selectedQuote.subTotal | number : "1.2-2" }}
                    </h6>
                  </div>
                  <div class="details-box">
                    <h4>Discount(-)</h4>
                    <h5>:</h5>
                    <h6>AED {{ getTotalDiscount() | number : "1.2-2" }}</h6>
                  </div>
                  <div class="details-box">
                    <h4>VAT (5%)</h4>
                    <h5>:</h5>
                    <h6>AED {{ selectedQuote.tax | number : "1.2-2" }}</h6>
                  </div>
                  <div class="details-box">
                    <h4>Net Amount</h4>
                    <h5>:</h5>
                    <h6>
                      AED {{ selectedQuote.totalAmount | number : "1.2-2" }}
                    </h6>
                  </div>
                </div>
              </div>

              <div class="terms-conditions">
                <h4>Terms & Conditions :</h4>
                <h6>
                  {{ selectedQuote?.termsCondition || "--" }}
                </h6>
              </div>

              <!-- <div class="signature-wrapper">
                <div class="box">
                  <h4>Contract Manager :</h4>
                  <h6>Manager Name</h6>
                </div>
                <div class="box">
                  <h4>Managing Director :</h4>
                  <h6>Director Name</h6>
                </div>
              </div> -->

              <!-- <p class="signature">Authorized Signature <span></span></p> -->

              <span class="published-note" *ngIf="selectedQuote?.status === 'DRAFT'"><img
                  src="assets/images/img-draft.svg" alt="canceled" /></span>
              <span class="published-note" *ngIf="selectedQuote?.status === 'ACCEPTED'"><img
                  src="assets/images/img-closed.svg" alt="Draft" /></span>
              <span class="published-note" *ngIf="selectedQuote?.status === 'DELETED'"><img
                  src="assets/images/img-cancelled.svg" alt="Draft" /></span>
              <span class="published-note" *ngIf="selectedQuote?.status === 'DECLINED'"><img
                  src="assets/images/img-cancelled.svg" alt="Draft" /></span>
              <span class="published-note" *ngIf="selectedQuote?.status === 'SENT'"><img
                  src="assets/images/img-issued.svg" alt="Draft" /></span>
            </div>
          </div>

          <div class="quotation-footer-wrapper">
            <!-- <div class="flex-parent">
              <div class="form-wrapper">
                <label for="">Notes</label>
                <mat-form-field>
                  <textarea matInput placeholder="Reason for rejection">
                  </textarea>
                </mat-form-field>
              </div>
              <div class="uploaded-file">
                <h6>
                  file-name.pdf
                  <img
                    src="assets/images/icons/icon-close.svg"
                    alt="Close Icon"
                  />
                </h6>
              </div>
            </div> -->
            <div class="submit-btn-wrapper" *ngIf="selectedQuote?.status === 'SENT'">
              <ng-container *ngIf="selectedQuote?.status === 'ACCEPTED'; else confirmButton">
                <button class="cmn-next-btn" type="button" disabled>
                  Confirmed
                </button>
              </ng-container>
              <ng-template #confirmButton>
                <button class="cmn-next-btn" type="button" (click)="openQuotaionApprovalDialog()">
                  Confirm Approval
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>