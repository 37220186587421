import { gql } from 'apollo-angular';

export const NEW_NOTIFICATION_SUBSCRIPTION = gql`
  subscription {
    newNotification {
      id
      recipientId
      referenceId
      title
      message
      notificationType
      status
      createdAt
      readAt
      isDeleted
      updatedAt
      deletedAt
    }
  }
`;