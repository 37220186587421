export const PAYMENT_MODES = [
  'Cash',
  'Cheque',
  'Bank Transfer',
  'Credit Card',
  'Bank Remittance',
  // 'UPI',
];

export const PAYMENT_TERMS = [
  'Net 15',
  'Net 30',
  'Net 45',
  'Net 60',
  'Net 90',
  'Net 120',
  'Due On Receipt',
  'Due end of the month',
  'Due end of next month',
  'Custom',
];

export enum PartnerTypesEnum {
  Customer = 'Customer',
  Vendor = 'Vendor',
}

export enum PaymentTypesEnum {
  INVOICE = 'INVOICE',
  BILL = 'BILL',
}

export enum QUOTESTATUS {
  DRAFT = 'Draft',
  SENT = 'Sent',
  DELETED = 'Deleted',
  DECLINED = 'Declined',
  ACCEPTED = 'Accepted',
  INVOICED = 'Invoiced',
}

export enum AddressTypeEnum {
  billing = 'BILLING',
  shipping = 'SHIPPING',
}

export const MAIL_ALLOWED_FILE_TYPES = [
  'application/pdf', // PDF files
  'application/msword', // DOC files
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX files
  'image/jpeg', // JPG and JPEG files
  'image/png' // PNG files
];

export const ASSET_CONDITIONS = ['New', 'Good', 'Fair', 'Poor'];
export const ASSET_TYPES = [
'Vehicle',
'Office Equipment',
'IT Equipment',
'Communication Devices',
'Furniture and Fixtures',
'Machinery and Tools',
'Software Licenses',
'Networking Equipment',
'Security Systems',
'Miscellaneous',
'Appliances and Utilities',
'Healthcare and Safety Equipment',
'Energy and Power Equipment',
'Event and Marketing Assets',
'Transportation and Logistics'
];
export enum AssetStatusEnum {
  Active = 'Active',
  Available = 'Available',
  UnderMaintenance = 'Under Maintenance',
  Transferred = 'Transferred',
  Disposed = 'Disposed',
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
  Damaged = 'Damaged',
  Lost = 'Lost',
  Stolen = 'Stolen',
  Reserved = 'Reserved',
  OnLoan = 'On Loan',
  UnderInspection = 'Under Inspection',
  Upgraded = 'Upgraded',
  AwaitingDeployment = 'Awaiting Deployment',
  Archived = 'Archived',
  Recalled = 'Recalled',
  PendingDisposal = 'Pending Disposal',
  UnderWarrantyClaim = 'Under Warranty Claim',
  Inactive = 'Inactive',
}

export enum AssetCurrentStatusEnum {
  Open = 'Open',
  InUse = 'In Use',
  Hold = 'Hold',
}
export enum AssetDisposalMethodEnum {
  Sale = 'Sale',
  Donation = 'Donation',
  Scrapping = 'Scrapping',
}


export const UNITTYPES = [
  {
    label: 'nos',
    value: 'nos'
  },
  {
    label: 'pcs',
    value: 'pcs'
  },
  {
    label: 'sheet',
    value: 'sheet'
  },
  {
    label: 'drum',
    value: 'drum'
  }, {
    label: 'barrel',
    value: 'barrel'
  }, {
    label: 'tin',
    value: 'tin'
  }, {
    label: 'pkt',
    value: 'pkt'
  }, {
    label: 'bundle',
    value: 'bundle'
  }, {
    label: 'litre',
    value: 'litre'
  }, {
    label: 'gallon',
    value: 'gallon'
  }, {
    label: 'pail',
    value: 'pail'
  }, {
    label: 'ls',
    value: 'ls'
  }, {
    label: 'hours',
    value: 'hours'
  }, {
    label: 'sqm',
    value: 'sqm'
  }, {
    label: 'mtr',
    value: 'mtr'
  }, {
    label: 'cbm',
    value: 'cbm'
  }, {
    label: 'cartoon',
    value: 'cartoon'
  }, {
    label: 'bag',
    value: 'bag'
  }, {
    label: 'kg',
    value: 'kg'
  }, {
    label: 'set',
    value: 'set'
  }, {
    label: 'pair',
    value: 'pair'
  }, {
    label: 'roll',
    value: 'roll'
  }, {
    label: 'm2',
    value: 'm2'
  }, {
    label: 'pmt',
    value: 'pmt'
  }, {
    label: 'item',
    value: 'item'
  },
  // {
  //   label: 'sign',
  //   value: 'sign'
  // },
  //  {
  //   label: 'iot',
  //   value: 'iot'
  // }, 
  {
    label: 'sqft',
    value: 'sqft'
  }, {
    label: 'ml',
    value: 'ml'
  }, {
    label: 'boxes',
    value: 'boxes'
  }, {
    label: 'yards',
    value: 'yards'
  }, {
    label: 'rmtr',
    value: 'rmtr'
  }, {
    label: 'cft',
    value: 'cft'
  }, {
    label: 'lm',
    value: 'lm'
  }, {
    label: 'lot',
    value: 'lot'
  }, {
    label: 'length',
    value: 'length'
  }, {
    label: 'trip',
    value: 'trip'
  }, {
    label: 'buckets',
    value: 'buckets'
  }, {
    label: 'week',
    value: 'week'
  }, {
    label: 'visit',
    value: 'visit'
  }, {
    label: 'bottle',
    value: 'bottle'
  }, {
    label: 'dozen',
    value: 'dozen'
  }, {
    label: 'mm',
    value: 'mm'
  }, {
    label: 'm3',
    value: 'm3'
  },
  // {
  //   label: 'tub',
  //   value: 'tub'
  // }, 
  {
    label: 'days',
    value: 'days'
  },
  // {
  //   label: 'pce',
  //   value: 'pce'
  // }, 
  {
    label: 'strips',
    value: 'strips'
  }, {
    label: 'month',
    value: 'month'
  }, {
    label: 'monthly',
    value: 'monthly'
  }, {
    label: 'ton',
    value: 'ton'
  },
  // {
  //   label: 'kw',
  //   value: 'kw'
  // }, 
  // {
  //   label: 'opc',
  //   value: 'opc'
  // }, 
  {
    label: 'year',
    value: 'year'
  }, {
    label: 'test',
    value: 'test'
  },
  //  {
  //   label: 'sec',
  //   value: 'sec'
  // },
];
