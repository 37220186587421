import gql from 'graphql-tag';

export const CREATE_PETTY_CASH = gql`
  mutation createPettyCash(
    $createPettyCashInput: CreatePettyCashInput!,
    ) {
      createPettyCash(
      createPettyCashInput: $createPettyCashInput) {
    id
    projectId
    description
    amount
    status
    createdAt
    updatedAt
  }
  }
`;

export const UPDATE_PETTY_CASH = gql`
  mutation updatePettyCash(
    $updatePettyCashInput: UpdatePettyCashInput!,
    ) {
      updatePettyCash(
        updatePettyCashInput: $updatePettyCashInput) {
    id
    projectId
    flow
    date
    description
    amount
    status
    createdAt
    updatedAt
  }
  }
`;

export const REMOVE_PETTY_CASH = gql`
  mutation removePettyCash(
    $id: Int!,
    ) {
      removePettyCash(
        id: $id){
    id
  }
  }
`;
