<section class="cmn-innerpage-wrapper petty-cash">
    <h2 class="cmn-inner-heading">{{pettyCashId ? 'Edit' : 'Add'}} Petty Cash</h2>
    <form [formGroup]="expenseForm" (ngSubmit)="onSubmit()">
        <div class="chart-account-form-wrapper">
            <div class="form-wrapper">
                <label for="">Project <span class="red">*</span></label>
                <mat-form-field>
                    <mat-select placeholder="Select Project" formControlName="projectId"
                        (selectionChange)="onProjectSelected($event)">
                        <mat-option *ngFor="let project of allProjects" [value]="project.id">
                            {{ project?.projectName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                            expenseForm.get('projectId')!?.touched &&
                            expenseForm.get('projectId')?.hasError('required')">
                            Project is required
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Petty Cash Number<span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter Petty Cash No." formControlName="pettyCashNumber" readonly />
                  </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                         expenseForm.get('pettyCashNumber')!?.touched &&
                            expenseForm.get('pettyCashNumber')?.hasError('required')">
                            Petty cash number is required
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper" *ngIf="!pettyCashId">
                <label for="">Employee Name <span class="red">*</span></label>
                <mat-form-field>
                    <mat-select placeholder="Select Employee" formControlName="employeeId">
                        <mat-option *ngFor="let employee of filterEmployees" [value]="employee?.id">
                            {{ employee?.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                         expenseForm.get('employeeId')!?.touched &&
                            expenseForm.get('employeeId')?.hasError('required')">
                            Employee is required
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper" *ngIf="pettyCashId">
                <label for="">Employee Name <span class="red">*</span></label>
                <mat-form-field>
                    <mat-select placeholder="Select Employee" formControlName="employeeId">
                        <mat-option *ngFor="let employee of employees" [value]="employee?.id">
                            {{ employee?.firstName }}
                        </mat-option>
                    </mat-select>                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                         expenseForm.get('employeeId')!?.touched &&
                            expenseForm.get('employeeId')?.hasError('required')">
                            Employee is required
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Type <span class="red">*</span></label>
                <mat-form-field>
                    <mat-select placeholder="Select Type" formControlName="flow">
                        <mat-option value="In">In</mat-option>
                        <mat-option value="Out">Out</mat-option>
                    </mat-select>

                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                         expenseForm.get('flow')!?.touched &&
                            expenseForm.get('flow')?.hasError('required')">
                            Type is required
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper" *ngIf="expenseForm.get('flow')?.value === 'In' ">
                <label for="">Payment Mode</label>
                <mat-form-field>
                    <mat-select placeholder="Payment Mode" id="paymentMode" formControlName="paymentMode">
                        <mat-option *ngFor="let mode of paymentModeList" [value]="mode">
                            {{ mode }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                        expenseForm.get('paymentMode')!.touched &&
                        expenseForm.get('paymentMode')!.hasError('required')
                      ">Payment Mode is required.</mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper" *ngIf="expenseForm.get('flow')?.value === 'In' ">
                <label for="">Paid Through</label>
                <mat-form-field>
                    <mat-select placeholder="Paid Through" id="depositTo" formControlName="accountId">
                        <mat-option *ngFor="let getAccount of getAccountList" [value]="getAccount?.id">
                            {{ getAccount?.accountName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                        expenseForm.get('accountId')!.touched &&
                        expenseForm.get('accountId')!.hasError('required')
                      ">Deposit To is required.</mat-error>
                    </span>
                </div>
            </div>
                        
            <div class="form-wrapper" *ngIf="
            expenseForm.get('flow')?.value === 'In' &&
            expenseForm.get('paymentMode')?.value === 'Cheque'">
                <label for="">Cheque No</label>
                <mat-form-field>
                    <input matInput placeholder="Cheque No" type="text" formControlName="chequeNumber" />
                </mat-form-field>
            </div>
            <div class="form-wrapper" *ngIf="
            expenseForm.get('flow')?.value === 'In' &&
            expenseForm.get('paymentMode')?.value === 'Cheque'">
                <label for="">Cheque Date</label>
                <mat-form-field>
                    <input matInput [matDatepicker]="chequeDatePicker" placeholder="Cheque Date" type="text" formControlName="chequeDate" />
                    <mat-datepicker-toggle matIconSuffix [for]="chequeDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #chequeDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div formArrayName="expenses">
            <div class="chart-account-form-wrapper loop-wrap" *ngFor="let expense of expenses.controls; let i = index"
                [formGroupName]="i">
                <div class="form-wrapper">
                    <label for="">Date <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Select date" formControlName="date"
                            [matDatepickerFilter]="dateFilter" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>

                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                             expenseForm.get('expenses')!?.touched &&
                                expenseForm.get('expenses')?.hasError('required')">
                                Date is required
                            </mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter description" formControlName="description"></textarea>
                        <mat-error *ngIf="expense.get('description')?.hasError('maxlength')">
                            Notes cannot exceed 500 characters
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Amount <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput type="number" placeholder="AED" formControlName="amount" />
                        <mat-error *ngIf="
                         expenseForm.get('amount')!?.touched &&
                        expense.get('amount')?.hasError('required')">
                            Amount is required
                        </mat-error>
                        <mat-error *ngIf="expense.get('amount')?.hasError('pattern')">
                            Amount must be a valid positive number
                        </mat-error>
                    </mat-form-field>
                </div>
                <button type="button" class="loop-remove-btn" (click)="removeExpenseRow(i)"
                    *ngIf="this.expenses?.value?.length > 1">
                    <img src="/assets/images/icons/icon-close.svg" alt="Remove" />
                </button>
            </div>
            <div class="loop-btn-wrapper" *ngIf="!pettyCashId">
                <button type="button" class="add-new-btn" (click)="addExpenseRow()">
                    <img src="/assets/images/icons/icon-add-color.svg" alt="Add" />
                    Add New
                </button>
            </div>
        </div>

        <div class="submit-btn-wrapper">
            <button type="button" class="cmn-cancel-btn" [disabled]="loading" (click)="onClearForm()">
                Cancel
            </button>
            <button class="cmn-next-btn" [disabled]="loading">
                {{ loading ? "Loading..." : "Save" }}
            </button>
        </div>
    </form>
</section>