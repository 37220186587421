<section class="cmn-innerpage-wrapper banking">
<div class="inner-content">
    <div class="banking-details-tab-wrapper">
        <button class="tab-custom-button" [class.active]="true">
            <h2>Uncategorized Transactions
            </h2>
            <h3>From Bank Statements</h3>
        </button>
    </div>
    <div class="inner-tab-content-wrapper">
        <div class="banking-table-wrapper">
            <table *ngIf="statementAmount; else noData">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Statement Details</th>
                        <th>Account</th>
                        <th>Deposits</th>
                        <th>Withdrawals</th>
                    </tr>
                </thead>
                <tbody *ngFor="let statement of statementAmount">
                    <tr (click)="toggleCategorize($event, statement)" style="cursor: pointer;">
                        <td>{{ statement?.date | date: 'dd/MM/YYYY' }}</td>
                        <td>
                            <p style="display: block; line-height: 1.5">Reference#:
                                {{statement?.referenceNumber}}</p>
                            <p style="display: block; line-height: 1.5">Description:
                                {{statement?.description}}</p>
                            <p style="display: block; line-height: 1.5">Payee:
                                {{statement?.payee}}</p>
                        </td>
                        <td>{{ statement?.offsetAccount?.accountName || '--'}}</td>
                        <td>{{ statement?.flow === 'Credit' && statement?.amount ? 'AED ' + statement?.amount : '-'
                            }}</td>
                        <td>{{ statement?.flow === 'Debit' && statement?.amount ? 'AED ' + statement?.amount :
                            '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #noData>
            <div class="no-data-message">
                No data found.
            </div>
        </ng-template>
        <div [ngClass]="{ show: showUncategorize }" class="banking-side-dialog" #dropdown>
            <button class="close-dropdown" (click)="toggleCategorize($event)"></button>
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms">
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Match Transactions</h2>
                        </ng-template>
                        <ng-container
                            *ngIf="(matchedData?.bestMatches?.length || 0) > 0 || (matchedData?.possibleMatches?.length || 0) > 0; else noMatch">
                            <div class="banking-sidedialog-content-wrapper seperate-scrollbar">
                                <ng-container *ngIf="matchedData?.bestMatches?.length > 0">
                                    <div class="content-head">
                                        <div class="left-set">
                                            <h2>Best Matches</h2>
                                            <h3>Transactions upto 26 Aug 2024</h3>
                                        </div>
                                    </div>
                                    <!-- common height and scroll removed -->
                                    <div class="banking-tab-inner-content">
                                        <!-- ngFor -->
                                        <div class="content-group" *ngFor="let data of matchedData?.bestMatches">
                                            <div class="left-set">
                                                <h4>Bill for AED {{ data?.amount || '--' }}</h4>
                                                <h5>Dated {{ data?.date | date: 'dd MMMM y' }}</h5>
                                                <h5>{{ data?.description || '--' }}</h5>
                                                <h5><span class="hyper-span">Ref# {{ data?.referenceNumber || '--'
                                                        }}</span>
                                                </h5>
                                            </div>
                                            <div class="right-set">
                                                <div class="submit-btn-wrapper">
                                                    <!-- <button class="cmn-cancel-btn" (click)="toggleCategorize($event)">Cancel</button> -->
                                                    <button class="cmn-next-btn"
                                                        (click)="onBestMatch(data?.id)">Match</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- ngFor -->
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="matchedData?.possibleMatches?.length > 0">
                                    <div class="content-head">
                                        <div class="left-set">
                                            <h2>Possible Matches</h2>
                                            <h3>Transactions upto 26 Aug 2024</h3>
                                        </div>
                                        <div class="right-set">
                                            <span class="hyper-span" (click)="selectAll()">Select All</span>
                                        </div>
                                    </div>
                                    <!-- common height and scroll removed -->
                                    <div class="banking-tab-inner-content">
                                        <!-- ngFor -->
                                        <div class="content-group" *ngFor="let data of matchedData?.possibleMatches">
                                            <div class="left-set">
                                                <h4>Bill for AED {{ data?.amount || '--' }}</h4>
                                                <h5>Dated {{ data?.date | date: 'dd MMMM y' }}</h5>
                                                <h5>{{ data?.description || '--' }}</h5>
                                                <h5><span class="hyper-span">Ref# {{ data?.referenceNumber || '--'
                                                        }}</span>
                                                </h5>
                                            </div>
                                            <div class="right-set">
                                                <div class="check-wrapper">
                                                    <div class="check-box">
                                                        <label class="checkbox-wrapper">
                                                            <input type="checkbox" [value]="data?.id"
                                                                [checked]="transactionIds.includes(data?.id)"
                                                                (change)="toggleTransaction(data, $event)" />Select
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- ngFor -->
                                    </div>
                                </ng-container>
                                <!-- <div class="submit-btn-wrapper">
                            <h1 class="leave-tab-title">Total Amount</h1>
                            <h1 class="leave-tab-red">AED {{ totalAmount | number: '1.2-2' }}</h1>
                        </div> -->
                                <div class="submit-btn-wrapper">
                                    <h5 *ngIf="isCreate" (click)="onCreate()"><span class="hyper-span">+ Create New
                                            Transaction</span></h5>
                                    <h5 *ngIf="isCreate">Pending : <span class="hyper-span-green">AED {{ pendingAmount |
                                            number: '1.2-2' }}</span></h5>
                                    <!-- <h5 *ngIf="isCreate">Pending : <span class="hyper-span-green">AED {{ (totalAmount -
                                    statement?.amount) | number: '1.2-2' }}</span></h5> -->
                                </div>
                                <div class="submit-btn-wrapper">
                                    <button class="cmn-cancel-btn" (click)="toggleCategorize($event)">Cancel</button>
                                    <button class="cmn-next-btn" (click)="onMatch()">Match</button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noMatch>
                            <h2 class="leave-tab-title">No match found</h2>
                        </ng-template>
                    </mat-tab>

                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Categorize Manually</h2>
                        </ng-template>
                        <div class="banking-sidedialog-content-wrapper">
                            <div class="banking-tab-inner-content-form">
                                <form [formGroup]="categorizeForm">
                                    <div class="manually-form-wrapper">
                                        <div class="form-wrapper">
                                            <label for="">Category<span class="red">*</span></label>
                                            <mat-form-field>
                                                <mat-select (selectionChange)="selectionChangeCategory($event)"
                                                    placeholder="Add Transactions" formControlName="category">
                                                    <ng-container *ngFor="let category of categories">
                                                        <mat-option
                                                            [value]="{ flow: category?.categoryStatus, id: category?.id }"
                                                            style="text-transform: capitalize;">
                                                            {{ category?.categoryName }}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-error *ngIf="
                                    categorizeForm.get('category')!.touched &&
                                    categorizeForm.get('category')!.hasError('required')">Category is required.
                                            </mat-error>
                                        </div>
                                        <div class="form-wrapper">
                                            <label for="">Date <span class="red">*</span></label>
                                            <mat-form-field>
                                                <input matInput [matDatepicker]="picker" placeholder="Choose date"
                                                    formControlName="date" />
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                            <mat-error *ngIf="
                                    categorizeForm.get('date')!.touched &&
                                    categorizeForm.get('date')!.hasError('required')">Date is required.
                                            </mat-error>
                                        </div>
                                        <div class="form-wrapper amount-wrapper">
                                            <label for="">Amount <span class="red">*</span></label>
                                            <h6>AED {{manualAmount | number: '1.2-2' }}*</h6>
                                        </div>
                                        <div class="form-wrapper">
                                            <label for="">Reference#</label>
                                            <mat-form-field>
                                                <input matInput placeholder="REF-0023" formControlName="reference" />
                                            </mat-form-field>
                                        </div>
                                        <div class="form-wrapper">
                                            <label for="">Description</label>
                                            <mat-form-field>
                                                <textarea matInput placeholder="Enter description"
                                                    formControlName="description"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="submit-btn-wrapper">
                                <button class="cmn-cancel-btn">Cancel</button>
                                <button class="cmn-next-btn" (click)="onCategorizeManually()">Save</button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
</section>