import { Component, HostListener, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { PurchaseOrdereService } from '../../features/purchase-order/services/purchase-orderservice';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-local-purchase-order',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular
  ],
  templateUrl: './local-purchase-order.component.html',
  styleUrl: './local-purchase-order.component.scss'
})
export class LocalPurchaseOrderComponent {
  public viewPortSize: boolean = false;
  public rowData: any[] = [];

  readonly dialog = inject(MatDialog);
  private searchSubject = new Subject<string>();
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];

  constructor(
    private router: Router,
    private purchaseOrderService: PurchaseOrdereService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.fetchAllPurchaseOrder();
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchAllPurchaseOrder(searchTerm);
      });

    this.checkViewportSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      { headerName: 'Date', field: 'date', flex: this.viewPortSize ? 1 : 0 },
      // {
      //   headerName: 'Employee Name',
      //   field: 'name',
      //   flex: this.viewPortSize ? 1 : 0,
      //   cellRenderer: (params: any) => {
      //     return `
      //       <div style="display: flex; align-items: center;">
      //         <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
      //         <span>${params.data.name}</span>
      //       </div>
      //     `;
      //   }
      // },
      // { headerName: 'Purchase order Id', field: 'id', flex: this.viewPortSize ? 1 : 0 },

      { headerName: 'Local Purchase order#', field: 'purchaseOrder', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Reference#', field: 'reference', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Vendor Name', field: 'vendorName', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Status',
        field: 'status',
        flex: this.viewPortSize ? 1 : 0,
        // cellRenderer: (params: any) => {
        //   const status = params.data.status;
        //   const statusClass = status === "On Leave" ? 'on-leave' : 'working'; // Apply class if "On Leave"
        //   return `<span class="${statusClass}">${status}</span>`;
        // }
      },
      { headerName: 'Billed Status', field: 'billedStatus', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Amount', field: 'amount', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Expected Delivery', field: 'expectedDelivery', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Actions',
        field: 'action',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');
          container.appendChild(editIcon);
          container.appendChild(deleteIcon);
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';


  onCellClicked(params: any) {
    const purchaseOrderId = params.data?.id;
    const billedStatus = params.data?.billedStatus;
    const purchaseOrderStatus = params.data?.purchaseOrderStatus;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;
      if (action === 'edit') {
        this.navigateToEdit(purchaseOrderId, purchaseOrderStatus, billedStatus)
      }
      else if (action === 'delete') {
        this.onConfirmDelete(purchaseOrderId);
      }
    }
    else {
      const id = params.data?.id;
      this.router.navigate([`local-purchase-order/local-purchase-order-details`], {
        queryParams: { id: id },
      });
      // this.router.navigate([`/local-purchase-order/local-purchase-order-details/${id}`]);
    }
  }
  onConfirmDelete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(+id);
      }
    });
  }

  onDelete(purchaseOrderId: any) {
    const id = +purchaseOrderId;
    this.purchaseOrderService.removePurchaseOrder(id).subscribe({
      next: () => {
        this.fetchAllPurchaseOrder();
        this.toastr.success('Deleted successfully.');
      },
      error: (error) => {
        this.toastr.error(error.message || 'Failed to delete.');
      },
    });
  }
  navigateToEdit(id: string, status: string, billedStatus: string) {
    if (!id) {
      this.toastr.error('Purchase Order ID is missing. Unable to navigate to edit.');
      return;
    }
    
    // Check if both conditions are true
    if (status === 'CLOSED' && billedStatus === 'BILLED') {
      this.toastr.error('Cannot edit. Purchase order is closed and has already been billed.');
      return;
    }
  
    this.router.navigate([`/local-purchase-order/local-purchase-order-edit/${id}`])
  }
  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  fetchAllPurchaseOrder(search: string = '') {
    this.purchaseOrderService.fetchAllPurchaseOrder(search, true).subscribe({
      next: (orders) => {
        this.rowData = orders.map((order: any) => {
          return {
            id: order?.id,
            date: this.datePipe.transform(order?.billDate, 'MM/dd/YYYY') || '--',
            purchaseOrder: order?.purchaseOrderNo,
            reference: order?.reference,
            vendorName: order?.vendor?.displayName,
            status: order?.purchaseStatus,
            billedStatus: order?.billStatus,
            amount: order?.totalPrice,
            expectedDelivery: this.datePipe.transform(order?.dueDate, 'MM/dd/YYYY') || '--',
            purchaseOrderStatus: order?.purchaseStatus

          }
        })
      }
    })

  }
}
