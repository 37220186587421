import { Subject, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { Component, HostListener, Inject, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { HolidayCalendarService } from '../../features/holiday-calendar/services/holiday-calendar.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { HolidayTypes } from '../../../assets/holidayTypes';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { DialogRef } from '@angular/cdk/dialog';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-holiday',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    AgGridAngular,
  ],
  templateUrl: './holiday.component.html',
  styleUrl: './holiday.component.scss',
})
export class HolidayComponent implements OnInit {
  public viewPortSize: boolean = false;
  isDropdownOpen = false;
  public rowData: any[] | null | undefined = null;
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();

  readonly dialog = inject(MatDialog);
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  profileName: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      { headerName: 'Occasion', field: 'holidayName', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Date', field: 'date', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Day', field: 'day', flex: this.viewPortSize ? 1 : 0 },

      {
        field: 'action',
        headerName: 'Action',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const div = document.createElement('div');
          div.style.display = 'flex';
          div.style.alignItems = 'center';
          div.style.gap = '20px';

          const createButton = (iconSrc: string, onClick: () => void) => {
            const span = document.createElement('span');
            span.style.cursor = 'pointer';
            span.style.display = 'flex';
            span.innerHTML = `<img src="${iconSrc}"/>`;
            span.addEventListener('click', onClick);
            return span;
          };
          div.appendChild(
            createButton('assets/images/icons/icon-edit.svg', () =>
              this.openEditDialog(params?.data)
            )
          );

          if (this.profileName === 'admin') {
            div.appendChild(
              createButton('assets/images/icons/icon-delete.svg', () =>
                this.onRemoveHoliday(params.data.id)
              )
            );
          }

          return div;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    private holidayService: HolidayCalendarService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit(): void {
    this.fetchHolidayCalendar();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchHolidayCalendar('', '', searchTerm);
      });

    this.checkViewportSize();
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchHolidayCalendar(
    holidayDate: string = '',
    year: string = '',
    search: string = ''
  ) {
    this.loading = true;
    this.holidayService
      .getHolidayCalendarList(holidayDate, year, search)
      .subscribe({
        next: (holidays) => {
          this.rowData = holidays.map((holiday: any) => {
            return {
              ...holiday,
              date: this.datePipe.transform(holiday.holidayDate, 'dd/MM/yyyy'),
              day: new Date(holiday.holidayDate).toLocaleDateString('en-US', {
                weekday: 'long',
              }),
            };
          });

          // Update pagination options based on the rowData length
          if (this.rowData) {
            this.paginationPageSizeSelector = PaginationHelper.getPaginationPageSizeOptions(this.rowData);
          } else {
            this.paginationPageSizeSelector = [];
          }
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.loading = false;
        },
      });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadHolidayDialog);
    dialogRef.afterClosed().subscribe((data) => {
      this.fetchHolidayCalendar();
    });
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddHolidayDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchHolidayCalendar();
      }
    });
  }

  openEditDialog(holidayData: any) {
    const dialogRef = this.dialog.open(AddHolidayDialog, {
      disableClose: true,
      data: holidayData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchHolidayCalendar();
      }
    });
  }

  onRemoveHoliday(holidayId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeHoliday(holidayId);
      }
    });
  }

  private removeHoliday(holidayId: number): void {
    this.holidayService.removeHolidayCalendarById(holidayId).subscribe({
      next: (response) => {
        // this.toastr.success('Holiday removed successfully.');
        // console.log("holiday response", response)
        // this.fetchHolidayCalendar();
        if (response.removeHolidayCalendar === true) {
          // Update the UI immediately
          this.rowData = this.rowData?.filter((holiday) => holiday.id !== holidayId);
          this.toastr.success('Holiday removed successfully.');
  
          // Fetch fresh data from the API for consistency
          this.fetchHolidayCalendar();
        } 
      },
      error: (error) => {
        this.toastr.error('Failed to remove holiday. Please try again.');
      },
    });
  }

  openConfirm() {
    const dialogRef = this.dialog.open(ConfirmDialog);
  }
}

@Component({
  selector: 'add-holiday-dialog',
  templateUrl: 'add-holiday-dialog.html',
  styleUrls: ['./holiday.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatFormField,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AddHolidayDialog implements OnInit {
  holidayCalendarForm!: FormGroup;
  public holidayTypeData: { label: string; value: string }[] = [];
  holidayData: any;
  loading: boolean = false;

  constructor(
    private holidayService: HolidayCalendarService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddHolidayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.holidayData = this.data;
    }
    this.loadHolidayTypes();
    this.initForm();
  }

  initForm(): void {
    this.holidayCalendarForm = this.fb.group({
      id: [this.holidayData?.id || ''],
      holidayName: [this.holidayData?.holidayName || '', Validators.required],
      holidayDate: [this.holidayData?.holidayDate || '', Validators.required],
      holidayType: [this.holidayData?.holidayType || '', Validators.required],
      isMoon: [this.holidayData?.isMoon || false],
      description: [this.holidayData?.description || ''],
    });
  }

  private loadHolidayTypes() {
    this.holidayTypeData = Object.values(HolidayTypes).map((type) => ({
      label: type.label,
      value: type.value,
    }));
  }

  private formatToLocalDate(date: any): string {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Return in 'YYYY-MM-DD' format
  }

  onSubmit(): void {
    if (!this.holidayData) {
      this.holidayCalendarForm.markAllAsTouched();
      if (this.holidayCalendarForm.valid) {
        let { holidayName, holidayDate, holidayType, isMoon, description } =
          this.holidayCalendarForm.value;

        // Format holidayDate to 'YYYY-MM-DD'
        holidayDate = this.formatToLocalDate(holidayDate);

        const createHolidayCalendarInput = {
          holidayName,
          holidayDate,
          holidayType,
          isMoon,
          description,
        };

        this.loading = true;
        this.holidayService
          .createHolidayCalendar(createHolidayCalendarInput)
          .subscribe(
            (response) => {
              this.loading = false;
              this.toastr.success('Holiday created successfully!', 'Success', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              });
              this.dialogRef.close('success');
            },
            (error) => {
              this.loading = false;
              let errorMessage = 'Failed to add holiday. Please try again';
              if (error.error && error.error.message) {
                errorMessage = error.error.message;
              } else if (error.message) {
                errorMessage = error.message;
              }

              this.toastr.error(errorMessage, 'Error', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              });
            }
          );
      }
    } else {
      this.holidayCalendarForm.markAllAsTouched();
      if (this.holidayCalendarForm.valid) {
        let holidayData = { ...this.holidayCalendarForm.value };

        // Ensure the holidayDate is treated as local date
        holidayData.holidayDate = this.formatToLocalDate(
          holidayData.holidayDate
        );

        this.loading = true;
        this.holidayService.updateHolidayCalendar(holidayData).subscribe(
          (response) => {
            this.toastr.success('Holiday updated successfully!', 'Success', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            });
            this.dialogRef.close('success');
          },
          (error) => {
            let errorMessage = 'Failed to update holiday. Please try again.';
            if (error.error && error.error.message) {
              errorMessage = error.error.message;
            } else if (error.message) {
              errorMessage = error.message;
            }

            this.toastr.error(
              'Failed to update holiday. Please try again.',
              'Error',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              }
            );
          }
        );
      }
    }
  }
}

@Component({
  selector: 'upload-holiday-dialog',
  templateUrl: 'upload-holiday-dialog.html',
  styleUrls: ['./holiday.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class UploadHolidayDialog {
  file: any = null;
  url: any = null;
  loading: boolean = false;
  uploadForm: FormGroup;

  constructor(
    private toastr: ToastrService,
    private holidayService: HolidayCalendarService,
    private dialogRef: DialogRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.uploadForm = this.fb.group({
      file: [null, Validators.required],
    });
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  uploadDocuments(csvFile: any) {
    this.loading = true;
    this.holidayService.bulkCreateHolidayCalendar(csvFile).subscribe({
      next: (response) => {
        console.log(response);
        this.loading = false;
        if (response && !response.errors) {
          this.toastr.success(
            'Holidays updated successfully!'
          );
          this.dialogRef.close();
        } else if (response?.errors) {
          this.toastr.error(response.errors[0]?.message, 'Error');
          this.dialogRef.close();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk holiday creation failed');
        this.dialogRef.close();
        this.loading = false;
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  onUpload() {
    if (this.uploadForm.invalid && !this.file) {
      this.uploadForm.markAllAsTouched();
      return;
    }
    this.uploadDocuments(this.file);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
