import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as NotificationActions from '../../../core/notifications/notification.action';
import { Notification } from '../../../core/notifications/notification.model';
import { GET_MY_NOTIFICATIONS } from '../graphql/querries/notification.querries';
import { NEW_NOTIFICATION_SUBSCRIPTION } from '../graphql/subscription/notification.subscription';


export interface NewNotificationResponse {
  newNotification: {
    id: string;
    title: string;
    message: string;
    notificationType: string;
    createdAt: string;
  };
}


@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationsSubject = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notificationsSubject.asObservable();

  constructor(
    private apollo: Apollo,
    private store: Store
  ) {}

  startListening() {
    this.apollo.subscribe<{ newNotification: Notification }>({ query: NEW_NOTIFICATION_SUBSCRIPTION }).subscribe({
      next: ({ data }) => {
        if (data?.newNotification) {
          const currentNotifications = this.notificationsSubject.getValue();
          this.notificationsSubject.next([data.newNotification, ...currentNotifications]);
          this.store.dispatch(NotificationActions.addNotification({ notification: data.newNotification }));
        }
      },
      error: (err) => console.error('Error in notification subscription:', err),
      complete: () => console.log('Notification subscription completed'),
    });
  }

  loadNotifications() {    
    this.apollo.use('communication')
      .query<{ getMyNotifications: Notification[] }>({
        query: GET_MY_NOTIFICATIONS,
      })
      .subscribe({
        next: (response) => {          
          if( response?.data?.getMyNotifications){
            this.store.dispatch(NotificationActions.loadNotifications({ notifications: response.data.getMyNotifications }));
          }
        },
        error: (err) => console.error('Error loading notifications:', err),
      });
  }

  getNotifications() {
    return this.notifications$;
  }
}
