import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { filter, map } from 'rxjs';
import { AssetService } from '../../../../features/assets/services/asset.service';

@Component({
  selector: 'app-assets',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './assets.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class AssetsComponent implements OnInit {
  public viewPortSize: boolean = false;
  public themeClass: string = 'ag-theme-quartz';
  public loading: boolean = true;
  rowData: any[] = [];
  search: any;
  columnDefs: ({ headerName: string; field: string; flex?: undefined; cellRenderer?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; })[];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      { headerName: 'Asset Name', field: 'assetName' },
      { headerName: 'Asset ID', field: 'assetId', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Assigned Date', field: 'purchaseDate', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Condition', field: 'condition', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Status',
        field: 'assetStatus',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const status = params.data.assetStatus;
          const statusClass =
            status === 'Active'
              ? 'active'
              : status === 'Available'
                ? 'available'
                : status === 'Under Maintenance'
                  ? 'under-maintenance'
                  : status === 'Transferred'
                    ? 'transferred'
                    : '';
          return `<span class="${statusClass}">${status}</span>`;
        },
      },
    ];
  }
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public paginationPageSize = 25;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

  constructor(private assetService: AssetService) { }

  ngOnInit(): void {
    this.fetchAssetsForEmployee();
    this.checkViewportSize();
  }

  fetchAssetsForEmployee() {
    this.loading = true;
    this.assetService.getAssetsForEmployee().subscribe({
      next: (assets) => {
        this.rowData = assets.map((asset: any) => {
          return {
            assetName: asset.assetName,
            assetId: asset.id,
            purchaseDate: asset.purchaseDate,
            condition: asset.condition,
            assetStatus: asset.assetStatus,
          };
        });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching assets:', err);
        this.loading = false;
      },
    });
  }
}
