<section class="cmn-innerpage-wrapper expense-new">
  <h2 class="cmn-inner-heading">Record Expense</h2>
  <form [formGroup]="expenseForm" (ngSubmit)="onSubmit()">
    <div class="chart-account-form-wrapper">
      <div class="form-wrapper">
        <label for="">Date <span class="red">*</span></label>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="Select date" formControlName="date"
            [matDatepickerFilter]="dateFilter" />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="custom-error" *ngIf="expenseForm.get('date')!.touched">
          <span>
            <mat-error *ngIf="expenseForm.get('date')?.hasError('required')">Date is required</mat-error>
          </span>
        </div>
      </div>
      <div class="form-wrapper">
        <label for="">Paid Through <span class="red">*</span></label>
        <mat-form-field>
          <mat-select placeholder="Select an account" formControlName="paidThrough">
            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
              {{ account?.accountName }}
            </mat-option>
            <!-- <mat-option value="Single">Single</mat-option>
            <mat-option value="Married">Married</mat-option>
            <mat-option value="Divorced">Divorced</mat-option>
            <mat-option value="Widowed">Widowed</mat-option> -->
          </mat-select>
        </mat-form-field>
        <div class="custom-error">
          <span>
            <mat-error *ngIf="expenseForm.get('paidThrough')?.hasError('required')">Paid Through is required</mat-error>
          </span>
        </div>
      </div>
      <div class="form-wrapper">
        <label for="">Currency <span class="red">*</span></label>
        <mat-form-field>
          <mat-select placeholder="Select Currency" formControlName="currencyId">
            <mat-option *ngFor="let currency of currencyData" [value]="currency.id">
              {{ currency?.currencyCode }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <button type="button" class="loop-btn" (click)="onItemizeRemove()">
      {{ formArrayInitialized ? "Back to single expense view" : "Itemize" }}
    </button>
    <div formArrayName="expenses">
      <div class="chart-account-form-wrapper loop-wrap" *ngFor="let expense of expenses.controls; let i = index"
        [formGroupName]="i">
        <div class="form-wrapper">
          <label for="">Expense Account <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select an account" formControlName="accountId">
              <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                {{ account?.accountName }}
              </mat-option>
              <!-- <mat-option value="Single">Single</mat-option>
                <mat-option value="Married">Married</mat-option>
                <mat-option value="Divorced">Divorced</mat-option>
                <mat-option value="Widowed">Widowed</mat-option> -->
            </mat-select>
          </mat-form-field>
          <div class="custom-error" *ngIf="expenseForm.get('date')!.touched">
            <span>
              <mat-error *ngIf="expense.get('accountId')?.hasError('required')">Expense Account is required</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Notes</label>
          <mat-form-field>
            <textarea matInput placeholder="Enter description" formControlName="description"></textarea>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="expense.get('description')?.hasError('maxlength')">Notes cannot exceed 500
                characters</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Amount <span class="red">*</span></label>
          <mat-form-field>
            <input matInput type="number" placeholder="AED" formControlName="amount" />
          </mat-form-field>
          <div class="custom-error" *ngIf="expenseForm.get('date')!.touched">
            <span>
              <mat-error *ngIf="expense.get('amount')?.hasError('required')">Amount is required</mat-error>
            </span>
            <span>
              <mat-error *ngIf="expense.get('amount')?.hasError('pattern')">Amount must be a valid positive
                number</mat-error>
            </span>
          </div>
        </div>
        <button type="button" class="loop-remove-btn" (click)="removeExpenseRow(i)"
          *ngIf="this.expenses?.value?.length > 1">
          <img src="/assets/images/icons/icon-close.svg" alt="Remove" />
        </button>
      </div>
      <div class="loop-btn-wrapper">
        <button type="button" class="add-new-btn" (click)="addExpenseRow()" *ngIf="formArrayInitialized">
          <img src="/assets/images/icons/icon-add-color.svg" alt="Add" />
          Add New
        </button>
      </div>
    </div>
    <div class="chart-account-form-wrapper">
      <div class="form-wrapper">
        <label for="">Vendor</label>
        <mat-form-field>
          <mat-select placeholder="Select an account" formControlName="venderId">
            <mat-option *ngFor="let vendors of vendorsList" [value]="vendors.id">
              {{ vendors?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-wrapper">
        <label for="">Invoice Number#</label>
        <mat-form-field>
          <input matInput placeholder="IN-0023" formControlName="invoiceNo" />
        </mat-form-field>
        <div class="custom-error">
          <span>
            <mat-error *ngIf="expenseForm.get('invoiceNo')?.hasError('required')">Invoice Number is required</mat-error>
          </span>
          <span>
            <mat-error *ngIf="expenseForm.get('invoiceNo')?.hasError('pattern')">Invoice Number must start with 'IN-' and contain only alphanumeric haracters</mat-error>
          </span>
          <span>
            <mat-error *ngIf="expenseForm.get('invoiceNo')?.hasError('maxLength')">Invoice Number cannot exceed 50 characters</mat-error>
          </span>
        </div>
      </div>

      <div class="form-wrapper">
        <!-- <label for="">Customer</label>
        <mat-form-field>
          <mat-select placeholder="Select vendor" formControlName="customerId">
            <mat-option
              *ngFor="let customer of customerList"
              [value]="customer.id"
            >
              {{ customer?.name }}
            </mat-option>
          
          </mat-select>
        </mat-form-field> -->
        <div class="cmn-Search-select-container">
          <label for="clientName">Customer Name</label>
          <mat-form-field>
            <mat-select placeholder="Search Customer" formControlName="partnerId"
              (selectionChange)="onCustomerSelected($event)">
              <mat-option>
                <ngx-mat-select-search (input)="onSearch($event)" placeholderLabel="Search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let customer of customerList" [value]="customer.id">
                {{ customer?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button type="button" class="add-customer" (click)="openCreateClientDialog()">
            <div>
              <img src="/assets/images/icons/icon-add-color.svg" alt="Add client" />
            </div>
            <span>Add customer</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="this.expenseForm?.value?.customerId">
      <div class="form-wrapper">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isBillable" />Billable
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="form-wrapper">
        <label for="">Projects</label>
        <mat-form-field>
          <mat-select placeholder="Choose customer" formControlName="projectId">
            <mat-option *ngFor="let project of filterProjects" [value]="project.id">
              {{ project?.projectName }}
            </mat-option>
            <!-- <mat-option value="Single">Single</mat-option>
            <mat-option value="Married">Married</mat-option>
            <mat-option value="Divorced">Divorced</mat-option>
            <mat-option value="Widowed">Widowed</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="form-wrapper">
        <label for="">Invoice#</label>
        <mat-form-field>
          <input matInput placeholder="IN-0023" />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Description</label>
        <mat-form-field>
          <textarea matInput placeholder="Enter description"></textarea>
        </mat-form-field>
      </div> -->
    <div class="submit-btn-wrapper">
      <button type="button" class="cmn-cancel-btn" [disabled]="loading" (click)="onClearForm()">
        Cancel
      </button>
      <button class="cmn-next-btn" [disabled]="loading">
        {{ loading ? "Loading..." : "Save" }}
      </button>
    </div>
  </form>
</section>