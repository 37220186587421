<mat-dialog-content>
  <section class="upload-employees-wrapper">
    <h2 class="cmn-popup-title">Petty Cash Details</h2>
    <button class="cmn-close" mat-dialog-close></button>
 
      <div class="title">
        <h2>{{data?.projectName}}</h2>
      </div>

      <div class="table-wrapper" *ngIf="pettyCashDetail && pettyCashDetail?.pettyCashes?.length; else noData">
        <table>
          <thead>
              <tr>
                  <th>Description</th>
                  <th>In</th>
                  <th>Out</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let petty of pettyCashDetail?.pettyCashes">
                <td>{{ petty?.description ? (petty.description | slice:0:20) + (petty.description.length > 20 ? '...' : '') : '--' }}</td>
                  <td>
                    {{ petty?.flow === 'In' && petty?.amount ? 'AED ' + petty?.amount.toFixed(2) : '-' }}
                  </td>
                  <td> 
                    {{ petty?.flow === 'Out' && petty?.amount ? 'AED ' + petty?.amount.toFixed(2) : '-' }}
                  </tr>
          </tbody>
      </table>
      </div>

      <ng-template #noData>
        <p>No records found.</p>
      </ng-template>
      <button
        class="cmn-upload-submit-btn active"
        (click)="onClose()"
      >
        <span> Close</span>
      </button>
  </section>
</mat-dialog-content>
