<div class="banking-sidedialog-content-wrapper">
    <div class="banking-tab-inner-content-form">
        <form [formGroup]="categorizeForm">
            <div class="manually-form-wrapper">
                <div class="form-wrapper">
                    <label for="">Category<span class="red">*</span></label>
                    <mat-form-field>
                        <mat-select (selectionChange)="selectionChangeCategory($event)"
                            placeholder="Add Transactions" formControlName="category">
                            <ng-container *ngFor="let category of categories">
                                <mat-option
                                    [value]="category"
                                    style="text-transform: capitalize;">
                                    {{ category?.categoryName }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Date <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Choose date"
                            formControlName="date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Amount <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Enter amount" formControlName="amount" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Reference#</label>
                    <mat-form-field>
                        <input matInput placeholder="REF-0023" formControlName="reference" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Description</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter description"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn">Cancel</button>
            <button class="cmn-next-btn" (click)="onUpdate()">Update</button>
        </div>
    </div>
</div>