import { gql } from 'apollo-angular';

export const PAYMENTS = gql`
query payments($search: String, $filter: PaymentFilterInput) {
  payments(search: $search, filter: $filter) {
    id
    partnerId
    partner {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      trnNumber
      paymentStatus
      addresses {
        addressLine2
        attention
        addressType
        country
        addressLine1
        state
      }
    }
    paymentType
    amount
    bankCharge
    paymentDate
    paymentNo
    referenceNo
    depositId
    deposit {
      id
      accountName
      accountType
      amount
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    paymentMode
    totalAmount
    receiptUrl
    note
    createdAt
    updatedAt
    deletedAt
    paymentInfo {
      id
      paymentId
      refType
      refId
      bill {
        id
        balance
        billDate
        totalPrice
        subTotal
        subject
        billNo
        customerNote
        __typename
      }
      invoice {
        id
        invoiceNo
        orderNo
        invoiceDate
        paymentTerm
        paymentStatus
        reason
        dueDate
        subject
        customerNote
        isPayment
        subTotal
        tax
        adjustment
        totalPrice
        partnerId
        deletedAt
        __typename
      }
      amount
      __typename
    }
    PaymentsJournalData {
      id
      paymentId
      journalId
      JournalData {
        id
        currencyId
        journalEnteries {
          id
          debit
          credit
          __typename
        }
        __typename
      }
      __typename
    }
      branchData {
        id
        branchName
        address
        city
        state
        country
        zipCode
        __typename
      }
    journal {
      journalNo
      id
      journalEnteries {
        chartOfAccountId
        ChartOfAccount {
          accountName
          __typename
        }
        journalId
        partnerId
        debit
        credit
        __typename
      }
      __typename
    }
    __typename
  }
}
`;

export const GET_PARTNERS = gql`
  query getPartnersDropDownItems(
  $id: Int,
    $search: String
    $partnerType: PartnerTypesEnum
  ) {
    getPartnersDropDownItems(id:$id, search: $search, partnerType: $partnerType) {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
  }
`;

export const GET_ACCOUNT = gql`
  query {
    getAccountDropDownItems {
      id
      accountName
      accountType
      balance
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const DEPOSIT_TO = gql`
  query getChartOfAccountDropDownItems(
    $search: String
    $isPaymementReceive: Boolean
    $isPaymementMode: Boolean
  ) {
    getChartOfAccountDropDownItems(
      search: $search
      isPaymementReceive: $isPaymementReceive
      isPaymementMode: $isPaymementMode
    ) {
      id
      accountType
      accountName
      accountCode
      description
      amount
      parentAccountId
      isSubAccount
      isEditable
      status
    }
  }
`;

export const GET_DUE_INVOICES_BY_PARTNER = gql`
  query getDueInvoicesByPartner($partnerId: Int!, $dateRange: DateRangeInput) {
    getDueInvoicesByPartner(partnerId: $partnerId, dateRange: $dateRange) {
      id
      projectModelId
      quoteId
      journalId
      partnerId
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      paymentInfos {
        id
        paymentId
        refType
        refId
        amount
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_DUE_BILLS_BY_PARTNER = gql`
  query getDueBillsByPartner($partnerId: Int!, $dateRange: DateRangeInput) {
    getDueBillsByPartner(partnerId: $partnerId, dateRange: $dateRange) {
      id
      billNo
      billDate
      paymentTerm
      dueDate
      subject
      customerNote
      billPaymentStatus
      subTotal
      tax
      adjustment
      totalPrice
      balance
      billStatus
      status
      partnerId
      partnerData {
        displayName
        partnerType
      }
    }
  }
`;

export const PAYMENT = gql`
  query payment($id: Int!) {
    payment(id: $id) {
      id
      partnerId
      partner {
        id
        partnerType
        customerType
        displayName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        createdAt
        updatedAt
        deletedAt
      }
      paymentType
      amount
      bankCharge
      paymentDate
      paymentNo
      referenceNo
      chequeNo
      chequeDate
      depositId
      deposit {
        id
        accountName
        accountType
        amount
        createdAt
        updatedAt
        deletedAt
      }
      paymentMode
      totalAmount
      receiptUrl
      note
      createdAt
      updatedAt
      deletedAt
      paymentInfo {
        id
        paymentId
        refType
        refId
        invoice {
          id
          invoiceNo
          orderNo
          invoiceDate
          paymentTerm
          paymentStatus
          reason
          dueDate
          subject
          customerNote
          isPayment
          subTotal
          tax
          adjustment
          totalPrice
          partnerId
          deletedAt
        }
        amount
      }
      PaymentsJournalData {
        id
        paymentId

        journalId
      }
      paymentAttachments {
        id
        fileName
        fileUrl
        createdAt
        deletedAt
        updatedAt
      }
    }
  }
`;

export const COMMENTS = gql`
  query comments($conditions: CommentConditionsInput) {
    comments(conditions: $conditions) {
      id
      employeeId
      refId
      type
      activityType
      note
      createdAt
      employee {
        id
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GET_PAYMENT_DETAILS = gql`
  query getPaymentDetails($id: Int!, $invoiceOrBillIds: [Int!]) {
    getPaymentDetails(id: $id, invoiceOrBillIds: $invoiceOrBillIds) {
      id
      partnerId
      partner {
        email
        primaryContact {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
      }
      paymentTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
      paymentType
      amount
      bankCharge
      paymentDate
      paymentNo
      referenceNo
      depositId
      paymentMode
      totalAmount
      receiptUrl
      note
      createdAt
    }
  }
`;



export const GENERATE_PAYMENT_PDF = gql`
  query generatePaymentPdf($paymentId: Int!, $paymentType: String!) {
    generatePaymentPdf(paymentId: $paymentId, paymentType: $paymentType)
  }
`;
