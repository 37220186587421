
    <section class="cmn-innerpage-wrapper payments-made-details">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
            </div>
            <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button>
            <button class="cmn-add-btn" (click)="navigateTo('/payments-made/bill-payment-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Payments
            </button>
            <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
                <span *ngIf="selectedCount > 0">{{ selectedCount }}</span>
            </button>
            <!-- Filter -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading" (click)="clearFilters()">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Payment Mode</h2>
                        <div class="check-wrapper">
                            <div class="check-box" *ngFor="let mode of paymentModeList">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" [checked]="selectedModes.includes(mode)"
                                        (change)="toggleMode(mode)" />
                                    {{ mode }}
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="button" (click)="onApplyFilters()">Apply</button>
                    </div>
                </form>
            </div>
            <!-- Filter -->
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All Payment Made</li>
                <li class="edit" (click)="navigateToMail()"><img src="assets/images/icons/icon-send.svg" alt="Mail"> Send to mail</li>
                <li class="edit" (click)="navigateToEdit('/payments-made/bill-payment-new')"><img
                        src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
                <li class="edit" (click)="onViewDocument()" [ngClass]="{ 'loading-cursor': pdfLoading }"> <img src="assets/images/icons/icon-pdf.svg" alt="PDF" /> PDF </li>
            </ul>
            <div class="mat-menu-wrapper">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="downloadPdf()">
                        <span>PDF download</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper">
                    <button *ngFor="let item of allItems; let i = index" class="tab-custom-button"
                        [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                        <div class="inn-box">
                            <h2>{{ item?.partner?.displayName || "---" }}</h2>
                            <h3> {{ item?.paymentNo || "---" }} </h3>
                        </div>
                        <div class="inn-box">
                            <h6>{{ item?.paymentDate ? (item?.paymentDate | date: 'MM/dd/yyyy') : "---" }}</h6>
                            <h5>AED <span>{{ item?.amount | number:'1.2-2'}}</span></h5>
                        </div>
                        <div class="inn-box">
                            <h4>{{ item?.paymentMode || "---" }}</h4>
                        </div>
                    </button>
                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div *ngIf="itemData">
                    <div class="chart-tab-content">
                        <div class="tab-container">
                            <div class="tab-inner">
                                <div class="note-header">
                                    <div class="left-set">
                                        <h4>{{itemData?.branchData?.branchName}}</h4>
                                        <h3>{{itemData?.branchData?.address}}</h3>
                                        <h3>{{itemData?.branchData?.city}}, {{itemData?.branchData?.country}}</h3>
                                        <h3>TRN: 100291397600003</h3>
                                    </div>
                                    <div class="right-set">
                                        <h2>PAYMENT MADE</h2>
                                        <h3>{{itemData?.paymentNo}}</h3>
                                        <h3>{{itemData?.paymentDate | date: 'dd/MM/yyyy'}}</h3>
                                    </div>
                                </div>
                                <br/>

                                <div class="journal-details">
                                    <div class="left-set">
                                        <div class="details-box">
                                            <h4>Bill To </h4>
                                        </div>
                                        <br/>
                                        <div class="left-set">
                                                <h4>{{itemData?.partner?.displayName }}</h4>
                                                <h3>{{billingAddress?.addressLine1}}</h3>
                                                <h3>TRN: {{itemData?.partner?.trnNumber}}</h3>
                                        </div>
                                    </div>
                                    <div class="right-set">
                                        <div class="receipt-box">
                                            <h5>Amount Paid</h5>
                                            <h6>AED {{ itemData?.amount | number:'1.2-2' }}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="crdit-note-page-table">
                                    <h1>Payment for</h1>
                                    <table>
                                        <tr>
                                            <th>Bill#</th>
                                            <th>Bill Date</th>
                                            <th>Bill Amount</th>
                                            <th>Payment Amount</th>
                                        </tr>
                                        <tr *ngFor="let info of itemData?.paymentInfo;">
                                            <td>{{info?.bill?.billNo || "___"}}</td>
                                            <td>{{ info?.bill?.billDate ? (info?.bill?.billDate | date:
                                                'MM/dd/yyyy')
                                                : "---" }}</td>
                                            <td>AED {{info?.bill?.totalPrice| number:'1.2-2'}}</td>
                                            <td>AED {{info?.amount | number:'1.2-2'}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="credit-note-footer-table-wrapper">

                            <table>
                                <tr class="title">
                                    <td>Journal</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Account</td>
                                    <td>Debit</td>
                                    <td>Credit</td>
                                </tr>
                                <tr *ngFor="let entry of journalData?.journalEnteries" class="bold">
                                    <td>{{ entry.ChartOfAccount?.accountName || 'N/A' }}</td>
                                    <td>AED {{ entry.debit | number:'1.2-2' }}</td>
                                    <td>AED {{ entry.credit | number:'1.2-2' }}</td>
                            </tr>
                            <!-- <tr class="bold border">
                                <td>Personal (Current)</td>
                                <td>AED 3,500.00</td>
                                <td>AED 0.00</td>
                            </tr> -->
                            <tr class="bold border">
                                <td></td>
                                <td>AED {{ calculateTotalDebit(journalData?.journalEnteries) | number:'1.2-2' }}</td>
                                <td>AED {{ calculateTotalCredit(journalData?.journalEnteries) | number:'1.2-2' }}</td>
                            </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
