import { gql } from "apollo-angular";

export const LIST_BILLS = gql`
  query bills($search: String ) {
    bills(search: $search) {
    id
    billNo
    # orderNo
    purchaseOrderData{
      purchaseOrderNo
    }
    billDate
    paymentTerm
    dueDate
    subject
    customerNote
    billPaymentStatus
    subTotal
    tax
    adjustment
    totalPrice
    balance
    billStatus
    status
    createdAt
    updatedAt
    deletedAt
    billItems {
      id
      billId
      itemId
      description
      quantity
      rate
      discount
      amount
      status
      createdAt
      updatedAt
      deletedAt
    }
    billAttachments {
      id
      billId
      fileUrl
      status
      createdAt
      updatedAt
      deletedAt
    }
    projectId
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      partnerId
    }
    partnerId
    partnerData {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    billingAddressId
    billingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    shippingAddressId
    shippingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    journalId
    journalData {
      id
      date
      journalNo
      reference
      notes
      currencyId
      inCash
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
  }
`;

export const LIST_BILL = gql`
  query bill($id: Int!) {
    bill(id: $id) {
    id
    billNo
    # orderNo
    purchaseOrderId
    billDate
    paymentTerm
    dueDate
    subject
    customerNote
    billPaymentStatus
    subTotal
    tax
    adjustment
    totalPrice
    balance
    billStatus
    status
    createdAt
    updatedAt
    deletedAt
    billItems {
      id
      billId
      purchaseItemData{
        id
        quantity
        unit
        billItems{
          id
          quantity
        }
      }
      itemData {
        id
        itemName
        itemType
      }
      description
      quantity
      rate
      tax
      discount
      amount
      status
      createdAt
      updatedAt
      deletedAt
    }
    billAttachments {
      id
      billId
      fileUrl
      fileName
      status
      createdAt
      updatedAt
      deletedAt
    }
    projectId
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      partnerId
    }
    partnerId
    partnerData {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    billingAddressId
    billingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    shippingAddressId
    shippingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    journalId
    journalData {
      id
      date
      journalNo
      reference
      notes
      currencyId
      inCash
      journalEnteries {
        id
        chartOfAccountId
        ChartOfAccount {
          id
          accountName
        }
        journalId
        partnerId
        description
        debit
        credit
      }
    }
    paymentInfos {
      id
      paymentId
      refType
      refId
      amount
      createdAt
      updatedAt
    }
  }
  }
`;

export const PURCHASE_ORDER_FOR_BILL = gql`
  query getPurchaseOrderForBillCreation($id: Int!) {
    getPurchaseOrderForBillCreation(id: $id)  {
    id
    name
    description
    quantity
    balanceQuantity
    purchaseId
    purchaseOrder {
      id
      vendorId
      # vendor
      projectId
      # project
      customerId
      # customer
      billingAddressId
      # billingAddressData
      # shippingAddressId
      # shippingAddressData
      purchaseOrderNo
      subject
      reference
      dueDate
      billDate
      paymentTerms
      customerNote
      shippmentInstructions
      status
      billStatus
      purchaseStatus
      isLocal
      tax
      purchaseUrl
      TRNno
      totalPrice
      subTotal
      adjustment
      purchaseItems{
        quantity
      }
      # goodsReceived
      currencyId
      # currencyData
      workorderId
      # workorderData
      # purchaseOrderTemplate
      # branchId
      # branchData
    }
    itemId
    purchaseAccountId
    purchaseAccount {
      id
      accountType
      accountName
      accountCode
      description
      amount
      accountId
      # accountInfo
      # parentAccountId
      # parentAccountData
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
      # JournalEnteries
      # transactions
      # statements
      # depositedPayments
      # accountDetails
      # expenseItems
      # accountData
      # paidThroughData
      # childAccounts
    }
    item {
      id
      itemType
      skuCode
      imageUrl
      isSalesActive
      isPurchaseActive
      isDisplay
      quantity
      unit
      itemName
      sellingPrice
      salesAccount
      salesDescription
      status
      notes
      costPrice
      purchaseAccount
      purchaseDescription
      salesAccountId
      # salesAccountData
      # purchaseAccountId
      # purchaseAccountData
      # createdAt
      # updatedAt
      # deletedAt
      # itemBillItems
      # items
      # itemData
    }
    unit
    rate
    discount
    amount
    isManual
    goodsReceivedItems {
      id
      quantity
      grnItemStatus
      status
      remark
      goodsReceivedId
      # goodsReceivedData
      # purchaseItemId
      # purchaseItemData
    }
    billItems {
      id
      billId
      billData{
        status
      }
      # itemId
      # itemData
      # purchaseItemId
      # purchaseItemData
      description
      quantity
      rate
      discount
      amount
      tax
      status
      createdAt
      updatedAt
      deletedAt
    }
    status
  }
  }
`;

