import { Component, HostListener, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { LeaveService } from '../../../../../../features/leave/services/leave.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-history',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './leave-history.component.html',
  styleUrl: '../../../../employee-details.component.scss'
})
export class LeaveHistoryComponent {
  public viewPortSize : boolean = false;
  public loading: boolean = true;
  rowData: any[] = [];
  filter: any;
  employeeId: number;
  public themeClass: string = 'ag-theme-quartz';
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; flex: number; cellRenderer: (params: any) => string; field?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => "" | HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];

  @HostListener('window:resize', ['$event'])
  onResize(event : any) {
      this.checkViewportSize();
  }

  checkViewportSize() {
      if (window.innerWidth > 1200) {
          this.viewPortSize = true;
      } else {
          this.viewPortSize = false;
      }
      this.setAGColumn();
  }

  setAGColumn() {
      this.columnDefs = [
    { headerName: 'Leave Type', field: "leaveType", flex: this.viewPortSize ? 1 : 0 },
    { headerName: 'Duration', field: "duration", flex: this.viewPortSize ? 1 : 0 },
    {
      headerName: 'Days', field: "days", flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const days = params.data?.days;
        if (days <= 1) {
          return `<span>${days} Day</span>`;
        } else if (days > 1) {
          return `<span>${days} Days</span>`;
        } else {
          return `<span>${days}</span>`;
        }
      }
    },
    // { headerName: 'Reporting Manager', field: "reportingManager", flex: this.viewPortSize ? 1 : 0 },
    {
      headerName: 'Reporting Manager',
      flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const approver1 = params.data?.approver1
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver1}</span>`
          : '';
        const approver2 = params.data?.approver2
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver2}</span>`
          : '';
        const approver3 = params.data?.approver3
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver3}</span>`
          : '';
        const approver4 = params.data?.approver4
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver4}</span>`
          : '';

        return `
              <div>
                ${approver1}
                ${approver2}
                ${approver3}
                ${approver4}
              </div>
            `;
      },
    },
    {
      headerName: 'Status', field: "status", flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const status = params.data?.status;
        const statusClass =
          status === "Pending" ? 'yellow' : status === "Approved" ? 'green' : status === "Rejected" ? 'red' : '';
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
    {
      headerName: 'Action',
      field: "status",
      flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const status = params.data?.status;
        const showButton = status === "Pending" || status === "Approved";
        if (showButton) {
          const button = document.createElement('button');
          button.className = 'leave-cancel';
          button.innerText = 'Cancel Request';
          button.addEventListener('click', () => {
            this.onCancel(params?.data?.id);
          });
          const wrapper = document.createElement('div');
          wrapper.className = 'submit-btn-wrapper';
          wrapper.appendChild(button);
          return wrapper;
        }
        return '';
      },
      floatingFilter: false,
      filter: false,
    }
       
  ];
}

  public defaultColDef: ColDef = {
    filter: "agTextColumnFilter",
    floatingFilter: true,
  };
  public paginationPageSize = 25;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  readonly dialog = inject(MatDialog);

  constructor(
    private leaveService: LeaveService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
      this.route.params.subscribe((params: Params) => {
      this.employeeId = params['id'];
    })
  }

  ngOnInit() {
    this.fetchLeaves();
    this.checkViewportSize();
  }

  ngOnChanges(){
    this.fetchLeaves();
  }

  fetchLeaves() {
    this.filter = { employeeId: Number(this.employeeId) };
    this.loading = true;
    this.leaveService.fetchLeaves(this.filter).subscribe({
      next: (leaves) => {
        this.rowData = leaves
          .map((leaves: any) => {
            return {
              // date: this.datePipe.transform((leaves?.createdAt), 'MMM d, yyyy') || '--',
              id: leaves?.id,
              leaveType: leaves?.leaveType || '--',
              days: leaves?.totalLeaveDays || '--',
              status: leaves?.leaveStatus || '--',
              duration: this.datePipe.transform((leaves?.startDate), 'MMM d') + ' - ' +
                this.datePipe.transform((leaves?.endDate), 'MMM d') || '--',
                reportingManager: (leaves?.employee?.reportingManager?.firstName && leaves?.employee?.reportingManager?.lastName) 
                ? leaves.employee.reportingManager.firstName + ' ' + leaves.employee.reportingManager.lastName 
                : '--',   
                approver1:
                leaves?.employee?.employeeApprovers[0]?.levelOneData?.firstName +
                ' ' +
                leaves?.employee?.employeeApprovers[0]?.levelOneData
                  ?.lastName || '--',

              ...(leaves?.employee?.employeeApprovers[0]?.levelTwoData && {
                approver2:
                leaves?.employee?.employeeApprovers[0]?.levelTwoData
                    ?.firstName +
                  ' ' +
                  leaves?.employee?.employeeApprovers[0]?.levelTwoData?.lastName,
              }),

              ...(leaves?.employee?.employeeApprovers[0]?.levelThreeData && {
                approver3:
                leaves?.employee?.employeeApprovers[0]?.levelThreeData
                    ?.firstName +
                  ' ' +
                  leaves?.employee?.employeeApprovers[0]?.levelThreeData
                    ?.lastName,
              }),

              ...(leaves?.employee?.employeeApprovers[0]?.levelFourData && {
                approver4:
                leaves?.employee?.employeeApprovers[0]?.levelFourData
                    ?.firstName +
                  ' ' +
                  leaves?.employee?.employeeApprovers[0]?.levelFourData?.lastName,
              }),           
            };  
          });
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error('Failed to load leaves. Please try again.', 'Error');
        this.loading = false;
      },
    });
  }

  onCancel(leaveId: any){
      this.leaveService.updateMyLeaveStatus(leaveId, 'CANCELLED').subscribe({
        next: (data) => {
          if(data){
            this.toastr.success('Leave request cancelled successfully','Success');
            this.fetchLeaves();
          }
        },
        error: (error) => {
          this.toastr.error(error?.message, 'Error')
        }
      });
  }
}