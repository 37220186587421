import { Component, HostListener, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { QuotationService } from '../../features/quotation/services/quotation.service';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-quotation',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './quotation.component.html',
  styleUrl: './quotation.component.scss',
})
export class QuotationComponent implements OnInit {
  public viewPortSize: boolean = false;
  public rowData: any[] = [];
  public loading: boolean = true;
  public summaryLoading: boolean = true;
  public error: any = null;
  quotesDashboardData: any;
  private searchSubject = new Subject<string>();

  columnDefs: (
    | {
        headerName: string;
        field: string;
        flex: number;
        cellRenderer?: undefined;
        floatingFilter?: undefined;
        filter?: undefined;
      }
    | {
        field: string;
        headerName: string;
        flex: number;
        cellRenderer: (params: any) => HTMLDivElement;
        floatingFilter: boolean;
        filter: boolean;
      }
  )[];
  profileName: any;
  parentQuoteStatus: string;
  
  constructor(
    private router: Router,
    private quotationService: QuotationService,
    private datePipe: DatePipe,
    private quoteService: QuotationService,
    private toastr: ToastrService
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadQuotationDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      {
        headerName: 'Code',
        field: 'parentCode',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Date',
        field: 'quoteDate',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Quote Number',
        field: 'quoteCode',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Customer Name',
        field: 'customerName',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Total Amount',
        field: 'totalAmount',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Status',
        field: 'parentQuoteStatus',
        flex: this.viewPortSize ? 1 : 0,
      },
      // {
      //   headerName: 'Status',
      //   field: 'parentQuoteStatus',
      //   flex: this.viewPortSize ? 1 : 0,
      // },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          // const editIcon = document.createElement('img');
          // editIcon.src = 'assets/images/icons/icon-edit.svg';
          // editIcon.style.cursor = 'pointer';
          // editIcon.setAttribute('data-action', 'edit');

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');
          // container.appendChild(editIcon);

          if (this.profileName === 'admin') {
            container.appendChild(deleteIcon);
          }
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';

  ngOnInit(): void {
    this.fetchQuotations();
    this.fetchQUoteSummary();
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchQuotations(searchTerm);
      });

    this.checkViewportSize();
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchQUoteSummary() {
    this.summaryLoading = true;
    this.quotationService.FetchQuoteSummary().subscribe({
      next: (summary) => {
        this.quotesDashboardData = summary;
      }
    })
  }

  fetchQuotations(search: string = '') {
    this.loading = true;
    this.quotationService.fetchParentQuotes(search, this.parentQuoteStatus).subscribe({
      next: (quotes) => {
        this.rowData = quotes.map((quote: any) => {
          return {
            id: quote?.id,
            parentCode: quote?.parentCode,
            quoteDate: this.datePipe.transform(quote?.quoteDate, 'dd/MM/yyyy'),
            quoteCode: quote?.quoteCode,
            customerName: quote?.partnerData?.displayName,
            parentQuoteStatus: this.findStatus(quote?.quoteData[0]?.status),
            totalAmount: quote?.totalAmount.toFixed(2),
          };
        });

        // Update pagination options based on the rowData length
        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);

        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching journals:', err);
        this.toastr.error("Failed to fetch quotes");
        this.loading = false;
      },
    });
  }


  findStatus(status: string) {
    if(status === 'SENT') {
      return 'Open';
    } else if(status === "ACCEPTED") {
      return  'Confirmed'
    } else {
      return 'Draft';
    }
  }


  fetchParents(status: string): void {
    console.log('Fetching parents for status:', status);
    this.parentQuoteStatus = status;
    this.fetchQuotations(); // Optionally, pass the status to the service
  }

  openEdit(quoteData: any) {
    const quoteId = quoteData?.id;
    if (quoteId) {
      this.router.navigate([`/quotation/quotation-edit/${quoteId}`]);
    }
  }

  onCellClicked(params: any) {
    const quoteId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;
      if (action === 'delete') {
        this.onDeleteParentQuote(params?.data);
      }
    } else {
      const id = params.data?.id;
      // this.router.navigate([`/quotation/quotation-details/${quoteId}`]);
      this.router.navigate([`quotation/quotation-details`], {
        queryParams: { id: id },
      });
    }
  }

  onDeleteParentQuote(parentQuoteData: any): void {
    if (parentQuoteData?.parentQuoteStatus === 'Confirmed') {
      this.toastr.warning('This quote is confirmed and cannot be deleted.');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeParentQuote(parentQuoteData?.id);
      }
    });
  }

  private removeParentQuote(parentQuoteId: number): void {
    this.quoteService.removeParentQuote(parentQuoteId).subscribe({
      next: () => {
        this.toastr.success('Quotation removed successfully.');
        this.fetchQuotations();
      },
      error: () => {
        this.toastr.error('Failed to remove Quotation. Please try again.');
      },
    });
  }
}

@Component({
  selector: 'upload-quotation-dialog',
  templateUrl: 'upload-quotation-dialog.html',
  styleUrls: ['./quotation.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadQuotationDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
