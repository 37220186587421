import { Component, HostListener, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ExpenseServices } from '../../features/expenses/services/expenses.service';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ProjectService } from '../../features/projects/services/projects.service';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged ,filter} from 'rxjs/operators';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
})
export class ProjectsComponent implements OnInit {
  public viewPortSize : boolean = false;
  loading: boolean = false;
  error: any = '';
  rowData = [];
  readonly dialog = inject(MatDialog);
  columnDefs: any[];
  profileName: any;
  search: any;
  private searchSubject = new Subject<string>();


  constructor(
    private router: Router,
    private currencyService: CurrencyAdjustmentService,
    private chartAccountService: ChartAccountsService,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private expenseService: ExpenseServices,
    private datePipe: DatePipe,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    this.fetchAllProjects();

    this.searchSubject
    .pipe(
      debounceTime(300), 
      distinctUntilChanged(), 
      filter((searchTerm:any) => searchTerm.trim().length >= 3 || searchTerm.trim().length === 0)
    )
    .subscribe((searchTerm) => {
      this.search = searchTerm.trim();
      this.fetchAllProjects();
    });

    this.checkViewportSize();
  }

  defaultMethod: any = {
    FixedCostForProject: 'Fixed Cost For Project',
    HourlyRate: 'Hourly Rate',
  };

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  


@HostListener('window:resize', ['$event'])
onResize(event : any) {
    this.checkViewportSize();
}

checkViewportSize() {
    if (window.innerWidth > 1200) {
        this.viewPortSize = true;
    } else {
        this.viewPortSize = false;
    }
    this.setAGColumn();
}

setAGColumn() {

  this.columnDefs = [
    {
      headerName: 'Customer Name',
      field: 'custName',
      flex: this.viewPortSize ? 1 : 0,
    },
    { headerName: 'Project Name', field: 'projName', flex: this.viewPortSize ? 1 : 0, },
    { headerName: 'Billing Method', field: 'billMethod', flex: this.viewPortSize ? 1 : 0, },
    { headerName: 'Total Cost', field: 'totalCost', flex: this.viewPortSize ? 1 : 0, },
    {
      field: 'action',
      headerName: 'Actions',
      flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        container.appendChild(editIcon);

        if(this.profileName === 'admin'){
          container.appendChild(deleteIcon);
        }
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];
}

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  private fetchAllProjects() {
    this.projectService.getAllProjects(this.search).subscribe({
      next: (projects: any) => {
        // Sort the designations by 'id' in descending order so the newest branch comes first
        this.rowData = projects.map((project: any) => {
          return {
            id: project?.id || '--',
            custName: project?.partner?.displayName ?? '--',
            billMethod: this.defaultMethod[project?.billingMethod] ?? '--',
            projName: project?.projectName ?? '--',
            totalCost: project.totalProjectCost
              ? `AED ` + project.totalProjectCost
              : '--',
          };
        });

        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);
      },
      error: (error: any) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  onCellClicked(params: any) {
    const projectId = params.data?.id;

    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(projectId);
      } else if (action === 'delete') {
        this.onConfirmDelete(projectId);
      }
    }
     else {
      this.router.navigate([`/projects/project-dashboard/${projectId}`]);
    }
  }

  onConfirmDelete(id: number): void {
    console.log(id);

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }
  onDelete(id: any) {
    this.projectService.deleteProject(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success('Project deleted successfully', 'Success');
          this.fetchAllProjects();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }
  onEdit(id: any) {
    console.log(' inside the');
    this.router.navigate([`/projects/project-edit/${id}`]);
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchSubject.next(input.value);
  }
  
}
