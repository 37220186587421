import { gql } from 'apollo-angular';

export const GET_EMPLOYEES = gql`
  query getEmployees($search: String, $filter: EmployeeFilter) {
    getEmployees(search: $search, filter: $filter) {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
    }
  }
`;

export const GET_ALL_EMPLOYEES = gql`
  query getAllEmployees($search: String) {
    getAllEmployees(search: $search) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_BRANCH_NAMES = gql`
  query branches($search: String) {
    branches(search: $search) {
      id
      branchName
      address
      city
      state
      email
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  query listProjects($search: String) {
    listProjects(search: $search) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      revenueBudget
      branchId
      billingMethod
      partnerId
      partner {
        id
        displayName
      }
    }
  }
`;

export const LIST_PROJECTS_BY_EMPLOYEE = gql`
  query getProjectsByEmployee($id: Int) {
    getProjectsByEmployee(id: $id) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      billingMethod
      costBudget
      revenueBudget
      branchId
      branchData {
        id
        branchName
      }
      costCenterId
      partnerId
      partner {
        id
        partnerType
        customerType
        displayName
      }
      projectTasks {
        id
        taskName
      }
      projectInvoices {
        id
        projectModelId
        invoiceNo
        orderNo
        invoiceDate
        paymentTerm
        paymentStatus
        reason
        dueDate
        subject
        customerNote
        isPayment
        subTotal
        tax
        adjustment
        totalPrice
        invoiceUrl
        balance
        status
      }
      quotes {
        id
        parentQuoteId
        quoteCode
        partnerId
        TRN
        SOW
        quoteDate
        expiryDate
        approvalDate
        createdBy
        branchId
        billingAddressId
        shippingAddressId
        refNumber
        projectId
        newProjectName
        quoteTitle
        subject
        subTotal
        tax
        adjustment
        totalAmount
        paymentTerm
        deliveryTerm
        validity
        termsCondition
        customerNote
        additionalNote
        quoteUrl
        status
      }
      parentQuotes {
        id
        parentCode
        quoteCode
        quoteDate
        partnerId
        projectId
        parentQuoteStatus
      }
    }
  }
`;

export const GET_SINGLE_PROJECT = gql`
  query projectDetail($id: Int!) {
    projectDetail(id: $id) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      threshold
      billingMethod
      costBudget
      revenueBudget
      branchId
      costCenterId
      partnerId
      projectTasks {
        id
        taskName
        description
      }
      projectResources {
        id
        projectId
        employeeId
        employeeData {
          firstName
          lastName
          email
        }
        status
      }
    }
  }
`;

export const GET_COUNTS = gql`
  query getProjectOverviewCounts($id: Int!) {
    getProjectOverviewCounts(id: $id) {
      quotes
      invoices
      workOrders
      purchaseOrders
      localPurchaseOrders
      goodsReceivedCount
      bills
    }
  }
`;

export const OVERVIEW_COST = gql`
  query projectOverviewCost($id: Int!) {
    projectOverviewCost(id: $id) {
      project {
        id
        projectCode
        projectName
        totalProjectCost
        description
        startDate
        endDate
        status
        budget
        threshold
        billingMethod
        costBudget
        revenueBudget
      }
      totalNetPrice
    }
  }
`;

export const OVERVIEW_LIST = gql`
  query projectOverviewList($id: Int!) {
    projectOverviewList(id: $id) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      billingMethod
      costBudget
      revenueBudget
      partnerId
      projectTasks {
        id
        taskName
        description
      }
      projectResources {
        id
        projectId
        employeeData {
          firstName
          lastName
          designation {
            designationName
          }
        }
        status
      }
      quotes {
        id
        parentQuoteId
        quoteDate
        expiryDate
        approvalDate
        createdBy
        newProjectName
        quoteTitle
        subTotal
        tax
        adjustment
        totalAmount
      }
      parentQuotes {
        id
        parentCode
        quoteCode
        quoteDate
        partnerId
      }
      projectWorkOrders {
        id
        workOrderStatus
        workOrderNo
        partnerId
      }
    }
  }
`;
