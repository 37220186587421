import { gql } from 'apollo-angular';


export const GET_MY_NOTIFICATIONS = gql`
  query getMyNotifications {
    getMyNotifications {
      id
      recipientId
      referenceId
      title
      message
      notificationType
      status
      createdAt
      readAt
      isDeleted
      updatedAt
      deletedAt
    }
  }
`;