import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { RolesService } from '../../features/roles/services/roles.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as PermissionActions from '../../core/permissions/permission.action';

@Component({
  selector: 'app-roles-management',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    FormsModule
  ],
  templateUrl: './roles-management.component.html',
  styleUrl: './roles-management.component.scss'
})
export class RolesManagementComponent {
  isDropdownOpen = false;
  filter: any = {};
  modules: any[] = [];
  search: any;
  permissions: any;
  assignedModule: any;
  moduleId: any;
  profileId: any;
  selectedPermissionIds: number[] = [];
  isToggleOn: boolean = false;
  permissionNames: any[] = [];
  permissionMap: any = {};
  permissionMapId: any = {};
  // isChildDropdownOpen: boolean;
  dropdownState: { [key: number]: boolean } = {};
  childModules: any;
  permissionsState: { [key: number]: { isToggleOn: boolean } } = {};  // Track toggle state for each module


  constructor(
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private store: Store,

  ) { }

  ngOnInit() {
    this.getModules();
    this.getPermissions();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      console.log(id);

      if (id) {
        this.profileId = id;
        this.getPermissionsById(this.profileId);
      }
    })
  }

  toggleDropdown(event?: MouseEvent, moduleId?: any) {
    if (event) {
      event.stopPropagation();
    }
    if (moduleId) {
      this.moduleId = moduleId;
      this.markAssignedPermissions(this.moduleId)
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleChildDropdown(event?: Event, moduleId?: any): void {
    const mouseEvent = event as MouseEvent;
    if (mouseEvent) {
      mouseEvent?.stopPropagation();
    }
    if (moduleId) {
      this.moduleId = moduleId;
      this.markAssignedPermissions(moduleId)
    }
    this.dropdownState[moduleId] = !this.dropdownState[moduleId];
    this.markAssignedPermissions(moduleId)
  }

  getModules() {
    this.rolesService.modules().subscribe({
      next: (data) => {
        this.modules = data;
        this.childModules = data?.childModules;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  getPermissions() {
    this.rolesService.permissions(this.search).subscribe({
      next: (data) => {
        this.permissions = data;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  getPermissionsById(id: any) {
    if (id) {
      this.rolesService?.getModuleData(Number(id)).subscribe({
        next: (data) => {
          this.assignedModule = data;
          this.populatePermissionMap()
        },
        error: (error) => {
          console.error(error, 'Error');
        }
      })
    }
  }

  populatePermissionMap() {
    this.permissionMap = { ...this.permissionMap }; 
    this.permissionMapId = { ...this.permissionMapId }; 

    this.assignedModule?.forEach((module: any) => {
      const permissions = module.rolesAndPermissions?.[0]?.permissions || [];
      const activePermissions = permissions.filter((perm: any) => perm.status === 1);

      this.permissionMap[module.id] = permissions
        .filter((perm: any) => perm?.status === 1) // Only include active permissions
        .map((perm: any) => perm.permissionName); // Extract permission names

      this.permissionMapId[module.id] = permissions
        .filter((perm: any) => perm?.status === 1) // Only include active permissions
        .map((perm: any) => perm.id); 

        this.permissionsState[module.id] = {
         isToggleOn: activePermissions.length > 0 && activePermissions.length === permissions.length,
       };
    });
  }


  markAssignedPermissions(moduleId: number) {
    if (!this.assignedModule || this.assignedModule.length === 0) {
      console.error('No assigned module data available.');
      return;
    }

    const rolesAndPermissions = this.assignedModule;
    const module = rolesAndPermissions.find((mod: any) => mod.id === moduleId);

    if (!module) {
      console.error(`Module with ID ${moduleId} not found.`);
      return;
    }
    const rolesAndPermissionsForModule = module.rolesAndPermissions;

    if (!rolesAndPermissionsForModule || rolesAndPermissionsForModule.length === 0) {
      console.error(`No roles and permissions found for module ID ${moduleId}.`);
      return;
    }

    console.log('rolesAndPermissionsForModule', rolesAndPermissionsForModule);

    // Find the role within the rolesAndPermissions array
    const role = rolesAndPermissionsForModule.find(
      (rolePermission: any) => {
        rolePermission.profileId === this.profileId;
        return rolePermission;
      }
      // Assuming profileId is used to identify roles
    );
    if (role && role.permissions) {
      const assignedPermissionIds = role.permissions
        .filter((perm: any) => perm?.status === 1)
        .map((perm: any) => perm.id);

      this.permissions = this.permissions.map((permission: any) => ({
        ...permission,
        checked: assignedPermissionIds.includes(permission.id), // Add or update `checked`
      }
      )
      );
      this.selectedPermissionIds = this.permissions
        .filter((permission: any) => permission.checked)
        .map((permission: any) => permission.id);

    } else {
      console.error('Role not found or no permissions assigned.');
      this.permissions = this.permissions.map((permission: any) => ({
        ...permission,
        checked: false,
      }));
    }
  }

  togglePermission(permissionId: number, event: Event, moduleId: number): void {
    const checkbox = event.target as HTMLInputElement;

    // Update the checked state of the specific permission
    const permission = this.permissions.find((p: any) => p.id === permissionId);
    if (permission) {
      permission.checked = checkbox?.checked;
    }

    // Recalculate the isToggleOn state for the module based on the permissions
    const allPermissionsChecked = this.permissions.every((p: any) => p.checked);
    this.permissionsState[moduleId] = {
      isToggleOn: allPermissionsChecked
    };

    // Update the selectedPermissionIds based on the new checked state
    if (checkbox?.checked) {
      this.selectedPermissionIds.push(permissionId);
    } else {
      this.selectedPermissionIds = this.selectedPermissionIds.filter(id => id !== permissionId);
    }
  }

  toggleAllPermissions(event: Event, moduleId: number): void {
    const toggleSwitch = event.target as HTMLInputElement;
    const isChecked = toggleSwitch.checked;

    this.permissionsState[moduleId] = {
      isToggleOn: isChecked,
    };
    this.permissions = this.permissions.map((permission: any) => ({
      ...permission,
      checked: isChecked, 
    }));

    if (isChecked) {
      // Add all permission IDs from the current permissions list
      this.selectedPermissionIds = Array.from(new Set([
        ...this.selectedPermissionIds,
        ...this.permissions.map((permission: any) => permission.id),
      ]));
    } else {
      // Remove all permission IDs from the current permissions list
      this.selectedPermissionIds = this.selectedPermissionIds.filter(
        (id) => !this.permissions.some((permission: any) => permission.id === id)
      );
    }
    this.moduleId = moduleId;
    this.onApply();

  }


  onApply() {
    const updatedData = {
      profileId: this.profileId,
      permissionIds: this.selectedPermissionIds,
      moduleId: String(this.moduleId)
    }

    this.rolesService.updateRolesPermissions(this.profileId, this.selectedPermissionIds, String(this.moduleId)).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Permission updated successfully', 'Success');
          if (this.isDropdownOpen) {
            this.isDropdownOpen = false;
          }
          if (this.dropdownState[this.moduleId]) {
            this.dropdownState[this.moduleId] = false;
          }
          console.log('this.selectedPermissionIds', this.permissionMapId);

          this.store.dispatch(PermissionActions.setPermissions({ permissions: this.permissionMapId }));
          this.getModules();
          this.getPermissions();
          this.getPermissionsById(this.profileId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }
}

