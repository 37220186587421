import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_MODULES, GET_PERMISSIONS, GET_PERMISSION_BY_ID, GET_PROFILES } from '../graphql/queries/roles.query';
import { CLONE_PROFILE, CREATE_PROFILE, REMOVE_PROFILE, UPDATE_PERMISSIONS } from '../graphql/mutation/roles.mutation';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private apollo: Apollo) {}

  profiles(search : any): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: GET_PROFILES,
        variables: {
          search
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.profiles || [])
      );
  }

  modules(): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: GET_MODULES,
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.getModulesList || [])
      );
  }

  permissions(search : any): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: GET_PERMISSIONS,
        variables: {
          search
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.permissions || [])
      );
  }

  getModuleData(profileId : any): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: GET_PERMISSION_BY_ID,
        variables: {
          profileId
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map((result) => {
          const data = result.data?.getModuleDataNew || [];
          return data;
        })
      );
  }
  // getModuleData(moduleId : any): Observable<any> {
  //   return this.apollo
  //     .use('hrms')
  //     .query<any>({
  //       query: GET_PERMISSION_BY_ID,
  //       variables: {
  //         moduleId
  //       },
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map((result) => {
  //         const data = result.data?.getModuleData || [];
  //         return data;
  //       })
  //     );
  // }

  createProfile(createProfileInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: CREATE_PROFILE,
        variables: {
          createProfileInput
        }
      })
      .pipe(
        map((result: any) => result.data?.createProfile || [])
      );
  }

  updateRolesPermissions(profileId: any, permissionIds: any, moduleId: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: UPDATE_PERMISSIONS,
        variables: {
          profileId,
          permissionIds,
          moduleId
        }
      })
      .pipe(
        map((result: any) => result.data?.updateRolesPermissions || [])
      );
  }
  
  removeProfile(id: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: REMOVE_PROFILE,
        variables: {
          id
        }
      })
      .pipe(
        map((result: any) => result.data?.removeProfile || [])
      );
  }

  cloneProfile(profileId: any, profileName: any, description: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: CLONE_PROFILE,
        variables: {
          profileId,
          profileName,
          description
        }
      })
      .pipe(
        map((result: any) => result.data?.removeProfile || [])
      );
  }
}