import {CommonModule} from "@angular/common";
import {Component, Inject, inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {CustomerService} from "../../../../features/customers/services/customer.service";
import {ShippingAddressDialog} from "../add-shipping-address-dialog/shipping-address-dialog.component";

@Component({
    selector: 'select-address-dialog',
    templateUrl: 'list-shipping-address-dialog.component.html',
    styleUrls: ['../../invoice-new.component.scss'],
    standalone: true,
    imports: [MatDialogModule, CommonModule]
})
export class ListShippingAddressDialog {
    shippingAddressList : any[];
    selectedAddressId : number | null = null;

    readonly dialog = inject(MatDialog);

    constructor(private customerService : CustomerService, private dialogRef : MatDialogRef < ListShippingAddressDialog >, @Inject(MAT_DIALOG_DATA)public data : {
        selectedCustomerId: any;
        type: any
    }) {}

    ngOnInit(): void {
        this.fetchPartners();
    }

    selectAddress(address : any): void {
        this.selectedAddressId = address.id;
        this.dialogRef.close(address);
    }

    openShippingAddressDialog(type : any, id: any = null): void {
        this.dialogRef.close();

        this.dialog.open(ShippingAddressDialog, {
            width: '400px',
            data: {
                clientId: this.data ?. selectedCustomerId,
                type,
                addressId: id
            }
        });
    }

    private fetchPartners() {
        const id = this.data ?. selectedCustomerId;
        const type = this.data ?. type;
        this.customerService.fetchPartnerById(id).subscribe({
            next: (partners) => { // this.shippingAddressList = partners?.addresses;
                this.shippingAddressList = partners ?. addresses ?. filter((address : any) => address.addressType == type);
            },
            error: (error) => console.error(error)
        });
    }
}
