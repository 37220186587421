import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseOrdereService } from '../../features/purchase-order/services/purchase-orderservice';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-purchase-order-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
  ],
  templateUrl: './purchase-order-details.component.html',
  styleUrl: './purchase-order-details.component.scss',
})
export class PurchaseOrderDetailsComponent {
  allOrders: any[] = [];
  OrderData: any = {};
  purchaseOderId: any;
  orderDetails: any;
  selectedTabIndex = 0; // Default to the first tab
  commentForm: FormGroup;
  allComments: any;
  isCommentsOpen = false;
  public loading: boolean = true;
  termsAndConditions: any[];
  private searchSubject = new Subject<string>();
  readonly dialog = inject(MatDialog);
  paymentTerms: any[];
  groupedItems: { [key: string]: any[] } = {};
  groupedItemsKeys: string[] = [];
  globalSlNo = 0;
  categorizedPurchaseItems: { [key: string]: any[] } = {};
  totalItems: number = 0; 
  subjectIndex: number = 0;
  slNo: number = 0;

  subjectList = Object.keys(this.categorizedPurchaseItems);

  constructor(
    private router: Router,
    private purchaseOrderService: PurchaseOrdereService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private itemService: CreditDebitNoteService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.termsAndConditions = [];
    this.fetchAllPurchaseOrder();
    this.route.queryParamMap.subscribe((queryParams) => {
      this.purchaseOderId = queryParams.get('id');

      if (this.purchaseOderId) {
        this.fetchPurchaseById();
      }
    });

    this.listAllComments();
    this.commentForm = this.fb.group({ comment: ['', Validators.required] });

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchAllPurchaseOrder(searchTerm);
      });
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
      this.listAllComments();
    }
    this.isCommentsOpen = !this.isCommentsOpen;
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }
  onMarkAsCanceled(id: any) {
    const purchaseId = +id;
    if (id) {
      this.purchaseOrderService.markAsCanceled(purchaseId).subscribe({
        next: (resp) => {
          if (resp?.data?.markAsCancelled?.id) {
            this.router.navigate([`/purchase-order`]);
            this.toastr.success('Success.');
          }
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to change.');
        },
      });
    }
  }

  getSerialNumber() {
    return this.globalSlNo++;
  }

  listAllComments() {
    const orderId = +this.purchaseOderId;
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const conditions = {
      employeeId: employeeId,
      refId: orderId,
      type: 'PurchaseOrder',
      activityType: 'Comment',
    };
    this.purchaseOrderService
      .listAllPurchaseOrderComments(conditions)
      .subscribe({
        next: (response) => {
          if (response) {
            this.allComments = response;
          }
        },
        error: (error) => {
          console.error(error, 'Error');
        },
      });
  }

  fetchComments(refId: number) {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee.employeeId;

    const value = {
      employeeId: employeeId,
      refId: refId,
      activityType: 'Comment',
      type: 'PurchaseOrder',
    };
    this.loading = true;
    this.purchaseOrderService.comments(value).subscribe({
      next: (items) => {
        this.allComments = items;
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }
  onCreateComment() {
    if (this.commentForm.invalid) {
      this.commentForm.markAllAsTouched();
      return;
    }
    const orderId = +this.purchaseOderId;
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;

    const data = this.commentForm.value;

    const createCommentInput = {
      employeeId: employeeId,
      refId: orderId,
      type: 'PurchaseOrder',
      activityType: 'Comment',
      note: data?.comment,
    };

    this.itemService.createcomment(createCommentInput).subscribe({
      next: (result) => {
        if (result) {
          console.log('Creating comment result:', result);
          const selectedIteam = this.allOrders[this.selectedTabIndex];
          const itemId = selectedIteam.id;
          // this.listAllComments
          this.fetchComments(itemId);
          this.toastr.success('Comment added successfully!', 'Success');
          this.commentForm.reset();
          this.isCommentsOpen = true;
        }
      },

      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  onViewDocument() {
    const url = this.orderDetails?.purchaseUrl;
    console.log('url', url);

    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
      .then((response: any) => response.blob())
      .then((blob: any) => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });
  }

  onEdit() {
    if (!this.orderDetails || !this.purchaseOderId) {
      this.toastr.error('Order details or Purchase Order ID is missing.');
      return;
    }
    console.log(this.orderDetails);

    const restrictedStatuses = ['CLOSED'];
    // if ()) {
    //   this.toastr.error(`Cannot edit. Purchase order is already ${this.orderDetails.purchaseStatus.toLowerCase()}.`);
    //   return;
    // }
    // console.log(this.orderDetails.billedStatus);

    if (
      this.orderDetails.billStatus === 'BILLED' &&
      restrictedStatuses.includes(this.orderDetails.purchaseStatus)
    ) {
      this.toastr.error(
        'Cannot edit. Purchase order is closed and has already been billed.'
      );
      return;
    }
    this.router.navigate([
      `/purchase-order/purchase-order-edit/${this.purchaseOderId}`,
    ]);
  }
  
  // fetchPurchaseById() {

  //   this.purchaseOrderService.fetchPurchaseOrder(Number(this.purchaseOderId)).subscribe({
  //     next: (data) => {
  //       this.orderDetails = data;
  //       console.log(this.orderDetails?.billDate);
  //             // Group items by subjectList
  //     if (this.orderDetails?.purchaseItems) {
  //       this.groupItemsBySubject(this.orderDetails.purchaseItems);
  //     }
  //       console.log("groupedItems", this.groupedItems);

  //       this.termsAndConditions = this.orderDetails?.customerNote ? this.orderDetails.customerNote.split('\n') : [];
  //       this.paymentTerms = this.orderDetails?.paymentTerms ? this.orderDetails.paymentTerms.split('\n') : [];
  //       if (this.purchaseOderId) {
  //         const targetId = +this.purchaseOderId;
  //         this.selectedTabIndex = this.allOrders.findIndex((c: any) => {
  //           return c.id === targetId;
  //         });
  //         if (this.selectedTabIndex === -1) {
  //           this.selectedTabIndex = 0;
  //         }
  //       } else {
  //         this.selectedTabIndex = 0;
  //       }
  //     },
  //     error: (error) => {
  //       console.error(error, 'Error')
  //     }
  //   })
  // }
  getSerialNo(subjectIndex: number, itemIndex: number): number {
    let serialNo = 0;
    for (let i = 0; i < subjectIndex; i++) {
      serialNo += this.categorizedPurchaseItems[this.objectKeys(this.categorizedPurchaseItems)[i]].length;
    }
    return serialNo + itemIndex + 1;
  }
  
  // Group items by their subjectList
  // fetchPurchaseById() {
  //   this.purchaseOrderService.fetchPurchaseOrder(Number(this.purchaseOderId)).subscribe({
  //     next: (data) => {
  //       this.orderDetails = data;
  //       this.globalSlNo=0;
  //       // Split customer note and payment terms into arrays
  //       this.termsAndConditions = this.orderDetails?.customerNote ? this.orderDetails.customerNote.split('\n') : [];
  //       this.paymentTerms = this.orderDetails?.paymentTerms ? this.orderDetails.paymentTerms.split('\n') : [];

  //       // Categorize purchase items by subjectList, add to "NoSubject" if subjectList is empty
  //       this.categorizedPurchaseItems = this.orderDetails.purchaseItems.reduce((acc: any, item: any) => {
  //         const subject = item.subjectList?.trim() || 'NoSubject'; // Check for empty subjectList and assign "NoSubject"

  //         // If the subject already exists, push the item into the array
  //         if (!acc[subject]) {
  //           acc[subject] = [];
  //         }

  //         acc[subject].push(item);
  //         return acc;
  //       }, {});
  //       // Calculate total number of items across all categories
  //       this.totalItems = Object.values(this.categorizedPurchaseItems).reduce((sum, items) => sum + items.length, 0);
  //       // Handle the selected tab index based on purchaseOrderId
  //       if (this.purchaseOderId) {
  //         const targetId = +this.purchaseOderId;
  //         this.selectedTabIndex = this.allOrders.findIndex((c: any) => c.id === targetId);
  //         if (this.selectedTabIndex === -1) {
  //           this.selectedTabIndex = 0;
  //         }
  //       } else {
  //         this.selectedTabIndex = 0;
  //       }
  //     },
  //     error: (error) => {
  //       console.error(error, 'Error');
  //     }
  //   });
  // }
  fetchPurchaseById() {
    this.purchaseOrderService.fetchPurchaseOrder(Number(this.purchaseOderId)).subscribe({
      next: (data) => {
        this.orderDetails = data; 
        this.globalSlNo = 0;
  
        // Split customer note and payment terms into arrays
        this.termsAndConditions = this.orderDetails?.customerNote ? this.orderDetails.customerNote.split('\n') : [];
        this.paymentTerms = this.orderDetails?.paymentTerms ? this.orderDetails.paymentTerms.split('\n') : [];
  
        // Categorize purchase items by subjectList
        const categorizedItems = this.orderDetails.purchaseItems.reduce((acc: any, item: any) => {
          const subject = item.subjectList?.trim() || 'NoSubject'; // Default to "NoSubject" if subjectList is empty
          if (!acc[subject]) {
            acc[subject] = [];
          }
          acc[subject].push(item);
          return acc;
        }, {});
  
        // Ensure "NoSubject" is the first key
        const sortedCategorizedItems: any = {};
        if (categorizedItems['NoSubject']) {
          sortedCategorizedItems['NoSubject'] = categorizedItems['NoSubject'];
        }
        Object.keys(categorizedItems).forEach((key) => {
          if (key !== 'NoSubject') {
            sortedCategorizedItems[key] = categorizedItems[key];
          }
        });
  
        this.categorizedPurchaseItems = sortedCategorizedItems;
  
        // Calculate total number of items across all categories
        this.totalItems = Object.values(this.categorizedPurchaseItems).reduce((sum, items) => sum + items.length, 0);
  
        // Handle the selected tab index based on purchaseOrderId
        if (this.purchaseOderId) {
          const targetId = +this.purchaseOderId;
          this.selectedTabIndex = this.allOrders.findIndex((c: any) => c.id === targetId);
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    });
  }
  
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  fetchAllPurchaseOrder(search: string = '') {
    this.loading = true;
    this.purchaseOrderService.fetchAllPurchaseOrder(search, false).subscribe({
      next: (orders) => {
        this.allOrders = orders;
        if (this.purchaseOderId) {
          const targetId = +this.purchaseOderId;
          this.selectedTabIndex = this.allOrders.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
        this.OrderData = this.allOrders[this.selectedTabIndex] || {};
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching all purchase-orders:', err);
      },
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  onMarkAsIssued(id: any) {
    const purchaseId = +id;
    if (id) {
      this.purchaseOrderService.markAsIssued(purchaseId).subscribe({
        next: (res) => {
          this.fetchAllPurchaseOrder();
          if (res?.data?.markAsIssued?.id) {
            const id = res?.data?.markAsIssued?.id;
            console.log(id);

            this.router.navigate([`/purchase-order`]);
            this.toastr.success('Success.');
          }
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to change.');
        },
      });
    } else {
      this.toastr.error('Could not find Id');
    }
  }

  convertToBill(id: any) {
    const purchaseId = +id;
    if (id) {
      this.purchaseOrderService.convertToBill(purchaseId).subscribe({
        next: (res) => {
          this.fetchAllPurchaseOrder();
          const purchaseData = res?.data;
          if (purchaseData) {
            this.router.navigate([`/purchase-order`]);
            //   this.router.navigate(['/vendor-invoice/vendor-invoice-new'], {
            //     queryParams: { purchaseOrderId: purchaseId }
            //   });
            this.toastr.success('Success.');
          }
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to change.');
        },
      });
    } else {
      this.toastr.error('Could not find Id');
    }
  }

  navigateToSendMail(): void {
    const selectedQuote = this.allOrders[this.selectedTabIndex];
    const purchaseId = selectedQuote.id;
    // this.router.navigate([`purchase-order/purchase-order-details/${purchaseId}`]);
    this.router.navigate([`purchase-order/purchase-order-details/mail`], {
      queryParams: { id: purchaseId },
    });
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.OrderData = this.allOrders[index];
    const selectedOrder = this.allOrders[index];
    this.router.navigate(['purchase-order/purchase-order-details'], {
      queryParams: { id: this.OrderData.id },
    });
    // if (selectedOrder) {
    //   this.router.navigate([`/purchase-order/purchase-order-details/${selectedOrder.id}`]);
    // }
  }
  openDialog() {
    const dialogRef = this.dialog.open(UploadPurchaseOrderDetailsDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onDelete(purchaseOrderId: any) {
    const id = +purchaseOrderId;
    this.purchaseOrderService.removePurchaseOrder(id).subscribe({
      next: () => {
        this.fetchAllPurchaseOrder();
        this.toastr.success('Deleted successfully.');
      },
      error: (error) => {
        this.toastr.error(error.message || 'Failed to delete.');
      },
    });
  }
}

@Component({
  selector: 'upload-purchase-order-dialog',
  templateUrl: 'upload-purchase-order-dialog.html',
  styleUrls: ['./purchase-order-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadPurchaseOrderDetailsDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
