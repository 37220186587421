<section class="cmn-innerpage-wrapper add-project-inventory">
    <h2 class="cmn-inner-heading">{{vendorId ? 'Edit Vendor' : 'New Vendor'}}</h2>
    <form [formGroup]="newVendorForm" (ngSubmit)="onSubmit()">
        <!-- <label class="radio-label">Customer type</label> -->
        <!-- Format Selection -->
        <!-- <div class="type-selection"> -->
        <!-- <label class="radiobtn-wrapper">
                    <input type="radio" name="customerType" value="Bussiness" formControlName="customerType" />
                    <span class="radiomark"></span>
                    Business
                </label> -->
        <!-- <label class="radiobtn-wrapper">
                    <input type="radio" name="customerType" value="Individual" formControlName="customerType" />
                    <span class="radiomark"></span>
                    Individual
                </label> -->
        <!-- <div *ngIf="
                    newVendorForm.get('customerType')!.invalid &&
                    newVendorForm.get('customerType')!.touched
                    " class="error">
                    Customer Type is required.
                </div> -->
        <!-- </div> -->
        <div class="chart-account-form-wrapper">
            <div class="form-wrapper">
                <label for="">Primary Contact <span class="red">*</span></label>
                <mat-form-field>
                    <mat-select placeholder="Salutation" formControlName="primaryContact">
                        <mat-option value="Mr">Mr</mat-option>
                        <mat-option value="Mrs">Mrs</mat-option>
                        <mat-option value="Miss">Miss</mat-option>
                        <mat-option value="Dr">Dr</mat-option>
                        <mat-option value="Prof">Prof</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error
                            *ngIf="newVendorForm.get('primaryContact')!.touched && newVendorForm.get('primaryContact')!.hasError('required')">
                            Salutation is required.
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">First Name <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter First Name" formControlName="firstName" />
                    <!-- <mat-error *ngIf="newVendorForm.get('firstName')!.hasError('required')">
                            First name is required.
                          </mat-error> -->
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="newVendorForm.get('firstName')!.hasError('maxlength')">
                            First name must be at most 50 characters long.
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Last Name <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter Last Name" formControlName="lastName" />
                    <!-- <mat-error *ngIf="newVendorForm.get('lastName')!.hasError('required')">
                            Last name is required.
                          </mat-error> -->

                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
    newVendorForm.get('lastName')!.touched &&
    newVendorForm.get('lastName')!.hasError('maxlength')
  ">Last name must be at most 50 characters long.</mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Company Name</label>
                <mat-form-field>
                    <input matInput placeholder="Enter company name" formControlName="companyName" />
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                        newVendorForm.get('companyName')!.touched &&
                        newVendorForm.get('companyName')!.hasError('maxlength')
                      ">Company name must be at most 100 characters long.</mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Vendor Display Name <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter name" formControlName="displayName" />
                </mat-form-field>
                <div class="custom-error" *ngIf="newVendorForm.get('displayName')!.touched">
                    <span>
                        <mat-error *ngIf="newVendorForm.get('displayName')!.hasError('required')">Display name is
                            required.</mat-error>
                    </span>
                    <!-- <span>
                        <mat-error *ngIf="newVendorForm.get('displayName')!.hasError('maxlength')">Display name must be at most 50 characters long.</mat-error>
                    </span> -->
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Vendor Email <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter email address" formControlName="email" />
                </mat-form-field>
                <div class="custom-error">
                    <div *ngIf="newVendorForm.get('email')!.touched" class="error">
                        <span>
                            <mat-error *ngIf="
                            newVendorForm.get('email')!.touched &&
                            newVendorForm.get('email')!.hasError('maxlength')
                          ">Email must be at most 50 characters long.</mat-error>
                        </span>
                        <!-- Required Error - shows only if empty -->
                        <span>
                            <mat-error *ngIf="newVendorForm.get('email')!.hasError('required')" class="error">
                                Email ID is required.
                            </mat-error>
                        </span>
                        <!-- Invalid Email Format Error - shows only if not empty but invalid -->
                        <span>
                            <mat-error
                                *ngIf="newVendorForm.get('email')!.hasError('invalidEmail') && !newVendorForm.get('email')!.hasError('required')"
                                class="error">
                                Please enter a valid email address.
                            </mat-error>
                        </span>
                    </div>

                </div>

            </div>
            <div class="form-wrapper">
                <label for="">Telephone <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter phone number" formControlName="phone" />
                </mat-form-field>
                <div *ngIf="newVendorForm.get('phone')!.touched" class="custom-error">
                    <!-- Required Error - only if field is empty -->
                    <!-- <div *ngIf="newVendorForm.get('phone')!.hasError('required')" class="error">
                            Work phone number is required.
                        </div> -->
                    <!-- Min Length Error - only if value is present but below minimum length -->
                    <span>
                        <mat-error
                            *ngIf="newVendorForm.get('phone')!.hasError('minlength') && !newVendorForm.get('phone')!.hasError('required')"
                            class="error">
                            Work phone number must be at least 8 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Max Length Error - only if value is present but above maximum length -->
                        <mat-error
                            *ngIf="newVendorForm.get('phone')!.hasError('maxlength') && !newVendorForm.get('phone')!.hasError('required')"
                            class="error">
                            Work phone number cannot exceed 15 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Pattern Error - only if value is present but invalid pattern -->
                        <mat-error
                            *ngIf="newVendorForm.get('phone')!.hasError('pattern') && !newVendorForm.get('phone')!.hasError('required')"
                            class="error">
                            Only digits are allowed.
                        </mat-error>
                    </span>
                </div>
            </div>

            <div class="form-wrapper">
                <label for="">Mobile <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter mobile number" formControlName="mobile" />
                </mat-form-field>
                <div *ngIf="newVendorForm.get('mobile')!.touched" class="custom-error">
                    <!-- Required Error - only if field is empty -->
                    <!-- <div *ngIf="newVendorForm.get('mobile')!.hasError('required')" class="error">
                            Mobile number is required.
                        </div> -->
                    <span>
                        <!-- Min Length Error - only if value is present but below minimum length -->
                        <mat-error
                            *ngIf="newVendorForm.get('mobile')!.hasError('minlength') && !newVendorForm.get('mobile')!.hasError('required')"
                            class="error">
                            Mobile number must be at least 8 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Max Length Error - only if value is present but above maximum length -->
                        <mat-error
                            *ngIf="newVendorForm.get('mobile')!.hasError('maxlength') && !newVendorForm.get('mobile')!.hasError('required')"
                            class="error">
                            Mobile number cannot exceed 15 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Pattern Error - only if value is present but invalid pattern -->
                        <mat-error *ngIf="newVendorForm.get('mobile')!.hasError('pattern') && !newVendorForm.get('mobile')!.hasError('required')"
                            class="error">
                            Only digits are allowed.
                        </mat-error>
                    </span>
                </div>
            </div>
        </div>

        <div class="common-noimage-tab-wrapper">
            <mat-tab-group animationDuration="0ms">
                <!-- Mat Tab One -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Other Details</h2>
                    </ng-template>
                    <app-other-details [vendorData]="vendorData"
                        (formSubmit)="collectOtherDetails($event)"></app-other-details>
                </mat-tab>
                <!-- Mat Tab Two -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Address</h2>
                    </ng-template>
                    <app-address [vendorData]="vendorData" (formSubmit)="collectAddress($event)"></app-address>
                </mat-tab>
                <!-- Mat Tab Three -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Contact Persons</h2>
                    </ng-template>
                    <app-contact-presons [vendorData]="vendorData"
                        (formSubmit)="collectContactDetails($event)"></app-contact-presons>
                </mat-tab>
                <!-- Mat Tab Four -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Remarks</h2>
                    </ng-template>
                    <app-remarks [vendorData]="vendorData" (formSubmit)="collectRemarkDetails($event)"></app-remarks>
                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- <div *ngIf="validationError" class="error" style="text-align: center; margin: 0">
                Please fill mandatory fields!
              </div> -->
        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="button" (click)="onCancel()">Cancel</button>
            <button class="cmn-next-btn" type="submit">Save</button>
        </div>
    </form>
</section>