import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PartnerTypesEnum, PAYMENT_TERMS } from '../../helpers/helper-file';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { ToastrService } from 'ngx-toastr';
import { PurchaseOrdereService } from '../../features/purchase-order/services/purchase-orderservice';
import { ActivatedRoute, Router } from '@angular/router';
import { nonNegativeValidator } from '../../shared/services/validations';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { WorkOrderService } from '../../features/workOrder/services/workOrder.service';
import { ListBillingAddressDialog } from '../invoice-new/dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { ListShippingAddressDialog } from '../invoice-new/dialog-components/list-shipping-address-dialog/list-shipping-address-dialog.component';
import { ShippingAddressDialog } from '../invoice-new/dialog-components/add-shipping-address-dialog/shipping-address-dialog.component';
import { BillingAddressDialog } from '../invoice-new/dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { PreferenceService } from '../../shared/services/preference.service';

@Component({
  selector: 'app-local-purchase-order-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule
  ],
  templateUrl: './local-purchase-order-new.component.html',
  styleUrl: './local-purchase-order-new.component.scss'
})
export class LocalPurchaseOrderNewComponent {

  public arr = [1, 2, 3, 4, 5, 6, 7];
  currentDate: string;
  public vendorsData: any[] = [];
  public cutomersData: any[] = [];
  public billingAddressList: any[];
  public shippingAddressList: any[];
  accountData: any[] = [];
  paymentTermsValue = PAYMENT_TERMS;
  public itemData: any[] = [];
  public filteredItemData: any[] = [];
  purchaseOrderForm: FormGroup;
  loading: boolean = false;
  purchaseOrderData: any;
  AdminAddressData: any;
  purchaseOrderId: any;
  selectedVendorId: any;
  allCurrencies: any;
  workOrders: any;
  readonly dialog = inject(MatDialog);
  private searchSubject = new Subject<string>();
  public selectedBillingAddress: any = {};
  public selectedShippingAddress: any = {};
  isCustomerSelected: boolean = false;
  selectedCustomerId: number | null = null;
  vendorControl = new FormControl();
  prefix: any;
  purchaseOrderNo: any;
  selectedVendorTRN : any;
  private partnerTypes = PartnerTypesEnum;

  constructor(
    private invoiceService: InvoiceService,
    private itemService: InventoryItemService,
    private creditDebitNoteService: CreditDebitNoteService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private purchaseOrderService: PurchaseOrdereService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private workOrderService: WorkOrderService,
    private preferenceService: PreferenceService
  ) {
    this.currentDate = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  ngOnInit(): void {
    this.initForm();
    this.fetchPartners();
    this.fetchItems();
    this.fetchAccounts();
    this.listAllWorkOrders();
    this.fetchCustomers();
    if(this.isCustomerSelected === false){
    this.setAdminAddress();
    }

    this.preferenceCode();

    // this.listCurrencies();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.purchaseOrderId = +id;
        this.loadPurchaseOrderData();
      }
    });
    if (!this.purchaseOrderId) {
      this.addItem();
    }

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });

    this.purchaseOrderForm
      .get('dueDate')
      ?.setValidators([
        Validators.required,
        this.dueDateBeforeBillDateValidator.bind(this),
      ]);

    this.vendorControl.valueChanges.subscribe((searchTerm) => {
      if (!searchTerm) {
        this.fetchPartners();  // Fetch all vendors when the search term is cleared
      } else {
        this.searchSubject.next(searchTerm);  // Proceed with the search logic
      }
    });
  }

  initForm() {
    this.purchaseOrderForm = this.fb.group({
      id: [this.purchaseOrderData?.id || ''],
      vendorId: [, Validators.required],
      purchaseOrderNo: ['', [Validators.required, Validators.maxLength(15)]],
      reference: ['', [Validators.maxLength(15)]],
      paymentTerms: ['', Validators.required],
      currency: ['AED', Validators.required],
      customerId: [],
      workorderId: [Validators.required],
      dueDate: [this.purchaseOrderData?.dueDate ? new Date(Number(this.purchaseOrderData.dueDate)).toISOString().split('T')[0] : null, Validators.required],
      shipmentPreference: [''],
      shippingAddressId: this.selectedShippingAddress.id,
      billingAddressId: this.selectedBillingAddress.id,
      items: this.fb.array([]),
      termsConditions: [],
      customerNotes: [],
      billDate: [
        this.purchaseOrderData?.billDate
          ? new Date(Number(this.purchaseOrderData.billDate))
            .toISOString()
            .split('T')[0]
          : '',
        Validators.required,
      ],
      trnNumber:['']
    });
    // this.addItem();
  }


  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Local Purchase Order').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.purchaseOrderNo = prefix?.prefix;
        })
        const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        this.prefix = `${this.purchaseOrderNo + randomSuffix}`;
        this.purchaseOrderForm.patchValue({ purchaseOrderNo: this.prefix });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }
  dueDateBeforeBillDateValidator(control: any) {
    const billDate = this.purchaseOrderForm.get('billDate')?.value;
    const dueDate = control.value;

    if (billDate && dueDate) {
      if (new Date(dueDate) < new Date(billDate)) {
        return { dueDateBeforeBillDate: true };
      }
    }
    return null;
  }





  onVendonSelected(event: MatSelectChange) {
    const selectedVendorId = event.value;
    this.selectedVendorId = selectedVendorId;
  }
  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  loadPurchaseOrderData() {
    if (this.purchaseOrderId != null) {
      this.purchaseOrderService
        .fetchPurchaseOrder(this.purchaseOrderId)
        .subscribe({
          next: (data) => {
            this.purchaseOrderData = data;

            this.purchaseOrderForm.patchValue({

              vendorId: this.purchaseOrderData?.vendorId,
              purchaseOrderNo: this.purchaseOrderData?.purchaseOrderNo,
              reference: this.purchaseOrderData?.reference,
              workorderId: this.purchaseOrderData?.workorderData?.id,
              trnNumber: this.purchaseOrderData?.TRNno,
              paymentTerms: this.purchaseOrderData?.paymentTerms,
              dueDate: this.purchaseOrderData.dueDate
                ? new Date(Number(this.purchaseOrderData.dueDate))
                : null, //Date
              shipmentPreference: this.purchaseOrderData?.shippmentInstructions,
              customerNotes: this.purchaseOrderData?.customerNote,
              billDate: this.purchaseOrderData.billDate
                ? new Date(Number(this.purchaseOrderData.billDate))
                : null, //Date
              billingAddressId: this.purchaseOrderData?.billingAddressData?.id,
              shippingAddressId: this.purchaseOrderData?.shippingAddressData?.id,
            });

            this.isCustomerSelected = this.purchaseOrderData?.customerId !== 0;
            if(this.purchaseOrderData?.customerId !== 0){
              this.selectedBillingAddress = this.purchaseOrderData?.billingAddressData;
              this.selectedShippingAddress = this.purchaseOrderData?.shippingAddressData;
            }
            if( this.purchaseOrderData?.customerId === 0) {
              
             this.toggleOrgainization();
            }


            if (data.purchaseItems) {

              data.purchaseItems.forEach((item: any) => {

                console.log('Item Name:', item?.item?.itemName);
                const itemForm = this.fb.group({
                  id: [item.id || null],
                  selectItem: [item?.itemId,Validators.required],
                  itemName: [item?.item?.itemName ],
                  account: [item?.item?.purchaseAccountId || '', Validators.required],
                  isManual: [item.isManual || false],
                  quantity: [item.quantity, [Validators.pattern(/^\d+(\.\d+)?$/), Validators.required]],
                  rate: [item.rate, [Validators.pattern(/^\d+(\.\d+)?$/), Validators.required]],
                  discount: [
                    item.discount,
                    [Validators.pattern(/^\d+(\.\d+)?$/), Validators.max(100), // Restrict discount to 100%
                    ],
                  ],
                  total: [
                    item.amount,
                    [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)],
                  ],

                });

                this.items.push(itemForm);
                this.calculateAmount(itemForm);

                // Handle dynamic validation based on `isManual`
                itemForm.get('isManual')?.valueChanges.subscribe((isManual) => {
                  const itemIdControl = itemForm.get('selectItem');
                  if (isManual) {
                    itemIdControl?.clearValidators();
                    itemIdControl?.setValue(null);
                  } else {
                    itemIdControl?.setValidators(Validators.required);
                  } itemIdControl?.updateValueAndValidity();
                });

                // Sync rate and quantity when itemId changes
                itemForm
                  .get('itemId')
                  ?.valueChanges.subscribe(() =>
                    this.calculateAmount(itemForm)
                  );
                itemForm
                  .get('rate')
                  ?.valueChanges.subscribe(() =>
                    this.calculateAmount(itemForm)
                  );
                itemForm
                  .get('quantity')
                  ?.valueChanges.subscribe(() =>
                    this.calculateAmount(itemForm)
                  );
                itemForm
                  .get('discount')
                  ?.valueChanges.subscribe(() =>
                    this.calculateAmount(itemForm)
                  );
              });
            }
          },
        });
    }
  }

  private fetchAccounts(search: string = '') {
    this.creditDebitNoteService
      .chartOfAccounts(null, search, null, null)
      .subscribe({
        next: (accounts: any[]) => {
          this.accountData = accounts;
        },
        error: (error: any) => console.error(error),
      });
  }
  get items(): FormArray {
    return this.purchaseOrderForm.get('items') as FormArray;
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      selectItem: [null],
      itemName: ['',Validators.required], // Add itemName field
      isManual: [true],
      account: [null, Validators.required],
      quantity: ['1', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'),]],
      rate: ['0', [Validators.required, Validators.min(0), Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'),]],
      discount: [0, [Validators.min(0), Validators.max(100), Validators.pattern(/^\d+(\.\d+)?$/)]],
      subTotal: [0, [Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
      total: [0, [Validators.required, nonNegativeValidator]],
    });

    this.items.push(itemGroup);


    itemGroup.get('isManual')?.valueChanges.subscribe((isManual) => {
      const itemIdControl = itemGroup.get('selectItem');
      if (isManual) {
        itemIdControl?.clearValidators();
        itemIdControl?.setValue(null); // Set itemId to null when manual
      } else {
        itemIdControl?.setValidators(Validators.required); // Set back to required when not manual
      } itemIdControl?.updateValueAndValidity();
    });

    // Trigger validation checks after adding the group
    itemGroup.updateValueAndValidity();
    this.items.updateValueAndValidity();

    // Subscribe to changes in 'selectItem' to autofill other fields
    itemGroup.get('selectItem')?.valueChanges.subscribe((itemId) => {
      const selectedItem = this.itemData.find((item) => item.id === itemId);
      if (selectedItem) {
        itemGroup.patchValue({
          quantity: '1',
          rate: selectedItem.costPrice,
          discount: selectedItem.discount,
          total: +selectedItem.quantity * +selectedItem.costPrice,
          subTotal: +selectedItem.quantity * +selectedItem.costPrice,
        });
        this.calculateAmount(itemGroup);
      }
    });

    itemGroup.get('rate')?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup.get('quantity')?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup.get('discount')?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  }



  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isPurchaseActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.filteredItemData = items;
        this.itemData = items;
        console.log(this.filteredItemData);
        
      },
      error: (error) => console.error(error),
    });
  }


  listAllWorkOrders() {
    this.workOrderService.getAllWorkOrders().subscribe({
      next: (workorders) => {
        this.workOrders = workorders;
      },
      error: (error) => console.error(error)
    })
  }

  listCurrencies(search: string = '', filter: any = {}) {
    this.purchaseOrderService.listcurrencies(search, filter).subscribe({
      next: (currencies) => {
        this.allCurrencies = currencies;
      },
      error: (error) => console.error(error)
    });
  }

// Method to handle vendor selection and update TRN number
onVendorChange(vendorId: string) {
  const selectedVendor = this.vendorsData.find(vendor => vendor.id === vendorId);
  if (selectedVendor) {
    this.selectedVendorTRN = selectedVendor.trnNumber || '';
    this.purchaseOrderForm.get('trnNumber')?.setValue(this.selectedVendorTRN);
  }
}

  private fetchPartners(search: string = '') {
    this.invoiceService.fetchCustomers(search,  this.partnerTypes.Vendor).subscribe({
      next: (partners) => {
        this.vendorsData = partners
          // .filter((data: any) => data.partnerType === 'Vendor')
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
            trnNumber: data.trnNumber
          }));
        // this.billingAddressList = partners
        //   .flatMap((partner: any) => partner.addresses || [])
        //   .filter((address: any) => address.addressType === 'BILLING');

        // this.shippingAddressList = partners
        //   .flatMap((partner: any) => partner.addresses || [])
        //   .filter((address: any) => address.addressType === 'SHIPPING');
      },
      error: (error) => console.error(error),
    });
  }




  removeItem(index: number): void {
    this.items.removeAt(index);
  }




  private fetchCustomers(search: string = '') {
    this.invoiceService.fetchCustomers(search,  this.partnerTypes.Customer).subscribe({
      next: (partners) => {
        this.cutomersData = partners
          // .filter((data: any) => data.partnerType === 'Customer')
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
          }));

        // this.billingAddressList = partners
        //   .flatMap((partner: any) => partner.addresses || [])
        //   .filter((address: any) => address.addressType === 'BILLING');

        // this.shippingAddressList = partners
        //   .flatMap((partner: any) => partner.addresses || [])
        //   .filter((address: any) => address.addressType === 'SHIPPING');
      },
      error: (error) => console.error(error),
    });
  }

  getTotalAmount(): number {
    return this.items.controls.reduce((total, itemGroup) => {
      return total + (itemGroup.get('total')?.value || 0);
    }, 0);
  }

  getSubTotal(): number {
    return this.items.controls.reduce((subtotal, itemGroup) => {
      const rate = parseFloat(itemGroup.get('rate')?.value || 0);
      const quantity = parseFloat(itemGroup.get('quantity')?.value || 0);
      return subtotal + rate * quantity;
    }, 0);
  }

  getDiscount(): number {
    const subtotal = this.getSubTotal();
    const total = this.getTotalAmount();
    return subtotal - total; // Discount = Subtotal - Total
  }

  getVAT(): number {
    const subtotal = this.getSubTotal(); // VAT is calculated on Subtotal before discount
    return (subtotal * 5) / 100; // VAT = Subtotal * 5%
  }

  getGrandTotal(): number {
    const subtotal = this.getSubTotal();
    const discount = this.getDiscount();
    const vat = this.getVAT();
    const subtotalAfterDiscount = subtotal - discount; // Subtotal after applying discount
    return subtotalAfterDiscount + vat; // Grand Total = Subtotal after Discount + VAT
  }


  calculateAmount(itemGroup: FormGroup): void {
    const rate = parseFloat(itemGroup.get('rate')?.value || 0);
    const quantity = parseFloat(itemGroup.get('quantity')?.value || 0);
    const discount = parseFloat(itemGroup.get('discount')?.value || 0); // Ensure discount is parsed as a number
    const subTotal = rate * quantity; // Subtotal is rate * quantity without discount
    let total = subTotal;

    if (discount > 0) {
      total -= (total * discount) / 100; // Apply discount to calculate total
    }

    // Patch values for both subtotal and total
    itemGroup.patchValue({ subTotal, total });
  }

  // calculateAmount(itemGroup: FormGroup): void {
  //   const rate = parseFloat(itemGroup.get('rate')?.value || '0');
  //   const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
  //   const discount = itemGroup.get('discount')?.value || 0;
  //   let total = rate * quantity;
  //   if (discount > 0) {
  //     total -= (total * discount) / 100;
  //   }
  //   itemGroup.patchValue({ total });
  // }


  toggleCustomer() {
    this.isCustomerSelected = true;
    this.selectedBillingAddress = '';
    this.selectedShippingAddress = '';
  }

  toggleOrgainization() {
    this.isCustomerSelected = false;
    this.selectedBillingAddress = '';
    this.selectedShippingAddress = '';
    this.setAdminAddress();

  }
  openSelectBillingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;

    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedBillingAddress = address;
        this.purchaseOrderForm.patchValue({ billingAddressId: address.id });
      }
    });
  }

  openSelectShippingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'SHIPPING';

    const dialogRef = this.dialog.open(ListShippingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedShippingAddress = address;
        this.purchaseOrderForm.patchValue({ shippingAddressId: address.id });
      }
    });
  }

  openBillingAddressDialog(type: any) {
    const selectedClientId = this.selectedCustomerId;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openShippingAddressDialog(type: any) {
    const selectedClientId = this.selectedCustomerId;
    this.dialog.open(ShippingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  onCustomerChange(event: MatSelectChange): void {
    this.selectedCustomerId = event.value; // Get the selected customer ID
    const selectedCustomer = this.cutomersData.find(
      (customer) => customer.id === this.selectedCustomerId
    );

    if (selectedCustomer && selectedCustomer.addresses) {
      // Find billing address
      const billingAddress = selectedCustomer.addresses.find(
        (address: any) => address.addressType === 'BILLING'
      );
      this.selectedBillingAddress = billingAddress || null;

      // Find shipping address
      const shippingAddress = selectedCustomer.addresses.find(
        (address: any) => address.addressType === 'SHIPPING'
      );
      this.selectedShippingAddress = shippingAddress || null;

      // Update the form with the selected addresses
      this.purchaseOrderForm.patchValue({
        billingAddressId: this.selectedBillingAddress?.id || null,
        shippingAddressId: this.selectedShippingAddress?.id || null,
      });
    } else {
      console.error('No addresses found for the selected customer.');
      this.selectedBillingAddress = null;
      this.selectedShippingAddress = null;
    }
  }


  setAdminAddress() {
    this.purchaseOrderService.fetchAdminAdress().subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.selectedCustomerId = data[0]?.partnerId ?? null;
        } else {
          console.error("No admin address found or empty address array.");
        }
        this.selectedBillingAddress = data.find((address: any) => address.addressType === 'BILLING');
        this.selectedShippingAddress = data.find((address: any) => address.addressType === 'SHIPPING');
        this.purchaseOrderForm.patchValue({
          billingAddressId: this.selectedBillingAddress?.id || null,
          shippingAddressId: this.selectedShippingAddress?.id || null,
        });
      },
      error: (err) => {
        console.error('Error fetching Admin Address:', err); // Handle errors gracefully
      },
    });
  }



  onItemSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;

    if (searchTerm) {
      this.fetchItems(searchTerm);
    } else {
      this.filteredItemData = this.itemData;
    }
  }




  onSelectItem(event: MatAutocompleteSelectedEvent): void {
    const selectedItem = this.itemData.find(item => item.id === event.option.value);
    console.log('selectedItem',selectedItem);

    if (selectedItem) {
      const itemGroup = this.items.at(this.items.length - 1);
      itemGroup.patchValue({
        selectItem: selectedItem.id,
        itemName: selectedItem.itemName,
        isManual: false
      });
    }
  }


  saveAsDraft(): void {
    // this.status = 'DRAFT';
    this.createPurchaseOrder('local-purchase-order/local-purchase-order-details');
  }

  saveAndSend(): void {
    // this.status = 'DRAFT';
    this.createPurchaseOrder('local-purchase-order/local-purchase-order-details/mail');
  }

  createPurchaseOrder(navigateTo: string) {
    if (this.purchaseOrderForm.invalid) {
      this.purchaseOrderForm.markAllAsTouched();
      this.toastr.warning('Please fill in all required fields.');
      return;
    }

    else {
      const formValues = this.purchaseOrderForm.value;
      const createPurchaseOrderInput = {
        vendorId: formValues.vendorId,
        isLocal: true,
        tax: this.getVAT(),
        subTotal: this.getSubTotal(),
        totalPrice: this.getGrandTotal(),
        adjustment: 0.0,
        TRNno: formValues.trnNumber,
        workorderId: formValues.workorderId,
        currencyId: 10,
        customerId: formValues.customerId ? formValues.customerId : 0,
        paymentTerms: formValues.paymentTerms,
        purchaseOrderNo: formValues.purchaseOrderNo,
        billingAddressId: formValues.billingAddressId,
        shippingAddressId: formValues.shippingAddressId,
        billDate: formValues.billDate,
        dueDate: formValues.dueDate,
        reference: formValues.reference,
        customerNote: formValues.customerNotes,
      };

      const createPurchaseItemInput = this.purchaseOrderForm.value.items.map(
        (item: any) => {
          // console.log(item);

          if (item.isManual) {
            return {
              itemId: null, // Manual entry, so set itemId to null
              name: item.itemName, // Set the manually typed item name
              isManual: true,
              quantity: item.quantity.toString(),
              balanceQuantity:item.quantity.toString(),
              rate: item.rate.toString(),
              discount: item.discount,
              amount: item.subTotal,
              purchaseAccountId: item.account

            };
          } else {
            return {
              itemId: item.selectItem,
              name: item.itemName, // Selected item, use itemId
              isManual: false,
              quantity: item.quantity.toString(),
              balanceQuantity:item.quantity.toString(),
              rate: item.rate.toString(),
              discount: item.discount,
              amount: item.subTotal,
              purchaseAccountId: item.account
            };
          }
        });

      this.loading = true;
      if (this.purchaseOrderId) {
        console.log(this.purchaseOrderId);
        
        const updatePurchaseOrderInput = {
          id: this.purchaseOrderId,
          ...createPurchaseOrderInput,
        };
        const updatePurchaseOrdereItemInput = this.getUpdatePurchaseOrderItemInput();
        this.purchaseOrderService.updatePurchaseOrder(updatePurchaseOrderInput, updatePurchaseOrdereItemInput).subscribe({
          next: (response) => {
            this.loading = false;
            this.toastr.success('Purchase Order updated successfully.');
            const updatedPurchaseOrderId = response?.data?.updatePurchaseOrder?.id;
            if (updatedPurchaseOrderId) {
              this.purchaseOrderService.generatepurchasepdf(updatedPurchaseOrderId).subscribe({

                next: (resp) => {
                  this.router.navigate([`${navigateTo}`], {
                    queryParams: { id: updatedPurchaseOrderId },
                  });
                  const pdfurl = resp?.generatepurchasepdf;
                  if (pdfurl) {
                    this.toastr.success(resp.message || 'Successfully generated PDF.');
                  }
                },
                error: (err) => {
                  this.toastr.error(err.message || 'Failed to generate PDF.');
                }
              });
            } else {
              this.toastr.error('Purchase order ID is missing');
            }
          },
          error: (error) => {
            this.loading = false;
            console.error('Error updating purchase order :', error);
            this.toastr.error(error.message || 'Failed to update purchase order. ');
          },
        });
      }
      else {
        this.purchaseOrderService.createpurchaseorder(createPurchaseOrderInput, createPurchaseItemInput)
          .subscribe({

            next: (response) => {

              this.loading = false;
              const createdId = response?.data?.createPurchaseOrder?.id;
              console.log(createdId);

              if (createdId) {
                this.purchaseOrderService.generatepurchasepdf(createdId).subscribe({
                  next: (resp) => {
                    // this.router.navigate([`${navigateTo}/${createdId}`]);
                    this.router.navigate([`${navigateTo}`], {
                      queryParams: { id: createdId },
                    });
                    const pdfurl = resp?.generatepurchasepdf;
                    if (pdfurl) {
                      this.toastr.success(resp.message || 'Successfully generated PDF.');
                    }
                  },
                  error: (err) => {
                    this.toastr.error(err.message || 'Failed to generate PDF.');
                  }
                })
              }
              this.toastr.success('Local purchase order created successfully');
            },

            error: (error) => {
              this.loading = false;
              this.toastr.error(error.message || 'Failed to create Purchase-order.');
            },
          });
      }
    }
  }


  getUpdatePurchaseOrderItemInput() {
    return this.items.controls.map((itemGroup) => {
      const id = itemGroup.get('id')?.value;
      const itemId = itemGroup.get('selectItem')?.value;
      const isManual = itemGroup.get('isManual')?.value;
      const name = itemGroup.get('itemName')?.value;
      const quantity = itemGroup.get('quantity')?.value.toString();
      const balanceQuantity = itemGroup.get('quantity')?.value;
      const rate = itemGroup.get('rate')?.value.toString();
      const discount = itemGroup.get('discount')?.value;
      const amount = itemGroup.get('total')?.value;
      const purchaseAccountId = itemGroup.get('account')?.value;

      const existingPurchaseOrderItem = this.purchaseOrderData?.purchaseItems?.find((purchaseorderItem: any) => purchaseorderItem.itemId === itemId);

      return {
        // id: existingPurchaseOrderItem ? existingPurchaseOrderItem.id : null, // Existing item gets id; new item gets null
        itemId: itemId || null, // Ensure itemId is set correctly
        name: name || (existingPurchaseOrderItem ? existingPurchaseOrderItem.name : ''),
        isManual: !!isManual,
        quantity,
        balanceQuantity,
        rate,
        discount,
        amount,
        purchaseAccountId
      };
    });
  }
  onCancel(): void {
    this.purchaseOrderForm.reset();
    this.router.navigate(['/local-purchase-order']);
  }
}
