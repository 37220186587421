import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormArray,
} from '@angular/forms';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ExpenseServices } from '../../features/expenses/services/expenses.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../features/projects/services/projects.service';
import { BankingService } from '../../features/banking/services/banking.service';
import { PettyCashService } from '../../features/petty-cash/services/petty-cash.service';

@Component({
  selector: 'app-petty-cash',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './petty-cash.component.html',
  styleUrl: './petty-cash.component.scss'
})
export class AddPettyCashComponent {

  expenseForm: FormGroup;
  currencyData: any;
  formArrayInitialized = false;
  today: Date = new Date();
  chartOfAccounts: any = [];
  vendorsList: any = [];
  customerList: any = [];
  readonly dialog = inject(MatDialog);
  private searchSubject = new Subject<string>();
  loading: boolean = false;
  pettyCashId: number | null = null;
  allProjects: any = [];
  filterEmployees: any = [];
  bankId: any;
  categoryId: any;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private expenseService: ExpenseServices,
    private datePipe: DatePipe,
    private pettyCashService: PettyCashService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private bankingService: BankingService,
  ) {
    this.expenseForm = new FormGroup({
      projectId: new FormControl('', [Validators.required]),
      employeeId: new FormControl('', [Validators.required]),
      flow: new FormControl('', [Validators.required]),
      expenses: new FormArray([]), // Initialize as an empty FormArray
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.pettyCashId = +id;
        this.initializeExpenseForm();
        this.loadSinglePettyCash(+id);
      } else {
        this.addExpenseRow();
      }
    });
    this.fetchAllProjects();
  }

  initializeExpenseForm() {
    this.expenseForm = new FormGroup({
      projectId: new FormControl({ value: '', disabled: !!this.pettyCashId }, [Validators.required]),
      employeeId: new FormControl({ value: '', disabled: !!this.pettyCashId }, [Validators.required]),
      flow: new FormControl('', [Validators.required]),
      expenses: new FormArray([]),
    });
  }

  loadSinglePettyCash(pettyCashId: number): void {
    this.pettyCashService.fetchPettyCashById(pettyCashId).subscribe(async (data) => {
      try {
        const expenseData = data;
        if (expenseData) {
          this.fetchEmployee(expenseData?.projectId);
          console.log('expenseData', expenseData);
          const item = {
            date: expenseData.date,
            description: expenseData.description,
            amount: expenseData.amount,
          };
          this.updateExpenseRow(item);

          this.expenseForm.patchValue({
            projectId: expenseData?.projectId,
            employeeId: expenseData?.employeeId,
            flow: expenseData?.flow,
          });
        }
      } catch (error) {
        this.router.navigate(['/petty-cash']);
        console.error('Error loading employee data:', error);
      }
    });
  }

  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };

  fetchEmployee(id: any) {
    this.projectService.getOneProject(Number(id)).subscribe({
      next: (data: any) => {
        data?.projectResources.forEach((emp: any) => {
          this.filterEmployees.push({
            id: emp?.employeeId,
            name: emp?.employeeData?.firstName + ' ' + emp?.employeeData?.lastName,
          });
        })
      },
      error: (error) => {
        console.log('the error is ', error);
        this.toastr.error(error?.message);
      },
    });
  }

  fetchAllProjects() {
    this.projectService.getAllProjects('').subscribe({
      next: (data: any) => {
        this.allProjects = data;
      },
      error: (error) => {
        console.log('the error is ', error);
        this.toastr.error(error?.message);
      },
    });
  }


  get expenses(): FormArray {
    return this.expenseForm.get('expenses') as FormArray;
  }

  onItemizeRemove(): void {
    if (!this.formArrayInitialized) {
      this.formArrayInitialized = true;
      // this.addExpenseRow();
    } else {
      this.formArrayInitialized = false;
      // this.removeAllExpenses();
      this.addExpenseRow();
    }
  }
  addExpenseRow() {
    const expenseGroup = new FormGroup({
      date: new FormControl('', Validators.required),
      description: new FormControl('', [Validators.maxLength(500)]),
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d+$/),
      ]),
    });

    this.expenses.push(expenseGroup);
  }

  updateExpenseRow(data: any) {
    const expenseGroup = new FormGroup({
      date: new FormControl(data?.date ?? '', Validators.required),
      description: new FormControl(data?.description ?? '', [
        Validators.maxLength(500),
      ]),
      amount: new FormControl(data?.amount ?? '', [
        Validators.required,
        Validators.pattern(/^\d+$/), // Validates positive integers only
      ]),
    });

    this.expenses.push(expenseGroup);
  }

  removeExpenseRow(index: number) {
    const itemData = this.expenses.at(index) as FormGroup;
    const values = itemData?.value;
    if (values?.id) {
      this.expenseService.removeExpenseItem(values?.id).subscribe({
        next: (response) => {
          this.expenses.removeAt(index);
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to remove item');
          this.loading = false;
        },
      });
    } else {
      this.expenses.removeAt(index);
    }
  }

  onSubmit(): void {
    if (this.expenseForm.invalid) {
      this.expenseForm.markAllAsTouched();
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      return;
    }
    let expenseItems: any;
    const values = this.expenseForm?.value;
    expenseItems = values?.expenses;
    expenseItems = expenseItems.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, 'yyyy-MM-dd'), // Format the date
    }));

    let createPettyCashInput: any;


    console.log('createExpenseInput', createPettyCashInput);

    this.loading = true;
    if (this.pettyCashId) {

      const formattedExpenseItem = expenseItems?.length
        ? {
          date: expenseItems[0]?.date, // Assuming only the first item is relevant
          description: expenseItems[0]?.description,
          amount: expenseItems[0]?.amount,
        }
        : null;
      createPettyCashInput = {
        id: this.pettyCashId,
        projectId: values?.projectId,
        employeeId: values?.employeeId,
        flow: values?.flow,
        ...formattedExpenseItem
      };

      this.pettyCashService
        .updatePettyCash(
          createPettyCashInput,
        )
        .subscribe({
          next: (response) => {
            this.toastr.success('Petty cash updated successfully');
            this.router.navigate(['/petty-cash']);
            this.loading = false;
          },
          error: (error) => {
            this.toastr.error(error.message || 'Failed to create invoice.');
            this.loading = false;
          },
        });
    }
    else {
      createPettyCashInput = {
        projectId: values?.projectId,
        employeeId: values?.employeeId,
        flow: values?.flow,
        expenseItems: expenseItems
      };

      this.pettyCashService
        .createPettyCash(createPettyCashInput)
        .subscribe({
          next: (response) => {
            this.toastr.success('Petty cash created successfully')
            this.router.navigate(['/petty-cash']);
            this.loading = false;
          },
          error: (error) => {
            this.toastr.error(error.message || 'Failed to create invoice.');
            this.loading = false;
          },
        });
    }
  }


  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    console.log('onSearchonSearchonSearch', input);

    this.searchSubject.next(searchTerm);
  }

  onProjectSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.expenseForm.get('projectId')?.setValue(selectedCustomerId);
    this.expenseForm.get('employeeId')?.setValue(null);
    this.fetchEmployee(selectedCustomerId);
  }

  onClearForm(): void {
    this.expenseForm.reset();
  }
}
