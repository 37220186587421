import { gql } from 'apollo-angular';

export const UPDATE_WORK_ORDER = gql`
  mutation updateWorkOrder(
    $updateWorkOrderInput: UpdateWorkOrderInput!
    $workOrderItems: [UpdateWorkOrderSowInput!]
    $fileAttachments: [UpdateWorkOrderAttachmentInput!]
  ) {
    updateWorkOrder(
      updateWorkOrderInput: $updateWorkOrderInput
      workOrderItems: $workOrderItems
      fileAttachments: $fileAttachments
    ) {
      id
      workOrderStatus
      workOrderNo
      partnerId
    }
  }
`;

export const REMOVE_WORK_ORDER = gql`
  mutation removeWorkOrder($id: Int!) {
    removeWorkOrder(id: $id)
  }
`;
