import { gql } from 'apollo-angular';

export const CREATE_NOTE = gql`
  mutation createNote(
    $createNoteInput: CreateNoteInput!
    $noteItems: [CreateNoteItemInput!]!
  ) {
    createNote(createNoteInput: $createNoteInput, noteItems: $noteItems) {
      id
      status
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation createComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) {
      id
      employeeId
    }
  }
`;

export const CHANGE_NOTE_STATUS = gql`
  mutation changeNoteStatus($noteId: Int!, $newStatus: String!) {
    changeNoteStatus(noteId: $noteId, newStatus: $newStatus) {
      id
      partnerId
    }
  }
`;

export const REMOVE_NOTE = gql`
  mutation removeNote($id: Int!) {
    removeNote(id: $id) {
      id
      status
    }
  }
`;

export const REMOVE_NOTE_ITEM = gql`
  mutation removeNoteItem($id: Int!) {
    removeNoteItem(id: $id) {
      id
      status
    }
  }
`;

export const SEND_NOTE_MAIL = gql`
  mutation sendNoteMail(
    $noteId: Int!
    $to: [String!]!
    $cc: [String!]
    $subject: String!
    $body: String!
    $attachment: [Upload!]
    $isAttachment: Boolean
  ) {
    sendNoteMail(
      noteId: $noteId
      to: $to
      cc: $cc
      subject: $subject
      body: $body
      attachment: $attachment
      isAttachment: $isAttachment
    )
  }
`;

export const GENERATE_NOTE_PDF = gql`
  query generateNotePdf($noteId: Int!, $noteType: String!) {
    generateNotePdf(noteId: $noteId, noteType: $noteType)
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote(
    $updateNoteInput: UpdateNoteInput!
    $noteItems: [UpdateNoteItemInput!]
  ) {
    updateNote(updateNoteInput: $updateNoteInput, noteItems: $noteItems) {
      id
      partnerId
    }
  }
`;

export const APPLY_TO_INVOICE = gql`
  mutation applyNoteInvoice($applyNoteInvoice: CreateNoteInvoiceInput!) {
    applyNoteInvoice(applyNoteInvoice: $applyNoteInvoice) {
      id
      invoiceId
      type
      refId
      amount
      status
    }
  }
`;
