<mat-dialog-content class="mat-typography">
  <section class="add-branch-dialog-wrapper">
    <h2 class="cmn-popup-title">{{desigantionData?.id ? 'Edit':'Add'}} Designation</h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form [formGroup]="designationForm" (ngSubmit)="onAddBranch()">
      <div class="branch-form-wrapper">
        <div class="form-wrapper">
          <label for="">Designation Name</label>
          <mat-form-field>
            <input matInput placeholder="Enter Designation Name" formControlName="designationName" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="designationForm.get('designationName')!.touched && designationForm.get('designationName')!.hasError('required')">Designation
                Name is required.</mat-error>
            </span>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="cmn-next-btn" type="submit" [disabled]="loading">
          <span *ngIf="!loading">{{desigantionData?.id ? 'Save':'Add Designation'}}</span>
          <span *ngIf="loading">Loading...</span>
        </button>
      </div>
    </form>
  </section>
</mat-dialog-content>