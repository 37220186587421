import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {
  DateFilterFn,
  MatDatepickerModule,
} from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  PartnerTypesEnum,
  PAYMENT_MODES,
  PAYMENT_TERMS,
  PaymentTypesEnum,
  UNITTYPES,
} from '../../helpers/helper-file';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { addDays, addMonths, endOfMonth } from 'date-fns';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import {
  nonNegativeValidator,
  quantityValidator,
} from '../../shared/services/validations';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { CreateClientDialog } from './dialog-components/create-customer-dialog/create-client-dialog.component';
import { BillingAddressDialog } from './dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { ShippingAddressDialog } from './dialog-components/add-shipping-address-dialog/shipping-address-dialog.component';
import { ListBillingAddressDialog } from './dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { ListShippingAddressDialog } from './dialog-components/list-shipping-address-dialog/list-shipping-address-dialog.component';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { PreferenceService } from '../../shared/services/preference.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-invoice-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
  ],
  templateUrl: './invoice-new.component.html',
  styleUrl: './invoice-new.component.scss',
})
export class InvoiceNewComponent implements OnInit {
  public arr = [1, 2, 3, 4, 5, 6, 7];
  filter: {} = {};
  isEditMode: boolean = false;
  search: string = '';
  loading: boolean = false;
  invoiceForm: FormGroup;
  invoiceData: any;
  invoiceId: number | null = null;
  addedItems: any[] = [];
  workOrderData: any[] = [];
  quoteData: any[] = [];
  isBranchReadOnly: boolean = false;
  public partnersData: any[] = [];
  public bankData: any[] = [];
  public projectData: any[] = [];
  public itemData: any[] = [];
  public employeesData: any[] = [];
  public depositAccountData: any[] = [];
  readonly dialog = inject(MatDialog);
  paymentModes = PAYMENT_MODES;
  paymentTermsValue = PAYMENT_TERMS;
  isManualDateChange = true;
  private searchSubject = new Subject<string>();
  private searchBank = new Subject<string>();
  public selectedBillingAddress: any = {};
  public selectedShippingAddress: any = {};
  showIsPaymentCheckbox = false;
  showPaymentTable = false;
  public billingAddressList: any[];
  public shippingAddressList: any[];
  selectedCustomerId: any;
  selectedBranchId: number;
  selectedWorkOrderId: number;
  selectedQuoteId: number;
  branchName: string | null = null;
  quoteCode: string = '';
  projectName: string = '';
  private partnerTypes = PartnerTypesEnum;

  paymentTerm: string | null = null;
  invoiceIncrementCode: string;
  public branchData: any[] = [];

  unitTypes: any = UNITTYPES;

  public filteredItemData: any[] = [];

  file: any = null;
  csvFile: File[] = [];
  url: string = '';
  fileDetailsArray: {
    fileName: string;
    fileUrl: string;
    id?: number | null;
  }[] = [];

  @ViewChild('fileInput') fileInput!: ElementRef;
  currentFiles: File[] = [];

  private status: 'Draft' | 'Sent' = 'Sent';
  prefix: any;
  invoiceNo: any;

  constructor(
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private itemService: InventoryItemService,
    private documentUploadService: UploadFileService,
    private preferenceService: PreferenceService,
    private authService: AuthService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.fetchPartners();
    this.fetchAccountsDeposit();
    this.fetchItems();
    this.fetchBanks();

    this.selectedBranchId = this.authService.getSelectedBranchId();

    if (this.selectedBranchId == 0 || !this.selectedBranchId) {
      this.isBranchReadOnly = false;
    } else {
      this.isBranchReadOnly = true;
    }

    this.items.valueChanges.subscribe(() => {
      this.items.controls.forEach((control, index) => {
        this.updateItemTotal(index);
      });
    });

    this.invoiceForm.get('partnerId')?.valueChanges.subscribe((partnerId) => {
      this.setAddresses(partnerId);
    });

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {

        // this.invoiceForm.get('partnerId')?.disable();
        this.invoiceId = +id;
        this.loadInvoiceData();
        this.isEditMode = true;
      }
    });

    this.items.valueChanges.subscribe(() => {
      this.getTotalAmount();
      this.getSubTotalAmount();
      this.getTotalDiscount();
    });

    if (!this.invoiceId) {
      this.addItem();
      // this.addPayment();
    }

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });

    this.searchBank
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((bankSearchTerm) => {
        this.fetchBanks(bankSearchTerm)
      })

    this.items.valueChanges.subscribe(() => {
      this.getTotalAmount();
    });


  }

  initForm(): void {
    this.invoiceForm = this.fb.group({
      id: [this.invoiceData?.id || ''],
      partnerId: [this.invoiceData?.partnerId, Validators.required],
      shippingAddressId: this.selectedShippingAddress.id,
      billingAddressId: this.selectedBillingAddress.id,
      projectModelId: [this.invoiceData?.projectModelId],
      workOrderId: [this.invoiceData?.workOrderId],
      quoteId: [this.invoiceData?.quoteId],
      bankId: [this.invoiceData?.bankId, Validators.required],
      invoiceNo: [
        this.invoiceData?.invoiceNo ? this.invoiceData?.invoiceNo : this.prefix,
        Validators.required,
      ],
      orderNo: [this.invoiceData?.orderNo],
      invoiceDate: [
        this.invoiceData?.invoiceDate || new Date(),
        Validators.required,
      ],
      tax: [this.invoiceData?.tax],
      branchId: [
        this.invoiceData?.branchId,
        [Validators.required, 
        ],
      ],
      paymentTerm: [this.invoiceData?.paymentTerm],
      dueDate: [
        this.invoiceData?.dueDate ? new Date(this.invoiceData.dueDate) : null,
      ],
      subject: [''],
      items: this.fb.array([]),
      payments: this.fb.array([]),
      invoiceAttachments: this.fb.array([]),
      customerNote: [''],
      isPayment: [false],
    });
  }

  loadInvoiceData(): void {
    if (this.invoiceId !== null) {
      this.invoiceService.fetchInvoiceById(this.invoiceId).subscribe({
        next: (data) => {
          this.invoiceData = data;

          this.fetchPartners();
          this.fetchWorkOrders(this.invoiceData.partnerId, '');
          this.fetchQuotesByWorkOrder(this.invoiceData?.workOrderId);
          this.initForm();

          this.invoiceForm.patchValue({
            partnerId: this.invoiceData.partnerId,
            shippingAddressId: this.invoiceData.shippingAddressId,
            billingAddressId: this.invoiceData.billingAddressId,
            projectModelId: this.invoiceData.projectModelId,
            workOrderId: this.invoiceData.workOrderId,
            quoteId: this.invoiceData.quoteId,
            bankId: this.invoiceData.bankId,
            invoiceNo: this.invoiceData.invoiceNo,
            orderNo: this.invoiceData.orderNo,
            tax: this.invoiceData.tax,
            invoiceDate: this.invoiceData.invoiceDate
              ? new Date(Number(this.invoiceData.invoiceDate))
              : null,
            branchId: this.invoiceData.branchId,
            paymentTerm: this.invoiceData.paymentTerm,
            dueDate: this.invoiceData.dueDate
              ? new Date(Number(this.invoiceData.dueDate))
              : null,
            subject: this.invoiceData.subject,
            customerNote: this.invoiceData.customerNote,
            isPayment: this.invoiceData.isPayment,
          });
          this.selectedBillingAddress = this.invoiceData.billingAddressData;
          this.selectedShippingAddress = this.invoiceData.shippingAddressData;

          // this.quoteCode = this.invoiceData?.quoteData?.quoteCode || '';
          // this.projectName = this.invoiceData?.projectData?.projectName || '';

          // Make customer and work order fields read-only
          this.invoiceForm.get('partnerId')?.disable();
          this.invoiceForm.get('workOrderId')?.disable();

          this.setAddresses(this.invoiceData?.partnerData?.id);
          this.selectedCustomerId = this.invoiceData.partnerData.id;

          if (data.invoiceItems) {
            data.invoiceItems.forEach((item: any) => {
              const itemForm = this.fb.group({
                id: [item.id || null],
                itemId: [item.itemId, Validators.required],
                itemName: [item.itemData.itemName],
                isManual: [item.isManual],
                unit: [item.unit, Validators.required],
                quantity: [item.quantity, Validators.required],
                rate: [item.rate, Validators.required],
                discount: [item.discount, Validators.pattern(/^\d+(\.\d+)?$/)],
                tax: [item.vat],
                amount: [item.amount, Validators.required],
                netAmount: [item.netAmount, Validators.pattern(/^\d+(\.\d+)?$/)],
              });

              // this.items.push(itemForm);
              this.addedItems.push(itemForm);
              this.calculateAmount(itemForm);

              itemForm.get('isManual')?.valueChanges.subscribe((isManual) => {
                const itemIdControl = itemForm.get('itemId');
                if (isManual) {
                  itemIdControl?.clearValidators();
                  itemIdControl?.setValue(null);
                } else {
                  itemIdControl?.setValidators(Validators.required);
                }
                itemIdControl?.updateValueAndValidity();
              });

              // // Sync rate and quantity when itemId changes
              // itemForm
              //   .get('itemId')
              //   ?.valueChanges.subscribe(() => this.calculateAmount(itemForm));
              // itemForm
              //   .get('rate')
              //   ?.valueChanges.subscribe(() => this.calculateAmount(itemForm));
              // itemForm
              //   .get('quantity')
              //   ?.valueChanges.subscribe(() => this.calculateAmount(itemForm));
            });
          }

          if (data.invoiceAttachments) {
            data.invoiceAttachments.forEach((attachment: any) => {
              this.fileDetailsArray.push({
                id: attachment.id,
                fileName: attachment.fileName,
                fileUrl: attachment.fileUrl,
              });
            });
          }
        },
        error: (error) => console.error(error),
      });
    }
  }

  dueDateBeforeInvoiceDateValidator(control: any) {
    const invoiceDate = this.invoiceForm.get('invoiceDate')?.value;
    const dueDate = control.value;

    if (invoiceDate && dueDate) {
      const invoiceDateObj = new Date(invoiceDate);
      const dueDateObj = new Date(dueDate);

      if (dueDateObj < invoiceDateObj) {
        return { dueDateBeforeInvoiceDate: true };
      }
    }
    return null;
  }

  private formatToLocalDate(date: any): string {
    if (!date) {
      return '';
    }
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return '';
    }
    return dateObj.toISOString().split('T')[0];
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  onBankSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const bankSearchTerm = input.value;
    this.searchBank.next(bankSearchTerm);
  }

  onItemSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;

    if (searchTerm) {
      this.fetchItems(searchTerm);
    } else {
      this.filteredItemData = this.itemData;
    }
  }

  onSelectItem(event: MatAutocompleteSelectedEvent): void {
    const selectedItem = this.itemData.find(
      (item) => item.id === event.option.value
    );
    if (selectedItem) {
      const itemGroup = this.items.at(this.items.length - 1);
      itemGroup.patchValue({
        itemId: selectedItem.id,
        itemName: selectedItem.itemName,
        isManual: false,
      });
    }
  }

  getUpdateInvoiceItemInput(): any[] {
    return this.items.controls.map((itemGroup) => {
      const id = itemGroup.get('id')?.value;
      const itemId = itemGroup.get('itemId')?.value;
      const isManual = itemGroup.get('isManual')?.value;
      const name = itemGroup.get('itemName')?.value;
      const quantity = itemGroup.get('quantity')?.value;
      const unit = itemGroup.get('unit')?.value;
      const rate = itemGroup.get('rate')?.value;
      const discount = itemGroup.get('discount')?.value;
      const amount = parseFloat(itemGroup.get('amount')?.value);
      const taxControl = itemGroup.get('vat');
      const vat = parseFloat(taxControl?.value || '0');

      const existingInvoiceItem = this.invoiceData?.invoiceItems?.find(
        (invoiceItem: any) => invoiceItem.itemId === itemId
      );

      return {
        id: existingInvoiceItem ? existingInvoiceItem.id : null,
        itemId: itemId || null,
        name: name || (existingInvoiceItem ? existingInvoiceItem.name : ''),
        isManual: !!isManual,
        quantity,
        unit,
        rate,
        discount,
        amount,
        vat,
      };
    });
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      itemId: [null],
      itemName: ['', Validators.required],
      isManual: [true],
      quantity: [
        '1',
        [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
          quantityValidator,
        ],
      ],
      unit: ['', Validators.required],
      rate: ['0', Validators.required],
      discount: [
        0,
        [Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
      amount: [0, [Validators.required, nonNegativeValidator]],
      vat: [0, [Validators.required, nonNegativeValidator]],
      netAmount: [0, [Validators.required, nonNegativeValidator]]
    });

    this.items.push(itemGroup);

    itemGroup.get('isManual')?.valueChanges.subscribe((isManual) => {
      const itemIdControl = itemGroup.get('itemId');
      if (isManual) {
        itemIdControl?.clearValidators();
        itemIdControl?.setValue(null);
      } else {
        itemIdControl?.setValidators(Validators.required);
      }
      itemIdControl?.updateValueAndValidity();
    });

    itemGroup.updateValueAndValidity();
    this.items.updateValueAndValidity();

    itemGroup.get('itemId')?.valueChanges.subscribe((itemId) => {
      const selectedItem = this.itemData.find((item) => item.id === itemId);
      if (selectedItem) {
        itemGroup.patchValue({
          rate: selectedItem.sellingPrice.toString(),
          quantity: '1',
        });
        this.calculateAmount(itemGroup);
      }
      itemGroup.get('itemId')?.updateValueAndValidity();
    });

    itemGroup
      .get('rate')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('quantity')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('discount')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  }

  calculateAmount(itemGroup: FormGroup): void {
    const rate = parseFloat(itemGroup.get('rate')?.value || '0');
    const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
    const discount = itemGroup.get('discount')?.value || 0;
    let netAmount = rate * quantity;
    if (discount > 0) {
      netAmount = (netAmount - discount);
    }
    itemGroup.patchValue({ netAmount });
    const vat = (netAmount * 0.05).toFixed(2) || 0;
    itemGroup.patchValue({ vat });
    itemGroup.patchValue({ netAmount });
    let amount = Number(netAmount) + Number(vat);
    itemGroup.patchValue({ amount })
  }

  getSubTotalAmount(): number {
    const newItemsSubtotal = this.items.controls.reduce((subtotal, item) => {
      const quantity = parseFloat(item.get('quantity')?.value || '0');
      const rate = parseFloat(item.get('rate')?.value || '0');
      return subtotal + quantity * rate;
    }, 0);

    const existingItemsSubtotal = this.invoiceData?.invoiceItems.reduce(
      (subtotal: any, item: any) => {
        const quantity = parseFloat(item.quantity || '0');
        const rate = parseFloat(item.rate || '0');
        return subtotal + quantity * rate;
      },
      0
    );
    return newItemsSubtotal + (existingItemsSubtotal || 0);
  }


  getTotalDiscount(): number {
    const newItemsDiscount = this.items.controls.reduce(
      (totalDiscount, item) => {
        const totalAmount = this.getItemTotalAmount(item.value);
        const netAmount = item.get('netAmount')?.value || 0;
        return totalDiscount + (totalAmount - netAmount);
      },
      0
    );

    const existingItemsDiscount = this.invoiceData?.invoiceItems.reduce(
      (totalDiscount: any, item: any) => {
        const discount = item.discount || 0;
        return totalDiscount + discount
      },
      0
    );

    return newItemsDiscount + (existingItemsDiscount || 0);
  }

  getVAT(): number {
    let newItemsVAT = 0;
  
    this.items.controls.forEach((item) => {
      const vat = item.get('vat')?.value || 0; 
      newItemsVAT += vat;
    });
  
    // const existingVAT = this.invoiceData?.tax || 0;
      const existingVAT = this.invoiceData?.invoiceItems.reduce(
      (totalVatAmount: number, item: any) => {
        const vat = item.vat || 0;
        return totalVatAmount + vat;
      },
      0
    );

    const totatlVatAmount = Number(existingVAT) + Number(newItemsVAT)
  
    return totatlVatAmount;
  }
  
  getSubTotalDiscountDifference(): number {
    const subTotal = this.getSubTotalAmount();
    const totalDiscount = this.getTotalDiscount();
    return subTotal - totalDiscount;
  }

  getTotalAmount(): number {
    const subTotal = this.getSubTotalAmount();
    const totalDiscount = this.getTotalDiscount();
    const vat = this.getVAT();
    const TotalAmount = Number(subTotal) - Number(totalDiscount) + Number(vat)
    return TotalAmount;
  }

  getItemTotalAmount(item: any): number {
    const quantity = parseFloat(item?.quantity || '0');
    const rate = parseFloat(item?.rate || '0');
    return quantity * rate;
  }

  getItemNetAmount(item: any): number {
    const quantity = parseFloat(item?.quantity || '0');
    const rate = parseFloat(item?.rate || '0');
    const discount = item.get('discount')?.value || 0;
    let totalAmount = quantity * rate;
    if (discount > 0) {
      totalAmount = totalAmount - discount;
    }
    return totalAmount;
  }

  getItemGrossAmount(item: any): number {
    const quantity = parseFloat(item?.quantity || '0');
    const rate = parseFloat(item?.rate || '0');
    return quantity * rate;
  }

  updateItemTotal(index: number): void {
    const itemsControl = this.items.controls[index];
    const quantity = itemsControl.get('quantity')?.value || 0;
    const rate = itemsControl.get('rate')?.value || 0;
    const discount = itemsControl.get('discount')?.value || 0;

    const netAmount = quantity * rate - discount;
    itemsControl.get('netAmount')?.setValue(netAmount, { emitEvent: false })

    const vatControl = itemsControl.get('vat');
    if (!vatControl?.dirty) {
      const vatValue = +(netAmount * 0.05).toFixed(2);
      vatControl?.setValue(vatValue, { emitEvent: false });
    } else {
      const userEnteredVat = +vatControl?.value;
      const formattedVat = +userEnteredVat.toFixed(2);
      vatControl?.setValue(formattedVat, { emitEvent: false });
    }
    const vat = vatControl?.value || 0;
    const totalAmount = netAmount + vat;
    itemsControl.get('amount')?.setValue(totalAmount, { emitEvent: false });
  }

  onCustomerSelected(event: MatSelectChange): void {
    const selectedCustomerId = event.value;
    this.selectedCustomerId = selectedCustomerId;

    const selectedPartner = this.partnersData.find(
      (partner) => partner.id === selectedCustomerId
    );

    if (selectedPartner && selectedPartner.partnerProjects) {
      this.projectData = selectedPartner.partnerProjects.map(
        (project: any) => ({
          id: project.id,
          projectName: project.projectName,
        })
      );
    } else {
      this.projectData = [];
    }

    if (this.selectedCustomerId) {
      this.fetchWorkOrders(this.selectedCustomerId, '');
    }

    this.workOrderData = [];
    this.quoteData = [];
    this.invoiceForm.get('projectModelId')?.reset();
    this.invoiceForm.get('workOrderId')?.reset();
    this.invoiceForm.get('quoteId')?.reset();
    this.invoiceForm.get('branchId')?.reset();
  }

  onPaymentTermChange(term: string): void {
    const invoiceDate =
      this.invoiceForm.get('invoiceDate')?.value || new Date();
    let dueDate: Date | null = null;

    switch (term) {
      case 'Net 15':
        dueDate = addDays(invoiceDate, 15);
        break;
      case 'Net 30':
        dueDate = addDays(invoiceDate, 30);
        break;
      case 'Net 45':
        dueDate = addDays(invoiceDate, 45);
        break;
      case 'Net 60':
        dueDate = addDays(invoiceDate, 60);
        break;
      case 'Due On Receipt':
        dueDate = invoiceDate;
        break;
      case 'Due end of the month':
        dueDate = endOfMonth(invoiceDate);
        break;
      case 'Due end of next month':
        dueDate = endOfMonth(addMonths(invoiceDate, 1));
        break;
      case 'Custom':
        dueDate = null;
        break;
      default:
        dueDate = null;
    }

    if (dueDate) {
      this.isManualDateChange = false;
      this.invoiceForm.get('dueDate')?.setValue(dueDate);
    }
  }

  get items(): FormArray {
    return this.invoiceForm.get('items') as FormArray;
  }

  get invoiceAttachments(): FormArray {
    return this.invoiceForm.get('invoiceAttachments') as FormArray;
  }

  get payments(): FormArray {
    return this.invoiceForm.get('payments') as FormArray;
  }

  addPayment(): void {
    const paymentData = this.fb.group({
      paymentMode: [''],
      depositId: [''],
      amount: [0, Validators.pattern(/^\d+(\.\d+)?$/)],
    });
    this.payments.push(paymentData);
  }

  setAddresses(partnerId: number): void {
    const selectedPartner = this.partnersData.find((p) => p.id === partnerId);

    if (selectedPartner) {
      if (
        Array.isArray(selectedPartner.addresses) &&
        selectedPartner.addresses.length > 0
      ) {
        this.selectedBillingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'BILLING'
          ) || null;
        this.selectedShippingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'SHIPPING'
          ) || null;

        this.invoiceForm.patchValue({
          billingAddressId: this.selectedBillingAddress?.id || null,
          shippingAddressId: this.selectedShippingAddress?.id || null,
        });
      } else {
        console.error(
          `No addresses found or addresses is not an array for partnerId: ${partnerId}`
        );
      }
    } else {
      console.error(`Partner with ID ${partnerId} not found in partnersData.`);
    }
  }

  saveAsDraft(): void {
    this.status = 'Draft';
    this.createInvoice('invoice/invoice-details');
  }

  saveAndSend(): void {
    this.status = 'Draft';
    this.createInvoice('invoice/invoice-details/mail');
  }

  markAllControlsAsTouched(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAllControlsAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }

  logFormErrors(
    formGroup: FormGroup | FormArray,
    parentKey: string = ''
  ): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      const controlPath = parentKey ? `${parentKey}.${key}` : key;

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.logFormErrors(control, controlPath); // Recursively log nested controls
      } else if (control && control.invalid) {
        console.error(`Control: ${controlPath}, Errors:`, control.errors);
      }
    });
  }

  createInvoice(navigateTo: string): void {
    this.markAllControlsAsTouched(this.invoiceForm);

    if (this.invoiceForm.invalid) {
      console.error('Form is invalid:');
      this.logFormErrors(this.invoiceForm);
      this.toastr.warning('Please fill in all required fields.');
      console.log('Form values--', this.invoiceForm.value);
      return;
    }

    const billingAddressId = this.invoiceForm.get('billingAddressId')?.value;
    const shippingAddressId = this.invoiceForm.get('shippingAddressId')?.value;

    if (!billingAddressId || !shippingAddressId) {
      this.toastr.warning('Please select both billing and shipping addresses.');
      return;
    }

    if(!this.invoiceId) {
      const items = this.invoiceForm.value.items;
      if (!items || items.length === 0) {
        this.toastr.warning('Please add at least one item to the invoice.');
        return;
      }
    }

    else {
      const formValues = this.invoiceForm.value;
      const subTotal = this.getSubTotalAmount();
      const totalPrice = this.getTotalAmount();
      const tax = this.getVAT();
      const createInvoiceInput = {
        partnerId: formValues.partnerId,
        billingAddressId: formValues.billingAddressId,
        shippingAddressId: formValues.shippingAddressId,
        projectModelId: formValues.projectModelId,
        workOrderId: formValues.workOrderId,
        quoteId: formValues.quoteId,
        bankId: formValues.bankId,
        invoiceNo: formValues.invoiceNo,
        orderNo: formValues.orderNo,
        invoiceDate: formValues.invoiceDate,
        branchId: formValues.branchId,
        dueDate: formValues.dueDate
          ? this.formatToLocalDate(formValues.dueDate)
          : null,
        paymentTerm: formValues.paymentTerm,
        subject: formValues.subject,
        customerNote: formValues.customerNote,
        isPayment: formValues.isPayment,
        tax,
        totalPrice,
        subTotal,
        status: this.status,
        balance: totalPrice
      };

      const createInvoiceItemInput = this.invoiceForm.value.items.map(
        (item: any) => {
          if (item.isManual) {
            return {
              itemId: null,
              name: item.itemName,
              isManual: true,
              quantity: item.quantity,
              unit: item.unit,
              rate: item.rate,
              discount: item.discount,
              vat: item.vat,
              amount: item.amount,
            };
          } else {
            return {
              itemId: item.itemId,
              isManual: false,
              quantity: item.quantity,
              unit: item.unit,
              rate: item.rate,
              discount: item.discount,
              vat: item.vat,
              amount: item.amount,
            };
          }
        }
      );

      const createInvoiceAttachmentInput = this.fileDetailsArray
        .filter((file) => !file.id)
        .map((file) => ({
          // id: null,
          fileName: file.fileName,
          fileUrl: file.fileUrl,
        }));

      const updateInvoiceAttachmentInput = this.fileDetailsArray.map(
        (file) => ({
          id: file.id || null,
          fileName: file.fileName,
          fileUrl: file.fileUrl,
        })
      );

      const updateInvoiceInput = {
        id: this.invoiceId,
        ...createInvoiceInput,
      };

      const updateInvoiceItemInput = this.getUpdateInvoiceItemInput();

      const loginEmployee: any = localStorage.getItem('loggedInUser');
      const employee = JSON.parse(loginEmployee);
      const employeeId = employee.employeeId;

      this.loading = true;
      if (this.invoiceId) {
        this.invoiceService
          .updateInvoice(
            updateInvoiceInput,
            updateInvoiceItemInput,
            updateInvoiceAttachmentInput
          )
          .subscribe({
            next: (response) => {
              this.loading = false;
              this.toastr.success('Invoice updated successfully.');
              const invoiceId = response?.data?.updateInvoice?.id;
              if (invoiceId) {
                this.invoiceService.generateInvoicePdf(invoiceId, employeeId).subscribe({
                  next: (pdfResponse) => {
                    console.log('PDF generated successfully', pdfResponse);
                    // this.router.navigate([
                    //   `${navigateTo}/${invoiceId}`,
                    // ]);
                    this.router.navigate([`${navigateTo}`], {
                      queryParams: { invoiceId },
                    });
                  },
                  error: (pdfError) => {
                    console.error('Error generating PDF:', pdfError);
                    this.toastr.error(
                      pdfError.message || 'Failed to generate PDF.'
                    );
                  },
                });
                // this.router.navigate([`${navigateTo}/${invoiceId}`]);
              } else {
                console.error('Invoice ID is missing');
              }
            },
            error: (error) => {
              this.loading = false;
              console.error('Error updating invoice:', error);
              this.toastr.error(error.message || 'Failed to update invoice.');
            },
          });
      } else {
        this.invoiceService
          .createInvoice(
            createInvoiceInput,
            createInvoiceItemInput,
            createInvoiceAttachmentInput
          )
          .subscribe({
            next: (response) => {
              this.toastr.success('Invoice created successfully');
              // this.router.navigate([navigateTo]);
              const invoiceId = response?.data?.createInvoice?.id;
              if (invoiceId) {
                this.invoiceService.generateInvoicePdf(invoiceId, employeeId).subscribe({
                  next: (pdfResponse) => {
                    console.log('PDF generated successfully', pdfResponse);
                    // this.router.navigate([
                    //   `${navigateTo}/${invoiceId}`,
                    // ]);
                    this.router.navigate([`${navigateTo}`], {
                      queryParams: { invoiceId },
                    });
                  },
                  error: (pdfError) => {
                    console.error('Error generating PDF:', pdfError);
                    this.toastr.error(
                      pdfError.message || 'Failed to generate PDF.'
                    );
                  },
                });
              } else {
                console.error('Invoice ID is missing');
              }
              this.loading = false;
            },
            error: (error) => {
              this.toastr.error(error.message || 'Failed to create invoice.');
              this.loading = false;
            },
          });
      }
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files);

      if (this.fileDetailsArray.length + newFiles.length > 10) {
        this.toastr.warning('You can only upload a maximum of 10 files.');
        return;
      }

      newFiles.forEach((newFile) => {
        const allowedExtensions = ['pdf', 'doc', 'jpg', 'jpeg', 'png'];
        const fileExtension = newFile.name.split('.').pop()?.toLowerCase();

        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          this.toastr.warning('Only PDF, DOC, and image files are allowed.');
          return;
        }

        // Prevent duplicates based on name and size
        if (
          !this.fileDetailsArray.some((file) => file.fileName === newFile.name)
        ) {
          this.fileDetailsArray.push({
            id: null,
            fileName: newFile.name,
            fileUrl: '',
          });
        }
      });

      this.uploadDocuments(newFiles);
    }
  }

  uploadDocuments(files: File[]): void {
    files.forEach((file: File) => {
      this.documentUploadService.uploadDocument(file, 'items').subscribe({
        next: (response) => {
          const uploadedFile = this.fileDetailsArray.find(
            (f) => f.fileName === file.name
          );
          if (uploadedFile) {
            uploadedFile.fileUrl = response.url;
          }
        },
        error: (error) => {
          this.toastr.error(error, 'File upload failed');
        },
      });
    });
    this.fileInput.nativeElement.value = '';
  }

  onViewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch((error) => console.error('Error:', error));
  }

  removeFile(index: number): void {
    const file = this.fileDetailsArray[index];

    if (file.id) {
      this.onRemoveitemAttachment(file.id);
    } else {
      // New file - simply remove from array
      this.fileDetailsArray.splice(index, 1);
    }
  }

  onCancel(): void {
    this.invoiceForm.reset();
    this.router.navigate(['/invoice']);
  }

  onRemoveitemAttachment(invoiceAttachmentId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((response) => {
      if (response === true) {
        this.invoiceService
          .removeInvoiceAttachmentById(invoiceAttachmentId)
          .subscribe({
            next: () => {
              this.fileDetailsArray = this.fileDetailsArray.filter(
                (file) => file.id !== invoiceAttachmentId
              );
            },
            error: () => {
              this.toastr.error('Failed to remove invoice attachment.');
            },
          });
      }
    });
  }

  onRemoveNewlyAddedItems(index: number): void {
    this.items.removeAt(index);
  }

  onRemoveInvoiceItem(index: number, invoiceItemId: number): void {
    if (invoiceItemId) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeItem(invoiceItemId, index);
        }
      });
    } else {
      console.error('Item ID is null or undefined');
      this.items.removeAt(index);
    }
  }

  private removeItem(invoiceItemId: number, index: number): void {
    this.invoiceService.removeInvoiceItemById(invoiceItemId).subscribe({
      next: () => {
        this.toastr.success('Invoice item removed successfully.');
        console.log('Invoice item removed successfully');

        this.invoiceData = {
          ...this.invoiceData,
          invoiceItems: [
            ...this.invoiceData.invoiceItems.slice(0, index),
            ...this.invoiceData.invoiceItems.slice(index + 1),
          ],
        };
      },
      error: () => {
        this.toastr.error('Failed to remove invoice item.');
        console.log('Failed to remove invoice item.');
      },
    });
  }

  private fetchBanks(search: string = '') {
    this.invoiceService.fetchBanks(search).subscribe({
      next: (banks) => {
        this.bankData = banks.map((data: any) => ({
          id: data.id || '--',
          bankName: data.bankName || '--',
          accountName: data.accountName || '--',
        }))
      }, error: (error) => console.error(error),
    })
  }

  private fetchPartners(search: string = '') {
    this.invoiceService.fetchCustomers(search, this.partnerTypes.Customer).subscribe({
      next: (partners) => {
        this.partnersData = partners
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
            partnerProjects: data.partnerProjects || [],
          }));
        this.billingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'BILLING');
        this.shippingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'SHIPPING');
      },
      error: (error) => console.error(error),
    });
  }

  fetchWorkOrders(partnerId: number, search: ''): void {
    this.invoiceService.fetchWorkOrders(partnerId, search).subscribe({
      next: (data) => {
        this.workOrderData = data.map((order: any) => ({
          id: order.id,
          workOrderNo: order.workOrderNo,
        }));
      },
      error: (error) => console.error('Error fetching work orders', error),
    });
  }

  onWorkOrderSelected(event: MatSelectChange): void {
    const selectedWorkOrderId = event.value;

    if (!this.selectedCustomerId) {
      this.toastr.warning('Please select a customer first.');
      this.invoiceForm.get('workOrderId')?.setValue(null);
      return;
    }

    this.selectedWorkOrderId = selectedWorkOrderId;

    if (this.selectedWorkOrderId) {
      this.fetchQuotesByWorkOrder(this.selectedWorkOrderId);
    }
  }  

  fetchQuotesByWorkOrder(workOrderId: number): void {
    if (this.invoiceData) {
      this.quoteCode = this.invoiceData?.quoteData?.quoteCode || '';
      this.projectName = this.invoiceData?.projectData?.projectName || '';
      this.branchName = this.invoiceData?.branchData?.branchName || '';

      this.invoiceForm.get('quoteId')?.setValue(this.invoiceData.quoteData.id);
      this.invoiceForm.get('projectModelId')?.setValue(this.invoiceData.projectData.id);
      this.invoiceForm.get('branchId')?.setValue(this.invoiceData.branchData.id);
    }
    else {
      this.invoiceService.fetchQuoteByWorkOrder(workOrderId).subscribe({
        next: (data) => {
          if (data) {
            this.quoteCode = data.quoteData.quoteCode;
            this.projectName = data.projectData.projectName;
            this.branchName = data.branchDetails.branchName;
            this.paymentTerm = data.quoteData.paymentTerm;

            // Set the IDs to form controls
            this.invoiceForm.get('quoteId')?.setValue(data.quoteData.id);
            this.invoiceForm.get('projectModelId')?.setValue(data.projectData.id);
            this.invoiceForm.get('branchId')?.setValue(data.branchDetails.id);
            this.invoiceForm.get('paymentTerm')?.setValue(data.quoteData.paymentTerm);

            if (this.quoteCode) {
              const exctractedCode = this.extractQuoteCode(this.quoteCode);
              this.invoiceService.getInvoiceIncrementByQuoteCode(this.quoteCode).subscribe({
                next: (code) => {
                  if (code) {
                    this.invoiceIncrementCode = code;
                    this.preferenceCode(exctractedCode, this.invoiceIncrementCode);
                  }
                }
              })
            }
          } else {
            // Clear fields if no data
            this.toastr.warning('No corresponding data found for the selected work order.');
            this.clearQuoteAndProjectFields();
          }
        },
        error: (error) => {
          console.error('Error fetching quote for work order', error);
          this.toastr.error('Failed to fetch quote. Please try again later.');
        },
      });
    }
  }

  clearQuoteAndProjectFields(): void {
    this.quoteCode = '';
    this.projectName = '';
    this.branchName = '';
    this.invoiceForm.get('quoteId')?.setValue(null);
    this.invoiceForm.get('projectModelId')?.setValue(null);
    this.invoiceForm.get('branchId')?.setValue(null);
    this.invoiceForm.get('paymentTerm')?.setValue(null);
  }

  extractQuoteCode(quoteCode: any): string {
    const match = quoteCode.match(/-(.+)/);
    return match ? match[1] : '';
  }

  preferenceCode(extractedCode: string, incrementCode: string): void {
    this.preferenceService.preferenceCodes('', 'Invoice').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.prefix = prefix?.sequenceCode;
        });

        // Combine preference code with extracted quote code
        const invoiceNumber = `${this.prefix}-${extractedCode}-${incrementCode}`;
        this.invoiceForm.patchValue({ invoiceNo: invoiceNumber }); // Set the invoice number
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isSalesActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.itemData = items;
        this.filteredItemData = items;
      },
      error: (error) => console.error(error),
    });
  }

  private fetchAccountsDeposit(search: string = '') {
    this.invoiceService.fetchAccountsToDeposit(search).subscribe({
      next: (accounts) => {
        this.depositAccountData = accounts;
      },
      error: (error) => console.error(error),
    });
  }

  openCreateClientDialog() {
    const dialogRef = this.dialog.open(CreateClientDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
      }
      this.fetchPartners();
    });
  }

  openBillingAddressDialog(type: any) {
    const selectedClientId = this.invoiceForm.get('partnerId')?.value;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openShippingAddressDialog(type: any) {
    const selectedClientId = this.invoiceForm.get('partnerId')?.value;
    this.dialog.open(ShippingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openSelectBillingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedBillingAddress = address;
        this.invoiceForm.patchValue({ billingAddressId: address.id });
      }
    });
  }

  openSelectShippingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'SHIPPING';

    const dialogRef = this.dialog.open(ListShippingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        console.log;
        this.selectedShippingAddress = address;
        this.invoiceForm.patchValue({ shippingAddressId: address.id });
      }
    });
  }

  dateFilter: (date: Date | null) => boolean = (date: Date | null): boolean => {
    if (this.invoiceId) {
      return true; // Allow all dates for updating
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight for comparison
    return date ? date >= today : false; // Allow only current and future dates
  };

  calculateGrossAmount(rate: number, quantity: number): number {
    return rate * quantity;
  }

  calculateNetAmount(grossAmount: number, discount: number): number {
    return grossAmount - discount;
  }

  getCalculatedItems() {
    return this.invoiceData?.invoiceItems.map((item: any) => {
      const grossAmount = this.calculateGrossAmount(item.rate, item.quantity);
      const netAmount = this.calculateNetAmount(grossAmount, item.discount || 0);
      return {
        ...item,
        grossAmount,
        netAmount,
        vat: item.vat,
      };
    });
  }
}
