import { gql } from 'apollo-angular';

export const GET_PARTNERS = gql`
  query getPartnersDropDownItems(
    $search: String
    $partnerType: PartnerTypesEnum
  ) {
    getPartnersDropDownItems(search: $search, partnerType: $partnerType) {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
      primaryContact {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      contacts {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      addresses {
        id
        addressType
        addressLine1
        addressLine2
        attention
        city
        country
        fax
        state
      }
    }
  }
`;

export const NOTES = gql`
  query notes($noteType: String, $search: String) {
    notes(noteType: $noteType, search: $search) {
      id
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
        createdAt
        updatedAt
        deletedAt
      }
      noteType
      noteNo
      noteDate
      subject
      subTotal
      referenceId
      customerNotes
      termsAndConditions
      tax
      adjustments
      totalPrice
      balance
      noteUrl
      status
      noteStatus
      createdAt
      updatedAt
      shippingAddressId
      shippingAddressData {
        id
        partnerId

        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }

      noteItems {
        id
        noteId
        accountId
        accountData {
          accountName
        }
        itemId
        itemData {
          itemName
        }

        quantity
        rate
        discount
        discountPrice
        amount
      }
      journalData {
        id
        date
        journalNo
        reference
        notes
        currencyId

        inCash
        status
        isManual
        refType
        refId
        createdAt
        updatedAt
        deletedAt
        journalEnteries {
          id
          chartOfAccountId
          ChartOfAccount {
            id
            accountName
          }
          journalId
          partnerId
          description
          debit
          credit
        }
      }
    }
  }
`;

export const NOTE = gql`
  query note($id: Int!) {
    note(id: $id) {
      id
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
        createdAt
        updatedAt
        deletedAt
      }
      noteType
      noteNo
      noteDate
      subject
      subTotal
      referenceId
      customerNotes
      termsAndConditions
      tax
      adjustments
      totalPrice
      balance
      noteUrl
      status
      noteStatus
      createdAt
      updatedAt
      shippingAddressId
      shippingAddressData {
        id
        partnerId

        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }

      noteItems {
        id
        noteId
        accountId
        description
        itemId
        quantity
                accountData {
          accountName
        }
        rate
        itemData {
          itemName
        }
        discount
        discountPrice
        amount
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query items($search: String, $filter: ItemFilter) {
    items(search: $search, filter: $filter) {
      id
      itemType
      skuCode
      imageUrl
      isSalesActive
      isPurchaseActive
      quantity
      unit
      itemName
      sellingPrice
      salesAccount
      salesDescription
      status
      notes
      costPrice
      purchaseAccount
      purchaseDescription
      vendorId
      vendorData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
      }
      salesAccountId
      salesAccountData {
        id
        accountType
        accountName
        accountCode
        description
        parentAccountId
        isSubAccount
        createdAt
        updatedAt
        deletedAt
      }
      purchaseAccountId
      purchaseAccountData {
        id
        accountType
        accountName
        accountCode
        description
        parentAccountId
        isSubAccount
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const CHART_ACCOUNTS = gql`
  query chartOfAccounts(
    $accountType: [String!]
    $search: String
    $parentAccountId: Int
    $isSubAccount: Int
  ) {
    chartOfAccounts(
      accountType: $accountType
      search: $search
      parentAccountId: $parentAccountId
      isSubAccount: $isSubAccount
    ) {
      id
      accountType
      accountName
      accountCode
      description
      parentAccountId
      parentAccountData {
        id
        accountType
        accountName
        accountCode
        description
        parentAccountId
        # parentAccountData
        isSubAccount
        createdAt
        updatedAt
        deletedAt
        # JournalEnteries
      }
      isSubAccount
      isEditable
      createdAt
      updatedAt
      deletedAt
      JournalEnteries {
        id
        chartOfAccountId
        # ChartOfAccount
        journalId
        # Journal
        partnerId
        # Partner
        description
        debit
        credit
      }
    }
  }
`;

export const GET_NOTE_DETAILS = gql`
  query getNoteDetails($id: Int!) {
    getNoteDetails(id: $id) {
      id
      partnerId
      noteNo
      noteUrl
      partnerData {
        email
        primaryContact {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
      }
      emailTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
    }
  }
`;


export const GET_INVOICE_BY_CUSTOMER_OR_VENDOR = gql`
  query getInvoiceByCustomerOrVendor($partnerId: Int!, $partnerType: String!) {
    getInvoiceByCustomerOrVendor(partnerId: $partnerId, partnerType: $partnerType) {
    id
    invoiceNo
    orderNo
    invoiceDate
    paymentTerm
    paymentStatus
    reason
    dueDate
    subject
    customerNote
    isPayment
    subTotal
    tax
    adjustment
    totalPrice
    invoiceUrl
    balance
    }
  }
`;
export const COMMENTS = gql`
  query comments($conditions: CommentConditionsInput) {
    comments(conditions: $conditions) {
      id
      employeeId
      refId
      type
      activityType
      note
      createdAt
      employee {
        id
        firstName
        lastName
        middleName
      }
    }
  }
`;