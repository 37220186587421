import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { PayrollService } from '../../../features/payroll/services/payroll.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageService } from '../../services/image.service';

@Component({
  selector: 'app-payroll-list-table',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './payroll-list-table.html',
  styleUrl: './payroll-list-table.scss'
})
export class PayrollListTableComponent {
  public viewPortSize: boolean = false;
  @Input() filter: any;
  @Input() selectedMonth: any;
  @Input() selectedYear: any;
  public rowData: any[] | null = null;
  search: any = '';
  columnDefs: { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; }[];

  constructor(
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private router: Router,
    private imageService: ImageService,
  ) { }

  ngOnInit() {
    this.employeeSalaryDetails();
    this.checkViewportSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter']) {
    }
    if (changes['selectedMonth'] || changes['selectedYear']) {
      this.employeeSalaryDetails();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [

      {
        headerName: 'Employee Name',
        field: 'name',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          console.log(params?.data);

          return `
              <div style="display: flex; align-items: center;">
                <img src="${params?.data?.profilePicture}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
                <div>
                  <span>${params?.data?.name}</span>
                </div>
              </div>
            `;
        }
      },
      {
        headerName: 'CTC', field: 'ctc', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `<span>AED ${params?.data?.ctc}</span>`;
        }
      },
      {
        headerName: 'Salary Per Month', field: 'basicSalary', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `<span>AED ${params?.data?.basicSalary}</span>`;
        }
      },
      {
        headerName: 'Deduction', field: 'deduction', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `<span>AED ${params?.data?.deduction}</span>`;
        }
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const status = params.data.status;
          const statusClass = params.data.status === 'Pending' ? 'yellow' : 'green';
          return `<span class="${statusClass}">${status}</span>`;
        }
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  employeeSalaryDetails() {
    this.payrollService.getPayrollByMonthYear(Number(this.selectedYear), this.selectedMonth).subscribe({
      next: async (data: any) => {
        this.rowData = await Promise.all(data?.employeePayrolls.map(async (employeeData: any) => {

          let profilePicture = 'assets/images/dummy-profile-image.jpg';

          if (employeeData?.employees?.profilePicture) {
            try {
              profilePicture = await this.imageService.getImageAsBase64(employeeData?.employees?.profilePicture);
            } catch (error) {
              console.error("Error fetching profile picture:", error);
            }
          }
          return {
            id: employeeData?.id,
            employeeId: employeeData?.employeeId,
            profilePicture: profilePicture,
            name: (employeeData?.employees?.firstName + ' ' + employeeData?.employees?.lastName) || '--',
            basicSalary: employeeData?.basicSalary || '--',
            ctc: employeeData?.employees?.salary || '--',
            deduction: employeeData?.deductions || '--',
            netPay: employeeData?.netPay || '--',
            status: employeeData?.employeePayrollStatus || '--',
          }
        }))
        console.log(this.rowData);

      },
      error: (error) => {
        this.rowData = [];
      }
    })
  }

  onRowClicked(event: any) {
    const id = event.data.id;
    this.router.navigate([`payroll/payroll-process/${id}`],
      {
        queryParams: { month: this.selectedMonth, year: this.selectedYear }
      });
  }


}
