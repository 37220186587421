import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BillService } from '../../features/bills/services/bills.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { CustomerService } from '../../features/customers/services/customer.service';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { InvoiceService } from '../../features/invoice/services/invoice.service';

@Component({
  selector: 'app-bill-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './bill-details.component.html',
  styleUrl: './bill-details.component.scss'
})
export class BillDetailsComponent {
  selectedTabIndex = 0;
  readonly dialog = inject(MatDialog);
  isDropdownOpen = false;
  billId: any;
  billDetails: any;
  search: any;
  allBills: any;
  mailForm: FormGroup
  mails: any;
  isMailDropdownOpen = false;
  filterForm: FormGroup;
  filter: string[];
  profileName: any;

  constructor(
    private router: Router,
    private billService: BillService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    this.fetchBills();
    this.route.paramMap.subscribe(params => {
      this.billId = params.get('id');
      if (this.billId) {
        this.fetchBills();
        this.fetchBillById();
        this.fetchMails();
      }
    });
    this.mailForm = this.fb.group({
      mails: ['']
    });
    this.filterForm = this.fb.group({
      status: this.fb.array([]),
    });
  }

  get statusFormArray() {
    return this.filterForm.get('status') as FormArray;
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openBiilsApplyCreditDialog() {
    const dialogCreditNoteApplyCreditDialogItemRef = this.dialog.open(
      BiilsApplyCreditDialog,
      { 
        data: { noteId: this.billId },
        disableClose: true,
      }
    );
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    const selectedBill = this.allBills[index];
    if (selectedBill) {
      this.router.navigate([`/vendor-invoice/vendor-invoice-details/${selectedBill.id}`]);
    }
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchBills();
  }

  onSubmit() {
    const selectedStatus = [];
    const formValues = this.filterForm.value;
    for (const leaveType in formValues) {
      if (formValues[leaveType]) {
        selectedStatus.push(leaveType);
      }
    }
    this.filter = selectedStatus;
    this.isDropdownOpen = false;
  }

  clearFilter() {
    this.filterForm.reset();
  }

  

  openDialog() {
    const dialogRef = this.dialog.open(UploadBillDetailsDialog);
  }

  onEdit() {
    this.router.navigate([`/vendor-invoice/vendor-invoice-edit/${this.billId}`]);
  }

  editJournal() {
    this.router.navigate([`/manual-journals/edit/${this.billDetails?.journalId}`]);
  }

  onRecordPayment() {
    const vendorId = this.billDetails?.partnerId;
    const billId = this.billId;
    this.router.navigate(['/payments-made/bill-payment-new'], {
      queryParams: { vendorId, billId },
    });
  }

  onConfirmDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteBill(this.billId);
      }
    });
  }

  onViewDocument() {
    const billId = this.billId;
    console.log(billId);
    
    if (billId) {
      this.billService
        .generateBillPdf(Number(billId))
        .subscribe({
          next: (pdfResponse) => {
            console.log('PDF generated successfully', pdfResponse);
            const url = pdfResponse;
            const token = localStorage.getItem('AUTH_TOKEN');
            fetch(url, {
              method: 'GET',
              headers: {
                'authorization': token ? `Bearer ${token}` : ''
              }
            }).then((response: any) => response.blob()).then((blob: any) => {
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
            }).catch((error: any) => {
              console.error('Error:', error)
            });
          },
          error: (pdfError) => {
            console.error('Error generating PDF:', pdfError);
            this.toastr.error(
              pdfError.message || 'Failed to generate PDF.'
            );
          },
        });
    } else {
      console.error('Bill ID is missing');
    }

  }


  deleteBill(id: any) {
    this.billService.removeBill(Number(id)).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Bill deleted successfully', 'Error');
          this.fetchBills();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      }
    })
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleMailDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isMailDropdownOpen = !this.isMailDropdownOpen;
  }

  fetchBills() {
    this.billService.fetchBills(this.search).subscribe({
      next: (data) => {
        this.allBills = data;
        if (this.billId) {
          const targetId = +this.billId;
          this.selectedTabIndex = this.allBills.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  getDebitTotal(): number {
    return this.billDetails?.journalData?.journalEnteries?.reduce((sum: any, entry: any) => sum + (entry.debit || 0), 0) || 0;
  }

  getCreditTotal(): number {
    return this.billDetails?.journalData?.journalEnteries?.reduce((sum: any, entry: any) => sum + (entry.credit || 0), 0) || 0;
  }

  fetchBillById() {
    this.billService.fetchBillById(Number(this.billId)).subscribe({
      next: (data) => {
        this.billDetails = data;
        this.fetchBills();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  fetchMails() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const conditions = {
      employeeId: employeeId,
      refId: Number(this.billId),
      type: 'Vendor',
      activityType: 'Mail'
    }
    this.customerService.comments(conditions).subscribe({
      next: (data) => {
        if (data) {
          this.mails = data;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  markAsSent() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const billInput = {
      id: Number(this.billId),
      billStatus: 'Open'
    };
    const billItems: any = [];
    const billAttachments: any = [];
    this.billService.updateBill(billInput, employeeId, billItems, billAttachments).subscribe({
      next: (data: any) => {
        if (data) {
          this.toastr.success('Bill marked as sent!', 'Success');
          this.router.navigate([`/vendor-invoice/vendor-invoice-details/${this.billId}`]);
        }
      },
      error: (error: any) => {
        this.toastr.error(error, 'Error');
      }
    })
  }

  onMail() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const mailData = {
      employeeId: employeeId,
      refId: Number(this.billId),
      type: 'Vendor',
      activityType: 'Mail',
      note: this.mailForm.value.mails
    }
    this.customerService.createComment(mailData).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Mail added successfully!', 'Success');
          this.mailForm.reset();
          this.fetchMails();
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

}



@Component({
  selector: 'upload-bill-details-dialog',
  templateUrl: 'upload-bill-details-dialog.html',
  styleUrls: ['./bill-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadBillDetailsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

}


@Component({
  selector: 'bills-apply-credits-dialog',
  templateUrl: 'bills-apply-credits-dialog.html',
  styleUrls: ['./bill-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule],
})



export class BiilsApplyCreditDialog {
  billId: number;
  noteDetails: any = null;
  partnerId: any;
  allInvoices: any = [];
  invoiceForm: FormGroup;
  totalAmountToCredit: number = 0;
  invoiceBalanceDue: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: CreditDebitNoteService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<BiilsApplyCreditDialog>,
    private billService: BillService,
    private invoiceService: InvoiceService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.billId = +this.data.noteId;
      // this.fetchNote();
      this.fetchVendorInvoiceById();
    }
  }

  initForm(): void {
    const controls = this.allInvoices.map((_: any) => this.fb.control(0)); // Default credit = 0 for each invoice
    this.invoiceForm = this.fb.group({
      credits: this.fb.array(controls),
    });
  }
  get credits() {
    return this.invoiceForm.get('credits') as FormArray;
  }

  // Add a helper method to safely access FormControls
  getCreditControl(index: number): FormControl {
    const invoiceBalance = this.allInvoices[index]?.balance || 0;
    if (!this.credits.at(index)) {
      this.credits.insert(index, new FormControl(0, [Validators.max(invoiceBalance), Validators.min(0)]));
    }
    return this.credits.at(index) as FormControl;
  }

  get invoices() {
    return this.invoiceForm.get('invoices') as FormArray;
  }
  // Initialize form controls for each invoice
  initializeCreditControls(): void {
    this.allInvoices.forEach((invoice: any, index: number) => {
      const creditControl = this.fb.control(
        '', // Initialize with an empty string
        [Validators.max(invoice.balance)] // Use Angular's built-in max validator
      );
      this.credits.push(creditControl);
    });
  }

  fetchVendorInvoiceById(): void {
    this.billService.fetchBillById(this.billId).subscribe({
      next: (invoice) => {
        this.noteDetails = invoice;
        console.log("Invoice partner details----", this.noteDetails);
        this.partnerId = this.noteDetails?.partnerData?.id;

        if(this.partnerId) {
          this.invoiceService.fetchNotesByCustomerOrVendor(this.partnerId, 'Vendor').subscribe({
            next: (response) => {
              this.allInvoices = response || []
              this.initForm();
            },
            error: (error) => {
              console.error(error);
            }
          })
        }
      },
      error: (err) => {
        console.error('Error fetching invoice:', err);
      }
    })
  }



  calculateInvoiceBalanceDue(): number {
    return this.allInvoices.reduce((totalBalanceDue: number, invoice: any, index: number) => {
      const creditApplied = this.credits.at(index)?.value || 0;
      const invoiceBalance = invoice.balance || 0;

      // Ensure we don't subtract more credits than the invoice balance
      const remainingBalance = invoiceBalance - Math.min(creditApplied, invoiceBalance);

      return totalBalanceDue + remainingBalance;
    }, 0);

  }

  calculateAmountToCredit(): void {
    const availableCredits = this.noteDetails?.subTotal || 0;
    let totalAppliedCredits = 0;

    this.credits.controls.forEach((control, index) => {
      const invoiceBalance = this.allInvoices[index]?.subTotal || 0;
      const appliedCredit = control.value || 0;

      // Ensure credit does not exceed invoice balance
      if (appliedCredit > invoiceBalance) {
        control.setErrors({ ...control.errors, exceedsInvoiceBalance: true });
      } else if (control.hasError('exceedsInvoiceBalance')) {
        const { exceedsInvoiceBalance, ...errors } = control.errors || {};
        control.setErrors(Object.keys(errors).length ? errors : null);
      }

      totalAppliedCredits += appliedCredit;
    });

    // Ensure total applied credits do not exceed available credits
    if (totalAppliedCredits > availableCredits) {
      this.credits.controls.forEach((control) => {
        control.setErrors({ ...control.errors, exceedsAvailableCredits: true });
      });
    } else {
      this.credits.controls.forEach((control) => {
        if (control.hasError('exceedsAvailableCredits')) {
          const { exceedsAvailableCredits, ...errors } = control.errors || {};
          control.setErrors(Object.keys(errors).length ? errors : null);
        }
      });
    }

    // Update total amount to credit
    this.totalAmountToCredit = Math.min(totalAppliedCredits, availableCredits);

    // Update invoice balance due
    this.invoiceBalanceDue = this.calculateInvoiceBalanceDue();
  }
  

  applyCreditsToInvoices() {
    const notes = this.credits.controls.map((control, index) => {
      const appliedCredit = control.value || 0; // Default to 0 if no value is entered
      const invoice = this.allInvoices[index];

      if (appliedCredit <= 0) {
        return null;
      }

      return {
        billId: this.noteDetails.id, 
        refId: invoice.id, // Reference to the debit note ID
        type: 'DN',
        amount: appliedCredit,
      };
    }).filter(note => note !== null); // Remove null entries

    // Ensure there's at least one note to apply
    if (notes.length === 0) {
      console.error('No credits to apply.');
      return;
    }


    const payload = {
      notes,
    };

    this.billService.applyNoteBill(payload).subscribe({
      next: (response) => {
        this.toastr.success('Debits applied to vendor invoice successfully.');
        this.dialogRef.close();
      },
      error: (error) => {
        this.toastr.error('Failed to apply debits to vendor invoice .');
        console.log(error);

      },
    });
  }

}
