import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ExpenseServices } from '../../features/expenses/services/expenses.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-expense-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './expense-details.component.html',
  styleUrl: './expense-details.component.scss',
})
export class ExpenseDetailsComponent implements OnInit {
  selectedOption = 'today';
  selectedTabIndex = 0;
  readonly dialog = inject(MatDialog);
  isDropdownOpen = false;
  expenseDetails: any = {};
  detailsLoading: any = false;
  loading: boolean = false;
  allExpenses: any = [];
  profileName: any;
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private expenseService: ExpenseServices,
    private toastr: ToastrService
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id']; // Get the 'id' parameter

      if (id) {
        this.selectedTabIndex = +id;
        console.log('The id in the URL is:', id);
        this.loading = true;
        this.fetchExpensesData();
        this.loadSingleExpense(+id);
      }
    });
  }

  loadSingleExpense(expenseId: number): void {
    this.detailsLoading = true;
    this.expenseService.getExpenseById(expenseId).subscribe(async (data) => {
      try {
        this.expenseDetails = data;
        this.detailsLoading = false;
      } catch (error) {
        this.detailsLoading = false;
        this.router.navigate(['/expense']);
        console.error('Error loading employee data:', error);
      }
    });
  }

  private fetchExpensesData() {
    this.expenseService.fetchExpenses().subscribe({
      next: (expenses: any) => {
        this.allExpenses = expenses;
        this.loading = false;
      },
      error: (error: any) => {
        console.log('the errror is', error);
        this.toastr.error(error.message || 'Failed to fetch');
        this.loading = false;
      },
    });
  }

  calculateAmount(item: any): void {
    return item?.expenseItems?.length
      ? item?.expenseItems?.reduce(
          (sum: any, item: any) => sum + item.amount,
          0
        )
      : item?.amount || '--';
  }

  paidToAccount(item: any): void {
    return item?.expenseItems?.length > 0
      ? item?.expenseItems
          ?.map((el: any) => el?.chartOfAccountData?.accountName)
          .join(', ')
      : item?.accountData?.accountName || '--';
  }

  renterInvoices(item: any): void {
    if (item?.expenseItems?.length) {
    }
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
  onEditNavigateTo(route: string) {
    this.router.navigate([`${route}/${this.selectedTabIndex}`]);
  }

  // Default to the first tab

  selectTab(index: number) {
    if (this.selectedTabIndex !== index) {
      this.selectedTabIndex = index;
      this.location.replaceState(`expense/expense-details/${index}`);
      this.loadSingleExpense(+index);
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadExpenseDetailsDialog);
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onConfirmDelete(id: number): void {
    console.log(id);

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onRemoveExpense(id);
      }
    });
  }

  onRemoveExpense(id: number): void {
    this.expenseService.removeExpense(id).subscribe({
      next: (response) => {
        this.toastr.success('Expense deleted successfully');
        const removesExpenses = this.allExpenses?.filter(
          (el: any) => el.id !== id
        );

        if (removesExpenses?.length > 0) {
          const newItem = removesExpenses[0];
          // this.allExpenses = removesExpenses;
          this.fetchExpensesData();
          this.selectTab(newItem?.id);
        } else {
          this.router.navigate(['/expense']);
        }
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error(error.message || 'Failed to delete failed.');
        this.loading = false;
      },
    });
  }
  // updateUrl(newId: number): void {
  //   this.location.replaceState(`/expense-details/${newId}`);
  // }
}

@Component({
  selector: 'upload-expense-details-dialog',
  templateUrl: 'upload-expense-details-dialog.html',
  styleUrls: ['./expense-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadExpenseDetailsDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
