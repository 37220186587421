<div class="profile-inner-main-wrapper">
  <div class="details-wrapper">
    <h2>Visa Number</h2>
    <h3>{{employeeData?.visaNumber}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Visa Type</h2>
    <h3>{{employeeData?.visaType}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Visa Isuue Date</h2>
    <h3>{{employeeData?.visaIssueDate | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Visa Expiry Date</h2>
    <h3>{{employeeData?.visaExpiryDate | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Passport Number</h2>
    <h3>{{employeeData?.passportNumber}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Passport Issue Date</h2>
    <h3>{{employeeData?.passportIssueDate | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Passport Expiry Date</h2>
    <h3>{{employeeData?.passportExpiryDate| date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Emirates ID Number</h2>
    <h3>{{employeeData?.emiratesIDNo}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Driving License Number</h2>
    <h3>{{employeeData?.drivingLicenseNumber}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Labour Card Expiry Date</h2>
    <h3>{{employeeData?.laborCardExpiryDate | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Bank Name</h2>
    <h3>{{employeeData?.bankName}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Account Number</h2>
    <h3>{{employeeData?.bankAccountNumber}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Routing Code</h2>
    <h3>{{employeeData?.SWIFTCode}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>MOL Number</h2>
    <h3>{{employeeData?.MOLNo}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>WPS Number</h2>
    <h3>{{employeeData?.WPSNo}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>IBAN Number</h2>
    <h3>{{employeeData?.IFSCCode}}</h3>
  </div>
</div>
