import { Component, HostListener, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-debit-note',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './debit-note.component.html',
  styleUrl: './debit-note.component.scss',
})
export class DebitNoteComponent {
  public viewPortSize: boolean = false;
  public rowData: any[] | null = null;
  private searchSubject = new Subject<string>();
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  profileName: any;

  constructor(
    private router: Router,
    private itemService: CreditDebitNoteService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
   }

  ngOnInit(): void {
    this.fetchNotes();
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchNotes(searchTerm);
      });

    this.checkViewportSize();
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      { headerName: 'Date', field: 'date', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Debit Note#', field: 'creditNote', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Reference Number', field: 'referenceNumber', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Vendor Name',
        field: 'customerName',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const status = params.data.status;
          const statusClass = status === 'On Leave' ? 'on-leave' : 'working'; // Apply class if "On Leave"
          return `<span class="${statusClass}">${status}</span>`;
        },
      },
      { headerName: 'Amount', field: 'amount', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Balance', field: 'balance', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Actions',
        field: 'action',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');
          container.appendChild(editIcon);

          if(this.profileName === 'admin'){
            container.appendChild(deleteIcon);
          }
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  readonly dialog = inject(MatDialog);

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchNotes(search: string = '') {
    this.itemService.fetchNotes('Debit', search).subscribe({
      next: (items) => {
        this.rowData = items.map((item: any) => {
          return {
            id: item?.id,
            date: this.datePipe.transform(item?.noteDate, 'MM/dd/YYYY') || '--',
            creditNote: item?.noteNo || '--',
            referenceNumber: item?.referenceId || '--',
            customerName: item?.partnerData?.displayName || '--',

            status: item?.noteStatus || '0.00',
            amount: item?.totalPrice || '0.00',
            balance: item?.balance || '0.00',
            action: '',
          };
        });
        // Update pagination options based on the rowData length
        // this.paginationPageSizeSelector =
        //   PaginationHelper.getPaginationPageSizeOptions(this.rowData);
      },
    });
  }

  // onCellClicked(params: any) {
  //   const debitNoteId = params.data?.id;
  //   if (
  //     params.column.colId === 'action' &&
  //     params.event.target.dataset.action
  //   ) {
  //     let action = params.event.target.dataset.action;

  //     if (action === 'edit') {
  //       this.openEdit(debitNoteId);
  //     } else if (action === 'delete') {
  //       this.onConfirmDelete(debitNoteId);
  //     }
  //   } else {
  //     this.router.navigate([`debit-note/debit-note-details`], {
  //       queryParams: { id: debitNoteId },
  //     });
  //   }
  // }

  onCellClicked(params: any) {
    const customerId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.openEdit(customerId);
      } else if (action === 'delete') {
        this.onConfirmDelete(customerId);
      }
    } else {
      this.router.navigate([`debit-note/debit-note-details`], {
        queryParams: { id: customerId },
      });
    }
  }


  openEdit(id: string) {
    const debitnoteId = id;
    console.log(debitnoteId);

    if (debitnoteId) {
      this.router.navigate(['/debit-note/debit-note-edit'], { queryParams: { id: debitnoteId } });
    }
  }

  onConfirmDelete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(+id);
      }
    });
  }

  onDelete(purchaseOrderId: any) {
    this.itemService.removeNote(purchaseOrderId).subscribe({
      next: () => {
        this.fetchNotes();
        this.toastr.success('Deleted successfully.');
      },
      error: (error) => {
        this.toastr.error(error.message || 'Failed to delete.');
      },
    });
  }
}
