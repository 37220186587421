import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { BankingService } from '../../features/banking/services/banking.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-bank-account',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './add-bank-account.component.html',
  styleUrl: './add-bank-account.component.scss'
})
export class AddBankAccountComponent {
  addBankAccountForm: FormGroup
  search: string | undefined;
  filter: {};
  currencyData: any;
  bankId: any;
  bankDetail: any;

  constructor(
    private fb: FormBuilder,
    private currencyService: CurrencyAdjustmentService,
    private bankingService: BankingService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    
    this.route.paramMap.subscribe((params) => {
      this.bankId = params.get('id');;
      this.fetchBankById(Number(this.bankId));
    });
    this.initForm();
    this.fetchCurrencies();
  }

  initForm(){
    this.addBankAccountForm = this.fb.group({
      bankType: [this.bankDetail?.bankType || 'Bank', Validators.required],
      accountName: [this.bankDetail?.accountName || '', Validators.required],
      currencyId: [this.bankDetail?.currencyId || '', Validators.required],
      routingNumber: [this.bankDetail?.routingNumber || '', Validators.required],
      swiftCode: [this.bankDetail?.swiftCode || '', Validators.required],
      // BIC: ['', Validators.required],
      accountCode: [this.bankDetail?.accountCode || '', Validators.required],
      accountNumber: [this.bankDetail?.accountNumber || null],
      bankName: [this.bankDetail?.bankName || ''],
      description: [this.bankDetail?.description || ''],
    })
    if(!this.bankId){
      this.preferenceCode();
    }
  }

   preferenceCode() {
        const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        const prefix = `${'AC-' + randomSuffix}`;
        this.addBankAccountForm.patchValue({ accountCode: prefix });
  }

  fetchBankById(id: any){
    this.bankingService.fetchBankById(id).subscribe({
      next: (data) => {
        this.bankDetail = data;
        console.log(this.bankDetail);
        this.initForm();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }


  fetchCurrencies() {
    this.currencyService.fetchCurrencies(this.search, this.filter).subscribe({
      next: (currency) => {
        this.currencyData = currency;
        console.log(this.currencyData);

      },
      error: (error) => console.error(error),
    });
  }

  onCancel() {
    this.addBankAccountForm.reset({
      bankType: this.addBankAccountForm.get('bankType')?.value
    });
  }

  onSave() {
    if (this.addBankAccountForm.invalid) {
      this.addBankAccountForm.markAllAsTouched();
      return;
    }
    const bankAccountData = {
      ...this.addBankAccountForm.value,
      id: this.bankId ? Number(this.bankId): undefined
    };
    if(this.bankId){
      this.bankingService.updateBank(bankAccountData).subscribe({
        next: (data) => {
          if (data) {
            this.addBankAccountForm.reset({
              bankType: this.addBankAccountForm.get('bankType')?.value,
            });
            if (bankAccountData.bankType === 'Credit') {
              this.toastr.success('Credit card updated successfully', 'Success');
            } else {
              this.toastr.success('Bank updated successfully', 'Success');
            }
            this.router.navigate(['/banking']);
          }
        },
        error: (error) => {
          this.toastr.error(error, 'Error')
        }
      })
    } else {
    this.bankingService.createBank(bankAccountData).subscribe({
      next: (data) => {
        if (data) {
          this.addBankAccountForm.reset({
            bankType: this.addBankAccountForm.get('bankType')?.value,
          });
          if (bankAccountData.bankType === 'Credit') {
           
            this.toastr.success('Credit card created successfully', 'Success');
          } else {
            this.toastr.success('Bank created successfully', 'Success');
          }
          this.router.navigate(['/banking'])
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }
  }
}

