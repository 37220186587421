<section class="project-dashboard-wrapper">
  <div class="dashboard-card-wrapper-grid">
    <div class="dash-card-wrapper dash-color-one">
      <div class="dash-card">
        <div class="arrow-icon" (click)="navigateTo('quotation')" style="cursor: pointer;">
          <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
        </div>
        <div class="head-wrapper">
          <div>
            <img
              src="../../../assets/images/icons/project-quotes-icon.svg"
              alt=""
            />
          </div>
          <h5>Quotes</h5>
        </div>
        <h6>{{overviewCounts?.quotes || '0'}}</h6>
      </div>
    </div>
    <div class="dash-card-wrapper dash-color-three">
      <div class="dash-card">
        <div class="arrow-icon" (click)="navigateTo('invoice')" style="cursor: pointer;">
          <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
        </div>
        <div class="head-wrapper">
          <div>
            <img
              src="../../../assets/images/icons/project-invoice-icon.svg"
              alt=""
            />
          </div>
          <h5>Invoice</h5>
        </div>
        <h6>{{overviewCounts?.invoices || '0'}}</h6>
        <div class="add-btn">
          <button (click)="navigateTo('invoice/invoice-add')">
            add
            <span>
              <img
                src="../../../assets/images/icons/white-add-icon.svg"
                alt=""
              />
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="dash-card-wrapper dash-color-two">
      <div class="dash-card">
        <div class="arrow-icon" (click)="navigateTo('purchase-order')" style="cursor: pointer;">
          <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
        </div>
        <div class="head-wrapper">
          <div>
            <img
              src="../../../assets/images/icons/project-purchaseorder-icon.svg"
              alt=""
            />
          </div>
          <h5>Purchase Order</h5>
        </div>
        <h6>{{totalPOCount || '0'}}</h6>
        <div class="add-btn">
          <button (click)="navigateTo('purchase-order/purchase-order-new')">
            add
            <span>
              <img
                src="../../../assets/images/icons/white-add-icon.svg"
                alt=""
              />
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="dash-card-wrapper dash-color-four">
      <div class="dash-card">
        <div class="arrow-icon" (click)="navigateTo('work-order')" style="cursor: pointer;">
          <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
        </div>
        <div class="head-wrapper">
          <div>
            <img
              src="../../../assets/images/icons/project-workorder-icon.svg"
              alt=""
            />
          </div>
          <h5>Work Order</h5>
        </div>
        <h6>{{overviewCounts?.workOrders || '0'}}</h6>
        <div class="add-btn">
          <button (click)="navigateTo('work-order-new')">
            add
            <span>
              <img
                src="../../../assets/images/icons/white-add-icon.svg"
                alt=""
              />
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="dash-card-wrapper dash-color-five">
      <div class="dash-card">
        <div class="arrow-icon" (click)="navigateTo('grn')" style="cursor: pointer;">
          <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
        </div>
        <div class="head-wrapper">
          <div>
            <img
              src="../../../assets/images/icons/project-grn-icon.svg"
              alt=""
            />
          </div>
          <h5>GRN</h5>
        </div>
        <h6>{{overviewCounts?.goodsReceivedCount || '0'}}</h6>
        <div class="add-btn">
          <button (click)="navigateTo('goods-received-note/grn-new')">
            add
            <span>
              <img
                src="../../../assets/images/icons/white-add-icon.svg"
                alt=""
              />
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="dash-card-wrapper dash-color-five">
      <div class="dash-card">
        <div class="arrow-icon" (click)="navigateTo('vendor-invoice')" style="cursor: pointer;">
          <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
        </div>
        <div class="head-wrapper">
          <div>
            <img
              src="../../../assets/images/icons/project-grn-icon.svg"
              alt=""
            />
          </div>
          <h5>Vendor Invoice</h5>
        </div>
        <h6>{{overviewCounts?.bills || '0'}}</h6>
        <div class="add-btn">
          <button (click)="navigateTo('vendor-invoice/vendor-invoice-new')">
            add
            <span>
              <img
                src="../../../assets/images/icons/white-add-icon.svg"
                alt=""
              />
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="budget-allocation-wrapper">
      <h5>Budget Allocation</h5>

      <div class="allocation-indicators-wrapper">
        <span>Used</span>
        <span>Allocated</span>
      </div>

      <div class="progress-bar-wrapper">
        <div class="used-wrapper">
          <h6>AED <span>{{projectCosts?.totalNetPrice}}</span></h6>
        </div>
        <div class="allocated-wrapper">
          <h6>AED <span>{{projectCosts?.project?.totalProjectCost}}</span></h6>
        </div>
        <div class="progress-bar">
          <div class="progress" [style.width.%]="getProgressPercentage()"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-grid-wrapper">
    <div class="table-wrapper" *ngIf="projectLists?.projectTasks?.length > 0">
      <h4>Project Tasks</h4>
      <table>
        <thead>
          <tr>
            <th>Task Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let task of projectLists?.projectTasks">
            <td>{{task?.taskName || '--'}}</td>
            <td>{{task?.description || '--'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-wrapper" *ngIf="projectLists?.projectResources?.length > 0">
      <h4>Project Resources</h4>
      <table>
        <thead>
          <tr>
            <th>Employees</th>
            <th>Desingation</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resource of projectLists?.projectResources">
            <td>{{resource?.employeeData?.firstName + ' ' + resource?.employeeData?.lastName || '--'}}</td>
            <td>{{resource?.employeeData?.designation?.designationName || '--'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-wrapper" *ngIf="projectLists?.quotes?.length > 0">
      <h4>Project Quotes</h4>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Amount</th>
            <th>Quote Date</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let quote of projectLists?.quotes">
            <td>{{quote?.quoteTitle || '--'}}</td>
            <td>{{quote?.totalAmount || '--'}}</td>
            <td>{{quote?.quoteDate | date: 'dd MMM y'}}</td>
            <td>{{quote?.expiryDate | date: 'dd MMM y'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-wrapper" *ngIf="projectLists?.projectWorkOrders?.length > 0">
      <h4>Project Work Orders</h4>
      <table>
        <thead>
          <tr>
            <th>Work Order Number</th>
            <!-- <th>Status</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let workOrder of projectLists?.projectWorkOrders">
            <td>{{workOrder?.workOrderNo || '--'}}</td>
            <!-- <td>{{workOrder?.workOrderStatus || '--'}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  
  </div>
</section>
