import { Component } from '@angular/core';
import { CoreModule } from '../../core/core.module';
import { HeaderComponent } from '../../core/header/header.component';
import { SidebarComponent } from '../../core/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AuthService } from './../../core/services/auth.service'
import { DashboardService } from '../../features/dashboard/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPunchOutDialog } from '../../shared/components/confirm-dialog/confirm-punchout';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CoreModule,
    HeaderComponent,
    SidebarComponent,
    CommonModule,
    LayoutComponent,

  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  tableArray = [1,2,3,4]
  
  user: any;
  uaeTime: string;
  punchInInfo: any;

  constructor(
    private authService: AuthService,
    public dashboardService: DashboardService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {
    this.user = this.authService.getLoggedInUser();
  }

  ngOnInit() {
    this.getPunchInInfo();

    // Resume timer only if not punched out and punchTime exists
    if (!this.dashboardService.isPunchedOut && this.dashboardService.punchTime) {
      this.resumeTimer();
    } else {
      if (this.dashboardService.interval) {
        clearInterval(this.dashboardService.interval); // Ensure no timer runs
        this.dashboardService.interval = null;
      }
    }
  }

  getPunchInInfo() {
    this.dashboardService.getPunchInInfo().subscribe({
      next: (data) => {
        this.punchInInfo = data;
  
        // If checkOutTime exists, the user is punched out
        if (this.punchInInfo?.checkOutTime) {
          this.dashboardService.isPunchedOut = true;
          this.dashboardService.punchTime = null;
          this.dashboardService.timeDifference = '00:00:00';
  
          // Clear any active timer
          if (this.dashboardService.interval) {
            clearInterval(this.dashboardService.interval);
            this.dashboardService.interval = null;
          }
          return; // Exit early since no timer needs to be resumed
        }
  
        // If checkInTime exists and no checkOutTime, calculate time difference
        if (this.punchInInfo?.checkInTime) {
          const checkInDate = new Date();
          const [checkInHours, checkInMinutes, checkInSeconds] = this.punchInInfo.checkInTime.split(':');
          checkInDate.setHours(parseInt(checkInHours), parseInt(checkInMinutes), parseInt(checkInSeconds), 0);
  
          const currentDubaiTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' });
          const currentDateDubai = new Date(currentDubaiTime);
  
          const timeDiffInMs = currentDateDubai.getTime() - checkInDate.getTime();
          const timeDiffInSec = Math.floor(timeDiffInMs / 1000);
  
          const timeDiffHours = Math.floor(timeDiffInSec / 3600);
          const timeDiffMinutes = Math.floor((timeDiffInSec % 3600) / 60);
          const timeDiffSeconds = timeDiffInSec % 60;
  
          this.dashboardService.timeDifference = `${this.pad(timeDiffHours)}:${this.pad(timeDiffMinutes)}:${this.pad(timeDiffSeconds)}`;
          this.dashboardService.punchTime = this.punchInInfo.checkInTime;
          this.dashboardService.isPunchedOut = false;
  
          this.resumeTimer(); // Resume the timer since the user is still punched in
        } else {
          // Default state if no check-in or check-out time
          this.dashboardService.isPunchedOut = true;
          this.dashboardService.punchTime = null;
          this.dashboardService.timeDifference = '00:00:00';
  
          // Clear any active timer
          if (this.dashboardService.interval) {
            clearInterval(this.dashboardService.interval);
            this.dashboardService.interval = null;
          }
        }
      },
      error: (error) => {
        console.error(error, 'Error fetching punch-in info');
      },
    });
  }
  

  togglePunch() {
    if (this.dashboardService.isPunchedOut) {
      // Punch In
      const punchTime = this.updateTime();
      const punchDate = this.updateDate();
      this.dashboardService.punchTime = punchTime;
      this.dashboardService.isPunchedOut = false;
  
      this.logPunchInTime(punchTime, punchDate);
    } else {
      // Punch Out
      const punchTime = this.updateTime();
      const punchDate = this.updateDate();
      this.logPunchOutTime(punchTime, punchDate);
  
      // Stop the timer immediately
      if (this.dashboardService.interval) {
        clearInterval(this.dashboardService.interval);
        this.dashboardService.interval = null; // Ensure no lingering interval
      }
  
      this.dashboardService.isPunchedOut = true;
      this.dashboardService.punchTime = null;
      this.dashboardService.timeDifference = '00:00:00';
    }
  }
  

  onConfirmPunchOut(): void {
    if (!this.dashboardService.isPunchedOut) {
      const dialogRef = this.dialog.open(ConfirmPunchOutDialog);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.togglePunch();
        }
      });
    }
  }

  updateTime() {
    return new Date().toLocaleTimeString('en-US', {
      timeZone: 'Asia/Dubai',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  }

  updateDate() {
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = currentDate.split('/');
    return `${year}-${month}-${day}`;
  }

  timeDiff(): void {
    const currentTime = this.updateTime();

    if (!this.dashboardService.punchTime) {
      this.dashboardService.timeDifference = '00:00:00';
      return;
    }

    const [currentHours, currentMinutes, currentSeconds] = currentTime.split(':').map(Number);
    const [punchInHours, punchInMinutes, punchInSeconds] = this.dashboardService.punchTime
      .split(':')
      .map(Number);

    const totalSeconds1 = currentHours * 3600 + currentMinutes * 60 + currentSeconds;
    const totalSeconds2 = punchInHours * 3600 + punchInMinutes * 60 + punchInSeconds;

    let differenceInSeconds = Math.abs(totalSeconds2 - totalSeconds1);

    const hours = Math.floor(differenceInSeconds / 3600);
    differenceInSeconds %= 3600;
    const minutes = Math.floor(differenceInSeconds / 60);
    const seconds = differenceInSeconds % 60;

    this.dashboardService.timeDifference = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }


  resumeTimer() {
    this.dashboardService.interval = setInterval(() => this.timeDiff(), 1000);
  }

  logPunchInTime(time: any, date: any) {
    this.dashboardService.punchIn(time, date).subscribe({
      next: (data) => {
        console.log(data);

        if (data) {
          this.toastr.success('Punched in successfully!');
          this.resumeTimer();
        }
      },
      error: (error) => {
        this.toastr.error(error?.message, 'Error');
        this.dashboardService.isPunchedOut = true; // Ensure the state remains consistent
        this.dashboardService.punchTime = null; // Clear punch time on error
      },
    });
  }

  logPunchOutTime(time: any, date: any) {
    this.dashboardService.punchOut(time, date).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Punched out successfully!');
        }
      },
      error: (error) => {
        this.toastr.error(error?.message, 'Error');
      },
    });
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}


