import { createAction, props } from '@ngrx/store';
import { Notification } from './notification.model';

export const loadNotifications = createAction(
  '[Notification] Load Notifications',
  props<{ notifications: Notification[] }>()
);

export const addNotification = createAction(
  '[Notification] Add Notification',
  props<{ notification: Notification }>()
);

export const clearNotifications = createAction('[Notification] Clear Notifications');

export const markNotificationAsRead = createAction(
  '[Notification] Mark Notification As Read',
  props<{ notificationId: string }>()
);
