import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'unassign-dialog',
  templateUrl: 'unassign-dialog.html',
  styleUrls: ['./confirm-dialog.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule,MatFormFieldModule,  ReactiveFormsModule,  MatInputModule],
})
export class UnassignDialog implements OnInit{
  unAssign: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<UnassignDialog>,
    private fb: FormBuilder
  ) {}

ngOnInit(): void {
  this.unAssign = this.fb.group({
    note: ['']
  })
}
  onCancel(): void {
    this.dialogRef.close(false); 
  }

  // Close the dialog with 'PunchOut'
  onUnAssign(): void {
    const notes = this.unAssign.get('note')?.value;
    this.dialogRef.close({ confirmed: true, notes });
    // this.dialogRef.close(true);
  }
}
