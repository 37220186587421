import { gql } from 'apollo-angular';

export const GET_QUOTES = gql`
  query quotes($filter: QuoteFilter) {
    quotes(filter: $filter) {
      id
      parentQuoteId
      quoteCode
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
      }
      quoteDate
      expiryDate
      approvalDate
      createdBy
      createdByData {
        id
        employeeCode
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        reportingManagerId
        status
      }
      branchId
      branchData {
        id
        branchName
        address
        city
        state
        country
        zipCode
        phoneNumber
        email
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      refNumber
      projectId
      newProjectName
      quoteTitle
      subject
      subTotal
      tax
      adjustment
      totalAmount
      customerNote
      additionalNote
      status
      createdAt
      updatedAt
      deletedAt
      quoteExclusions {
        id
        exclusion
        status
      }
      quoteAttachment {
        id
        quoteId
        fileName
        fileUrl
      }
      quoteItems {
        id
        quoteId
        quoteData {
          id
          quoteCode
        }
        description
        quantity
        unit
        tax
        rate
        amount
        discount
        createdAt
        updatedAt
        deletedAt
      }
      quoteTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
    }
  }
`;

export const GET_QUOTE_SUMMARY = gql`
  query quoteSummary {
    quoteSummary {
      total
      open
      confirmed
      draft
    }
  }
`;

export const GET_PARENT_QUOTES = gql`
  query parentQuotes($search: String, $status: String) {
    parentQuotes(search: $search, status: $status) {
      id
      parentCode
      quoteCode
      quoteDate
      partnerId
      totalAmount
      parentQuoteStatus
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
      }
      projectId
      projectData {
        id
        projectCode
        projectName
      }
      quoteData {
        id
        parentQuoteId
        quoteCode
        TRN
        SOW
        quoteDate
        expiryDate
        approvalDate
        createdBy
        createdByData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        branchId
        branchData {
          id
          branchName
        }
        billingAddressId
        billingAddressData {
          id
          attention
          addressLine1
          addressLine2
          city
          state
          country
          pinCode
          phone
          fax
        }
        shippingAddressId
        shippingAddressData {
          id
          attention
          addressLine1
          addressLine2
          city
          state
          country
          pinCode
          phone
          fax
        }
        refNumber
        projectId
        projectData {
          id
          projectName
        }
        quoteTitle
        subTotal
        tax
        adjustment
        totalAmount
        paymentTerm
        deliveryTerm
        validity
        termsCondition
        customerNote
        additionalNote
        quoteUrl
        status
        createdAt
        updatedAt
        deletedAt
        quoteAttachment {
          id
          fileName
          fileUrl
        }
        quoteItems {
          id
          description
          quantity
          unit
          tax
          rate
          amount
          discount
        }
        quoteExclusions {
          id
          exclusion
          status
        }
        quoteTemplate {
          id
          templateName
          templateBody
        }
        quoteData {
          id
          workOrderStatus
          workOrderNo
        }
      }
    }
  }
`;

export const LIST_PROJECTS = gql`
  query listProjects($search: String) {
    listProjects(search: $search) {
      id
      projectCode
      projectName
    }
  }
`;

export const FETCH_QUOTE_WORK_SPLIT_BY_ID = gql`
  query findOneQuotesWork($id: Int!) {
    findOneQuotesWork(id: $id) {
      id
      branchCode
      quoteId
      description
      amount
    }
  }
`;

export const FETCH_QUOTE_BY_ID = gql`
  query quote($id: Int!) {
    quote(id: $id) {
      id
      quoteCode
      partnerId
      quotesWorkData {
        id
        quoteId
        amount
        branchCode
        branchId
        description
        branchData {
          id
          branchName
        }
      }
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
        __typename
      }
      TRN
      SOW
      quoteDate
      expiryDate
      approvalDate
      createdBy
      createdByData {
        id
        employeeCode
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        status
        __typename
      }
      branchId
      branchData {
        id
        branchName
        address
        city
        state
        country
        zipCode
        phoneNumber
        __typename
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        partner {
          id
          displayName
          email
          __typename
        }
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
        __typename
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        partner {
          id
          displayName
          email
          __typename
        }
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
        __typename
      }
      refNumber
      projectId
      newProjectName
      subject
      quoteTitle
      subTotal
      tax
      adjustment
      totalAmount
      paymentTerm
      deliveryTerm
      validity
      termsCondition
      customerNote
      additionalNote
      quoteUrl
      status
      createdAt
      updatedAt
      deletedAt
      quoteExclusions {
        id
        exclusion
        status
        __typename
      }
      quoteAttachment {
        id
        quoteId
        fileName
        fileUrl
        __typename
      }
      quoteItems {
        id
        quoteId
        quoteData {
          id
          quoteCode
          __typename
        }
        description
        quantity
        unit
        rate
        tax
        amount
        discount
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      quoteTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
        __typename
      }
      __typename
    }
  }
`;

export const GET_PARENT_QUOTE_BY_ID = gql`
  query parentQuote($id: Int!) {
    parentQuote(id: $id) {
      id
      parentCode
      quoteCode
      quoteDate
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
      }
      projectId
      projectData {
        id
        projectCode
        projectName
      }
      quoteData {
        id
        parentQuoteId
        TRN
        quoteCode
        SOW
        quoteDate
        expiryDate
        approvalDate
        createdBy
        createdByData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        branchId
        branchData {
          id
          branchName
        }
        billingAddressId
        billingAddressData {
          id
          attention
          addressLine1
          addressLine2
          city
          state
          country
          pinCode
          phone
          fax
        }
        shippingAddressId
        shippingAddressData {
          id
          attention
          addressLine1
          addressLine2
          city
          state
          country
          pinCode
          phone
          fax
        }
        refNumber
        projectId
        projectData {
          id
          projectName
        }
        quoteTitle
        subTotal
        tax
        adjustment
        totalAmount
        paymentTerm
        deliveryTerm
        validity
        termsCondition
        customerNote
        additionalNote
        quoteUrl
        status
        createdAt
        updatedAt
        deletedAt
        quoteAttachment {
          id
          fileName
          fileUrl
        }
        quoteItems {
          id
          description
          quantity
          unit
          rate
          tax
          amount
          discount
        }
        quoteExclusions {
          id
          exclusion
          status
        }
        quoteTemplate {
          id
          templateName
          templateBody
        }
        quoteData {
          id
          workOrderStatus
          workOrderNo
        }
      }
    }
  }
`;

export const GENERATE_QUOTATION_PDF = gql`
  query getQuotePDF($quoteId: Int!) {
    getQuotePDF(quoteId: $quoteId)
  }
`;
