<section class="cmn-innerpage-wrapper work-order-details">
  <div class="head">
    <div class="search-wrapper">
      <input
        type="text"
        class="cmn-header-search"
        placeholder="Search"
        (input)="onSearch($event)"
      />
    </div>
    <button class="cmn-upload-btn" (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button>
    <!-- <button class="cmn-add-btn" (click)="navigateTo('/work-order-new')">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Work
      Order
    </button> -->
    <button
      class="cmn-filter-btn"
      (click)="toggleDropdown($event)"
      [ngClass]="{ 'class-clicked': isDropdownOpen }"
    >
      <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
      <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
    </button>
    <!-- Filter -->
    <div
      [ngClass]="{ show: isDropdownOpen }"
      class="common-dropdown filter-position"
      #dropdown
    >
      <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
      <form>
        <h6 class="cmn-popup-heading">Filter <span>Clear All</span></h6>
        <div class="filter-inner-content">
          <h2>Branch</h2>
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />
                test
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button class="cmn-apply-btn" type="submit">Apply</button>
        </div>
      </form>
    </div>
    <!-- Filter -->
  </div>
  <div class="sub-head">
    <ul>
      <li class="main">All Orders</li>
      <!-- <li class="edit" (click)="navigateToSendMail()">
        <img src="assets/images/icons/icon-send.svg" alt="Mail" /> Send to mail
      </li> -->
      <li
        class="edit"
        (click)="navigateToSendMail()"
        [class.loading]="isLoading"
      >
        <img
          src="assets/images/icons/icon-send.svg"
          alt="Mail"
          *ngIf="!isLoading"
        />
        <span *ngIf="!isLoading">Send to mail</span>
        <span *ngIf="isLoading">Loading...</span>
      </li>

      <li class="edit" (click)="navigateToPath('/work-order/work-order-edit')">
        <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" /> Edit
      </li>
      <li class="edit" (click)="navigateTo('/invoice')">
        <img src="assets/images/icons/icon-record.svg" alt="Record" /> Convert
        to Invoice
      </li>
      <!-- <li class="edit" (click)="genPdf(true)">
        <img src="assets/images/icons/icon-record.svg" alt="Record" /> Generate
        PDF With Price
      </li>
      <li class="edit" (click)="genPdf(false)">
        <img src="assets/images/icons/icon-record.svg" alt="Record" /> Generate
        PDF
      </li> -->
      <li>
        <mat-form-field>
          <mat-label>Generate PDF </mat-label>
          <mat-select>
            <mat-option (click)="genPdf(true)">With Price</mat-option>
            <mat-option (click)="genPdf(false)">Without Price</mat-option>
          </mat-select>
        </mat-form-field>
      </li>
    </ul>
    <div class="mat-menu-wrapper" *ngIf="profileName === 'admin'">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item>
          <span>Mark as Sent</span>
        </button>
        <button mat-menu-item>
          <span>Credit Available</span>
        </button>
        <button mat-menu-item>
          <span>Create Credit Note</span>
        </button>
        <button mat-menu-item>
          <span>Credit Applied</span>
        </button>
        <button mat-menu-item>
          <span>Clone</span>
        </button> -->
        <button mat-menu-item>
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="manual-journal-details-tab-wrapper">
    <div class="tab-left">
      <div class="tab-box-main-wrapper">
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === item?.id"
          (click)="selectTab(0)"
          (click)="selectTab(item?.id)"
          *ngFor="let item of allWorkOrders"
        >
          <div class="inn-box">
            <h2>{{ item?.partnerData?.displayName }}</h2>
            <h3>{{ item?.workOrderNo }}</h3>
          </div>
          <div class="inn-box">
            <h6>
              {{ item.projectManagerData?.firstName ?? "" }}
              {{ item.projectManagerData?.lastName || "" }}
            </h6>
            <!-- <h5>
              AED <span>{{ calculateAmount(item) }}</span>
            </h5> -->
          </div>
          <!-- <div class="inn-box">
            <h4 class="grey">{{ item?.workOrderStatus }}</h4>
          </div> -->
        </button>
        <!-- <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 1"
          (click)="selectTab(1)"
        >
          <div class="inn-box">
            <h2>Jerome Bell</h2>
            <h3>RE-000102</h3>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h4 class="yellow">Due 15d</h4>
          </div>
        </button>
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 2"
          (click)="selectTab(2)"
        >
          <div class="inn-box">
            <h2>Cody Fisher</h2>
            <h3>RE-000102</h3>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h4 class="green">Paid</h4>
          </div>
        </button>
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 3"
          (click)="selectTab(3)"
        >
          <div class="inn-box">
            <h2>Kathryn Murphy</h2>
            <h3>RE-000102</h3>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h4 class="blue">Confirmerd</h4>
          </div>
        </button>
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 4"
          (click)="selectTab(4)"
        >
          <div class="inn-box">
            <h2>Jerome Bell</h2>
            <h3>RE-000102</h3>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h4 class="grey">Draft</h4>
          </div>
        </button> -->
      </div>
    </div>
    <div class="manual-journal-details-content-wrapper">
      <div *ngIf="detailsLoading">
        <div class="chart-tab-content">
          <div>Loading....</div>
        </div>
      </div>

      <div *ngIf="!detailsLoading">
        <div class="chart-tab-content">
          <div class="tab-inner">
            <div class="note-header">
              <div class="left-set">
                <h4>
                  {{ singleWorkOrder?.partnerData?.displayName || "N/A" }}
                </h4>
                <h3>
                  {{ singleWorkOrder?.addressData?.addressLine1 || "N/A" }}
                </h3>
                <h3>{{ singleWorkOrder?.addressData?.city || "N/A" }}</h3>
              </div>
              <div class="right-set">
                <h2>WORK ORDER</h2>
                <h3>
                  <b>{{ singleWorkOrder?.workOrderNo || "N/A" }}</b>
                </h3>
                <h3>
                  {{ singleWorkOrder?.createdAt | date : "dd/MM/yyyy" }}
                </h3>
              </div>
            </div>

            <div class="journal-details">
              <div class="details-box">
                <h4>Client Name</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.partnerData?.displayName || "N/A" }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Project Name</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.projectData?.projectName || "N/A" }}
                </h6>
              </div>
              <div class="details-box">
                <h4>SOWs</h4>
                <h5>:</h5>
                <h6>{{ singleWorkOrder?.SOW || "N/A" }}</h6>
              </div>
              <div class="details-box">
                <h4>Quote Ref</h4>
                <h5>:</h5>
                <h6 style="cursor: pointer;" [routerLink]="['/quotation/quotation-details']" [queryParams]="{ id: singleWorkOrder?.quoteData?.parentQuoteId }">
                  {{ singleWorkOrder?.quoteData?.quoteCode || 'N/A' }}
                </h6>
                
              </div>
              <div class="details-box">
                <h4>Client Conf.Ref</h4>
                <h5>:</h5>
                <h6>{{ singleWorkOrder?.clientRef || "N/A" }}</h6>
              </div>
              <div class="details-box">
                <h4>Duration</h4>
                <h5>:</h5>
                <h6>{{ singleWorkOrder?.duration || "N/A" }}</h6>
              </div>
              <div class="details-box">
                <h4>Project Manager</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.projectManagerData?.firstName || " " }}
                  {{ " " }}
                  {{ singleWorkOrder?.projectManagerData?.lastName || " " }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Project Designer</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.projectDesignerData?.firstName || " " }}
                  {{ " " }}
                  {{ singleWorkOrder?.projectDesignerData?.lastName || " " }}
                </h6>
              </div>
              <!-- <div class="details-box">
                  <h4>Budget</h4>
                  <h5>:</h5>
                  <h6>{{ calculateAmount(singleWorkOrder) }}</h6>
                </div> -->
              <div class="details-box">
                <h4>Commencement Date</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.commencementDate | date : "dd/MM/yyyy" }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Start Date</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.startDate | date : "dd/MM/yyyy" }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Completion Date</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.completionDate | date : "dd/MM/yyyy" }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Primary Contact Person</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.partnerData?.displayName || "N/A" }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Project Engineer</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.projectInchargeData?.firstName || " " }}
                  {{ " " }}
                  {{ singleWorkOrder?.projectInchargeData?.lastName || " " }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Procurement Incharge</h4>
                <h5>:</h5>
                <h6>
                  {{
                    singleWorkOrder?.procurementInchargeData?.firstName || " "
                  }}
                  {{ " " }}
                  {{
                    singleWorkOrder?.procurementInchargeData?.lastName || " "
                  }}
                </h6>
              </div>
              <div class="details-box">
                <h4>Cost Estimator</h4>
                <h5>:</h5>
                <h6>
                  {{ singleWorkOrder?.estimatorData?.firstName || " " }}
                  {{ " " }}
                  {{ singleWorkOrder?.estimatorData?.lastName || " " }}
                </h6>
              </div>
            </div>

            <div
              class="crdit-note-page-table"
              *ngIf="singleWorkOrder?.workOrderSOWs?.length"
            >
              <table>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <!-- <th>Rate</th>
                  <th>Amount</th> -->
                </tr>
                <tr *ngFor="let item of singleWorkOrder?.workOrderSOWs">
                  <td>{{ item?.order }}</td>
                  <td>{{ item?.quantity }}</td>
                  <td>{{ item?.unit }}</td>
                  <!-- <td>{{ item?.rate }}</td>
                  <td>AED {{ item?.netPrice }}</td> -->
                </tr>
              </table>
            </div>
            <div class="page-footer-content">
              <!-- <div class="left-set">
                <div class="details-box">
                  <h4>Customer Notes :</h4>
                  <h6>Looking forward for your business.</h6>
                </div>
                <div class="details-box">
                  <h4>Terms & Conditions :</h4>
                  <h6>Privacy Policy</h6>
                </div>
              </div> -->
              <div class="right-set">
                <!-- <div class="details-box">
                  <h4>Sub total</h4>
                  <h5>:</h5>
                  <h6>PO-5315615</h6>
                </div>
                <div class="details-box">
                  <h4>Total</h4>
                  <h5>:</h5>
                  <h6>PO-5315615</h6>
                </div>
                <div class="details-box">
                  <h4>Credits Used</h4>
                  <h5>:</h5>
                  <h6>PO-5315615</h6>
                </div> -->
                <!-- <div class="details-box bold">
                  <h4>Total</h4>
                  <h5>:</h5>
                  <h6>AED {{ calculateAmount(singleWorkOrder) | number:'1.2-2' }}</h6>
                </div>   -->
              </div>
            </div>

            <p class="signature">Authorized Signature <span></span></p>

            <!-- <span class="published-note"
              ><img src="assets/images/img-draft.svg" [alt]='singleWorkOrder?.workOrderStatus'
            /></span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
