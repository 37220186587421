import { Router } from '@angular/router';
import { Component, ComponentFactoryResolver, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { EmployeeService } from '../../../../features/employees/services/employee.service';
import { MatSelectModule } from '@angular/material/select';
import { nonNegativeValidator, numbersOnlyValidator } from '../../../../shared/services/validations';
import { ToastrService } from 'ngx-toastr';
import { ClearanceStatus } from '../../../../../assets/ClearanceStatus';
import { SettlementStatus } from '../../../../../assets/SettlementStatus';
import { UploadFileService } from '../../../../shared/services/file-upload.service';
import { EmployeeExitType } from '../../../../../assets/employeeExitTypes';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-exit-employee',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        TextFieldModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
    ],
    templateUrl: './exit-employee.component.html',
    styleUrl: '../../employee-details.component.scss'
})
export class ExitEmployeeComponent implements OnInit {
    isDropdownOpen = false;
    employeeExitForm: FormGroup;
    csvFile: any;
    url: string = '';
    today: Date = new Date();
    public employeeExitTypeData: any[] = [];
    public isUpdating: boolean = false;
    basicSalaryValue: any;
    yearsOfServiceValue: any;
    firstFiveYearsValue: any;
    beyondFiveYearsValue: any;
    totalGratuityValue: any;

    public clearanceStatusData: {
        label: string;
        value: ClearanceStatus
    }[] = [];
    public settlementStatusData: {
        label: string;
        value: SettlementStatus
    }[] = [];
    public employeeExitTypes: {
        label: String;
        value: EmployeeExitType
    }[] = [];

    @Input() employeeId: any;
    @Input() employeeData: any = {};

    isEditMode = false;
    existingEmployeeExitId: number | null = null;
    selectedFileName: string = ''; // Property to hold the file name
    existingFileUrl: string | null = null; // Property for existing file URL

    exitDate: Date | null = null; // Store selected Exit Date

    constructor(private fb: FormBuilder, private employeeService: EmployeeService, private toastr: ToastrService, private documentUploadService: UploadFileService, private router: Router) {
        this.employeeExitForm = this.fb.group({
            exitDate: [
                '', Validators.required
            ],
            exitType: [
                '', Validators.required
            ],
            gratuity: [0, Validators.required],
            exitReason: [
                '', Validators.required
            ],
            interviewNotes: [
                '', Validators.required
            ],
            interviewDate: [
                '', Validators.required
            ],
            clearanceStatus: [
                '', Validators.required
            ],
            clearanceDate: [
                '', Validators.required
            ],
            settlementStatus: [
                '', Validators.required
            ],
            settlementAmount: [
                '',
                [
                    Validators.required, nonNegativeValidator
                ]
            ],
            settlementDate: [
                '', Validators.required
            ],
            lastWorkingDay: [
                '', Validators.required
            ],
            feedbackScore: [
                '',
                [
                    Validators.required, Validators.min(0), Validators.max(10)
                ],
            ],
            isAssetReturned: [false],
            isNoticePeriod: [false],
            isExitInterview: [false],
            isAccountsDisabled: [false],
            isManagerApproval: [false],
            isHrApproval: [false],
            isFinalApproval: [false],
            isRehireEligibility: [false],
            isToolStatus: [false],
            isVehicleStatus: [false],
            exitDocuments: ['']
        }, { validators: this.validateLastWorkingDay }
        )
    }

    ngOnInit(): void {
        this.loadClearanceStatus();
        this.loadSettlementStatus();
        this.loadEmployeeExitTypes();
        this.fetchEmployeeExitData();
        console.log("Employee data-----", this.employeeData)
    }

    // Filter for interviewDate - disables dates after exitDate
    interviewDateFilter = (d: Date | null): boolean => {
        const currentDate = d || new Date();
        return this.exitDate ? currentDate <= this.exitDate : true;
    };

    lastWorkingDayFilter = (d: Date | null): boolean => {
        const interviewDate = this.employeeExitForm.get('interviewDate')?.value;
        return interviewDate ? new Date(d!).getTime() >= new Date(interviewDate).getTime() : true;
    };

    private validateLastWorkingDay: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const interviewDate = control.get('interviewDate')?.value;
        const lastWorkingDay = control.get('lastWorkingDay')?.value;

        if (interviewDate && lastWorkingDay && new Date(lastWorkingDay) < new Date(interviewDate)) {
            return { lastWorkingDayBeforeInterviewDate: true };
        }
        return null;
    };

    // Trigger validation for related fields
    onDateChange() {
        this.employeeExitForm.get('lastWorkingDay')?.updateValueAndValidity();
        this.employeeExitForm.get('interviewDate')?.updateValueAndValidity();
    }

    // Update exitDate when selected
    onExitDateChange(event: Date) {
        this.exitDate = event; // Set exitDate when the user selects a date
        this.employeeExitForm.get('interviewDate')?.updateValueAndValidity(); // Update validity of interviewDate
        this.calculateGratuity();
    }

    private loadClearanceStatus() {
        this.clearanceStatusData = Object.values(ClearanceStatus).map((status) => ({ label: status, value: status }));
    }

    private loadSettlementStatus() {
        this.settlementStatusData = Object.values(SettlementStatus).map((status) => ({ label: status, value: status }));
    }

    private loadEmployeeExitTypes() {
        this.employeeExitTypes = Object.values(EmployeeExitType).map((status) => ({ label: status, value: status }));
    }

    removeFile(): void {
        if (this.csvFile) {
            this.csvFile = null;
        }
        if (this.existingFileUrl) {
            this.existingFileUrl = null;
            this.selectedFileName = '';
        }

        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) {
            input.value = '';
        }
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.csvFile = input.files[0];
        }
    }

    private fetchEmployeeExitData() {
        const exitId = this.employeeData?.employeeExits?.id;
        console.log("Exit id-----", exitId);
        console.log("Exit Data-----", this.employeeData?.employeeExits);
        if (exitId) {
            this.employeeService.getEmployeeExitById(exitId).subscribe({
                next: (employeeExitData) => {
                    if (employeeExitData) {
                        this.isEditMode = true;
                        this.existingEmployeeExitId = employeeExitData.id;
                        this.patchForm(employeeExitData);
                        this.existingFileUrl = employeeExitData.exitDocuments || null;
                        this.selectedFileName = this.existingFileUrl ? this.extractFileName(this.existingFileUrl) : '';
                    }
                },
                error: (error) => {
                    console.error('Error fetching employee exit data:', error);
                }
            });
        }
    }

    private extractFileName(url: string): string {
        return url.split('/').pop() || '';
    }

    private patchForm(data: any): void {
        this.employeeExitForm.patchValue({
            exitDate: new Date(Number(data.exitDate)),
            exitType: data.exitType,
            gratuity: data.gratuity,
            exitReason: data.exitReason,
            interviewNotes: data.interviewNotes,
            interviewDate: new Date(Number(data.interviewDate)),
            clearanceStatus: data.clearanceStatus,
            clearanceDate: new Date(Number(data.clearanceDate)),
            settlementStatus: data.settlementStatus,
            settlementAmount: data.settlementAmount,
            settlementDate: new Date(Number(data.settlementDate)),
            lastWorkingDay: new Date(Number(data.lastWorkingDay)),
            feedbackScore: data.feedbackScore,
            isAssetReturned: data.isAssetReturned,
            isNoticePeriod: data.isNoticePeriod,
            isExitInterview: data.isExitInterview,
            isAccountsDisabled: data.isAccountsDisabled,
            isManagerApproval: data.isManagerApproval,
            isHrApproval: data.isHrApproval,
            isFinalApproval: data.isFinalApproval,
            isRehireEligibility: data.isRehireEligibility,
            isVehicleStatus: data.isVehicleStatus,
            isToolStatus: data.isToolStatus,
            exitDocuments: data.exitDocuments
        });
    }

    // Update the existing employee exit record
    updateForm() {
        if (this.employeeExitForm.valid) {
            const formData = {
                ...this.employeeExitForm.value,
                gratuity: parseFloat(this.employeeExitForm.value.gratuity),
                id: this.existingEmployeeExitId
            };

            this.employeeService.updateEmployeeExit(formData).subscribe({
                next: (response) => {
                    this.toastr.success('Employee exit updated successfully.');
                },
                error: (error) => {
                    this.toastr.error('Error updating the employee exit.');
                }
            });
        }
    }

    onCancel(): void {
        this.employeeExitForm.reset();
    }

    onSubmit() {
        if (this.csvFile) {
            this.uploadDocuments(this.csvFile);
        } else {
            this.isEditMode ? this.updateForm() : this.submitForm();
        }
    }

    uploadDocuments(csvFile: any) {
        console.log(csvFile);
        this.documentUploadService.uploadDocument(csvFile, 'exit-employee').subscribe({
            next: (response) => {
                this.url = response.url || response;
                console.log('Document uploaded, URL:', this.url);
                this.employeeExitForm.patchValue({ exitDocuments: this.url });
                this.submitForm();
                this.router.navigate(['/employees']);
            },
            error: (error) => {
                console.error('File upload failed:', error);
                this.toastr.error('File upload failed');
            }
        });
    }

    submitForm() {
        if (!this.employeeExitForm.valid) {
            this.employeeExitForm.markAllAsTouched();
        } else {
            const formData = {
                ...this.employeeExitForm.value,
                gratuity: parseFloat(this.employeeExitForm.value.gratuity),
                employeeId: parseInt(this.employeeId, 10)
            };
            this.employeeService.submitEmployeeExit(formData).subscribe({
                next: (response) => {
                    this.toastr.success('Employee exit processed successfully.');
                    this.router.navigate(['/employees']);
                },
                error: (error) => {
                    console.error('Error processing the employee exit:', error);
                    this.toastr.error('There was an error processing the employee exit.');
                }
            });
        }
    }

    private calculateGratuity() {
        if (!this.employeeExitForm.value.exitDate && !this.employeeExitForm.value.exitType) {
            this.toastr.warning('Please fill in both Exit Date and Exit Type to calculate gratuity.');
            return null; // Exit the function if fields are missing
        }

        // Helper function to calculate completed years of service
        const doj = this.employeeData.dateOfJoining
            ? new Date(Number(this.employeeData.dateOfJoining)).toISOString().split('T')[0]
            : null;
        const dateOfExit = this.employeeExitForm.value.exitDate
            ? new Date(this.employeeExitForm.value.exitDate).toISOString().split('T')[0]
            : null;
        const typeOfExit = this.employeeExitForm.value.exitType;
        const basicSalary = this.employeeData.employeeSalaries[0].basicSalary

        function getYearsOfService(startDate: any, endDate: any) {
            const start = new Date(startDate);
            const end = new Date(endDate);

            let years = end.getFullYear() - start.getFullYear();
            let months = end.getMonth() - start.getMonth();
            let days = end.getDate() - start.getDate();

            if (days < 0) {
                months--;
                days += new Date(end.getFullYear(), end.getMonth(), 0).getDate();
            }

            if (months < 0) {
                years--;
                months += 12;
            }

            if (months === 11 && days >= 29) {
                return years + 1;
            }

            return years;
        }

        const dailyBasicSalary = basicSalary / 30;
        const yearsOfService = getYearsOfService(doj, dateOfExit);
        let gratuityDays = 0;
        let gratuityDetails = [];
        let firstFiveYearGratuityValue = [];
        let beyondFiveYearGratuityValue = [];

        if (yearsOfService < 1) {
            gratuityDays = 0;
        } else if (yearsOfService <= 3) {
            gratuityDays = yearsOfService * 14;
            firstFiveYearGratuityValue.push(`First ${yearsOfService} Years: (${basicSalary}/30) * 14 * ${yearsOfService} = ${(dailyBasicSalary * 14 * yearsOfService).toFixed(2)} AED`);
        } else if (yearsOfService <= 5) {
            gratuityDays = yearsOfService * 21;
            beyondFiveYearGratuityValue.push(`First ${yearsOfService} Years: (${basicSalary}/30) * 21 * ${yearsOfService} = ${(dailyBasicSalary * 21 * yearsOfService).toFixed(2)} AED`);
        } else {
            gratuityDays = (5 * 21) + ((yearsOfService - 5) * 30);
            firstFiveYearGratuityValue.push(`First 5 Years: (${basicSalary}/30) * 21 * 5 = ${(dailyBasicSalary * 21 * 5).toFixed(2)} AED`);
            beyondFiveYearGratuityValue.push(`Beyond 5 Years: (${basicSalary}/30) * 30 * ${yearsOfService - 5} = ${(dailyBasicSalary * 30 * (yearsOfService - 5)).toFixed(2)} AED`);
        }

        const gratuityAmount = Number((gratuityDays * dailyBasicSalary).toFixed(2));
        gratuityDetails.push(`Total Gratuity: ${gratuityAmount.toFixed(2)} AED`);
        this.employeeExitForm.get('gratuity')?.setValue(gratuityAmount.toFixed(2));
        this.basicSalaryValue = basicSalary;
        this.yearsOfServiceValue = yearsOfService;
        this.firstFiveYearsValue = firstFiveYearGratuityValue[0];
        this.beyondFiveYearsValue = beyondFiveYearGratuityValue[0];
        this.totalGratuityValue = +gratuityAmount

        return {
            yearsOfService,
            gratuityDays,
            gratuityAmount,
            details: gratuityDetails
        };
    }

    toggleDropdown(event?: MouseEvent) {
        if (event) {
            event.stopPropagation();
        }
        const gratuityResult = this.calculateGratuity();
        if (gratuityResult) {
            console.log('Gratuity Calculation Result:', gratuityResult);
            // this.isDropdownOpen = !this.isDropdownOpen;
            if (gratuityResult.gratuityDays === 0) {
                this.toastr.info('Gratuity cannot be calculated as the service years are less than 1.');
            } else {
                this.isDropdownOpen = !this.isDropdownOpen;
            }
        }
    }

    readonly dialog = inject(MatDialog);

    openDialog() {
        const dialogRef = this.dialog.open(GratuityDialog);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

}



@Component({
    selector: 'gratuity-dialog',
    templateUrl: 'gratuity-dialog.html',
    styleUrl: '../../employee-details.component.scss',
    imports: [MatDialogModule],
    standalone: true,
})

export class GratuityDialog { }