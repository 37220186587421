<div class="tab-content-wrapper">
  <form [formGroup]="branchAccessForm" (ngSubmit)="onNext()">
    <div class="branch-form-wrapper">
      <div class="form-wrapper">
        <label for="">Select Branch <span class="red">*</span></label>
        <mat-form-field>
          <mat-select placeholder="Select Branch" formControlName="branchName">
            <mat-option *ngFor="let branch of branchData" [value]="branch.id">
              {{ branch.branchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="custom-error">
          <span>
            <mat-error *ngIf="
            branchAccessForm.get('branchName')!.touched &&
            branchAccessForm.get('branchName')!.hasError('required')
          ">Branch Name is required.</mat-error>
          </span>
        </div>
      </div>
      <div formArrayName="approvers">
        <div *ngFor="let approver of getApprovers(); let i = index" [formGroupName]="i">
          <div class="form-wrapper">
            <label>{{ 'Level ' + (i + 1) }} Approver</label>
            <mat-form-field>
              <mat-select placeholder="Select Approver" formControlName="approverId"
                (selectionChange)="onApproverSelected($event, i)">
                <mat-option *ngFor="let approverData of approversData" [value]="approverData?.id">
                  {{ approverData.firstName + ' ' + approverData.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Remove button -->
            <span *ngIf="i !== 0" (click)="onRemoveApprover(i)" class="remove-btn">
              <img src="../../../../../assets/images/icons/icon-close.svg" alt="Close Icon" />
            </span>
          </div>
        </div>

        <!-- Add Approver button -->
        <ng-container *ngIf="getApprovers().length < 7">
              <button type="button" class="add-approver" (click)="onAddApprover()">
                <img src="assets/images/icons/icon-add-color.svg" alt="Add Approver" />
                Add Approver
              </button>
        </ng-container>
      </div>

    </div>

    <!-- <div class="form-wrapper">
      <label class="checkbox-wrapper">
        <input type="checkbox" formControlName="isPortalUser" />Is Portal User
        <span class="checkmark"></span>
      </label>
    </div> -->
    <div class="employee-form-wrapper">
      <div *ngIf="branchAccessForm.get('isPortalUser')?.value" class="form-wrapper">
        <label for="">Select User Role</label>
        <mat-form-field>
          <mat-select placeholder="Select User Role" formControlName="userRole">
            <mat-option *ngFor="let role of userRolesData" [value]="role.id">
              {{ role.profileName }}
            </mat-option> </mat-select>

        </mat-form-field>
        <div class="custom-error">
          <span>
            <mat-error *ngIf="
            branchAccessForm.get('userRole')!.touched &&
            branchAccessForm.get('userRole')!.hasError('required')
          ">User role is required.</mat-error>
          </span>
        </div>
      </div>
      <div *ngIf="
        branchAccessForm.get('isPortalUser')?.value &&
        (!isEditMode || !initialIsPortalUser)
      " class="form-wrapper">
        <label for="">Password</label>
        <mat-form-field>
          <input matInput placeholder="Enter Password" formControlName="password" />
        </mat-form-field>
        <div class="custom-error">
          <span>
            <mat-error *ngIf="
            branchAccessForm.get('password')!.touched &&
            branchAccessForm.get('password')!.hasError('required')
          ">Password is required.</mat-error>
          </span>
        </div>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>