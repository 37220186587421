<section class="cmn-innerpage-wrapper invoice-new">
  <h2 class="cmn-inner-heading">
    {{ billData ? "Edit" : "New" }} Vendor Invoice
  </h2>
  <form [formGroup]="newBillForm">
    <div class="invoice-new-form-wrapper">
      <div class="form-wrapper invoice-form-wrapper">
        <!-- Client Name Section -->
        <div class="cmn-Search-select-container">
          <label for="vendorName">Vendor Name<span class="red">*</span></label>
          <mat-form-field>
            <mat-select [formControl]="vendorControl" placeholder="Search Vendor" formControlName="partnerId"
              (selectionChange)="onVendorChange($event)">
              <mat-option>
                <ngx-mat-select-search [formControl]="vendorSearchControl" [placeholderLabel]="'Search'"
                  [noEntriesFoundLabel]="'No options found'"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let vendor of filteredVendors" [value]="vendor.id">
                {{ vendor.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newBillForm.get('partnerId')!.invalid &&
              newBillForm.get('partnerId')!.touched
            " class="error">Vendor is required.</mat-error>
            </span>
          </div>
          <button [routerLink]="['/vendors/vendor-new']">
            <div>
              <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
            </div>
            <span>Add Vendor</span>
          </button>
        </div>

        <!-- Invoice Number Section -->
        <div>
          <label for="invoiceNumber">Vendor Invoice# <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Bill No" formControlName="billNo" [readonly]="billData" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newBillForm.get('billNo')!.invalid &&
              newBillForm.get('billNo')!.touched
            " class="error">Vendor invoice number is required.</mat-error>
            </span>
          </div>
        </div>

        <!-- Order Number Section -->
        <!-- <div>
          <label for="orderNumber">Order Number</label>
          <mat-form-field>
            <input matInput placeholder="Enter Order No" formControlName="orderNo" maxlength="15" />
          </mat-form-field>
        </div> -->

        <div *ngIf="!billData">
          <label for="purchaseOrderId">PO Number<span class="red">*</span></label>
          <mat-form-field>
            <mat-select [formControl]="purchaseControl" placeholder="Search Purchase Order"
              formControlName="purchaseOrderId" (selectionChange)="onPOChange($event)">
              <mat-option>
                <ngx-mat-select-search [formControl]="purchaseSearchControl" [placeholderLabel]="'Search'"
                  [noEntriesFoundLabel]="'No options found'"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let purchase of filteredPurchase" [value]="purchase.id">
                {{ purchase.purchaseOrderNo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
            newBillForm.get('purchaseOrderId')!.invalid &&
            newBillForm.get('purchaseOrderId')!.touched
            " class="error">Purchase order is required.</mat-error>
            </span>
          </div>
          <button [routerLink]="['/purchase-order/purchase-order-new']">
            <div><img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client"></div>
            <span>Add purchase order</span>
          </button>
        </div>
        <!-- Invoice Date Section -->
        <div>
          <label for="invoiceDate">Vendor Invoice Date <span class="red">*</span></label>
          <mat-form-field>
            <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date" formControlName="billDate"
              [matDatepickerFilter]="dateFilter" />
            <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #invoiceDatePicker></mat-datepicker>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newBillForm.get('billDate')!.invalid &&
              newBillForm.get('billDate')!.touched
            " class="error">Vendor invoice date is required.</mat-error>
            </span>
          </div>

        </div>
        <!-- Terms Section -->
        <div>
          <label for="paymentTerms">Payment Terms</label>
          <mat-form-field>
            <input matInput placeholder="Enter payment term" formControlName="paymentTerm" readonly />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newBillForm.get('paymentTerm')!.touched &&
              newBillForm.get('paymentTerm')!.hasError('required')
            ">Payment Term is required.</mat-error>
            </span>
          </div>
        </div>
        <div>
          <label for="dueDate">Due Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="dueDatePicker" placeholder="Select Date" formControlName="dueDate"
              id="dueDate" />
            <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Subject Section -->
        <div>
          <label for="subject">Subject</label>
          <mat-form-field>
            <textarea matInput placeholder="Invoice subject" formControlName="subject"></textarea>
          </mat-form-field>
        </div>

        <!-- Payment Mode-->
        <div>
          <label for="invoiceNumber">Payment Mode <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select Payment Mode" formControlName="paymentMode">
              <mat-option value="OneTimePayment">One Time Payment</mat-option>
              <mat-option value="PartiallyPayment">Partial Payment</mat-option>
              <mat-option value="PaymentTermsBased">Payment Term Based</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newBillForm.get('paymentMode')!.invalid &&
              newBillForm.get('paymentMode')!.touched
            " class="error">Payment mode is required.</mat-error>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="newBillForm">
    <div class="item-service-adding-table-wrapper">
      <table>
        <thead>
          <tr>
            <th>SL No. </th>
            <th>Description</th>
            <!-- <th>Description</th> -->
            <th>Unit </th>
            <th>Total Quantity</th>
            <!-- <th>Quantity Left</th> -->
            <th>Quantity</th>
            <th>Rate</th>
            <th>Gross Amount</th>
            <th>Discount</th>
            <th>Net Amount</th>
            <th>VAT (5%)</th>
            <th>Total Amount</th>
            <th *ngIf="!billData && paymentMode === 'PaymentTermsBased'"></th>
          </tr>
        </thead>
        <tbody formArrayName="items">
          <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
            <td>{{ i + 1 }}</td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Item Name" formControlName="itemName" readonly />
              </mat-form-field>
              <!-- <mat-select placeholder="Select Item" formControlName="itemId">
                  <mat-option *ngFor="let availableItem of availableItems" [value]="availableItem?.id">
                    {{ availableItem?.itemName }}
                  </mat-option>
                </mat-select> -->
              <!-- <mat-error *ngIf="item.get('itemId')?.hasError('required')">Select an item.</mat-error> -->
            </td>
            <td>
              <mat-form-field>
                <!-- <mat-select placeholder="Enter Unit" formControlName="unit">
                    <mat-option *ngFor="let unit of unitTypes" [value]="unit.value">
                      {{ unit.value }}
                    </mat-option>
                  </mat-select> -->
                <input matInput placeholder="Unit" formControlName="unit" readonly />
                <!-- <mat-error *ngIf="item.get('unit')?.hasError('required')">
                    *Unit is required
                  </mat-error> -->
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Total Quantity" formControlName="totalQuantity" readonly />
              </mat-form-field>
            </td>
            <!-- <td>
            <mat-form-field>
              <input matInput placeholder="Quantity" formControlName="quantityLeft" readonly/>
            </mat-form-field>
          </td> -->
            <td>
              <mat-form-field>
                <input matInput placeholder="Quantity" formControlName="quantity" maxlength="6" />
              </mat-form-field>
              <div class="custom-error">
                <span>
                  <mat-error *ngIf="item.get('quantity')?.hasError('required')">Quantity is required.</mat-error>
                </span>
                <span>
                  <mat-error *ngIf="item.get('quantity')?.hasError('pattern')">Enter a valid quantity.</mat-error>
                </span>
                <span>
                  <mat-error *ngIf="item.get('quantity')?.hasError('totalQuantityExceeded')">Quantity exceeds the
                    allowed limit.</mat-error>
                </span>
              </div>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Rate" formControlName="rate" readonly />
              </mat-form-field>
              <div class="custom-error">
                <span>
                  <mat-error *ngIf="item.get('rate')?.hasError('required')">Rate is required.</mat-error>
                </span>
                <span>
                  <mat-error *ngIf="item.get('rate')?.hasError('pattern')">Enter a valid rate.</mat-error>
                </span>
              </div>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Gross Amount" formControlName="grossAmount" type="number" />
              </mat-form-field>
              <div class="custom-error">
                <span>
                  <mat-error *ngIf="item.get('grossAmount')?.hasError('pattern')">Enter a valid discount.</mat-error>
                </span>
              </div>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Discount" formControlName="discount" type="number" />
              </mat-form-field>
              <div class="custom-error">
                <span><mat-error *ngIf="item.get('discount')?.hasError('required')">Discount is
                    required.</mat-error></span>
                <span><mat-error *ngIf="item.get('discount')?.hasError('pattern')">Enter a valid
                    discount.</mat-error></span>
                <span><mat-error *ngIf="item.get('discount')?.hasError('discountExceedsGross')">Discount cannot exceed
                    gross amount.</mat-error></span>
              </div>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Amount" formControlName="amount" readonly />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="VAT" formControlName="tax" readonly />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Total Amount" formControlName="totalAmount" readonly />
              </mat-form-field>
            </td>
            <td *ngIf="!billData && paymentMode === 'PartiallyPayment'">
              <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                (click)="onRemoveAddedItem(i)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>


  <!-- <td (click)="onAddItem()">
  <img src="../../../assets/images/icons/icon-add-color.svg" alt="" />
</td> -->




  <div class="notes-tatal-amount-container">
    <form [formGroup]="notesForm">
      <div class="form-container">
        <div class="form-wrapper">
          <label for="customerNotes">Notes</label>
          <mat-form-field>
            <textarea matInput placeholder="Enter your notes here..." formControlName="notes"></textarea>
          </mat-form-field>
        </div>

        <div class="upload-file-group">
          <h2>Documents</h2>
          <input #fileInput type="file" style="display: none" formControlName="fileUrl"
            (change)="onFileSelected($event)" />
          <button class="cmn-upload-btn" (click)="fileInput.click()">
            <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
            <span>Upload File</span>
          </button>
          <div class="uploaded-file" *ngIf="billData?.billAttachments?.length > 0">
            <h6 *ngFor="let document of billData?.billAttachments">
              <span (click)="onViewDocument(document?.fileUrl)" style="cursor: pointer">
                {{ document?.fileName }}
              </span>
              <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="onRemoveDocument(document?.id)" />
            </h6>
          </div>
          <div class="uploaded-file" *ngIf="billData">
            <h6 *ngIf="uploadedName">
              <span *ngIf="uploadedName" (click)="onViewDocument(fileUrl)" style="cursor: pointer">
                {{ uploadedName }}
              </span>
              <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()" />
            </h6>
          </div>
          <div class="uploaded-file" *ngIf="fileName && !billId">
            <h6>
              <span (click)="onViewDocument(fileUrl)" style="cursor: pointer">
                {{ fileName }}
              </span>
              <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()" />
            </h6>
          </div>
        </div>
      </div>
    </form>
    <div class="amount-table-div" *ngIf="subTotal || totalPrice">
      <div>
        <!-- <h6>Discount <span>: AED -50.00</span></h6> -->
        <h6>
          Sub Total<span>: AED {{ subTotal | number:'1.2-2'}}</span>
        </h6>
        <h6>
          Total Discount(-)<span>: AED {{ totalDiscount | number : "1.2-2" }}</span>
        </h6>
        <h6>
          VAT (5%)<span>: AED {{ vat | number : "1.2-2" }}</span>
        </h6>
        <!-- <h6>Adjustment <span>: AED 0.00</span></h6> -->
      </div>
      <div>
        <h4>
          Total(AED) <span>: AED {{ totalPrice | number:'1.2-2' }}</span>
        </h4>
      </div>
    </div>
    <div *ngIf="(subTotal || totalPrice) && (paymentMode === 'PaymentTermsBased')">
      <form [formGroup]="percentForm">
        <div>
          <label for="percentage">Percent <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter percentage" type="number" formControlName="paymentPercentage" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="percentForm.get('paymentPercentage')?.hasError('invalidPaymentPercentage')">
                Payment Percentage must be greater than 0.
              </mat-error>
            </span>
            <!-- Custom validation for exceeding pending -->
            <span>
              <mat-error *ngIf="percentForm.get('paymentPercentage')?.hasError('maxPendingExceeded')">
                Payment Percentage cannot be greater than {{ pending }}.
              </mat-error>
            </span>
          </div>
        </div>
        <div>
          <label for="percentage">Pending</label>
          <h3>AED {{pending}}</h3>
        </div>
      </form>
    </div>
  </div>

  <div class="submit-btn-wrapper" *ngIf="!billData && !billId">
    <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
    <button class="cmn-draft-btn" (click)="onSaveAsDraft()">
      Save as Draft
    </button>
    <button class="cmn-next-btn" type="submit" (click)="onSaveAsOpen()">
      Save as Open
    </button>
  </div>
  <div class="submit-btn-wrapper" *ngIf="billId && billData">
    <button class="cmn-cancel-btn">Cancel</button>

    <!-- Save as Draft button (displayed always) -->
    <button class="cmn-draft-btn" *ngIf="billData?.billStatus === 'Open'" (click)="onSaveAsDraft()">
      Save as Draft
    </button>

    <!-- Save button, displayed when billStatus is 'Draft' -->
    <button class="cmn-draft-btn" *ngIf="billData?.billStatus === 'Draft'" (click)="onSaveAsOpen()">
      Save as Open
    </button>

    <!-- Save button, displayed when billStatus is 'Open' -->
    <button class="cmn-next-btn" *ngIf="billData?.billStatus === 'Open'" (click)="onSaveAsOpen()">
      Save
    </button>

    <button class="cmn-next-btn" *ngIf="billData?.billStatus === 'Draft'" (click)="onSaveAsDraft()">
      Save
    </button>
  </div>
</section>