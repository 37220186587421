import gql from 'graphql-tag';

export const CREATE_ASSET = gql`
  mutation createAsset($createAssetInput: CreateAssetInput!) {
    createAsset(createAssetInput: $createAssetInput) {
      id
      assetName
      assetType
      serialNumber
      purchaseDate
      purchaseCost
      assetCurrentStatus
      warrantyExpiryDate   
      rcExpiryDate
      insuranceExpiryDate
      assetStatus
      condition
      branchId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const ASSIGN_VEHICLE = gql`
  mutation assignORUnAssignVehicleToDriver(
    $id: Int!
    $actionType: String!
    $createVehicleLogInput: CreateVehicleLogInput
    $createVehicletaskInput: CreateVehicletaskInput
    $updateVehicletaskInput: UpdateVehicletaskInput
  ) {
    assignORUnAssignVehicleToDriver(
      id: $id
      actionType: $actionType
      createVehicleLogInput: $createVehicleLogInput
      createVehicletaskInput: $createVehicletaskInput
      updateVehicletaskInput: $updateVehicletaskInput
    )
  }
`;

export const UPDATE_ASSET = gql`
  mutation updateAsset($updateAssetInput:  UpdateAssetInput!) {
    updateAsset(updateAssetInput: $updateAssetInput) {
      id
      assetName
      assetType
      serialNumber
      purchaseDate
      purchaseCost
    }
  }
`;
export const TRANSFER_ASSETS = gql`
  mutation transferAsset($createAssetTransferInput: CreateAssetTransferInput!) {
    transferAsset(createAssetTransferInput: $createAssetTransferInput) {
      id
      assetId
      assetData {
        id
        assetName
        assetType
        serialNumber
        purchaseDate
        purchaseCost
        warrantyExpiryDate
        rcExpiryDate
        insuranceExpiryDate
        assetStatus
        condition
        branchId
      }
      fromBranch
      transferDate
      transferredBy
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_ASSET_TRANSFER = gql`
  mutation updateAssetTransfer($updateAssetTransferInput: UpdateAssetTransferInput! ) {
    updateAssetTransfer(updateAssetTransferInput: $updateAssetTransferInput) {
      id
      assetId
    }
  }
`;

export const DISPOSE_ASSET = gql`
  mutation disposeAsset($createAssetDisposalInput: CreateAssetDisposalInput!) {
    disposeAsset(createAssetDisposalInput: $createAssetDisposalInput) {
      id
      assetId
    }
  }
`;

export const ASSIGN_ASSET__TO_EMPOLOYEE =gql`
mutation assignAssetToEmployee($assignedToId: Int!, $assetId: Int!){
assignAssetToEmployee(assignedToId: $assignedToId, assetId: $assetId)
}
`;

export const ADD_FINE_VEHICLE = gql`
  mutation createVehicleFine($createVehicleFineInput: CreateVehicleFineInput!) {
    createVehicleFine(createVehicleFineInput: $createVehicleFineInput) {
      id
      vehicleId
    }
  }
`;

export const UNASSIGN_ASSETS = gql`
mutation unAssignAssetFromEmployee($assetId: Int!){
unAssignAssetFromEmployee(assetId: $assetId)
}
`;