import { gql } from "apollo-angular";

export const GET_PROFILES = gql`
  query profiles( $search: String) {
    profiles( search: $search) {
    id
    profileName
    description
    rolePermissions {
      id
      profileId
      profileData
      {
        id
        profileName
        description
      }
      moduleId
      moduleData{
        id
        parentModuleId
        moduleName
        isMenu
      }
      permissionId
      permission{
        id
        permissionName
        description
      }
    }
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const GET_MODULES = gql`
  query getModulesList {
    getModulesList {
    id
    parentModuleId
    moduleName
    url
    createdAt
    updatedAt
    deletedAt
    isMenu
    parentModuleData {
      moduleName
    }
    childModules {
      id
      moduleName
    }
  }
  }
`;
// export const GET_MODULES = gql`
//   query modules( $filter: ModuleFilter) {
//     modules( filter: $filter)  {
//     id
//     parentModuleId
//     parentModuleData {
//       id
//       parentModuleId
//       childModules{
//         id
//         moduleName
//         isMenu
//       }
//       moduleName
//       isMenu
//       url
//     }
//   }
//   }
// `;

export const GET_PERMISSIONS = gql`
  query permissions( $search: String) {
    permissions( search: $search)  {
    id
    permissionName
    description
    createdBy
    status
    rolePermissions {
      id
      profileId
      # profileData
      moduleId
      # moduleData
      permissionId
      # permission
    }
  }
  }
`;

export const GET_PERMISSION_BY_ID = gql`
  query getModuleDataNew( $profileId: Int!) {
    getModuleDataNew( profileId: $profileId) {
    id
    moduleName
    isMenu
    url
    rolesAndPermissions {
      profileId
      profileName
      permissions {
        id
        permissionName
        description
        status
      }
    }
  }
  }
`;