import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { AddVehicleFineDialog, AssignVehicleDialog, VehicleFineDialog, VehicleTasksDialog } from '../../assets.component';
import { MatDialog } from '@angular/material/dialog';
import { AssetService } from '../../../../features/assets/services/asset.service';
import { ToastrService } from 'ngx-toastr';
import { UnassignDialog } from '../../../../shared/components/confirm-dialog/unassign-vehicle';
import { AssetCurrentStatusEnum } from '../../../../helpers/helper-file';

@Component({
  selector: 'app-vehicle-management',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './vehicle-management.html',
  styleUrl: '../../assets.component.scss',
})
export class VehicleManagement implements OnInit, OnChanges {
  @Input() searchQuery: string = '';
  public rowData: any[] | null = null;
  userData: any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  readonly dialog = inject(MatDialog);


  constructor(
    private router: Router,
    private toastr: ToastrService,
    private assetsService: AssetService,
    private datePipe: DatePipe
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    this.userData = JSON.parse(loggedInUser);
  }

  ngOnInit(): void {
    this.fetchAllAssets();

    this.assetsService.dataUpdated$.subscribe((updated) => {
      if (updated) {
        this.fetchAllAssets(); // Reload data when notified
      }
    });
  }

  columnDefs = [
    { headerName: 'Vehicle', field: 'name', flex: 1 },
    { headerName: 'Number', field: 'number', flex: 1 },
    {
      headerName: 'RC Expiry Date',
      field: 'rcExpiryDate',
      flex: 1,
      cellStyle: (params: any) =>
        this.getExpiryDateStyle(params, 'rcExpiryDate'),
    },
    {
      headerName: 'Insurance Expiry Date',
      field: 'insuranceExpiryDate',
      flex: 1,
      cellStyle: (params: any) =>
        this.getExpiryDateStyle(params, 'insuranceExpiryDate'),
    },
    // { headerName: 'Category', field: 'category', flex: 1 },
    { headerName: 'Assignee', field: 'assignee', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === 'Assigned' ? 'red' : 'green'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const vehicleFine = document.createElement('img');
        vehicleFine.src = 'assets/images/icons/icon-add-fine.svg';
        vehicleFine.style.cursor = 'pointer';
        vehicleFine.setAttribute('data-action', 'vehicleFine');
        container.appendChild(vehicleFine);

        const listFine = document.createElement('img');
        listFine.src = 'assets/images/icons/icon-fine-history.svg';
        listFine.style.cursor = 'pointer';
        listFine.setAttribute('data-action', 'listFine');
        container.appendChild(listFine);

        if ( params?.data.status === "InUse") {
          const unassign = document.createElement('img');
          unassign.src = 'assets/images/icons/icon-unassign.svg';
          unassign.style.cursor = 'pointer';
          unassign.setAttribute('data-action', 'unassign');
          container.appendChild(unassign);
        }

        if (params?.data.status === AssetCurrentStatusEnum.Open) {
          const assign = document.createElement('img');
          assign.src = 'assets/images/icons/icon-assign.svg';
          assign.style.cursor = 'pointer';
          assign.setAttribute('data-action', 'assign');
          container.appendChild(assign);
        }


        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };

  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchQuery']) {
      const assetType ='vehicle';
      this.fetchAllAssets(this.searchQuery,assetType);
    }
    }

  fetchAllAssets(search: string = '', assetType = 'vehicle') {
    this.assetsService.getAllAssets(search, assetType).subscribe({
      next: (assets) => {
        this.rowData = assets.map((asset: any) => {
          return {
            name: asset?.assetName,
            number: asset?.serialNumber,
            id: asset?.id,
            type: asset?.assetType,
            status: asset?.assetCurrentStatus,
            assignee: asset?.assignedData?.firstName
              ? asset?.assignedData?.firstName
              : '' + asset?.assignedData?.middleName
              ? asset?.assignedData?.middleName
              : '' + asset?.assignedData?.lastName
              ? asset?.assignedData?.lastName
              : '',
            assigneeId: asset?.assignedData?.id,
            rcExpiryDate: this.datePipe.transform(asset?.rcExpiryDate),
            insuranceExpiryDate: this.datePipe.transform(asset?.insuranceExpiryDate ),
            assetVehicleTasks: asset?.assetVehicleTasks 
          };
        });
      },
    });
  }

  getExpiryDateStyle(params: any, dateField: string): { color: string } {
    const expiryDate = new Date(params.data[dateField]);
    const currentDate = new Date();
    const diffTime = expiryDate.getTime() - currentDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24)); // Convert to days

    if (diffDays <= 0) {
      return { color: 'red' };
    } else if (diffDays <= 10) {
      return { color: '#red' };
    } else if (diffDays <= 30) {
      return { color: '#DAA520' };
    }

    return { color: '' };
  }

  onCellClicked(params: any) {
    // if (  params.column.colId === 'action' && params.event.target.dataset.action ) {
    //     let action = params.event.target.dataset.action;
    //    if (action === 'assign') {
    //         this.dialog.open(AssignVehicleDialog);
    //    }
    // }
    //  else {
    //     this.dialog.open(AssignVehicleDialog);
    // }
    const assetName = params.data?.name;
    const vehicleNumber = params.data?.number;
    const status = params?.data?.satus;
    const id = params?.data?.id;
    if (params.column.colId === 'action' &&  params.event.target.dataset.action ) {
      let action = params.event.target.dataset.action;
      if (action === 'assign') {
        const assetVehicleTasks = params?.data?.assetVehicleTasks
        const dialogRef = this.dialog.open(AssignVehicleDialog, {
          data: { assetName, vehicleNumber, status, id , assetVehicleTasks },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.assetsService.notifyDataUpdated(true);
          }
        });
      }
      else if (action === 'unassign') {
        const dialogRef = this.dialog.open(UnassignDialog);
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            console.log('Unassign confirmed with notes:', result.notes);
            console.log( params?.data);
            
            const driverId = params?.data?.assigneeId;
            const assetId = params?.data?.id;
            const assetVehicleTasks = params?.data?.assetVehicleTasks;
            const idOfFirstElement = assetVehicleTasks[0]?.id;

            const actionType = 'unassign';


            console.log('unassigned',params?.data);
            
            const updateVehicletaskInput = {
              id:idOfFirstElement ,
              note:  result.notes,
              vehicletaskStatus: "Close"
            }
            const createVehicleLogInput = {
              vehicleId: params?.data?.id,
              driverId: driverId,
              action: 'In',
              date: new Date().toISOString().split('T')[0],
              time: new Date().toLocaleTimeString(),
            };
            this.assetsService
              .assignVehicle(assetId, actionType, createVehicleLogInput, null, updateVehicletaskInput)
              .subscribe({
                next: (data) => {
                  console.log(data);
                  this.assetsService.notifyDataUpdated(true);
                  this.toastr.success('Unassigned  asset.');
                },
                error: (err) => {
                  this.toastr.error('Something went wrong.');
                },
              });
          }
        });
      }
      else if (action === 'listFine'){
        const data = params?.data
        const dialogRef = this.dialog.open(VehicleFineDialog,{
          data: data
        });
      }
      else{
        const data = params?.data
        const dialogRef = this.dialog.open(AddVehicleFineDialog,{
          data: data
        });
      }
    }    
    else {
      const id = params?.data?.id;
      const dialogRef = this.dialog.open(VehicleTasksDialog,{
        data: id
      });
      
    }
  }
}
