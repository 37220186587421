import { Component } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectService } from '../../features/projects/services/projects.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-project-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './project-dashboard.component.html',
  styleUrl: './project-dashboard.component.scss'
})
export class ProjectDashboardComponent {

  tableArray = [1,2,3,4]
  overviewCounts: any;
  projectCosts: any;
  projectLists: any;
  totalPOCount: any;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
  ){}

  ngOnInit(){
    this.route.paramMap.subscribe((params) => {
      const projectId = params.get('id');
      if(projectId){
        this.getProjectOverviewCounts(projectId); 
        this.projectOverviewCost(projectId); 
        this.projectOverviewList(projectId); 
      }
    })
  }

  getProjectOverviewCounts(id: any){
    this.projectService.getProjectOverviewCounts(Number(id)).subscribe({
      next: (data) => {
        this.overviewCounts = data;
        this.totalPOCount =  
        (this.overviewCounts?.purchaseOrders || 0) + 
        (this.overviewCounts?.localPurchaseOrders || 0);
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }
  
  projectOverviewCost(id: any){
    this.projectService.projectOverviewCost(Number(id)).subscribe({
      next: (data) => {
        this.projectCosts = data;
        this.getProgressPercentage();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  getProgressPercentage(): number {
    if (this.projectCosts?.totalNetPrice && this.projectCosts?.project?.totalProjectCost) {
      const used = this.projectCosts.totalNetPrice;
      const allocated = this.projectCosts.project.totalProjectCost;
      return Math.min((used / allocated) * 100, 100); // Ensure it doesn't exceed 100%
    }
    return 0; // Default to 0% if values are missing
  }

  projectOverviewList(id: any){
    this.projectService.projectOverviewList(Number(id)).subscribe({
      next: (data) => {
        this.projectLists = data;
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}
