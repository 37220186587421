import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from 'apollo-angular/http';
import { NamedOptions } from 'apollo-angular';
import { environment } from '../../../environments/environment';
import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { split } from '@apollo/client/core';


const isTokenExpired = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('AUTH_TOKEN') ?? '';
  const currentBranchId = localStorage.getItem('selectedBranchId') ?? '';
  // console.log('currentBranchId',currentBranchId);
  
  

  if (token && isTokenExpired(token)) {
    localStorage.clear();
    sessionStorage.clear();

    const router = inject(Router);
    router.navigate(['/login']);
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      branchId: currentBranchId || '',
    },
  };
});

export const apolloOptions = (httpLink: HttpLink): NamedOptions => {
  const uploadLink = createUploadLink({
    uri: environment.url.defaultUrl,
  });

  // Create WebSocket link for subscriptions
  const wsLink = new GraphQLWsLink(
    createClient({
      // url: environment.url.websocketUrl,
      url: 'wss://shahid-4200.tl-workspace.techwarelab.com/graphql',
      on: {
        connected: () => console.log('WebSocket connected'),
        closed: () => console.log('WebSocket disconnected'),
        error: (err) => console.error('WebSocket error', err),
      },
      connectionParams: () => {
        const token = localStorage.getItem('AUTH_TOKEN') ?? '';
        const loginEmployee: any = localStorage.getItem('loggedInUser');
        const employee = JSON.parse(loginEmployee);
        const branchId = employee?.employeeData?.branchId;

        return {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
            branchId: branchId,
          },
        };
      },
    })
  );

  // Split based on operation type (query/mutation to HTTP, subscription to WebSocket)
  const link = split(
    ({ query }: { query: any }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink, // WebSocket link for subscriptions
    ApolloLink.from([authLink, uploadLink]) // HTTP link for queries and mutations
  );

  return {
    default: {
      cache: new InMemoryCache(),
      // link: ApolloLink.from([authLink, uploadLink]),
      link:link,
      defaultOptions: { query: { fetchPolicy: 'network-only' } },
    },
    hrms: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.hrmsModuleUrl,
        }),
      ]),
    },
    accounts: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.accountsUrl,
        }),
      ]),
    },
    project: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.projectModuleUrl,
        }),
      ]),
    },
    communication: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.communicationModuleUrl,
        }),
      ]),
    },
  };
};


// import { InMemoryCache } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import { HttpLink } from 'apollo-angular/http';
// import { NamedOptions } from 'apollo-angular';
// import { environment } from '../../../environments/environment';
// import { ApolloLink } from '@apollo/client';
// import { createUploadLink } from 'apollo-upload-client';
// import { Router } from '@angular/router';
// import { inject } from '@angular/core';
// import { WebSocketLink } from '@apollo/client/link/ws';  // Import the WebSocketLink
// import { getMainDefinition } from '@apollo/client/utilities';  // For split link logic

// const isTokenExpired = (token: string): boolean => {
//   try {
//     const payload = JSON.parse(atob(token.split('.')[1]));
//     const currentTime = Math.floor(Date.now() / 1000);
//     return payload.exp < currentTime;
//   } catch (error) {
//     return true;
//   }
// };

// export const authLink = setContext((_, { headers }) => {
//   const token = localStorage.getItem('AUTH_TOKEN') ?? '';
//   const loginEmployee: any = localStorage.getItem('loggedInUser');
//   const employee = JSON.parse(loginEmployee);
//   const branchId = employee?.employeeData?.branchId;

//   if (token && isTokenExpired(token)) {
//     localStorage.clear();
//     sessionStorage.clear();

//     const router = inject(Router);
//     router.navigate(['/login']);
//   }

//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//       branchId: branchId
//     },
//   };
// });

// // WebSocket link for subscriptions
// const wsLink = (uri: string) => new WebSocketLink({
//   uri,
//   options: {
//     reconnect: true,
//   },
// });

// export const apolloOptions = (httpLink: HttpLink): NamedOptions => {
//   const uploadLink = createUploadLink({
//     uri: environment.url.defaultUrl,
//   });

//   // WebSocket link URI for subscriptions
//   const wsUri = environment.url.wssUrl;  

//   return {
//     default: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([authLink, uploadLink]),
//       defaultOptions: { query: { fetchPolicy: 'network-only' } },
//     },
//     hrms: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.hrmsModuleUrl,
//         }),
//       ]),
//     },
//     accounts: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.accountsUrl,
//         }),
//       ]),
//     },
//     project: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.projectModuleUrl,
//         }),
//       ]),
//     },
//     communication: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.communicationModuleUrl,
//         }),
//       ]),
//     },
//   };
// };
