<mat-dialog-content>
  <div class="confrim-dialog-wrapper">
    <h2 class="cmn-popup-title">Confirm Unassign?</h2>
    <form [formGroup]="unAssign">
    <button class="cmn-close" mat-dialog-close></button>
    <p>Are you sure you want to unassign?</p>
    <div>
      <mat-form-field>
        <input matInput placeholder="Add notes" id="notes" formControlName="note" />
      </mat-form-field>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn"(click)=onUnAssign() >Yes</button>
    </div>
  </form>
  </div>
</mat-dialog-content>
