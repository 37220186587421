<section class="cmn-innerpage-wrapper new-chart-accounts">
    <h2 class="cmn-inner-heading">{{bankDetail ? 'Edit' : 'Add'}} Bank Account</h2>
    <form [formGroup]="addBankAccountForm">
        <label class="radio-label">Select Account Type <span class="red">*</span></label>
        <!-- Format Selection -->
        <div class="type-selection">
            <label class="radiobtn-wrapper">
                <input type="radio" name="bankType" value="Bank" formControlName="bankType" />
                <span class="radiomark"></span>
                Bank
            </label>
            <label class="radiobtn-wrapper">
                <input type="radio" name="bankType" value="Credit" formControlName="bankType" />
                <span class="radiomark"></span>
                Credit Card
            </label>
        </div>
        <div class="chart-account-form-wrapper">
            <div class="form-wrapper">
                <label for="">Account Name <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter name" formControlName="accountName" />
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                            addBankAccountForm.get('accountName')!.invalid &&
                            addBankAccountForm.get('accountName')!.touched
                            " class="error">Account name is required.</mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Account Code <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter code" formControlName="accountCode" readonly/>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                            addBankAccountForm.get('accountCode')!.invalid &&
                            addBankAccountForm.get('accountCode')!.touched
                            " class="error">Account code is required.</mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Currency <span class="red">*</span></label>
                <mat-form-field>
                    <mat-select placeholder="Select Currency" formControlName="currencyId">
                        <mat-option *ngFor="let currency of currencyData" [value]="currency.id">
                            {{ currency?.currencyCode }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                            addBankAccountForm.get('currencyId')!.invalid &&
                            addBankAccountForm.get('currencyId')!.touched
                            " class="error">Currency is required.</mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper" *ngIf="addBankAccountForm.get('bankType')?.value === 'Bank'">
                <label for="">Account Number</label>
                <mat-form-field>
                    <input matInput placeholder="Enter number" formControlName="accountNumber" />
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Bank Name</label>
                <mat-form-field>
                    <input matInput placeholder="Enter name" formControlName="bankName" />
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">IBAN Number <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter IBAN number" formControlName="routingNumber" />
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                            addBankAccountForm.get('routingNumber')!.invalid &&
                            addBankAccountForm.get('routingNumber')!.touched
                            " class="error">IBAN number is required.</mat-error>
                    </span>
                </div>
            </div>

            <div class="form-wrapper">
                <label for="">Swift Code <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter Swift Code" formControlName="swiftCode" />
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="
                            addBankAccountForm.get('swiftCode')!.invalid &&
                            addBankAccountForm.get('swiftCode')!.touched
                            " class="error">Swift code is required.</mat-error>
                    </span>
                </div>
            </div>
            <!-- <div class="form-wrapper" *ngIf="addBankAccountForm.get('accountType')?.value === 'Bank'">
                <label for="">BIC Code <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter BIC code" formControlName="BIC" />
                </mat-form-field>
            </div> -->
            <div class="form-wrapper">
                <label for="">Description</label>
                <mat-form-field>
                    <textarea matInput placeholder="Type something.." formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
            <button class="cmn-next-btn" (click)="onSave()">Save</button>
        </div>
    </form>
</section>