import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable, map, tap } from 'rxjs';
// import { selectPermissions } from '../permissions/permission.selector';
// import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  // hasInventoryPermission$: Observable<boolean>;
  constructor(private authService: AuthService, private router: Router, 
    // private store: Store,
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map(() => {
        const authToken = localStorage.getItem('AUTH_TOKEN');
        const isLoggedIn = !!authToken;

        if (isLoggedIn) {
          const loggedInUser: any = localStorage.getItem('loggedInUser');
          const userData = JSON.parse(loggedInUser);
          const profileName = userData?.profileData?.profileName;
          const allowedRoles = route.data['allowedRoles'] as Array<string>;
          
          if ((allowedRoles && !allowedRoles.includes(profileName))) {
            this.router.navigate(['/page-not-found']);
            return false;
          }
        }

        return isLoggedIn;
      }),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          localStorage.removeItem('AUTH_TOKEN');
          localStorage.removeItem('loggedInUser');
          sessionStorage.removeItem('AUTH_TOKEN');
          this.router.navigate(['/login']);
        }
      })
    );
  }
}