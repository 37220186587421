import { gql } from 'apollo-angular';


export const CREATE_PAYMENT = gql`
  mutation CreatePayment(
    $createPaymentInput: CreatePaymentInput!,
    $createPaymentAttachment: CreatePaymentAttachmentInput!,
    $invoiceIDAmountInput: [InvoiceIDAmountInput!],
    $billIDAmountInput: [BillIDAmountInput!]
  ) {
    createPayment(
      createPaymentInput: $createPaymentInput,
      createPaymentAttachment: $createPaymentAttachment,
      invoiceIDAmountInput: $invoiceIDAmountInput,
      billIDAmountInput: $billIDAmountInput
    ) {
      id
      partnerId
      paymentType
      amount
      bankCharge
      paymentDate
      paymentNo
      referenceNo
      paymentMode
      totalAmount
      receiptUrl
      note
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment(
    $updatePaymentInput: UpdatePaymentInput!
    $updatePaymentAttachment: UpdatePaymentAttachmentInput!
    $invoiceIDAmountInput: [InvoiceIDAmountInput!],
    $billIDAmountInput: [BillIDAmountInput!]
  ) {
    updatePayment(
      updatePaymentInput: $updatePaymentInput
      updatePaymentAttachment: $updatePaymentAttachment
      invoiceIDAmountInput: $invoiceIDAmountInput,
      billIDAmountInput: $billIDAmountInput
    ) {
      id
      partnerId
      paymentType
      amount
      bankCharge
      paymentDate
      paymentNo
      referenceNo
      paymentMode
      totalAmount
      receiptUrl
      note
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation createComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) {
      id
      employeeId
    }
  }
`;

export const SEND_PAYMENT_RECIEVED_MAIL = gql`
  mutation sendPaymentReceivedMail(
    $paymentId: Int!
    $to: [String!]!
    $cc: [String!]
    $subject: String!
    $body: String!
    $attachment: [Upload!]
    $isAttachment: Boolean
  ) {
    sendPaymentReceivedMail(
      paymentId: $paymentId
      to: $to
      cc: $cc
      subject: $subject
      body: $body
      attachment: $attachment
      isAttachment: $isAttachment
    )
  }
`;