<mat-dialog-content>
  <section class="upload-employees-wrapper">
    <div class="add-project-inventory">
      <form [formGroup]="shippingAddressForm" (ngSubmit)="onSubmitShippingAddress()">
        <div class="form-box">
          <button class="cmn-close" mat-dialog-close></button>
          <div class="form-sub-head">
            <h2 class="cmn-inner-heading">Shipping Address</h2>
            <div class="check-wrapper">
              <div class="check-box">
                <!-- <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    (change)="onCopyBillingAddress($event)"
                  />
                  Copy billing address
                  <span class="checkmark"></span>
                </label> -->
              </div>
            </div>
          </div>
          <div class="form-wrapper">
            <label for="">Attention</label>
            <mat-form-field>
              <input matInput placeholder="Attention" formControlName="attention" />
              <mat-error *ngIf="
              shippingAddressForm.get('attention')!.touched &&
                shippingAddressForm.get('attention')!.hasError('required')
              ">
                Attention is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Country/Region</label>
            <mat-form-field>
              <mat-select placeholder="Select country" formControlName="country">
                @for (country of countries; track country) {
                <mat-option [value]="country.name">{{
                  country.name
                  }}</mat-option>
                }
              </mat-select>
              <mat-error *ngIf="
              shippingAddressForm.get('country')!.touched &&
                shippingAddressForm.get('country')!.hasError('required')
              ">
                Country / region is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Address</label>
            <mat-form-field>
              <textarea matInput placeholder="Street 1" formControlName="addressLine1"></textarea>
              <mat-error *ngIf="
              shippingAddressForm.get('addressLine1')!.touched &&
                shippingAddressForm.get('addressLine1')!.hasError('required')
              ">
                Address is required.
              </mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="form-wrapper">
            <mat-form-field>
              <textarea
                matInput
                placeholder="Street 2"
                formControlName="addressLine2"
              ></textarea>
            </mat-form-field>
          </div> -->
          <div class="form-wrapper">
            <label for="">City</label>
            <mat-form-field>
              <input matInput placeholder="Enter city" formControlName="city" />
              <mat-error *ngIf="
              shippingAddressForm.get('city')!.touched &&
                shippingAddressForm.get('city')!.hasError('required')
              ">
                City is required.
              </mat-error>
              <mat-error *ngIf="
                  shippingAddressForm.get('city')!.touched &&
                  shippingAddressForm.get('city')!.hasError('maxlength')
                ">
                City name must be at most 15 characters long.
              </mat-error>

            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">State</label>
            <mat-form-field>
              <input matInput placeholder="Enter state" formControlName="state" />
              <mat-error *ngIf="
              shippingAddressForm.get('state')!.touched &&
                shippingAddressForm.get('state')!.hasError('required')
              ">
                State is required.
              </mat-error>
              <mat-error *ngIf="
                  shippingAddressForm.get('state')!.touched &&
                  shippingAddressForm.get('state')!.hasError('maxlength')
                ">
                State name must be at most 10 characters long.
              </mat-error>

            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Fax Number</label>
            <mat-form-field>
              <input matInput placeholder="Enter number" formControlName="fax" />
              <mat-error *ngIf="
              shippingAddressForm.get('fax')!.touched &&
                shippingAddressForm.get('fax')!.hasError('required')
              ">
                Fax number is required.
              </mat-error>
              <!-- Combined Error: Invalid fax number (only digits allowed) -->
              <mat-error *ngIf="
              shippingAddressForm.get('fax')!.touched &&
              shippingAddressForm.get('fax')!.hasError('pattern') &&
              !shippingAddressForm.get('fax')!.hasError('maxlength')
            ">
                Invalid fax number. Only digits are allowed.
              </mat-error>
              <mat-error *ngIf="
                  shippingAddressForm.get('fax')!.touched &&
                  shippingAddressForm.get('fax')!.hasError('maxlength')
                ">
                Fax number must be at most 15 characters long.
              </mat-error>
              <!-- <mat-error
                *ngIf="
                  shippingAddressForm.get('fax')!.touched &&
                  shippingAddressForm.get('fax')!.hasError('pattern')
                "
              >
                Invalid fax number. Only digits are allowed.
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Phone</label>
            <mat-form-field>
              <input matInput placeholder="Enter number"
                formControlName="phone" /><!-- Required Error - only if field is empty -->
              <!-- <mat-error
                *ngIf="
                  shippingAddressForm.get('phone')!.touched &&
                  shippingAddressForm.get('phone')!.hasError('required')
                "
              >
                Mobile number is required.
              </mat-error> -->

              <!-- Min Length Error - only if value is present but below minimum length -->
              <mat-error *ngIf="
                  shippingAddressForm.get('phone')!.touched &&
                  shippingAddressForm.get('phone')!.hasError('minlength')
                ">
                Mobile number must be at least 8 digits.
              </mat-error>

              <!-- Max Length Error - only if value is present but above maximum length -->
              <mat-error *ngIf="
                  shippingAddressForm.get('phone')!.touched &&
                  shippingAddressForm.get('phone')!.hasError('maxlength')
                ">
                Mobile number cannot exceed 15 digits.
              </mat-error>

              <!-- Pattern Error - only if value is present but invalid pattern -->
              <mat-error *ngIf="
                  shippingAddressForm.get('phone')!.touched &&
                  shippingAddressForm.get('phone')!.hasError('pattern')
                ">
                Invalid mobile number. Only digits are allowed.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="submit-btn-wrapper">
          <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
            Cancel
          </button>

          <button class="cmn-next-btn" type="submit" [disabled]="loading">
            <span *ngIf="!loading">Save</span>
            <span *ngIf="loading">Loading...</span>
          </button>
        </div>
      </form>
    </div>
  </section>
</mat-dialog-content>