<mat-dialog-content>
  <section class="invoice-apply-credits-wrapper">
    <h2 class="cmn-popup-title">Apply Credits to Invoice</h2>
    <button class="cmn-close" mat-dialog-close></button>

    <div class="form-main-content-wrapper">
      <div class="top-content-with-icon">
        <div>
          <div>
            <img src="../../../assets/images/cash.svg" alt="" />
          </div>
          <div>
            <h5>{{noteDetails?.invoiceNo}}</h5>
            <p>Invoice#</p>
          </div>
        </div>
        <div>
          <div>
            <img src="../../../assets/images/cah-gray.svg" alt="" />
          </div>
          <div>
            <h5>{{noteDetails?.subTotal}}</h5>
            <p>Total Invoice Amount</p>
          </div>
        </div>
      </div>

      <ul *ngFor="let invoice of allInvoices; let i = index">
        <li>
          <h6>Credit Note#</h6>
          <p>{{ invoice?.noteNo }}</p>
        </li>
        <li>
          <h6>Credit Note Date</h6>
          <p>{{ invoice?.noteDate | date: 'MM/dd/YYYY' }}</p>
        </li>
        <li>
          <h6>Invoice Amount</h6>
          <p>AED {{ invoice?.totalPrice }}</p>
        </li>
        <li>
          <h6>Invoice Balance</h6>
          <p>AED {{ invoice?.balance }}</p>
        </li>
        <li>
          <h6>Credits to Apply</h6>
          <div class="form-wrapper">
            <mat-form-field>
              <input
                matInput
                [formControl]="getCreditControl(i)"
                (input)="calculateAmountToCredit()"
                type="number"
                min="0"
                [max]="invoice.balance"
              />
              <mat-error
                *ngIf="getCreditControl(i).hasError('max') && getCreditControl(i).hasError('exceedsAvailableCredits')"
              >
                Cannot apply more than the available balance, and total applied
                credits exceed available credits.
              </mat-error>
              <mat-error
                *ngIf="getCreditControl(i).hasError('max') && !getCreditControl(i).hasError('exceedsAvailableCredits')"
              >
                Cannot apply more than the available balance.
              </mat-error>
              <mat-error
                *ngIf="!getCreditControl(i).hasError('max') && getCreditControl(i).hasError('exceedsAvailableCredits')"
              >
                Total applied credits exceed available credits.
              </mat-error>
            </mat-form-field>
          </div>
        </li>
      </ul>

      <div>
        <h6>Amount to Credit</h6>
        <p>: &nbsp;&nbsp; {{ totalAmountToCredit | currency: ' AED' }}</p>
      </div>
      <div>
        <h6>Invoice Balance Due</h6>
        <p>
          : &nbsp;&nbsp; {{ calculateInvoiceBalanceDue() | currency: ' AED' }}
        </p>
      </div>
    </div>

    <div class="btn-wrapper">
      <button class="cmn-cancel-btn">Cancel</button>
      <button class="cmn-next-btn" (click)="applyCreditsToInvoices()">
        Save
      </button>
    </div>
  </section>
</mat-dialog-content>
