
  <section class="cmn-innerpage-wrapper dashboard" *ngIf="user">
    <div class="head">
      <div class="left-set">
        <h2 class="cmn-inner-heading">Welcome back, {{user?.firstName}}!</h2>
        <p>Let's make today productive and successful.</p>
      </div>
      <div class="right-set" *ngIf="user?.profileData?.profileName !== 'admin' && user?.employeeData?.employeeStatus === 'Active'">
        <div class="punch-in-wrapper" [class.punch-active]="dashboardService.isPunchedOut">
          <h2>Total working hours</h2>
          <h3>{{ dashboardService.timeDifference || '00:00:00' }}</h3>
          <button (click)="dashboardService.isPunchedOut ? togglePunch() : onConfirmPunchOut()">
            {{ dashboardService.isPunchedOut ? 'Punch In' : 'Punch Out' }}
            <span><img src="assets/images/icons/icon-punch-in.svg" alt="Punching"></span>
          </button>
        </div>
      </div>
    </div>
  </section>


  <!-- <section class="project-dashboard-wrapper">
    <div class="dashboard-card-wrapper-grid">
      <div class="dash-card-wrapper dash-color-one">
        <div class="dash-card">
          <div class="arrow-icon">
            <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
          </div>
          <div class="head-wrapper">
            <div>
              <img
                src="../../../assets/images/icons/project-quotes-icon.svg"
                alt=""
              />
            </div>
            <h5>Quotes</h5>
          </div>
          <h6>120</h6>
        </div>
      </div>
      <div class="dash-card-wrapper dash-color-two">
        <div class="dash-card">
          <div class="arrow-icon">
            <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
          </div>
          <div class="head-wrapper">
            <div>
              <img
                src="../../../assets/images/icons/project-purchaseorder-icon.svg"
                alt=""
              />
            </div>
            <h5>Purchase Order</h5>
          </div>
          <h6>120</h6>
          <div class="add-btn">
            <button>
              add
              <span>
                <img
                  src="../../../assets/images/icons/white-add-icon.svg"
                  alt=""
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="dash-card-wrapper dash-color-three">
        <div class="dash-card">
          <div class="arrow-icon">
            <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
          </div>
          <div class="head-wrapper">
            <div>
              <img
                src="../../../assets/images/icons/project-invoice-icon.svg"
                alt=""
              />
            </div>
            <h5>Invoice</h5>
          </div>
          <h6>120</h6>
          <div class="add-btn">
            <button>
              add
              <span>
                <img
                  src="../../../assets/images/icons/white-add-icon.svg"
                  alt=""
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="dash-card-wrapper dash-color-four">
        <div class="dash-card">
          <div class="arrow-icon">
            <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
          </div>
          <div class="head-wrapper">
            <div>
              <img
                src="../../../assets/images/icons/project-workorder-icon.svg"
                alt=""
              />
            </div>
            <h5>Work Order</h5>
          </div>
          <h6>120</h6>
          <div class="add-btn">
            <button>
              add
              <span>
                <img
                  src="../../../assets/images/icons/white-add-icon.svg"
                  alt=""
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="dash-card-wrapper dash-color-five">
        <div class="dash-card">
          <div class="arrow-icon">
            <img src="../../../assets/images/icons/white-arrow-icon.svg" alt="" />
          </div>
          <div class="head-wrapper">
            <div>
              <img
                src="../../../assets/images/icons/project-grn-icon.svg"
                alt=""
              />
            </div>
            <h5>GRN</h5>
          </div>
          <h6>120</h6>
          <div class="add-btn">
            <button>
              add
              <span>
                <img
                  src="../../../assets/images/icons/white-add-icon.svg"
                  alt=""
                />
              </span>
            </button>
          </div>
        </div>
      </div>
  
      <div class="budget-allocation-wrapper">
        <h5>Budget Allocation</h5>
  
        <div class="allocation-indicators-wrapper">
          <span>Used</span>
          <span>Allocated</span>
        </div>
  
        <div class="progress-bar-wrapper">
          <div class="used-wrapper">
            <h6>AED <span>3000</span></h6>
          </div>
          <div class="allocated-wrapper">
            <h6>AED <span>4000</span></h6>
          </div>
          <div class="progress-bar">
            <div class="progress" style="width: 30%"></div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="table-grid-wrapper">
      <div class="table-wrapper" *ngFor="let item of tableArray" >
        <h4>Project Allocation</h4>
  
        <table>
          <thead>
            <tr>
              <th>type</th>
              <th>Desingation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Employee</td>
              <td>Designation</td>
            </tr>
            <tr>
              <td>Employee</td>
              <td>Designation</td>
            </tr>
            <tr>
              <td>Employee</td>
              <td>Designation</td>
            </tr>
            <tr>
              <td>Employee</td>
              <td>Designation</td>
            </tr>
          </tbody>
        </table>
      </div>
    
    </div>
  </section>
   -->