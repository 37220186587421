import { gql } from 'apollo-angular';

export const GET_ASSETS_FOR_EMPLOYEE = gql`
  query assetsForEmployee {
    assetsForEmployee {
      id
      assetName
      assetType
      serialNumber
      purchaseDate
      purchaseCost
      warrantyExpiryDate
      assetStatus
      condition
      branchId
      createdAt
      updatedAt
      deletedAt
      branch {
        id
        branchName
        address
        city
        state
        country
        zipCode
        phoneNumber
        branchCode
        isHeadOffice
        email
        createdAt
        updatedAt
        deletedAt
      }
      assignedTo
      assignedData {
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        isPortalUser
        departmentId
        designationId
        reportingManagerId
        branchId
      }
      vendorId
    }
  }
`;

export const FETCH_ALL_ASSETS = gql`
  query assets($search: String, $assetType: String) {
    assets(search: $search, assetType: $assetType) {
      id
      assetName
      assetType
      serialNumber
      purchaseDate
      purchaseCost
      assetCurrentStatus
      warrantyExpiryDate
      rcExpiryDate
      insuranceExpiryDate
      assetStatus
      condition
      branchId
      branch {
        branchName
      }
      createdAt
      updatedAt
      deletedAt
      assignedData {
        id
        firstName
        lastName
        middleName
      }
      assetVehicleTasks {
        id
        employeeId
        date
        task
        note
        status
        vehicletaskStatus
      }
    }
  }
`;


export const ASSETS_DROPDOWN = gql`
  query assetsDropDown($search: String) {
    assetsDropDown(search: $search) {
    id
    assetName
    assetType
    serialNumber
    }
  }
`;


export const ASSIGNEE_DROPDOWN = gql`
  query designations($search: String, $status: Int) {
    designations(search: $search, status: $status) {
      id
      designationName
      employees {
        id
        employeeCode
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        designationId
        email
        phoneNumber
        status
      }
      status
      createdAt
      updatedAt
      deletedAt
      isApprover
    }
  }
`;

export const ASSET_TRANSFER_LIST = gql`
  query assetTransfers($assetId: Int, $branchId: Int, $search: String) {
    assetTransfers(assetId: $assetId, branchId: $branchId, search: $search) {
      id
      assetId
      assetData {
        id
        assetName
        assetType
        serialNumber
        purchaseDate
        purchaseCost
        warrantyExpiryDate
        rcExpiryDate
        insuranceExpiryDate
        assetStatus
        condition
        branchId
        createdAt
        updatedAt
        deletedAt
      }
      fromBranch
      fromBranchData {
        id
        branchName
      }
      toBranch
      toBranchData {
        id
        branchName
        branchLogo
        address
        city
        state
        country
        zipCode
        phoneNumber
        branchCode
        licenseCode
        isHeadOffice
      }
      transferDate
      transferredBy
      transferredByData {
        id
        employeeCode
        firstName
        lastName
        middleName
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        nativeAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        passportIssueDate
        passportExpiryDate
        emiratesIDNo
        laborCardExpiryDate
        drivingLicenseNumber
        promotionDate
        previousDesignation
        warningLetterIssuedDate
        warningLetterReasons
        MOLNo
        WPSNo
        visaType
        visaNumber
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        SWIFTCode
        phoneStdCode
        nativeStdCode
        isPortalUser
        isApprover
        status
        departmentId
      }
    }
  }
`;

export const ASSET_DISPOSAL = gql`
  query assetDisposals($search : String,$assetId: Int) {
    assetDisposals(search: $search, assetId: $assetId) {
      id
      assetId
      assetData {
        id
        assetName
        assetType
        serialNumber
        purchaseDate
        purchaseCost
        warrantyExpiryDate
        assetStatus
        rcExpiryDate
        insuranceExpiryDate
      }
      disposalDate
      disposalMethod
      disposalReason
      disposedBy
      disposedByData {
        id
        employeeCode
        firstName
        lastName
        middleName
        dateOfBirth
        alternatePhoneNumber
        address
        permanentAddress
        nativeAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        passportIssueDate
        passportExpiryDate
        emiratesIDNo
        laborCardExpiryDate
        drivingLicenseNumber
        promotionDate
        previousDesignation
        warningLetterIssuedDate
        warningLetterReasons
        MOLNo
        WPSNo
        visaType
        visaNumber
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        SWIFTCode
        phoneStdCode
        nativeStdCode
        isPortalUser
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const VEHICLETASKS = gql`
  query vehicletasks($assetId: Int) {
    vehicletasks(assetId: $assetId) {
      id
      assetId
      assetData {
        assetName
        serialNumber
        assetStatus
        assetCurrentStatus
      }
      employeeData {
        firstName
      }
      date
      task
      note
      status
      vehicletaskStatus
    }
  }
`;

export const ASSET_LOGS = gql`
  query assetLogs($filter: AssetLogFilterInp) {
    assetLogs(filter: $filter) {
      id
      assetId
      asset {
        assetName
        assetCurrentStatus
      }
      logMessage
      actionByData {
        firstName
      }
      actionDate
      logType
    }
  }
`;


export const EMPLOYEE_DROPDOWN = gql`
  query employeesDropDown($search: String) {
    employeesDropDown(search: $search) {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      nativeAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      passportIssueDate
      passportExpiryDate
      emiratesIDNo
      laborCardExpiryDate
      drivingLicenseNumber
      promotionDate
      previousDesignation
      warningLetterIssuedDate
      warningLetterReasons
      MOLNo
      WPSNo
      visaType
      visaNumber
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      SWIFTCode
      phoneStdCode
      nativeStdCode
      isPortalUser
      isApprover
    }
  }
`;


export const VEHICLE_FINES = gql`
  query vehicleFines($vehicleId: Int, $search: String) {
    vehicleFines(vehicleId: $vehicleId, search: $search) {
      id
      vehicleId
      reason
      date
      amount
      employeeId
      employeeData {
        firstName
      }
      status
    }
  }
`;