import { Component, HostListener, inject, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ActivityLogService } from '../../features/activity-logs/services/activity-log.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-activity-log',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
  ],
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
})
export class ActivityLogComponent implements OnInit {

  public viewPortSize: boolean = false;
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  profileName: any;
  public rowData: any[] = [];
  // public columnDefs: ColDef[] = [];

  public paginationPageSize = 10;
  public themeClass : string = 'ag-theme-quartz';
  public search: string = '';
  readonly dialog = inject(MatDialog);

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

  constructor(
    private activityLogService: ActivityLogService,
    private datePipe: DatePipe,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.setAGColumn();
    this.fetchActivityLogs();
    this.checkViewportSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      { headerName: 'Action Type', field: 'ActionType', flex: 2 },
      { headerName: 'Description', field: 'Description', flex: 3 },
      { headerName: 'Employee', field: 'Employee', flex: 1 },
      { headerName: 'IP Address', field: 'IPAddress', flex: 2 },
      { headerName: 'Timestamp', field: 'Timestamp', flex: 2, 
        cellRenderer: (params:any) => {
          const date = new Date(params.value);
          return this.datePipe.transform(date, 'MMM d, y, h:mm a') || '--';
        },
      },
      {
        headerName: 'Response Time (ms)',
        field: 'AdditionalInfo.responseTime',
        flex: 1,
      },
      {
        headerName: 'Status Code',
        field: 'AdditionalInfo.statusCode',
        flex: 1,
      },
    ];
  }

  fetchActivityLogs() {
    this.activityLogService.fetchActivityLogs(this.search).subscribe({
      next: (data) => {        
        this.rowData = data.map((log:any) => ({
          id: log.id || '--',
          Employee: log.employeeData?.firstName || '--',
          ActionType: log.ActionType || '--',
          Description: log.Description.replace('GraphQL mutation on ', '') || '--',
          IPAddress: log.IPAddress || '--',
          Timestamp: log.Timestamp || '--',
          AdditionalInfo: {
            responseTime: log.AdditionalInfo?.responseTime ? `${log.AdditionalInfo.responseTime} ms` : '--',
            statusCode: log.AdditionalInfo?.statusCode || '--',
          },
        }));
      },
      error: (error) => {
        console.error('Error fetching activity logs:', error);
      },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchActivityLogs();
  }

  openDialog() {
    console.log('Open dialog clicked');
  }

  openDetailsDialog() {
    const dialogRef = this.dialog.open(ActivityDetailsDialog);
  }
  
}



@Component({
  selector: 'activity-details-dialog',
  templateUrl: './activity-details-dialog.html',
  styleUrls: ['./activity-log.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    CommonModule,
  ],
})
export class ActivityDetailsDialog {
  
}