<mat-dialog-content class="mat-typography">
    <section class="add-items-dialog-wrapper">
        <h2 class="cmn-popup-title">Add Items</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="itemGroup" (ngSubmit)="onSubmit()" (keydown)="preventEnterKey($event)">
            <div class="branch-form-wrapper">
                <div class="form-wrapper w-full">
                    <label for="">Item/Service <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Select or Type Item" [matAutocomplete]="auto"
                            formControlName="itemName" (input)="onItemSearch($event)"
                            [value]="itemGroup.get('itemName')?.value" />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectItem($event)">
                            <mat-option *ngFor="let item of filteredItemData" [value]="item.itemName">
                                {{ item.itemName }} <!-- Display item name in dropdown -->
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="itemGroup.get('itemName')?.errors?.['required']">
                            *Item Name is required.
                        </mat-error>
                    </mat-form-field>



                    <!-- <div class="custom-error">
                        <span>
                            <mat-error>Error Content</mat-error>
                        </span>
                    </div> -->
                </div>
                <div class="form-wrapper">
                    <label for="">Quantity <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Quantity" formControlName="quantity" type="number" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="itemGroup.get('quantity')?.hasError('required')">
                                Quantity is required.
                            </mat-error>
                        </span>
                    </div>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="itemGroup.get('quantity')?.hasError('pattern')">
                                Please enter a valid number with up to 3 decimal places.
                            </mat-error>
                        </span>
                    </div>
                    <div  class="custom-error">
                        <span>
                            <mat-error *ngIf="itemGroup.get('quantity')?.hasError('min')">
                                Quantity must be greater than 0./
                            </mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Unit <span class="red">*</span></label>
                    <mat-form-field>
                        <mat-select placeholder="Enter Unit" formControlName="unit">
                            <mat-option *ngFor="let unit of unitTypes" [value]="unit.value">
                                {{ unit.value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="itemGroup.get('unit')?.hasError('required')">
                            *Unit is required
                        </mat-error>
                    </mat-form-field>
                    <!-- <div class="custom-error">
                        <span>
                            <mat-error>Error Content</mat-error>
                        </span>
                    </div> -->
                </div>
                <div class="form-wrapper">
                    <label for="">Measurement </label>
                    <mat-form-field>
                        <input matInput placeholder="Enter measurement" formControlName="measurement"   />
                    </mat-form-field>
                    <!-- Custom error message -->
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="itemGroup.get('measurement')?.hasError('required')">
                                Gross Price is required.
                            </mat-error>
                        </span>
                    </div>
                    
                </div>
                <div class="form-wrapper">
                    <label for="">Rate <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Rate" formControlName="rate"  type="number"/>
                    </mat-form-field>

                    <!-- Custom error message -->
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="itemGroup.get('rate')?.hasError('required') && itemGroup.get('rate')?.touched">
                                Rate is required.
                              </mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="itemGroup.get('rate')?.hasError('pattern')">
                                Please enter a valid number with up to 2 decimal places.
                            </mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="itemGroup.get('rate')?.hasError('min') && itemGroup.get('rate')?.touched">
                                Rate must be greater than or equal to 1
                            </mat-error>
                        </span>
                    </div>
                </div>

                <div class="form-wrapper">
                    <label for="">Gross Price <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Gross Price" formControlName="subTotal" readonly  />
                    </mat-form-field>
                    <!-- Custom error message -->
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="itemGroup.get('subTotal')?.hasError('required')">
                                Gross Price is required.
                            </mat-error>
                        </span>
                    </div>
                    
                </div>
                <div class="form-wrapper">
                    <label for="">Discount</label>
                    <mat-form-field>
                      <input matInput placeholder="Enter Discount" maxlength="30" formControlName="discount" type="number" />
                    </mat-form-field>
                    
                    <!-- Custom error message -->
                    <div class="custom-error">
                      <span>
                        <mat-error *ngIf="itemGroup.get('discount')?.hasError('pattern')">
                          Please enter a valid number with up to 2 decimal places.
                        </mat-error>
                      </span>
                    </div>
                    <div class="custom-error">
                        <span>
                          <mat-error *ngIf="itemGroup.get('discount')?.hasError('discountTooHigh')">
                            *Discount cannot exceed the Gross Price.
                          </mat-error>
                        </span>
                      </div>
                      
                  </div>
                  
                <div class="form-wrapper">
                    <label for="">Net Price <span class="red">*</span> </label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Net Price" formControlName="netPrice" readonly/>
                    </mat-form-field>
                    <!-- <div class="custom-error">
                        <span>
                            <mat-error>Error Content</mat-error>
                        </span>
                    </div> -->
                </div>
                <div class="form-wrapper">
                    <label for="">VAT(5%) </label>
                    <mat-form-field>
                        <input matInput placeholder="Enter VAT(5%)" formControlName="vatAmount" />
                    </mat-form-field>
                    <!-- <div class="custom-error">
                        <span>
                            <mat-error>Error Content</mat-error>
                        </span>
                    </div> -->
                </div>
                <div class="form-wrapper">
                    <label for="">Amount <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Amount" formControlName="total" type="number" readonly/>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="itemGroup.get('total')?.errors?.['required']">
                                *Amount is required.
                            </mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper">
                    <div class="cmn-Search-select-container">
                        <label for="clientName">Subject </label>
                        <mat-form-field *ngIf="showFirstField">
                            <mat-select placeholder="Search Subject" formControlName="subject">
                                <mat-option *ngFor="let subject of subjects" [value]="subject">{{ subject
                                    }}</mat-option>
                                <!-- <mat-option>
                                    <ngx-mat-select-search></ngx-mat-select-search>
                                </mat-option> -->
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="!showFirstField">
                            <input matInput placeholder="Enter Subject" formControlName="subject" />
                            <button class="close-btn" mat-icon-button (click)="toggleField()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>

                        <button class="input-add-btn" *ngIf="showFirstField" (click)="toggleField()">
                            <div>
                                <img src="assets/images/icons/icon-add-color.svg" alt="Add Subject" />
                            </div>
                            <span>Add Subject</span>
                        </button>
                    </div>
                </div>


            </div>
            <div class="btn-wrapper">
                <!-- <button type="submit" class="cmn-popup-btn">
            {{branchData?.id ? 'Save':'Add Branch'}}
          </button> -->
                <button class="cmn-next-btn">
                    Add Items
                </button>
            </div>
        </form>
    </section>
</mat-dialog-content>