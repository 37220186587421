import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionState } from './permission.reducer';

export const selectPermissionState = createFeatureSelector<PermissionState>('permissions');

export const selectPermissions = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.permissions
);

export const selectMyProfile = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.myProfile
);

export const selectHeaderTitle = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.headerTitle
);

// export const hasPermission = (permission: any) =>{

//   console.log('hasPermission', permission);
  
//   createSelector(selectPermissions, (permissions: any[]) =>{
//     console.log('slectPermission',permissions);
    
//     return permissions.includes(permission)
//   }
//   );

export const hasPermission = (moduleId: string, permissionId: number) =>
  createSelector(selectPermissions, (permissions: any) => {
    console.log('Permissions object:', permissions);
    return permissions[moduleId]?.includes(permissionId) || false;
  }
);
// }
 
