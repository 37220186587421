<section class="cmn-innerpage-wrapper invoice-new">
  <h2 class="cmn-inner-heading">Update Work Order</h2>
  <form [formGroup]="workOrderForm">
    <div class="invoice-new-form-wrapper">
      <div class="form-wrapper invoice-form-wrapper">
        <!-- Work Order No -->
        <div>
          <label for="reference"
            >Work Order No </label
          >
          <mat-form-field>
            <input
              matInput
              placeholder="Work Order No"
              id="workorder"
              formControlName="workOrderNo"
              readonly
            />
            <mat-error
              *ngIf="workOrderForm.get('workorder')?.hasError('required')"
            >
              Work Order No is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Client Name Section -->
        <div class="cmn-Search-select-container">
          <label for="clientName">Client Name </label>
          <mat-form-field>
            <mat-select
              placeholder="Search Client"
              id="clientName"
              formControlName="clientId"
              (selectionChange)="onCustomerSelected($event)"
              [disabled]="isDisabled"
            >
              <mat-option>
                <ngx-mat-select-search
                  (input)="onSearch($event)"
                  placeholderLabel="Search"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let customer of customerList"
                [value]="customer.id"
              >
                {{ customer?.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="workOrderForm.get('clientId')?.hasError('required')"
            >
              Client Name No is required
            </mat-error>
          </mat-form-field>
          <!-- <button type="button" (click)="openCreateClientDialog()">
            <div>
              <img
                src="../../../assets/images/icons/icon-add-color.svg"
                alt="Add client icon"
              />
            </div>
            <span>Add client</span>
          </button> -->
        </div>

        <!-- Project Name Section -->

        <div class="cmn-Search-select-container">
          <label for="projectId">Project Name </label>
          <mat-form-field>
            <mat-select
              placeholder="Select project"
              id="projectId"
              formControlName="projectId"
              [disabled]="true"
            >
              <mat-option>
                <ngx-mat-select-search
                  (input)="onSearchProject($event)"
                  placeholderLabel="Search"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let project of allProjects"
                [value]="project.id"
              >
                {{ project?.projectName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="workOrderForm.get('projectId')?.hasError('required')"
            >
              Project Name No is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Primary Contact Person -->

        <div>
          <label for="reference"
            >Primary Contact Person </label
          >
          <mat-form-field>
            <input
              matInput
              placeholder="Primary Contact Person"
              id="primarycontactperson"
              formControlName="pContactNo"
              [readonly]="true"
            />
            <mat-error
              *ngIf="workOrderForm.get('pContactNo')?.hasError('required')"
            >
              Primary Contact Person No is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- SOW -->

        <div>
          <label for="orderNumber">SOW </label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter SOW"
              id="sow"
              formControlName="sow"
              [readonly]="true"
            />
            <mat-error *ngIf="workOrderForm.get('sow')?.hasError('required')">
              SOW is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- AMD Quote Ref Section -->

        <div>
          <label for="amd">Quote Ref </label>
          <mat-form-field>
            <input
              matInput
              placeholder="Quote Ref"
              id="amd"
              formControlName="quoteRef"
              [readonly]="true"
            />
            <mat-error
              *ngIf="workOrderForm.get('quoteRef')?.hasError('required')"
            >
              Quote Ref is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Client Conf. Ref. Section -->
        <div>
          <label for="amd">Client Conf. Ref. </label>
          <mat-form-field>
            <input
              matInput
              placeholder="Client Conf. Ref."
              id="client"
              formControlName="clientConfRef"
            />
            <mat-error
              *ngIf="workOrderForm.get('clientConfRef')?.hasError('required')"
            >
              Client Conf. Ref. is required
            </mat-error>
            <mat-error
              *ngIf="
                workOrderForm
                  .get('clientConfRef')
                  ?.hasError('noHtmlSpecialChars')
              "
            >
              HTML special characters like &lt;, &gt;, &, ", and ' are not
              allowed
            </mat-error>
            <mat-error
              *ngIf="workOrderForm.get('clientConfRef')?.hasError('spaceError')"
            >
              Cannot start or end with a space.
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Duration Section -->
        <div>
          <label for="amd">Duration </label>
          <mat-form-field>
            <input
              matInput
              placeholder="Duration"
              id="duration"
              formControlName="duration"
            />
            <mat-error
              *ngIf="workOrderForm.get('duration')?.hasError('required')"
            >
              Duration is required
            </mat-error>
            <mat-error
              *ngIf="
                workOrderForm.get('duration')?.hasError('noHtmlSpecialChars')
              "
            >
              HTML special characters like &lt;, &gt;, &, ", and ' are not
              allowed
            </mat-error>
            <mat-error
              *ngIf="workOrderForm.get('duration')?.hasError('spaceError')"
            >
              Cannot start or end with a space.
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Commencement Date Section -->
        <div>
          <label for="deliveryDate"
            >Commencement Date </label
          >
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker2"
              placeholder="Select Date"
              id="Commencement"
              formControlName="commencementDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error
              *ngIf="
                workOrderForm.get('commencementDate')?.hasError('required')
              "
            >
              Commencement Date is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Start Date Section -->
        <div>
          <label for="deliveryDate"
            >Start Date </label
          >
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker3"
              placeholder="Select Date"
              id="start"
              formControlName="startDate"
              [matDatepickerFilter]="dateFilter"
              (dateChange)="handleStartDateChange($event.value)"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker3"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            <mat-error
              *ngIf="workOrderForm.get('startDate')?.hasError('required')"
            >
              Start Date is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Completion Date Section -->
        <div>
          <label for="deliveryDate"
            >Completion Date </label
          >
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker4"
              placeholder="Select Date"
              id="completion"
              formControlName="completionDate"
              [matDatepickerFilter]="completionDateFilter"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker4"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
            <mat-error
              *ngIf="workOrderForm.get('completionDate')?.hasError('required')"
            >
              Completion Date is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Project Manager Section -->
        <!-- <div>
          <label for="paymentTerm"
            >Project Manager</label
          >
          <mat-form-field>
            <mat-select
              placeholder="Project Manager"
              id="incharge"
              formControlName="projectManager"
            >
              <mat-option
                *ngFor="let employee of employeeData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="workOrderForm.get('projectManager')?.hasError('required')"
            >
              Project Manager is required
            </mat-error>
          </mat-form-field>
        </div> -->
        <div class="cmn-Search-select-container">
          <label for="clientName"
            >Project Manager </label
          >
          <mat-form-field>
            <mat-select
              placeholder="Search Manager"
              formControlName="projectManager"
              id="incharge"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Search'"
                  [noEntriesFoundLabel]="'No options found'"
                  (input)="onProjectManagerSearch($event)"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let employee of projectManagerData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  workOrderForm.get('projectManager')!.touched &&
                  workOrderForm.get('projectManager')?.hasError('required')
                "
              >
                Project Manager is required
              </mat-error>
            </span>
          </div>
        </div>

        <!-- Project Engineer Section -->
        <!-- <div>
          <label for="paymentTerm"
            >Project Engineer </label
          >
          <mat-form-field>
            <mat-select
              placeholder="Project Engineer"
              id="incharge"
              formControlName="projectEngineer"
            >
              <mat-option
                *ngFor="let employee of employeeData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="workOrderForm.get('projectEngineer')?.hasError('required')"
            >
              Project Engineer is required
            </mat-error>
          </mat-form-field>
        </div> -->
        <div class="cmn-Search-select-container">
          <label for="clientName"
            >Project Engineer </label
          >
          <mat-form-field>
            <mat-select
              placeholder="Search Manager"
              formControlName="projectEngineer"
              id="incharge"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Search'"
                  [noEntriesFoundLabel]="'No options found'"
                  (input)="onProjectEngineerSearch($event)"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let employee of projectEngineerData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  workOrderForm.get('projectEngineer')!.touched &&
                  workOrderForm.get('projectEngineer')?.hasError('required')
                "
              >
                Project Engineer is required
              </mat-error>
            </span>
          </div>
        </div>
        <!-- Project Designer Section -->
        <!-- <div>
          <label for="paymentTerm"
            >Project Designer </label
          >
          <mat-form-field>
            <mat-select
              placeholder="Project Designer"
              id="designer"
              formControlName="projectDesigner"
            >
              <mat-option
                *ngFor="let employee of employeeData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="workOrderForm.get('projectDesigner')?.hasError('required')"
            >
              Project Designer is required
            </mat-error>
          </mat-form-field>
        </div> -->
        <div class="cmn-Search-select-container">
          <label for="clientName"
            >Project Designer </label
          >
          <mat-form-field>
            <mat-select
              placeholder="Search Manager"
              formControlName="projectDesigner"
              id="incharge"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Search'"
                  [noEntriesFoundLabel]="'No options found'"
                  (input)="onProjectDesignerSearch($event)"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let employee of projectdesignerData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  workOrderForm.get('projectDesigner')!.touched &&
                  workOrderForm.get('projectDesigner')?.hasError('required')
                "
              >
                Project Designer is required
              </mat-error>
            </span>
          </div>
        </div>

        <!-- Procurement Incharge Section -->
        <!-- <div>
          <label for="paymentTerm"
            >Procurement Incharge </label
          >
          <mat-form-field>
            <mat-select
              placeholder="Procurement Incharge"
              id="procurment"
              formControlName="procurementIncharge"
            >
              <mat-option
                *ngFor="let employee of employeeData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                workOrderForm.get('procurementIncharge')?.hasError('required')
              "
            >
              Procurement Incharge is required
            </mat-error>
          </mat-form-field>
        </div> -->  
        <div class="cmn-Search-select-container">
          <label for="clientName"
            >Procurement Incharge</label
          >
          <mat-form-field>
            <mat-select
              placeholder="Search Manager"
              formControlName="procurementIncharge"
              id="incharge"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Search'"
                  [noEntriesFoundLabel]="'No options found'"
                  (input)="onProjectProcurementSearch($event)"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let employee of projectProcurementData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  workOrderForm.get('procurementIncharge')!.touched &&
                  workOrderForm.get('procurementIncharge')?.hasError('required')
                "
              >
                Procurement Incharge is required
              </mat-error>
            </span>
          </div>
        </div>

        <!-- Cost Estimation Section -->
        <!-- <div>
          <label for="amd">Cost Estimater </label>
          <mat-form-field>
            <mat-select
              placeholder="Procurement Incharge"
              id="procurment"
              formControlName="costEstimater"
            >
              <mat-option
                *ngFor="let employee of employeeData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="workOrderForm.get('costEstimater')?.hasError('required')"
            >
              Cost Estimater is required
            </mat-error>
          </mat-form-field>
        </div> -->
        <div class="cmn-Search-select-container">
          <label for="clientName"
            >Cost Estimater </label
          >
          <mat-form-field>
            <mat-select
              placeholder="Search Manager"
              formControlName="costEstimater"
              id="incharge"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Search'"
                  [noEntriesFoundLabel]="'No options found'"
                  (input)="onProjectEstimaterSearch($event)"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let employee of projectEstimaterData"
                [value]="employee.id"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  workOrderForm.get('costEstimater')!.touched &&
                  workOrderForm.get('costEstimater')?.hasError('required')
                "
              >
                Cost Estimater is required
              </mat-error>
            </span>
          </div>
        </div>

        <!-- Budget Section -->
        <!-- <div>
          <label for="amd">Budget</label>
          <mat-form-field>
            <input matInput placeholder="Budget" id="budget" />
          </mat-form-field>
        </div> -->
      </div>
    </div>

    <div
      class="billing-shipping-address-wrapper"
      *ngIf="customerAddress?.addresses?.length"
    >
      <div>
        <div class="header-wrapper">
          <h5>Address</h5>
          <!-- <div>
            <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
          </div> -->
        </div>
        <div>
          <h6>{{ customerAddress?.name }}</h6>
          <ul>
            <li>{{ customerAddress?.addresses[0]?.addressLine1 }}</li>
            <!-- <li>{{ customerAddress?.addresses[0]?.addressLine2 }}</li> -->
            <li>{{ customerAddress?.addresses[0]?.city }}</li>
            <li>{{ customerAddress?.addresses[0]?.country }}</li>
            <li>Phone : {{ customerAddress?.addresses[0]?.phone }}</li>
          </ul>
          <button type="button" (click)="openBillingAddressDialog('add')">
            <span>Add Address</span>
          </button>
        </div>
      </div>
      <!-- <div>
        <div class="header-wrapper">
          <h5>Shipping Address</h5>
          <div>
            <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
          </div>
        </div>
        <div>
          <h6>Mohammed Al Quasimi</h6>
          <ul>
            <li>Vila 42,Al Wasl Road</li>
            <li>Jumeirah 1</li>
            <li>P.O. Box 12345</li>
            <li>United Arab Emirates</li>
            <li>Phone : +971 9654 6954</li>
          </ul>
        </div>
      </div> -->
    </div>

    <!-- Items/Services Section -->
    <!-- <div class="item-service-adding-table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Item/Service</th>
            <th>Unit</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="form-wrapper">
                <div>
                  <mat-form-field>
                    <mat-select placeholder="Select Item">
                      <mat-option value="item1">Item 1</mat-option>
                      <mat-option value="item2">Item 2</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <textarea
                      matInput
                      placeholder="Enter item description"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <mat-select placeholder="Sq.ft">
                    <mat-option value="Sq">Sq</mat-option>
                    <mat-option value="Kg">Kg</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="quantity" />
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="rate" />
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <div class="split-parent">
                  <div class="left-set">
                    <mat-form-field>
                      <input matInput placeholder="3.67" />
                    </mat-form-field>
                  </div>
                  <div class="right-set">
                    <mat-form-field>
                      <mat-select placeholder="%">
                        <mat-option value="inr">%</mat-option>
                        <mat-option value="usd">&</mat-option>
                        <mat-option value="eur">$</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="100" />
                </mat-form-field>
              </div>
            </td>
            <td>
              <img
                src="../../../assets/images/icons/icon-add-color.svg"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->

    <div
      class="quotation-table-container"
      *ngIf="workOrderData?.workOrderSOWs?.length"
    >
      <div>
        <table>
          <thead>
            <tr>
              <th>Sl</th>
              <th>Description</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Gross</th>
              <th>Discount</th>
              <th>Net</th>
              <th>VAT</th>
              <th>Total</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let item of workOrderSow; let i = index"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ item?.order }}</td>
              <td>{{ item?.unit }}</td>
              <td>{{ item?.quantity }}</td>
              <td><sub>AED </sub>{{ item?.rate }}</td>
              <td><sub>AED </sub>{{ item?.rate * item?.quantity }}</td>
              <td><sub>AED </sub>{{ item?.discount }}</td>
              <td><sub>AED </sub>{{ item?.subTotal }}</td>
              <td><sub>AED </sub>{{ item?.tax }}</td>
              <td><sub>AED </sub>{{ item.totalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Total Amount Section -->
    <div class="notes-tatal-amount-container">
      <div class="form-container">
        <!-- <div class="form-wrapper">
          <label for="customerNotes">Customer Notes</label>
          <mat-form-field>
            <textarea
              matInput
              placeholder="Enter your notes here..."
              formControlName="customerNotes"
            ></textarea>
          </mat-form-field>
        </div> -->
        <!-- <div class="form-wrapper">
          <label for="termsConditions">Terms & Conditions</label>
          <mat-form-field>
            <textarea
              matInput
              placeholder="Enter the terms & conditions"
              formControlName="termsAndConditions"
            ></textarea>
          </mat-form-field>
        </div> -->
        <!-- <div class="checkbox-container">
          <label>
            <input type="checkbox" />
            <span>I have received the payment</span>
          </label>
        </div> -->
      </div>
      <div class="amount-table-div">
        <div>
          <h6>
            Total Amount<span>: AED {{ totalPrice | number : "1.2-2" }}</span>
          </h6>
          <h6>
            Discount (-)<span
              >: AED {{ totalDiscountAmount | number : "1.2-2" }}</span>
          </h6>
          <h6>
            VAT (5%) <span>: AED {{   totalTaxAmount | number : "1.2-2" }}</span>
          </h6>
        </div>
        <div>
          <h4>
            Net Amount(AED)
            <span>: AED {{ workOrderData?.totalAmount | number : "1.2-2" }}</span>
          </h4>
        </div>
      </div>
    </div>

    <!-- Email Section -->
    <!-- <div class="payment-mode-wrapper">
      <div class="add-email-wrapper">
        <h6>Email Connection</h6>
        <div class="flex-wrapper-container">
          <div class="email-account-div">
            <div class="profile-image">
              <img
                src="../../../assets/images/profile.png"
                alt="Profile image"
              />
            </div>
            <span>example&#64;mail.com</span>
            <div class="close-btn">
              <img
                src="../../../assets/images/icons/icon-close.svg"
                alt="Remove email icon"
              />
            </div>
          </div>
          <button type="button">
            <div>
              <img
                src="../../../assets/images/icons/icon-add-color.svg"
                alt="Add email icon"
              />
            </div>
            <span>Add email</span>
          </button>
        </div>
      </div>
    </div> -->
    <div class="file-uploads-type-wrapper">
      <h2>Attachment</h2>
      <div class="cmn-upload-box">
        <div class="form-wrapper">
          <mat-form-field>
            <mat-select
              placeholder="Select Type"
              (selectionChange)="onSelectType($event)"
              formControlName="fileType"
            >
              <mat-option value="QUOTATION">Quotation</mat-option>
              <mat-option value="BOQ">BQO</mat-option>
              <mat-option value="LPO">LPO</mat-option>
              <mat-option value="DRAWING">Drawing</mat-option>
            </mat-select>
            <!-- <mat-error
              *ngIf="workOrderForm.get('fileType')?.hasError('required')"
            >
              Type is required
            </mat-error> -->
          </mat-form-field>
        </div>
        <!-- link -->
        <div
          class="form-wrapper"
          *ngIf="attachmentType && attachmentType == 'DRAWING'"
        >
          <label>Link </label>
          <mat-form-field>
            <input
              matInput
              placeholder="Pase Link Here"
              formControlName="fileUrl"
            />
            <mat-error
              *ngIf="workOrderForm.get('fileUrl')?.hasError('required')"
            >
              Link is required
            </mat-error>
          </mat-form-field>
        </div>
        <!-- link -->

        <!-- upload -->
        <div *ngIf="attachmentType && attachmentType != 'DRAWING'">
          <div class="upload-wrapper">
            <input
              multiple
              #fileInput
              type="file"
              style="display: none"
              (change)="onFileSelected($event, fileInput)"
            />
            <button
              class="cmn-choose-file-btn"
              (click)="fileInput.click()"
              type="button"
            >
              <img
                src="assets/images/icons/icon-add-color.svg"
                alt="Icon Add"
              />Choose a File
            </button>
          </div>
          <div
            class="uploaded-file"
            *ngFor="let uploadedFile of fileDetailsArray"
          >
            <h6>
              <span (click)="openFile(uploadedFile?.fileUrl)">{{
                uploadedFile?.fileName
              }}</span>
              <img
                src="assets/images/icons/icon-close.svg"
                alt="Close Icon"
                (click)="handleRemoveFile()"
              />
            </h6>
          </div>
        </div>
        <!-- upload -->
      </div>
    </div>

    <!-- Submit Section -->
    <div class="submit-btn-wrapper">
      <button
        class="cmn-cancel-btn"
        type="button"
        (click)="onNavigate('/work-order')"
      >
        Cancel
      </button>
      <button
        class="cmn-next-btn"
        type="button"
        (click)="handleDraft()"
        [disabled]="loading"
      >
        {{ loading ? "Loading.." : "Save" }}
      </button>
      <!-- <button
        class="cmn-next-btn"
        type="button"
        (click)="handleSaveAndSent()"
        [disabled]="loading"
      >
        {{ loading ? "Loading.." : "Save and Sent" }}
      </button> -->
    </div>
  </form>
</section>
