<mat-dialog-content class="mat-typography">
  <section class="add-currency-dialog-wrapper">
    <h2 class="cmn-popup-title">
     Update configuration
    </h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form [formGroup]="updateConfigure" (ngSubmit)="onSubmit()">
      <div class="currency-form-wrapper">
        <div class="form-wrapper">
          <label for="">Module Name</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Module Name"
              formControlName="category"
              readonly
            />
            <mat-error
              *ngIf="
              updateConfigure.get('category')!.touched &&
              updateConfigure.get('category')!.hasError('required')
              "
              >Module Name is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Prefix</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter prefix"
              formControlName="prefix"
            />
            <mat-error
              *ngIf="
              updateConfigure.get('prefix')!.touched &&
              updateConfigure.get('prefix')!.hasError('required')
              "
              >Prefix is required.</mat-error
            >
            <mat-error
              *ngIf="
              updateConfigure.get('prefix')!.touched &&
              updateConfigure.get('prefix')!.hasError('spaceError')
              "
              >Cannot start or end with a space.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="cmn-popup-btn" type="submit">
          Update
        </button>
      </div>
    </form>
  </section>
</mat-dialog-content>
