import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { QuillModule } from 'ngx-quill';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Location } from '@angular/common';
import { MAIL_ALLOWED_FILE_TYPES } from '../../helpers/helper-file';
import { SendToMailService } from '../../features/send-to-mail/services/send-to-mail.service';
import { QuotationService } from '../../features/quotation/services/quotation.service';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { ImageService } from '../../shared/services/image.service';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-send-mail',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    QuillModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './send-to-mail.component.html',
  styleUrl: './send-to-mail.component.scss',
})
export class SendToMailComponent implements OnInit {
  ID: number;
  noteId: number;
  invoiceId: number;
  quoteId: number;
  purchaseId: number;
  workOrderId: number;
  creditNoteId: number;
  debitNoteId: number;
  parentQuoteId: number;
  receivedId: number;
  madeId: number;
  public loading: boolean = true;
  invoiceTemplateControl = new FormControl();
  emailForm: FormGroup;
  getEmailData: any = null;
  pdfResponse: any;
  documentFile: File[] = [];
  documentFileNames: string[] = [];
  allowedFileTypes: string[] = MAIL_ALLOWED_FILE_TYPES;
  isAttachInvoice: boolean = true;
  workOrderPdfBlob: string;
  emailSuggestions: any[] = [];
  emailSuggestionsTo: any[] = [];
  emailSuggestionsCC: any[] = [];
  isLoading: boolean = false;

  constructor(
    private itemService: PaymentsItemService,
    private noteService: CreditDebitNoteService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private sendMailService: SendToMailService,
    private router: Router,
    private location: Location,
    private imageService: ImageService,
    private dialog: MatDialog
  ) {
    this.emailForm = this.fb.group({
      toEmailControl: ['', [Validators.required, Validators.email]],
      ccEmailControl: ['', Validators.email],
      bccEmailControl: ['', Validators.email],
      subject: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      console.log('params---', params);
      const pdfResponse = params['pdfResponse']
        ? JSON.parse(decodeURIComponent(params['pdfResponse']))
        : null;
      if (pdfResponse) {
        try {
          this.workOrderPdfBlob = pdfResponse;
        } catch (error) {
          console.log('No pdf found', error);
        }
      }

      //payment recieved/made
      const id = params['paymentID'];
      const invoiceOrBill_ids = params['ids'];
      if (id && invoiceOrBill_ids) {
        this.ID = +id;
        this.getPaymentDetails(invoiceOrBill_ids?.map((item: string) => +item));
      }

      //payment recieved
      const payementReceivedId = params['paymentReceivedId'];
      this.receivedId = +payementReceivedId;
      const payementIds = params['ids'];
      if (payementReceivedId && payementIds) {
        this.ID = +payementReceivedId;
        this.getPaymentDetails(payementIds?.map((item: string) => +item));
      }

      //payment made
      const payementMadeId = params['paymentMadeId'];
      this.madeId = +payementMadeId;
      const paymentIds = params['ids'];
      if (payementMadeId && paymentIds) {
        this.ID = +payementMadeId;
        this.getPaymentDetails(paymentIds?.map((item: string) => +item));
      }
      // Credit/Debit Note Service
      const noteId = params['noteId'];
      if (noteId) {
        this.noteId = +noteId;
        this.getNoteDetails(+noteId);
      }

      // Credit Note Service
      this.creditNoteId = params['creditNoteId'];
      if (this.creditNoteId) {
        this.noteId = +this.creditNoteId;
        this.getNoteDetails(this.noteId);
      }

      // Debit Note Service
      this.debitNoteId = params['debitNoteId'];
      if (this.debitNoteId) {
        this.noteId = +this.debitNoteId;
        this.getNoteDetails(this.noteId);
      }

      //invoice
      const invoiceId = params['invoiceId'];
      if (invoiceId) {
        this.invoiceId = +invoiceId;
        this.getInvoiceDetails(+invoiceId);
      }

      // //quotation
      // const quoteId = params['quoteId'];
      // if (quoteId) {
      //   this.quoteId = +quoteId;
      //   this.getQuoteDetails(+this.quoteId)
      // }

      // //purchase order and local purchase order
      // const purchaseId = params['id'];
      // if (purchaseId) {
      //   this.purchaseId = +purchaseId;
      //   this.getPurchaseDetails(+this.purchaseId);
      // }

      //quotation and purchase order 
      const quoteId = params['quoteId'];
      const purchaseId = params['id'];

      // If both 'id' and 'quoteId' exist, prioritize based on your logic
      if (quoteId && !isNaN(+quoteId)) {
        this.quoteId = +quoteId;
        this.getQuoteDetails(this.quoteId); // Call getQuoteDetails
      } else if (purchaseId && !isNaN(+purchaseId)) {
        this.purchaseId = +purchaseId;
        this.getPurchaseDetails(this.purchaseId); // Call getPurchaseDetails
      } else if (purchaseId || quoteId) {
        console.error('Invalid query parameters: id or quoteId is not a valid number');
      }


      //work order and local purchase order
      const workOrderId = params['workOrderId'];
      if (workOrderId) {
        this.workOrderId = +workOrderId;
        this.getWorkOrderDetails(+this.workOrderId);
      }
    });

    // Setup value changes for email search
    this.emailForm
      .get('toEmailControl')
      ?.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        switchMap((email) => {
          // Treat null as an empty string
          const query = email ? email.trim() : '';
          if (query === '') {
            // Clear suggestions if the input is empty
            this.emailSuggestionsTo = [];
            return []; // Return an empty observable
          }
          return this.sendMailService.searchUserEmails(query);
        })
      )
      .subscribe({
        next: (emails) => {
          this.emailSuggestionsTo = emails || [];
          this.isLoading = false;
        },
        error: (err) => {
          console.error('Error fetching email suggestions:', err);
          this.isLoading = false;
        },
      });

    // Setup value changes for 'Cc' email field
    this.emailForm
      .get('ccEmailControl')
      ?.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        switchMap((email) => {
          const query = email ? email.trim() : '';
          if (query === '') {
            this.emailSuggestionsCC = [];
            return []; // Return an empty observable
          }
          return this.sendMailService.searchUserEmails(query);
        })
      )
      .subscribe({
        next: (emails) => {
          this.emailSuggestionsCC = emails || [];
          this.isLoading = false;
        },
        error: (err) => {
          console.error('Error fetching email suggestions:', err);
          this.isLoading = false;
        },
      });
  }

  toEmails: string[] = [];
  ccEmails: string[] = [];
  bccEmails: string[] = [];

  toEmailControl = new FormControl('');
  ccEmailControl = new FormControl('');
  bccEmailControl = new FormControl('');

  showBcc = false;

  toggleInvoice(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isAttachInvoice = input.checked;
  }

  private getPaymentDetails(invoiceValue: any): void {
    if (this.ID !== null) {
      this.itemService.getPaymentDetails(this.ID, invoiceValue).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [data?.partner?.email];

          this.invoiceTemplateControl.setValue(
            data?.paymentTemplate?.templateBody
          );

          this.emailForm.patchValue({
            subject: data?.paymentTemplate?.templateSubject,
          });

          if (
            data.partner.primaryContact &&
            data.partner.primaryContact.email
          ) {
            this.ccEmails = [data.partner.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getNoteDetails(id: number): void {
    if (this.ID !== null) {
      this.noteService.getNoteDetails(id).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [data?.partnerData?.email];

          this.invoiceTemplateControl.setValue(
            data?.emailTemplate?.templateBody
          );

          this.emailForm.patchValue({
            subject: data?.emailTemplate?.templateSubject,
          });

          if (
            data.partner.primaryContact &&
            data.partner.primaryContact.email
          ) {
            this.ccEmails = [data.partner.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getInvoiceDetails(id: number): void {
    if (this.invoiceId !== null) {
      this.sendMailService.getInvoiceDetails(this.invoiceId).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [this.getEmailData?.partnerData?.email];
          this.invoiceTemplateControl.setValue(
            this.getEmailData?.invoiceTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject:
              this.getEmailData?.invoiceNo +
              ' ' +
              this.getEmailData?.invoiceTemplate?.templateSubject,
          });

          if (
            this.getEmailData.partnerData.primaryContact &&
            this.getEmailData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [
              this.getEmailData.partnerData.primaryContact.email,
            ];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getQuoteDetails(id: number): void {
    if (this.quoteId !== null) {
      this.sendMailService.getQuoteDetails(this.quoteId).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.parentQuoteId = +data.parentQuoteId;
          this.toEmails = [this.getEmailData?.partnerData?.email];
          this.invoiceTemplateControl.setValue(
            this.getEmailData?.quoteTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject:
              this.getEmailData?.quoteCode +
              ' ' +
              this.getEmailData?.quoteTemplate?.templateSubject,
          });

          if (
            this.getEmailData.partnerData.primaryContact &&
            this.getEmailData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [
              this.getEmailData.partnerData.primaryContact.email,
            ];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getPurchaseDetails(id: number): void {
    if (this.purchaseId !== null) {
      this.sendMailService.getPurchaseDetails(this.purchaseId).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [this.getEmailData?.vendor?.email];
          this.invoiceTemplateControl.setValue(
            this.getEmailData?.purchaseOrderTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject: this.getEmailData?.purchaseOrderTemplate?.templateSubject,
          });

          if (
            this.getEmailData.partnerData.primaryContact &&
            this.getEmailData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [
              this.getEmailData.partnerData.primaryContact.email,
            ];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getWorkOrderDetails(id: number): void {
    if (this.workOrderId !== null) {
      this.sendMailService.getWorkOrderDetails(id).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [this.getEmailData?.partnerData?.email];
          this.invoiceTemplateControl.setValue(
            this.getEmailData?.workOrderTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject:
              this.getEmailData?.workOrderNo +
              ' ' +
              this.getEmailData?.workOrderTemplate?.templateSubject,
          });

          if (
            this.getEmailData.partnerData.primaryContact &&
            this.getEmailData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [
              this.getEmailData.partnerData.primaryContact.email,
            ];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  sendEmail() {
    console.log('ToEmails----', this.toEmails);
    if (this.toEmails.length === 0) {
      this.toastr.warning(
        'Please add at least one recipient in the "To" field.'
      );
      return;
    }
    this.isLoading = true;
    const body = this.invoiceTemplateControl?.value;
    const fromEmail = 'sales@munawelconnect.com';
    const templateId = 2;

    // Ensure to filter out any empty or invalid emails
    const toEmails = [
      ...this.toEmails,
      ...(this.emailForm.value.toEmailControl
        ? this.emailForm.value.toEmailControl
          .split(',')
          .map((email: any) => email.trim())
          .filter((email: any) => this.isValidEmail(email))
        : []),
    ];
    // const toEmails = [
    //   ...this.toEmails,
    //   ...this.emailForm.value.toEmailControl
    //     .split(',')
    //     .map((email: any) => email.trim())
    //     .filter((email: any) => this.isValidEmail(email)),
    // ];

    const ccEmails = [
      ...this.ccEmails,
      ...(this.emailForm.value.ccEmailControl
        ? this.emailForm.value.ccEmailControl
          .split(',')
          .map((email: any) => email.trim())
          .filter((email: any) => this.isValidEmail(email))
        : []),
    ];
    // const ccEmails = [
    //   ...this.ccEmails,
    //   ...this.emailForm.value.ccEmailControl
    //     // .split(',')
    //     .map((email: any) => email.trim())
    //     .filter((email: any) => this.isValidEmail(email)),
    // ];

    const subject = this.emailForm.value?.subject;

    if (this.noteId) {
      this.noteService
        .sendNoteMail(
          this.noteId,
          toEmails,
          ccEmails,
          subject,
          body,
          this.documentFile,
          this.isAttachInvoice
        )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            if (this.creditNoteId) {
              this.router.navigate([`/credit-note/credit-note-details`], {
                queryParams: { id: this.creditNoteId },
              });
            } else if (this.debitNoteId) {
              this.router.navigate([`/debit-note/debit-note-details`], {
                queryParams: { id: this.debitNoteId },
              });
            }
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else if (this.invoiceId) {
      this.sendMailService
        .sendInvoiceMail(
          this.invoiceId,
          toEmails,
          ccEmails,
          subject,
          body,
          this.documentFile,
          this.isAttachInvoice
        )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            // this.location.back();
            this.router.navigate([`/invoice/invoice-details`], {
              queryParams: { id: this.invoiceId },
            });
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else if (this.quoteId) {
      const sendMailQuoteInput = {
        to: toEmails,
        cc: ccEmails,
        subject,
        body,
        isAttachment: this.isAttachInvoice,
        attachments: this.documentFile,
      };
      console.log('SendQUoteMailInput----', sendMailQuoteInput);
      this.sendMailService
        .sendQuotationMail(this.quoteId, sendMailQuoteInput)
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            // this.location.back();
            this.router.navigate([`/quotation/quotation-details`], {
              queryParams: { id: this.parentQuoteId },
            });
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else if (this.purchaseId) {
      this.sendMailService
        .sendPurchaseMail(
          this.purchaseId,
          toEmails,
          ccEmails,
          subject,
          body,
          this.documentFile,
          this.isAttachInvoice
        )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            // this.location.back();
            this.router.navigate([`/purchase-order/purchase-order-details`], {
              queryParams: { id: this.purchaseId },
            });
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else if (this.workOrderId) {
      const pdfAttachment = this.isAttachInvoice ? this.workOrderPdfBlob : '';
      this.sendMailService
        .sendWokOrderMail(
          this.workOrderId,
          toEmails,
          ccEmails,
          subject,
          body,
          pdfAttachment,
          this.documentFile,
          this.isAttachInvoice
        )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            // this.location.back();
            this.router.navigate([`/work-order/work-order-details`], {
              queryParams: { id: this.workOrderId },
            });
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else {
      this.itemService
        .sendPaymentRecievedMail(
          this.ID,
          toEmails,
          ccEmails,
          subject,
          body,
          this.documentFile,
          this.isAttachInvoice
        )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            // this.location.back();
            if (this.receivedId) {
              this.router.navigate(
                [`/payment-received/payment-received-details`],
                {
                  queryParams: { id: this.receivedId },
                }
              );
            } else if (this.madeId) {
              this.router.navigate([`/payments-made/payments-made-details`], {
                queryParams: { id: this.madeId },
              });
            }
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    }
  }

  addEmail(type: 'to' | 'cc' | 'bcc', selectedEmail?: string) {
    let control;
    let emailList;

    switch (type) {
      case 'to':
        control = this.emailForm.get('toEmailControl');
        emailList = this.toEmails;
        break;
      case 'cc':
        control = this.emailForm.get('ccEmailControl');
        emailList = this.ccEmails;
        break;
      case 'bcc':
        control = this.emailForm.get('bccEmailControl');
        emailList = this.bccEmails;
        break;
      default:
        return;
    }

    const email = selectedEmail || control?.value?.trim();

    if (email && this.isValidEmail(email) && !emailList.includes(email)) {
      // Add email to the respective list
      emailList.push(email);
      console.log(`${type.toUpperCase()} Emails:`, emailList);

      // Remove the selected email from suggestions
      this.emailSuggestionsTo = this.emailSuggestionsTo.filter(
        (e) => e.email !== email
      );
      this.emailSuggestionsCC = this.emailSuggestionsCC.filter(
        (e) => e.email !== email
      );

      // Clear the input field
      control?.reset();
    } else if (email && !this.isValidEmail(email)) {
      console.error('Invalid email format:', email);
    }
  }

  removeEmail(email: string, type: 'to' | 'cc' | 'bcc') {
    let emailList;

    switch (type) {
      case 'to':
        emailList = this.toEmails;
        break;
      case 'cc':
        emailList = this.ccEmails;
        break;
      case 'bcc':
        emailList = this.bccEmails;
        break;
    }

    const index = emailList.indexOf(email);
    if (index >= 0) {
      emailList.splice(index, 1);
    }
  }

  selectEmail(email: string) {
    if (email && !this.toEmails.includes(email)) {
      this.toEmails.push(email);
      this.emailForm.get('toEmailControl')?.setValue('');
      this.emailSuggestions = [];
      this.emailSuggestionsTo = [];
      this.emailSuggestionsCC = [];
    }
  }

  toggleBcc() {
    this.showBcc = !this.showBcc;
  }

  private isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const filesArray = Array.from(input.files);
      const validFiles = filesArray.filter((file) =>
        this.allowedFileTypes.includes(file.type)
      );

      if (validFiles.length < filesArray.length) {
        this.toastr.warning('Only PDF, DOC, and image files are allowed.');
      }

      this.documentFileNames.push(...validFiles.map((file) => file.name));
      this.documentFile.push(...validFiles);
    }
  }

  removeFile(index: number): void {
    this.documentFileNames.splice(index, 1);
    this.documentFile.splice(index, 1);
  }

  clearDocument(fileName: string): void {
    this.documentFileNames = this.documentFileNames.filter(
      (name: string) => name !== fileName
    );
    this.documentFile = this.documentFile.filter(
      (name: any) => name !== fileName
    );
  }

  onCancel(): void {
    // this.location.back();
    if (this.debitNoteId) {
      this.router.navigate([`/debit-note/debit-note-details`], {
        queryParams: { id: this.debitNoteId },
      });
    } else if (this.creditNoteId) {
      this.router.navigate([`/credit-note/credit-note-details`], {
        queryParams: { id: this.creditNoteId },
      });
    } else if (this.invoiceId) {
      this.router.navigate([`/invoice/invoice-details`], {
        queryParams: { id: this.invoiceId },
      });
    } else if (this.quoteId) {
      this.router.navigate([`/quotation/quotation-details`], {
        queryParams: { id: this.parentQuoteId },
      });
    } else if (this.workOrderId) {
      this.router.navigate([`/work-order/work-order-details`], {
        queryParams: { id: this.workOrderId },
      });
    } else if (this.receivedId) {
      this.router.navigate([`/payment-received/payment-received-details`], {
        queryParams: { id: this.receivedId },
      });
    } else if (this.madeId) {
      this.router.navigate([`/payments-made/payments-made-details`], {
        queryParams: { id: this.madeId },
      });
    } else if (this.purchaseId) {
      this.router.navigate([`/purchase-order/purchase-order-details`], {
        queryParams: { id: this.purchaseId },
      });
    }
  }
}
