import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { AddressComponent } from './components/address/address.component';
import { ContactPersonsComponent } from './components/contact-persons/contact-persons.component';
import { OtherDetailsComponent } from './components/other-details/other-details.component';
import { RemarksComponent } from './components/remarks/remarks.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { customEmailValidator } from '../../shared/services/validations';

@Component({
  selector: 'app-client-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    AddressComponent,
    ContactPersonsComponent,
    OtherDetailsComponent,
    RemarksComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './client-new.component.html',
  styleUrl: './client-new.component.scss',
})
export class ClientNewComponent {
  @ViewChild(OtherDetailsComponent)
  otherDetailsComponent!: OtherDetailsComponent;
  @ViewChild(AddressComponent) addressComponent!: AddressComponent;
  @ViewChild(ContactPersonsComponent)
  contactPersonsComponent!: ContactPersonsComponent;
  @ViewChild(RemarksComponent) remarksComponent!: RemarksComponent;
  newCustomerForm: FormGroup;
  customerData: any = {};
  addressDetails: any;
  contactDetails: any = [];
  contactPersons: any = [];
  remarkDetails: any;
  documentDetails: any = {};
  fileName: any;
  contacts: any;
  customerId: any;
  validationError: boolean = false;
  documentArray: any[] = [];

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe((params) => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.fetchPartnerById();
      }
    });
  }

  initForm() {
    if (this.customerData && this.contacts) {
      this.newCustomerForm = this.fb.group({
        id: Number(this.customerId),
        customerType: [
          this.customerData.customerType || '',
          Validators.required,
        ],
        primaryContact: [this.contacts?.salutation || 'Mr'],
        firstName: [this.contacts?.firstName || ''],
        lastName: [this.contacts?.lastName || ''],
        companyName: [this.customerData.companyName || ''],
        displayName: [this.customerData.displayName || '', Validators.required],
        email: [
          this.customerData.email || '',
          [Validators.required, customEmailValidator()],
        ],
        phone: [this.customerData.phone || '', [
          Validators.required,
          Validators.minLength(8), // Minimum length for phone number
          Validators.maxLength(15), // Maximum length for phone number
          Validators.pattern('^[0-9]+$'), // Only allows numeric values
        ],],
        mobile: [this.customerData.mobile || '', [
          Validators.required,
          Validators.minLength(8), // Minimum length for phone number
          Validators.maxLength(15), // Maximum length for phone number
          Validators.pattern('^[0-9]+$'), // Only allows numeric values
        ],],
        trnNumber: [this.customerData.trnNumber || ''],
        currency: [this.customerData.currency || 'AED'],
        openingBalance: [this.customerData.openingBalance || 0],
        paymentTerm: [this.customerData.paymentTerm || 'Net 30'],
        file: [this.fileName ? this.fileName : null],
      });
    } else {
      this.newCustomerForm = this.fb.group({
        customerType: ['Bussiness', Validators.required],
        primaryContact: ['', Validators.required],
        firstName: ['', [Validators.required, Validators.maxLength(50)]],
        lastName: ['', [Validators.required, Validators.maxLength(50)]],
        companyName: ['',[ Validators.maxLength(50)]],
        displayName: ['', [Validators.required]],
        email: ['', [Validators.required, customEmailValidator(), Validators.maxLength(50)]],
        phone: [
          '',
          [
            Validators.required,
            Validators.minLength(8), // Minimum length for phone number
            Validators.maxLength(15), // Maximum length for phone number
            Validators.pattern('^[0-9]+$'), // Only allows numeric values
          ],
        ],
        mobile: [
          '',
          [
            Validators.required,
            Validators.minLength(8), // Minimum length for phone number
            Validators.maxLength(15), // Maximum length for phone number
            Validators.pattern('^[0-9]+$'),
          ],
        ],
        trnNumber: [''],
        currency: [''],
        openingBalance: [0],
        paymentTerm: [''],
        file: [null],
      });
    }

    this.newCustomerForm.valueChanges.subscribe((formValue) => {
      this.contactPersonsComponent.updateFirstContactPerson(
        formValue.primaryContact,
        formValue.firstName,
        formValue.lastName,
        formValue.email,
        formValue.phone,
        formValue.mobile
      );
    });
  }

  fetchPartnerById() {
    this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
      next: (data) => {
        this.customerData = data;
        if (
          this.customerData.documents &&
          this.customerData.documents.length > 0
        ) {
          this.fileName = this.customerData.documents[0].documentName;
        }
        if (
          this.customerData.contacts &&
          this.customerData.contacts.length > 0
        ) {
          this.customerData.contacts.map((contact: any) => {
            if (contact?.primaryContact) {
              this.contacts = contact;
            }
          });
        }
        this.initForm();
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  async collectOtherDetails(data: any) {
    // this.otherDetailsData = data;
    const newCustomerData = { ...this.customerData };
    const allData = { ...this.customerData };
    newCustomerData.otherDetails = data;
    this.customerData = newCustomerData;
    console.log('this.customerData', allData);

    if (this.customerData?.documents?.length > 0) {
      this.documentArray = []
      this.documentDetails = {
        id: Number(this.customerData?.documents[0]?.id),
        documentName: data?.fileData?.documentName,
        documentUrl: data?.fileData?.documentUrl,
      };
      this.documentArray.push(this.documentDetails);
      console.log('documentArray--------->',this.documentArray);
    } 
    else if (data?.fileData?.documentName) {
      this.documentArray = []
      this.documentDetails = {
        documentName: data?.fileData?.documentName,
        documentUrl: data?.fileData?.documentUrl,
      };
      this.documentArray.push(this.documentDetails);
      console.log('documentArray--------->',this.documentArray);
    } 
    else if (allData?.otherDetails?.documents?.length > 0) {
      allData?.otherDetails?.documents.forEach((doc:any)=>{
        console.log('doc',doc);
        console.log('documentArrayyy--------->',this.documentArray);
        this.documentArray = []
        if (doc?.documentName && doc?.documentUrl) {
          this.documentDetails = {
            documentName: doc.documentName,
            documentUrl: doc.documentUrl,
          };
          this.documentArray.push(this.documentDetails);
        }
      })
      console.log('documentArray--------->',this.documentArray);
    } else {
      this.documentArray = []
      // this.documentArray.push(this.documentDetails);
      console.log('documentArray--------->',this.documentArray);
    }
    await this.newCustomerForm.patchValue({
      trnNumber: this.customerData.otherDetails.trnNumber,
      currency: this.customerData.otherDetails.currency,
      openingBalance: this.customerData.otherDetails.openingBalance,
      paymentTerm: this.customerData.otherDetails.paymentTerm,
    })
  }

  collectAddress(data: any) {
    this.customerData.addressDetails = this.customerData.addressDetails || {};
    this.customerData.addressDetails[data.type] = data.value;

    this.addressDetails = [
      ...(this.customerData.addressDetails.billing
        ? [this.customerData.addressDetails.billing]
        : []),
      ...(this.customerData.addressDetails.shipping
        ? [this.customerData.addressDetails.shipping]
        : []),
    ];
  }

  async collectContactDetails(data: any) {
    console.log(data);

    data.contactPersons.map((contact: any) => {
      this.contactPersons = data?.contactPersons;
      if (contact?.id) {
        this.contactDetails.push(contact);
      }
    });
  }

  collectRemarkDetails(data: any) {
    this.remarkDetails = data;
  }

  async onSubmit() {
    this.validationError = false;
    if (this.newCustomerForm.invalid) {
      this.newCustomerForm.markAllAsTouched();
      this.validationError = true;
      this.toastr.warning('Please fill mandatory fields!','Warning');
      return;
    }
    await this.otherDetailsComponent.onSubmit();
    this.contactPersonsComponent.onSubmit();
    this.remarksComponent.onSubmit();
  
    const billingValid = this.addressComponent.onSubmitBilling();
    const shippingValid = this.addressComponent.onSubmitShipping();
    if (!billingValid || !shippingValid) {
      this.validationError = true;
      this.toastr.warning('Please fill mandatory fields!','Warning');
      return;
    }

    console.log('onSubmit', this.newCustomerForm.value);

    const customerDetails = {
      partnerType: 'Customer',
      customerType: this.newCustomerForm.value.customerType,
      displayName: this.newCustomerForm.value.displayName,
      companyName: this.newCustomerForm.value.companyName,
      phone: this.newCustomerForm.value.phone,
      email: this.newCustomerForm.value.email,
      mobile: this.newCustomerForm.value.mobile,
      currency: this.newCustomerForm.value.currency,
      trnNumber: String(this.newCustomerForm.value.trnNumber),
      openingBalance: this.newCustomerForm.value.openingBalance,
      paymentTerm: this.newCustomerForm.value.paymentTerm || '',
      paymentStatus: 'Pending',
    };

    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    if (this.customerId && this.customerData) {
      const customerDetails = {
        id: Number(this.customerId),
        partnerType: 'Customer',
        customerType: this.newCustomerForm.value.customerType,
        displayName: this.newCustomerForm.value.displayName,
        companyName: this.newCustomerForm.value.companyName,
        phone: this.newCustomerForm.value.phone,
        email: this.newCustomerForm.value.email,
        mobile: this.newCustomerForm.value.mobile,
        currency: this.newCustomerForm.value.currency,
        trnNumber: String(this.newCustomerForm.value.trnNumber),
        openingBalance: this.newCustomerForm.value.openingBalance,
        paymentTerm: this.newCustomerForm.value.paymentTerm,
        paymentStatus: "Pending",
      };
      if (!this.remarkDetails?.id) {
        this.remarkDetails = {};
      }
      console.log('this.documentArray',this.documentArray);
      console.log('this.addressDetails',this.addressDetails);
      this.customerService.updatePartner(
        customerDetails,
        this.contactDetails,
        this.addressDetails,
        this.remarkDetails,
        employeeId,
        this.documentArray,
      ).subscribe({
        next: async (data) => {
          if (data) {
            this.toastr.success('Customer updated successfully', 'Success');
            await this.addNewContactPersons();
            this.onCancel();
            this.router.navigate([`/customers/customer-details/${this.customerId}`]);
          }
        },
        error: (error) => this.toastr.error(error, 'Error'),
      });

    } else {
      console.log('this.documentArray',this.documentArray);
      const newContacts = this.contactPersons.filter((contact: any) => !contact.id);
      this.customerService.createPartner(
        customerDetails,
        newContacts,
        this.addressDetails,
        this.remarkDetails,
        // this.documentDetails,
        this.documentArray,
        employeeId
      ).subscribe({
        next: async (data) => {
          this.toastr.success('Customer created successfully', 'Success');
          this.onCancel();
          this.router.navigate([`/customers`]);
        },
        error: (error) => this.toastr.error(error, 'Error'),
      });
    }
  }

  async addNewContactPersons() {
    const newContacts = this.contactPersons.filter((contact: any) => !contact.id);
    console.log('newContacts', newContacts);
  
    if (newContacts && newContacts.length > 0) {
      const validNewContacts = newContacts.filter((contact: any) =>
        contact.firstName && contact.lastName && contact.email
      );
  
      validNewContacts.map(async (data: any) => {
        const contactPerson = { ...data, partnerId: Number(this.customerId) };
        await this.customerService.createContact(contactPerson).toPromise().then((data) => {
          if (data) {
            // this.toastr.success('Contact added successfully!', 'Success');
          }
        }).catch(error => {
          this.toastr.error(error, 'Error');
        });
        this.fetchPartnerById();
      });
    } else {
      this.contactDetails.map((data: any) => {
        if (data?.id) {
          this.customerService.updateContact(data).toPromise().then((data) => {
            if (data) {
              // this.toastr.success('Contact updated successfully!', 'Success');
            }
          }).catch(error => {
            this.toastr.error(error, 'Error');
          });
          this.fetchPartnerById();
        }
      });
    }
  }
  

  onCancel() {
    this.newCustomerForm.reset();
    this.otherDetailsComponent.onCancel();
    this.addressComponent.onCancel();
    this.addressComponent.onCancel();
    this.contactPersonsComponent.onCancel();
    this.remarksComponent.onCancel();
  }
}
