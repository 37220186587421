import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { BankingService } from '../../features/banking/services/banking.service';
import { MatOptionModule } from '@angular/material/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-banking',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    RouterModule
  ],
  templateUrl: './banking.component.html',
  styleUrl: './banking.component.scss'
})
export class BankingComponent {
  selectedOption = 'allAccounts';
  readonly dialog = inject(MatDialog);
  search: any;
  accounts: any;
  netAmounts: any;

  constructor(
    private bankingService: BankingService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.fetchAccountsOfBankAndCash();
    // this.getNetAmountOfBankAndCash(); 
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadBankingDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fetchAccountsOfBankAndCash();
    });
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  fetchAccountsOfBankAndCash() {
    this.bankingService.fetchAccountsOfBankAndCash(this.search).subscribe({
      next: (accounts: any) => {
        this.accounts = accounts;
        console.log('accounts:', accounts);

      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  // getNetAmountOfBankAndCash() {
  //   this.bankingService.getNetAmountOfBankAndCash().subscribe({
  //     next: (netAmount: any) => {
  //       this.netAmounts = netAmount
  //     },
  //     error: (error) => { 
  //       console.error(error, 'Error');
  //     }
  //   })
  // }

  goToAccountDetail(account: any, event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (targetElement.closest('button')) {
      return;
    }
    this.router.navigate(['banking/banking-details', account?.chartOfAccount?.id])
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    console.log(this.search);

    this.fetchAccountsOfBankAndCash();
  }


  onEdit(id: any) {
    console.log('id',id);
    
    this.router.navigate([`/banking/edit-bank-account/${id}`])
  }

}


@Component({
  selector: 'upload-banking-dialog',
  templateUrl: 'upload-banking-dialog.html',
  styleUrls: ['./banking.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    FormsModule
  ],
})
export class UploadBankingDialog {
  importStatementForm: FormGroup;
  file: any = null;
  accounts: any;
  search: string;

  constructor(
    private bankingService: BankingService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
  ) { }

  ngOnInit() {
    this.fetchAccountsOfBankAndCash();
    this.importStatementForm = this.fb.group({
      accountName: ['', Validators.required],
      file: ['']
    })
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  fetchAccountsOfBankAndCash() {
    this.bankingService.fetchAccountsOfBankAndCash(this.search).subscribe({
      next: (accounts: any) => {
        this.accounts = accounts;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    const accountId = this.importStatementForm.value.accountName;
    this.bankingService.bulkCreateTransactions(this.file, accountId).subscribe({
      next: (response) => {
        console.log(response);

        if (response && !response.errors) {
          this.toastr.success('Transactions uploaded successfully!', 'Success');
          console.log(accountId);
          this.dialogRef.close(accountId);
        }
        else if (response?.errors) {
          this.toastr.error(response?.errors[0]?.message, 'Error');
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk transaction creation failed');
        this.dialogRef.close();
      },
    });
  }


}