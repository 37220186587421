<section class="sidebar-wrapper" [ngClass]="{ collapsedClass: sidebarData }">
  <div class="header">
    <img src="assets/images/logo.svg" alt="Logo" />
  </div>
  <div class="nav-items-wrapper">
    <ul class="main-nav-list">
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/dashboard') }" (click)="navigateTo('/dashboard')"
        *ngIf="['admin', 'employee', 'hr', 'account', 'sales', 'project','purchase manager'].includes(profileName)">
        <img src="assets/images/icons/icon-dashboard-2.svg" alt="icon Dashboard" matTooltip="Dashboard"
          matTooltipPosition="right" />
        <h3>Dashboard</h3>
      </li>
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/inventory') }" (click)="navigateTo('/inventory')"
        *ngIf="['admin', 'sales', 'project'].includes(profileName) || (hasInventoryPermission$ | async)">
        <img src="assets/images/icons/icon-inventory-2.svg" alt="icon Inventory" matTooltip="Inventory"
          matTooltipPosition="right" />
        <h3>Inventory</h3>
      </li>
      <li class="nav dropdown-nav" [ngClass]="{
        'active-class': isRouteActive2([
          '/customers',
          '/customer-new',
          '/quotation',
          '/quotation-new',
          '/quotation/quotation-details',
          '/projects',
          '/project-inventory',
          '/add-project-inventory',
          '/work-order',
          '/work-order-details',
          '/work-order/work-order-details',
          '/work-order-new',
          '/invoice',
          '/invoice-new',
          '/invoice-details',
          '/payment-received/payment-new',
          '/payment-received',
          '/payment-received/payment-received-details',
          '/credit-note',
          '/credit-note-details',
          '/credit-note/credit-note-new',
       
        ])
      }" *ngIf="['admin', 'account', 'sales', 'project','quote-new'].includes(profileName)">
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 1">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img src="assets/images/icons/icon-sales.svg" alt="icon Sales" matTooltip="Sales"
                  matTooltipPosition="right" />
                <h3>Sales</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li [ngClass]="{ active: isRouteActive('/customers') }" (click)="navigateTo('/customers')"
                *ngIf="['admin', 'sales', 'project', 'quote-new'].includes(profileName)">Customers</li>
              <li [ngClass]="{ active: isRouteActive('/quotation') }" (click)="navigateTo('/quotation')"
                *ngIf="['admin', 'sales', 'project', 'account','quote-new'].includes(profileName)">Quotes</li>
              <li [ngClass]="{ active: isRouteActive('/projects') }" (click)="navigateTo('/projects')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Project</li>
              <li [ngClass]="{ active: isRouteActive('/work-order') }" (click)="navigateTo('/work-order')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Work Order</li>
              <li [ngClass]="{ active: isRouteActive('/invoice') }" (click)="navigateTo('/invoice')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Invoice</li>
              <li [ngClass]="{ active: isRouteActive('/payment-received') }" (click)="navigateTo('/payment-received')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Payment Received</li>
              <li [ngClass]="{ active: isRouteActive('/credit-note') }" (click)="navigateTo('/credit-note')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Credit Note</li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li class="nav dropdown-nav" [ngClass]="{
        'active-class': isRouteActive2([
          '/vendors',
          '/vendor-details',
          '/vendor-new',
          '/expense',
          '/expense-details',
          '/expense-new',
          '/petty-cash',
          '/purchase-order',
          '/purchase-order-details',
          '/purchase-order-new',
          '/vendor-invoice',
          '/vendor-invoice-new',
          '/vendor-invoice-details',
          '/goods-received-note',
          '/grn-details',
          '/grn-new',
          '/payments-made',
          '/payments-made-details',
          '/payments-made/bill-payment-new',
          '/debit-note',
          '/debit-note-details',
          '/debit-note/debit-note-new',
        ])
      }" *ngIf="['admin', 'account', 'sales', 'project','purchase manager'].includes(profileName)">
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 2">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img src="assets/images/icons/icon-purchase.svg" alt="icon Purchase" matTooltip="Purchase"
                  matTooltipPosition="right" />
                <h3>Purchase</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li [ngClass]="{ active: isRouteActive('/vendors') }"
              (click)="navigateTo('/vendors')" *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Vendors</li>
              <li [ngClass]="{ active: isRouteActive('/expense') }"
              (click)="navigateTo('/expense')" *ngIf="['admin', 'account'].includes(profileName)">Expenses</li>
              <li [ngClass]="{ active: isRouteActive('/petty-cash') }" (click)="navigateTo('/petty-cash')"
                *ngIf="['admin', 'account'].includes(profileName)">Petty Cash</li>
              <li [ngClass]="{ active: isRouteActive('/purchase-order') }"
              (click)="navigateTo('/purchase-order')" *ngIf="['admin', 'sales', 'project','purchase manager', 'account'].includes(profileName)">Purchase Order</li>
              <!-- <li [ngClass]="{ active: isRouteActive('/local-purchase-order') }"
              (click)="navigateTo('/local-purchase-order')" *ngIf="['admin', 'sales', 'project'].includes(profileName)">Local Purchase Order</li> -->
              <li [ngClass]="{ active: isRouteActive('/vendor-invoice') }" (click)="navigateTo('/vendor-invoice')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Vendor Invoice</li>
              <li [ngClass]="{ active: isRouteActive('/goods-received-note') }"
                (click)="navigateTo('/goods-received-note')"
                *ngIf="['admin', 'sales', 'project'].includes(profileName)">GRN</li>
              <li [ngClass]="{ active: isRouteActive('/payments-made') }" (click)="navigateTo('/payments-made')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Payment Made</li>
              <li [ngClass]="{ active: isRouteActive('/debit-note') }" (click)="navigateTo('/debit-note')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Debit Note</li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/branch') }" (click)="navigateTo('/branch')"
        *ngIf="['admin', 'hr'].includes(profileName)">
        <img src="assets/images/icons/icon-branch.svg" alt="icon Branch" matTooltip="Branch"
          matTooltipPosition="right" />
        <h3>Branch</h3>
      </li>
      <li class="nav dropdown-nav" [ngClass]="{
          'active-class': isRouteActive2([
            '/employees',
            '/add-employee',
            '/attendance-management',
            '/holiday',
            '/leaves',
            '/leave-policy',
            '/payroll',
            '/payroll-list',
            '/vacation-management',
            '/designations',
            '/departments',
          ])
        }" *ngIf="['admin', 'hr', 'account'].includes(profileName)">
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 3">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img src="assets/images/icons/icon-hrms.svg" alt="icon HRMS" matTooltip="HRMS"
                  matTooltipPosition="right" />
                <h3>HRMS</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li [ngClass]="{ active: isRouteActive('/employees') }" (click)="navigateTo('/employees')"
                *ngIf="['admin', 'hr'].includes(profileName)">
                Employees
              </li>
              <li [ngClass]="{ active: isRouteActive('/attendance-management') }"
                (click)="navigateTo('/attendance-management')" *ngIf="['admin', 'hr'].includes(profileName)">
                Attendance
              </li>
              <li [ngClass]="{ active: isRouteActive('/leaves') }" (click)="navigateTo('/leaves')"
                *ngIf="['admin', 'hr'].includes(profileName)">
                Leave
              </li>
              <li [ngClass]="{ active: isRouteActive('/payroll') }" (click)="navigateTo('/payroll')"
                *ngIf="['admin', 'hr', 'account'].includes(profileName)">
                Payroll
              </li>
              <li [ngClass]="{ active: isRouteActive('/holiday') }" (click)="navigateTo('/holiday')"
                *ngIf="['admin', 'hr'].includes(profileName)">
                Holiday
              </li>
              <!-- <li [ngClass]="{'active': isRouteActive('/leave-policy')}" (click)="navigateTo('/leave-policy')">Leave Policy</li> -->
              <li [ngClass]="{ active: isRouteActive('/vacation-management') }"
                (click)="navigateTo('/vacation-management')" *ngIf="['admin', 'hr'].includes(profileName)">
                Vacation
              </li>
              <li [ngClass]="{ active: isRouteActive('/designations') }" (click)="navigateTo('/designations')"
                *ngIf="['admin', 'hr'].includes(profileName)">
                Designations
              </li>
              <li [ngClass]="{ active: isRouteActive('/departments') }" (click)="navigateTo('/departments')"
                *ngIf="['admin', 'hr'].includes(profileName)">
                Departments
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <!-- <li class="nav" *ngIf="['admin', 'manager'].includes(profileName)">
        <img
          src="assets/images/icons/icon-approvals.svg"
          alt="icon Approvals"
          matTooltip="Approvals"
          matTooltipPosition="right"
        />
        <h3>Approvals</h3>
      </li> -->
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/assets') }"
      (click)="navigateTo('/assets')" *ngIf="['admin', 'manager'].includes(profileName)">
        <img src="assets/images/icons/icon-assets.svg" alt="icon Assets" matTooltip="Assets"
        matTooltipPosition="right"/>
        <h3>Assets</h3>
      </li>
      <li
      class="nav"
      [ngClass]="{ 'active-class': isRouteActive('/banking') }"
      (click)="navigateTo('/banking')" *ngIf="['admin', 'account'].includes(profileName)"
    >
      <img src="assets/images/icons/icon-bank-2.svg" alt="icon Banking" matTooltip="Banking"
      matTooltipPosition="right"/>
      <h3>Banking</h3>
    </li>
      <li
        class="nav dropdown-nav"
        [ngClass]="{
          'active-class': isRouteActive2([
            '/currency-adjustments',
            '/chart-of-accounts',
            '/manual-journals',
            '/configure'
          ])
        }" *ngIf="['admin', 'account'].includes(profileName)">
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 4">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img src="assets/images/icons/icon-accounts.svg" alt="icon Accounts" matTooltip="Accounts"
                  matTooltipPosition="right" />
                <h3>Accounts</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li [ngClass]="{ active: isRouteActive('/currency-adjustments') }"
                (click)="navigateTo('/currency-adjustments')">
                Currency Adjustments
              </li>
              <li [ngClass]="{ active: isRouteActive('/manual-journals') }" (click)="navigateTo('/manual-journals')">
                Manual Journal
              </li>
              <li [ngClass]="{ active: isRouteActive('/chart-of-accounts') }"
                (click)="navigateTo('/chart-of-accounts')">
                Chart Of Accounts
              </li>
              <li [ngClass]="{ 'active-class': isRouteActive('/configure') }" (click)="navigateTo('/configure')"
                *ngIf="['admin'].includes(profileName)">
                Configure
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/reports') }" (click)="navigateTo('/reports')"
        *ngIf="['admin', 'account'].includes(profileName)">
        <img src="assets/images/icons/icon-report.svg" alt="icon Report" matTooltip="Report"
          matTooltipPosition="right" />
        <h3>Report</h3>
      </li>
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/roles') }" (click)="navigateTo('/roles')"
        *ngIf="['admin'].includes(profileName)">
        <img src="assets/images/icons/icon-roles.svg" alt="icon Roles" matTooltip="Roles & Permissions"
          matTooltipPosition="right" />
        <h3>Roles & Permissions</h3>
      </li>
    </ul>
  </div>
</section>