import {gql} from 'apollo-angular';

export const REMOVE_INVOICE_BY_ID = gql `
  mutation removeInvoice($id: Int!) {
    removeInvoice(id: $id)
  }
`;

export const REMOVE_INVOICE_ATTACHMENT_BY_ID = gql `
  mutation removeInvoiceAttachment($id: Int!) {
    removeInvoiceAttachment(id: $id)
  }
`;

export const REMOVE_INVOICE_ITEM_BY_ID = gql `
  mutation removeInvoiceItem($id: Int!) {
    removeInvoiceItem(id: $id)
  }
`;

export const CREATE_INVOICE = gql `
  mutation CreateInvoice(
    $createInvoiceInput: CreateInvoiceInput!
    $createInvoiceItemInput: [CreateInvoiceItemInput!]
    $createInvoiceAttachmentInput: [CreateInvoiceAttachmentInput!]
  ) {
    createInvoice(
      createInvoiceInput: $createInvoiceInput
      createInvoiceItemInput: $createInvoiceItemInput
      createInvoiceAttachmentInput: $createInvoiceAttachmentInput
    ) {
      id
      invoiceNo
      totalPrice
      balance
      status
      invoiceDate
      branchId
      dueDate
    }
  }
`;

export const GENERATE_INVOICE_PDF = gql `
mutation generateInvoicePdf($invoiceId: Int!){
  generateInvoicePdf(invoiceId: $invoiceId)
}`;

export const UPDATE_INVOICE = gql `
  mutation updateInvoice(
    $updateInvoiceInput: UpdateInvoiceInput!
    $updateInvoiceItemInput: [UpdateInvoiceItemInput!]
    $updateInvoiceAttachmentInput: [UpdateInvoiceAttachmentInput!]
  ) {
    updateInvoice(
      updateInvoiceInput: $updateInvoiceInput
      updateInvoiceItemInput: $updateInvoiceItemInput
      updateInvoiceAttachmentInput: $updateInvoiceAttachmentInput
    ) {
      id
      projectModelId
      quoteId
      journalId
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      invoiceItems {
        id
        invoiceId
        description
        quantity
        rate
        discount
        amount
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const CREATE_ADDRESS = gql `
  mutation createAddress($createAddressInput: CreateAddressInput!) {
    createAddress(createAddressInput: $createAddressInput) {
      id
      partnerId
    }
  }
`;

export const UPDATE_ADDRESS = gql `
mutation updateAddress($updateAddressInput: UpdateAddressInput!) {
  updateAddress(updateAddressInput: $updateAddressInput) {
    id
    partnerId
    partner {
      id
      partnerType
      customerType
      displayName
    }
    attention
    addressType
    addressLine1
    addressLine2
    city
    state
    country
    pinCode
    phone
    fax
  } 
}
`;
