import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { fetch_PDF } from '../../helpers/helper';
import { ToastrService } from 'ngx-toastr';
import { CreditNoteApplyCreditDialog } from '../credit-note-details/credit-note-details.component';

@Component({
  selector: 'app-debit-note-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule

  ],
  templateUrl: './debit-note-details.component.html',
  styleUrl: './debit-note-details.component.scss',
})
export class DebitNoteDetailsComponent {
  allItems: any[] = [];
  itemId: any;
  itemData: any = {};
  isCommentsOpen = false;
  commentForm : FormGroup;
  comment: any;
  journalData: any; 
  
  private searchSubject = new Subject<string>();

  constructor(
    private router: Router,
    private itemService: CreditDebitNoteService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb : FormBuilder
  ) {}

  ngOnInit(): void {
    this.fetchNotes();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchNotes(searchTerm);
      });

    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      this.itemId = +id;
    });
    this.commentForm = this.fb.group({comment: ['']});
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDebitNoteCreditApplyDialog() {
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const itemId = selectedIteam.id;
    const dialogDebitNoteCreditApplyDialogItemRef = this.dialog.open(
      DebitNoteCreditApplyDialog,
      { data: { noteId: this.itemId } }
    );
  }

  selectedOption = 'today';

  selectedTabIndex = 0; // Default to the first tab

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.itemData = this.allItems[index];
    this.journalData = this.itemData?.journalData;
    if (this.itemData) {
      this.router.navigate(['debit-note/debit-note-details'], {
        queryParams: { id: this.itemData.id },
      });
    }
  }
  calculateTotalDebit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.debit || 0), 0) || 0;
  }
  
  calculateTotalCredit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.credit || 0), 0) || 0;
  }
  

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadDebitNoteDialog);
  }

  navigateToMail(): void {
    const noteId = this.itemData.id;
    // this.router.navigate([`debit-note/debit-note-details/mail`], {
    //   queryParams: { noteId },
    // });
    this.router.navigate([`debit-note/debit-note-details/mail`], {
      queryParams: { debitNoteId: noteId },
    });
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  fetchNotes(search: string = '') {
    this.itemService.fetchNotes('Debit', search).subscribe({
      next: (items) => {
        this.allItems = items;
        if (this.allItems.length > 0) {
          this.selectTab(0);
        }

        if (this.itemId) {
          const targetId = +this.itemId;
          this.selectedTabIndex = this.allItems.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
        
        this.itemData = this.allItems[this.selectedTabIndex] || {};
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  downloadPdf(): void {
    const noteId = this.itemData.id
    const type = 'Debit';
    this.itemService.generateNotePdf(noteId, type).subscribe({
      next: (items) => {
        const url = items;
        const fileName = 'Debit-note';
        fetch_PDF(url, fileName);
      },
      error: (err) => {
        this.toastr.error(err.message, 'failed');
      },
    });
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isCommentsOpen = !this.isCommentsOpen;
  }

  createComment(): void {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee ?. employeeData ?. id;
    const createCommentInput = {
      employeeId: employeeId, 
      refId:this.itemData.id, 
      type: 'DebitNote', 
      activityType: 'Comment', 
      note: this.commentForm.value.comment,
    };
    this.itemService.createcomment(createCommentInput).subscribe({
      next: (data) => {
          if (data) {
              this.toastr.success('Comment added successfully!', 'Success');
              this.commentForm.reset();
          }
      },
      error: (error) => {
          console.error(error, 'Error');
      }
  });

  }
  navigateToEdit(path: string) {
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const itemId = selectedIteam.id;
    console.log(itemId);
    
    this.router.navigate([path], { queryParams: { id: itemId } });
  }

    // changestatusnote
    changeStatusNote(): void {
      console.log('change status called');
  
      const noteId = this.itemData.id;
      const status = 'Open';
      this.itemService.changestatusnote(noteId, status).subscribe({
        next: (data) => {
          if (data) {
            this.toastr.success('Success');
            this.fetchNotes();
          }
        },
        error: (error) => {
          console.error(error, 'Error');
        },
      });
    }
  
}

@Component({
  selector: 'upload-debit-note-dialog',
  templateUrl: 'upload-debit-note-dialog.html',
  styleUrls: ['./debit-note-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadDebitNoteDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}

@Component({
  selector: 'debit-note-apply-credit-dialog',
  templateUrl: 'debit-note-apply-credit-dialog.html',
  styleUrls: ['./debit-note-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class DebitNoteCreditApplyDialog {

  itemId: number;
  noteDetails: any = null;
  partnerId: any;
  allInvoices: any = [];
  invoiceForm: FormGroup;
  totalAmountToCredit: number = 0;
  invoiceBalanceDue: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: CreditDebitNoteService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<CreditNoteApplyCreditDialog>
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.itemId = this.data.noteId;
      this.fetchNote();
    }
  }

  initForm(): void {
    const controls = this.allInvoices.map((_: any) => this.fb.control(0)); // Default credit = 0 for each invoice
    this.invoiceForm = this.fb.group({
      credits: this.fb.array(controls),
    });
  }
  get credits() {
    return this.invoiceForm.get('credits') as FormArray;
  }

  // Add a helper method to safely access FormControls
  getCreditControl(index: number): FormControl {
    const invoiceBalance = this.allInvoices[index]?.balance || 0;
    if (!this.credits.at(index)) {
      this.credits.insert(index, new FormControl(0, [Validators.max(invoiceBalance), Validators.min(0)]));
    }
    return this.credits.at(index) as FormControl;
  }
  
  get invoices() {
    return this.invoiceForm.get('invoices') as FormArray;
  }
  // Initialize form controls for each invoice
  initializeCreditControls(): void {
    this.allInvoices.forEach((invoice: any, index: number) => {
      const creditControl = this.fb.control(
        '', // Initialize with an empty string
        [Validators.max(invoice.balance)] // Use Angular's built-in max validator
      );
      this.credits.push(creditControl);
    });
  }

  fetchNote(): void {
    this.itemService.fetchNote(this.itemId).subscribe({
      next: (note) => {
        this.noteDetails = note;
        this.partnerId = this.noteDetails?.partnerData?.id;
console.log(this.noteDetails);

        if (this.partnerId) {
          console.log("indide ");
          
          this.itemService.fetchinvoicesbycustomerorvendor(this.partnerId, "Vendor").subscribe({
            next: (response) => {
              this.allInvoices = response || [];
              this.initForm();
            },
            error: (error) => {
              console.error(error);
            },
          });
        }
      },
      error: (err) => {
        console.error('Error fetching note:', err);
      },
    });
  }

  calculateInvoiceBalanceDue(): number {
    return this.allInvoices.reduce((totalBalanceDue: number, invoice: any, index: number) => {
      const creditApplied = this.credits.at(index)?.value || 0;
      const invoiceBalance = invoice.balance || 0;
  
      // Ensure we don't subtract more credits than the invoice balance
      const remainingBalance = invoiceBalance - Math.min(creditApplied, invoiceBalance);
  
      return totalBalanceDue + remainingBalance;
    }, 0);
    
  }
  
  calculateAmountToCredit(): void {
    const availableCredits = this.noteDetails?.balance || 0;
    let totalAppliedCredits = 0;
  
    this.credits.controls.forEach((control, index) => {
      const invoiceBalance = this.allInvoices[index]?.balance || 0;
      const appliedCredit = control.value || 0;
  
      // Ensure credit does not exceed invoice balance
      if (appliedCredit > invoiceBalance) {
        control.setErrors({ ...control.errors, exceedsInvoiceBalance: true });
      } else if (control.hasError('exceedsInvoiceBalance')) {
        const { exceedsInvoiceBalance, ...errors } = control.errors || {};
        control.setErrors(Object.keys(errors).length ? errors : null);
      }
  
      totalAppliedCredits += appliedCredit;
    });
  
    // Ensure total applied credits do not exceed available credits
    if (totalAppliedCredits > availableCredits) {
      this.credits.controls.forEach((control) => {
        control.setErrors({ ...control.errors, exceedsAvailableCredits: true });
      });
    } else {
      this.credits.controls.forEach((control) => {
        if (control.hasError('exceedsAvailableCredits')) {
          const { exceedsAvailableCredits, ...errors } = control.errors || {};
          control.setErrors(Object.keys(errors).length ? errors : null);
        }
      });
    }
  
    // Update total amount to credit
    this.totalAmountToCredit = Math.min(totalAppliedCredits, availableCredits);
  
    // Update invoice balance due
    this.invoiceBalanceDue = this.calculateInvoiceBalanceDue();
  }
  
  applyCreditsToInvoices(): void {
    const notes = this.credits.controls.map((control, index) => {
      const appliedCredit = control.value || 0; // Default to 0 if no value is entered
      const invoice = this.allInvoices[index];
  
      if (appliedCredit <= 0) {
        return null;
      }
  
      return {
        invoiceId: invoice.id,
        refId: this.noteDetails.id, // Reference to the credit note ID
        type: 'DN', 
        amount: appliedCredit,
      };
    }).filter(note => note !== null); // Remove null entries
  
    // Ensure there's at least one note to apply
    if (notes.length === 0) {
      console.error('No credits to apply.');
      return;
    }
  

    const payload = {
      notes,
    };

    this.itemService.applytoinvoice(payload).subscribe({
      next: (response) => {
        this.toastr.success('Credits applied to invoices successfully.');  
        this.dialogRef.close();
      },

      error: (error) => {
        this.toastr.error('Failed to apply credits to invoices .');
        console.log(error);
      
      },
    });
  }
}
