<mat-dialog-content>
    <section class="add-profile-main-wrapper">
        <h2 class="cmn-popup-title">{{data ? 'Create' : ''}} New Profile</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="addProfileForm">
            <div class="add-profile-dialog-wrapper">
                <div class="form-wrapper">
                    <label for="">Role</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Role" formControlName="profileName" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                        addProfileForm.get('profileName')!.touched &&
                        addProfileForm.get('profileName')!.hasError('required')
                      ">Role name is required.</mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="addProfileForm.get('profileName')!.touched &&
                        addProfileForm.get('profileName')!.hasError('spaceError')" class="error">Cannot start or end
                                with a space.
                            </mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper" *ngIf="data">
                    <label for="">Clone Role</label>
                    <mat-form-field>
                        <mat-select placeholder="Select profile" formControlName="profileId" [disabled]="true">
                            <mat-option *ngFor="let profile of profiles"
                                [value]="profile?.id">{{profile?.profileName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Role Description</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Type something.." formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="btn-wrapper">
            <button class="cmn-popup-btn" type="submit" (click)="addProfile()">
                Add Role
            </button>
        </div>

    </section>
</mat-dialog-content>