<section class="cmn-innerpage-wrapper invoice-new">
  <h2 class="cmn-inner-heading">  {{ purchaseOrderId ? "Update Purchase Order" : "New Purchase Order" }}</h2>
  <form [formGroup]="purchaseOrderForm" (keydown)="preventEnterKey($event)">
    <div class="invoice-new-form-wrapper">
      <div class="form-wrapper invoice-form-wrapper">
        <!-- Client Name Section -->
        <div class="cmn-Search-select-container">
          <label for="clientName">Vendor Name <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Search vendor" id="clientName" formControlName="vendorId"
              (selectionChange)="onVendorChange($event.value)" >
              <mat-option >
                <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No options found'"
                (input)="onVendorSearch($event)"
                  ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let vendor of vendorsData" [value]="vendor.id">
                {{ vendor.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              purchaseOrderForm.get('vendorId')!.touched &&
              purchaseOrderForm.get('vendorId')!.hasError('required')
            ">*Vendor is required.</mat-error>
            </span>
          </div>
          <button *ngIf="profileName === 'admin'" (click)="openCreateVendorDialog()">
            <div>
              <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
            </div>
            <span>Add vendor</span>
          </button>
        </div>

        <!-- Invoice Number Section -->
        <div>
          <label for="purchaseOrderNo"> Purchase Order <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Purchase order number" id="purchaseOrderNo" formControlName="purchaseOrderNo"
              readonly />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                purchaseOrderForm.get('purchaseOrderNo')!.touched &&
                purchaseOrderForm.get('purchaseOrderNo')!.hasError('required')
              ">*Purchase order number is required.</mat-error>
            </span>
          </div>

          <!-- <mat-error *ngIf="
          purchaseOrderForm.get('purchaseOrderNo')!.touched &&
          purchaseOrderForm.get('purchaseOrderNo')!.hasError('maxlength')
          ">
            *Purchase order number exceeds limit (max limit is 15)
          </mat-error> -->
        </div>
        <!-- Project Name Section -->
        <!-- <div class="cmn-Search-select-container">
                    <label for="projectName">Project Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Select project" id="projectName">
                            <mat-option>
                                <ngx-mat-select-search></ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="project1">Project 1</mat-option>
                            <mat-option value="project2">Project 2</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

        <!-- Order Number Section -->
        <!-- <div>
                    <label for="orderNumber">Work Order Number</label>
                    <mat-form-field>
                        <input matInput placeholder="0923423" id="orderNumber" />
                    </mat-form-field>
                </div> -->

        <!-- TRN Number Section -->
        <div>
          <label for="trnNumber">TRN Number</label>
          <mat-form-field>
            <input matInput placeholder="TRN number" id="trnNumber" formControlName="trnNumber"
              [value]="selectedVendorTRN" />
          </mat-form-field>
        </div>

        <div>
          <label for="reference">Reference</label>
          <mat-form-field>
            <input matInput placeholder="Reference" id="reference" formControlName="reference" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              purchaseOrderForm.get('reference')!.touched &&
              purchaseOrderForm.get('reference')!.hasError('maxlength')
              ">*Reference exceeds limit (max limit is 15)</mat-error>
            </span>
          </div>

        </div>

        <!-- Terms Section -->
        <!-- <div>
          <label for="paymentTerms">Payment Terms <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Due on receipt" id="paymentTerms" formControlName="paymentTerms">
              <mat-option *ngFor="let term of paymentTermsValue" value="{{term}}">{{
                term
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="
              purchaseOrderForm.get('paymentTerms')!.touched &&
              purchaseOrderForm.get('paymentTerms')!.hasError('required')
            ">
            *Payment terms is required.
          </mat-error>
        </div> -->



        <!-- Milestone Section -->
        <!-- <div>
                    <label for="milestone">Milestone</label>
                    <mat-form-field>
                        <mat-select placeholder="Choose milestone" id="milestone">
                            <mat-option value="milestone1">Milestone 1</mat-option>
                            <mat-option value="milestone2">Milestone 2</mat-option>
                            <mat-option value="milestone3">Milestone 3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

        <!-- <div>
          <label for="workorderId">Work order number <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select work order number" id="workorderId" formControlName="workorderId">
              <mat-option *ngFor="let workorder of workOrders" [value]="workorder.id">{{ workorder?.workOrderNo
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="
              purchaseOrderForm.get('workorderId')!.touched &&
              purchaseOrderForm.get('workorderId')!.hasError('required')
            ">
            *Work order number is required.
          </mat-error>
        </div> -->

        <div class="cmn-Search-select-container">
          <label for="workorderId">Work order number <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Search work order" id="workorderId" formControlName="workorderId">
              <!-- Search functionality -->
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search work order'"
                  [noEntriesFoundLabel]="'No options found'" [formControl]="workOrderControl">
                </ngx-mat-select-search>
              </mat-option>
              <!-- Work Order Options -->
              <mat-option *ngFor="let workorder of filteredWorkOrders" [value]="workorder.id">
                {{ workorder?.workOrderNo }}-{{ workorder?.projectData?.projectName ?
                workorder?.projectData?.projectName : "NA" }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="purchaseOrderForm.get('workorderId')!.touched && 
              purchaseOrderForm.get('workorderId')!.hasError('required')">*Work order number is required.</mat-error>
            </span>
          </div>
        </div>
        <div>
          <label for="currency">Currency <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select currency" id="currency" formControlName="currency">
              <mat-option *ngFor="let currency of allCurrencies" [value]="currency.id">{{ currency?.currencyCode
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              purchaseOrderForm.get('currency')!.touched &&
              purchaseOrderForm.get('currency')!.hasError('required')
            ">Currency is required.</mat-error>
            </span>
          </div>

        </div>
        <!-- <div>
                    <label for="milestone">Currency</label>
                    <mat-form-field>
                        <input matInput value="AED" id="currency" placeholder="Currency" readonly formControlName="currency" />
                    </mat-form-field>
                    <mat-error
                    *ngIf="
                    purchaseOrderForm.get('currency')!.touched &&
                    purchaseOrderForm.get('currency')!.hasError('required')
                    "
                  >
                  Currency is required.
                  </mat-error>
                </div> -->

        <!-- Due Date Section -->
        <div>
          <label for="date">Bill Date <span class="red">*</span></label>
          <mat-form-field>
            <!-- <input matInput [matDatepicker]="currentDatePicker" id="date"
              formControlName="billDate" placeholder="Select date" (dateChange)="onDateChange($event)"/> -->
            <input matInput [matDatepicker]="currentDatePicker" placeholder="Select date" formControlName="billDate" />
            <!-- <input matInput [value]="currentDate" [matDatepicker]="currentDatePicker" id="date"
              formControlName="billDate" placeholder="Select date" /> -->
            <mat-datepicker-toggle matIconSuffix [for]="currentDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #currentDatePicker></mat-datepicker>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              purchaseOrderForm.get('billDate')!.touched &&
              purchaseOrderForm.get('billDate')!.hasError('required')
            ">*Date is required.</mat-error>
            </span>
          </div>
          
        </div>

        <!-- <div>
          <label for="dueDate">Expected Delivery Date <span class="red">*</span></label>
          <mat-form-field>
            <input matInput [matDatepicker]="dueDatePicker" placeholder="Select date" id="dueDate"
              formControlName="dueDate" />
            <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-error *ngIf="
              purchaseOrderForm.get('dueDate')!.touched &&
              purchaseOrderForm.get('dueDate')!.hasError('required')
            ">
            *Expected delivery date is required.
          </mat-error>
          <mat-error *ngIf="
              purchaseOrderForm
                .get('dueDate')
                ?.hasError('dueDateBeforeBillDate')
            ">
            *Expected delivery date cannot be earlier than the Bill Date.
          </mat-error>
        </div> -->

        <div>
          <label for="shipmentPreference">Shipment Preference</label>
          <mat-form-field>
            <input matInput placeholder="Shipment preference" id="shipmentPreference"
              formControlName="shipmentPreference" />
          </mat-form-field>
        </div>

        <div>
          <label for="paymentTerms">Payment Terms <span class="red">*</span></label>
          <mat-form-field appearance="fill">
            <textarea matInput placeholder="Enter payment terms" id="paymentTerms" formControlName="paymentTerms"
              rows="3"></textarea>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              purchaseOrderForm.get('paymentTerms')!.touched &&
              purchaseOrderForm.get('paymentTerms')!.hasError('required')
            ">*Payment terms is required.</mat-error>
            </span>
          </div>
          
        </div>
      </div>
      <br />
      <br />
      <div><label for="deliveryAddress"><strong>Delivery Address</strong></label>

        <div class="service-address-wrapper">

          <div class="type-selection">
            <label class="radiobtn-wrapper">
              <input type="radio" name="address" (click)="toggleOrgainization()" [checked]="!isCustomerSelected" />
              <span class="radiomark"></span>
              Organization
            </label>
            <label class="radiobtn-wrapper">
              <input type="radio" name="address" (click)="toggleCustomer()" [checked]="isCustomerSelected" />
              <span class="radiomark"></span>
              Customer
            </label>
          </div>

          <!-- Customer List -->
          <div class="form-wrapper invoice-form-wrapper" *ngIf="isCustomerSelected === true">
            <div class="cmn-Search-select-container">
              <mat-form-field>
                <mat-select placeholder="Search customer" id="clientName" formControlName="customerId"
                  (selectionChange)="onCustomerChange($event)">
                  <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No options found'"
                      [formControl]="customerControl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let customer of cutomersData" [value]="customer.id">
                    {{ customer.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="billing-shipping-address-wrapper">
            <div>
              <div class="header-wrapper">
                <h5>Billing Address <span class="red">*</span></h5>
                <div (click)="openSelectBillingAddressDialog()">
                  <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
                </div>
              </div>
              <div>
                <h6>{{ selectedBillingAddress?.attention}}</h6>
                <ul>
                  <li>{{ selectedBillingAddress?.addressLine1 }}</li>
                  <!-- <li>{{ selectedBillingAddress?.addressLine2 }}</li> -->
                  <li>{{ selectedBillingAddress?.city }}</li>
                  <li>{{ selectedBillingAddress?.state }}</li>
                  <li>{{ selectedBillingAddress?.country }}</li>
                  <li>{{ selectedBillingAddress?.phone }}</li>
                  <li>{{ selectedBillingAddress?.fax }}</li>
                </ul>
                <input type="hidden" formControlName="billingAddressId" [value]="selectedBillingAddress?.id" />
                <button (click)="openBillingAddressDialog('add')">
                  <span>Add Address</span>
                </button>

              </div>
            </div>
            <div>
              <div class="header-wrapper">
                <h5>Shipping Address <span class="red">*</span></h5>
                <div (click)="openSelectShippingAddressDialog()">
                  <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
                </div>
              </div>
              <div>
                <h6>{{ selectedShippingAddress?.attention }}</h6>
                <ul>
                  <li>{{ selectedShippingAddress?.addressLine1 }}</li>
                  <!-- <li>{{ selectedShippingAddress?.addressLine2 }}</li> -->
                  <li>{{ selectedShippingAddress?.city }}</li>
                  <li>{{ selectedShippingAddress?.state }}</li>
                  <li>{{ selectedShippingAddress?.country }}</li>
                  <li>{{ selectedShippingAddress?.phone }}</li>
                  <li>{{ selectedShippingAddress?.fax }}</li>
                </ul>
                <input type="hidden" formControlName="shippingAddressId" [value]="selectedShippingAddress?.id" />
                <button (click)="openShippingAddressDialog('add')">
                  <span>Add Address</span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="service-address-wrapper">
                <h5>Service Address</h5>
                <div>
                    <div class="form-label-group" >
                        <label for="">
                            <input type="radio" id="radio" name="radio" (click)="setAdminAddress()" >
                            <span>Organization</span>
                        </label>
                        <div *ngFor="let address of AdminAddressData">
                            <div>
                                <h6>{{ address?.attention }} </h6>
                                <div>
                                    <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="">
                                </div>
                            </div>
    
                            <p>{{ address?.addressLine1 }} {{ address?.addressLine2 }}</p>
                            <p>{{ address?.state }} {{ address?.pinCode }}</p>
                        </div>

                    </div>

                    <div>
                        <label for="">
                            <input type="radio" id="radio1" name="radio" (click)="selectCustomer()">
                            <span>Customer</span>
                        </label>
                    </div>

                    <div class="form-wrapper invoice-form-wrapper">
                    <div class="cmn-Search-select-container" *ngIf="isCustomerSelected">
                        <label for="clientName">Customer Name</label>
                        <mat-form-field>
                            <mat-select placeholder="Search Client" id="clientName">
                                <mat-option>
                                    <ngx-mat-select-search></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let customer of cutomersData" [value]="customer.id">
                                    {{ customer.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                </div>
            </div> -->



      <div class="form-wrapper">
        <label for="subject">Subject</label>
        <mat-form-field>
          <textarea matInput placeholder="Enter Subject" id="subject" formControlName="subject"></textarea>
        </mat-form-field>
      </div>
    </div>
    <!-- Checkbox for isVat -->
    <!-- <div class="form-wrapper">
      <mat-checkbox formControlName="isVat" id="isVat">Include VAT</mat-checkbox>
    </div> -->





    <div class="item-service-adding-table-wrapper">
      <!-- <table>
        <thead>
          <tr>
            <th>Item/Service <span class="red">*</span></th>
            <th>Quantity <span class="red">*</span></th>
            <th>Unit <span class="red">*</span></th>
            <th>Rate <span class="red">*</span></th>
            <th>Gross Price <span class="red">*</span></th>
            <th>Discount</th>
            <th>Net Price</th>
            <th>VAT(5%)</th>
            <th>Amount<span class="red">*</span></th>
            <th>Subject</th>
            <th></th>
          </tr>
        </thead>

        <tbody formArrayName="items">
          <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
            <td>
              <div class="form-wrapper">
                <div>
                  <mat-form-field>
                    <input matInput placeholder="Select or Type Item" [matAutocomplete]="auto"
                      formControlName="itemName" (input)="onItemSearch($event)"
                      value="{{item.get('itemName')?.value}}" />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectItem($event)">
                      <mat-option *ngFor="let item of filteredItemData" [value]="item.id">
                        {{ item.itemName }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="item.get('itemName')?.errors?.['required']">
                      *Item is required.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="quantity" formControlName="quantity" />
                  <mat-error *ngIf="
                      item.get('quantity')?.touched &&
                      item.get('quantity')?.hasError('required')
                    ">
                    *Quantity is required. </mat-error><mat-error *ngIf="item.get('quantity')?.hasError('nonNegative')">
                    *Quantity cannot be negative.
                  </mat-error>
                  <mat-error *ngIf="item.get('quantity')?.hasError('pattern')">
                    *Quantity must be a valid number.
                  </mat-error>
                </mat-form-field>
              </div>
            </td>

            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <mat-select placeholder="Enter Unit" formControlName="unit">
                    <mat-option *ngFor="let unit of unitTypes" [value]="unit.value">
                      {{ unit.value }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="item.get('unit')?.hasError('required')">
                    *Unit is required
                  </mat-error>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="Rate" formControlName="rate" />
                  <mat-error *ngIf="
                      item.get('rate')?.touched &&
                      item.get('rate')?.hasError('required')
                    ">
                    *Rate is required. </mat-error><mat-error *ngIf="item.get('rate')?.hasError('nonNegative')">
                    *Rate cannot be negative.
                  </mat-error>
                  <mat-error *ngIf="item.get('rate')?.hasError('pattern')">
                    *Rate must be a valid number .
                  </mat-error>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="0" formControlName="subTotal" type="number" />
                  <mat-error *ngIf="item.get('subTotal')?.hasError('nonNegative')">*sub total cannot be negative.
                  </mat-error>
                  <mat-error *ngIf="
                      item.get('subTotal')?.hasError('pattern') &&
                      item.get('subTotal')?.touched
                    ">*Please enter a valid sub total.
                  </mat-error>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <div class="split-parent">
                  <div class="left-set">
                    <mat-form-field>
                      <input matInput placeholder="0.00" formControlName="discount" type="number" />
                      <mat-error *ngIf="item.get('discount')?.hasError('nonNegative')">*Discount cannot be negative.
                      </mat-error><mat-error *ngIf="
                          item.get('discount')?.hasError('max') &&
                          item.get('discount')?.touched
                        ">*Discount cannot be greater than 100%.</mat-error>
                      <mat-error *ngIf="
                          item.get('discount')?.hasError('pattern') &&
                          item.get('discount')?.touched
                        ">*Please enter a valid discount amount.</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="Net price" formControlName="netPrice" type="number" />
                </mat-form-field>
              </div>
            </td>


            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="100" formControlName="vatAmount" type="number" />
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="100" formControlName="total" type="number" />
                  <mat-error *ngIf="
                      item.get('total')?.touched &&
                      item.get('total')?.hasError('required')
                    ">
                    *Total is required.
                  </mat-error>
                </mat-form-field>
              </div>
            </td>


            <td>
              <div class="form-wrapper">
                <div>
                  <mat-form-field>
                    <input matInput placeholder="Select or type" [matAutocomplete]="subAuto"
                      formControlName="subSubject"  (input)="onSubSubjectSearch($event)"
                      value="{{ item.get('subSubject')?.value }}" />
                    <mat-autocomplete #subAuto="matAutocomplete" (optionSelected)="onSelectSubSubject($event)">
                      <mat-option *ngFor="let sub of filteredSubSubjectList" [value]="sub">
                        {{ sub }}
                      </mat-option>
                    </mat-autocomplete>

                  </mat-form-field>
                </div>
              </div>
            </td>

            <td>
              <img src="../../../assets/images/icons/icon-close.svg" alt="Remove" (click)="removeItem(i)" />
            </td>
          </tr>
        </tbody>
      </table> -->


      <!-- <button type="button" (click)="openDialog()">
        <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" />
        Add Items
      </button> -->
      
    </div>

    <div class="quotation-table-container">
      <div>
        <table>
          <thead>
            <tr>
              <th>SL No. </th>
              <th>Description</th>
              <th>Unit </th>
              <th>QTY </th>
              <th>Rate </th>
              <!-- <th>Adjusted</th> -->
              <th>Gross Price</th>
              <th>Discount</th>
              <th>Net Price</th>
               <th>Adjusted</th>
              <th>VAT</th>
              <th>Amount</th>
              <th>Subject</th>
              <th>Action </th>
              <th></th>
            </tr>
          </thead>

          <!-- <tbody *ngIf="purchaseOrderData">
            <tr *ngFor="let item of purchaseOrderData.purchaseItems; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item?.item.itemName }}</td>
              <td>{{ item?.unit }}</td>
              <td>{{ item?.quantity }}</td>
              <td> {{item?.actualRate.toFixed(2) }}</td>
              <td>{{ item?.rate.toFixed(2) }}</td>
              <td>{{ (item?.rate * item?.quantity)?.toFixed(2) }}</td>
              <td>{{ item.discount.toFixed(2) }}</td>
              <td>{{ ((item?.rate * item?.quantity) - item?.discount)?.toFixed(2) }}</td>
              <td>{{ item?.vat }}</td>
              <td>{{( ((item?.rate * item?.quantity) - item?.discount) + item?.vat)?.toFixed(2) }}</td>
              <td>{{ item?.subjectList || "NA" }}</td>
              <td>
                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                  (click)="onRemoveInvoiceItem(i, item?.id)" />
              </td>
            </tr>
          </tbody> -->
          
          <tbody *ngIf="newPurchaseItems && newPurchaseItems.length > 0">
            <tr *ngFor="let item of newPurchaseItems; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item?.itemName }}</td>
              <td>{{ item?.unit }}</td>
              <td>{{ item?.quantity }}</td>
              <td> {{item?.actualRate.toFixed(2) }}</td>
              <!-- <td>{{ item?.rate.toFixed(2) }}</td> -->
              <td>{{ (item?.actualRate * item?.quantity)?.toFixed(2) }}</td>
              <td>{{ item?.discount ? item?.discount : 0.00 }}</td>
              <td>{{ ((item?.actualRate * item?.quantity) - item?.discount)?.toFixed(2) }}</td>
              <td>{{ ((item?.rate * item?.quantity) - item?.discount)?.toFixed(2) }}</td>
              <td>{{ (item?.vatAmount)?.toFixed(2) }}</td>
              <td>{{ (((item?.rate * item?.quantity) - item?.discount) + item?.vatAmount)?.toFixed(2)  }}</td>
              <td>{{ item.itemId ? item?.subjectList : item?.subject }}</td>
              <td>
                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                  (click)="removeNewPurchaseItem(i)" />
              </td>
            </tr>
          </tbody>


          <!-- <tbody *ngIf="purchaseOrderData">
            <tr *ngFor="let item of purchaseOrderData.purchaseItems; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item?.item.itemName }}</td>
              <td>{{ item?.unit }}</td>
              <td>{{ item?.quantity }}</td>
              <td>{{ item?.rate }}</td>
              <td>{{ item?.rate  *  item?.quantity}}</td>
              <td>{{ item.discount }}</td>
              <td>{{ ( item?.rate  *  item?.quantity) - item?.discount }}</td>
              <td> {{ item?.vat }}</td>
              <td>{{ item?.amount }}</td>
              <td>{{ item?.subjectList || "NA" }}</td>
              <td>
                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                  (click)="onRemoveInvoiceItem(i, item?.id)" />
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="newPurchaseItems && newPurchaseItems.length > 0">
            <tr *ngFor="let item of newPurchaseItems; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item?.itemName }}</td>
              <td>{{ item?.unit }}</td>
              <td>{{ item?.quantity }}</td>
              <td>{{ item?.rate }}</td>
              <td>{{ item?.rate  *  item?.quantity}}</td>
              <td>{{ item?.discount ? item?.discount : 0.00 }}</td>
              <td>{{ ( item?.rate  *  item?.quantity) - item?.discount }}</td>
              <td>{{  item?.vatAmount }} </td>
              <td>{{ item?.total }}</td>
              <td>{{ item?.subject || "NA" }}</td>
              <td>
                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left" (click)="removeNewPurchaseItem(i)"/>
              </td>
            </tr>
          </tbody> -->

        </table>
      </div>
    </div>

<div class="item-service-adding-table-wrapper">
  <button (click)="openDialog()">
    <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" />Add Items
  </button>
</div>

    <div class="notes-tatal-amount-container">
      <div>
        <div class="form-container">
          <div class="form-wrapper">
            <label for="customerNotes">Terms And Conditions</label>
            <mat-form-field>
              <textarea matInput placeholder="Enter your notes here..." formControlName="customerNotes"></textarea>
            </mat-form-field>
          </div>
          <!-- <div class="form-wrapper">
                    <label for="termsConditions">Payment Term</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"> The customer pays the invoice within 30 days of the invoice date</textarea>
                    </mat-form-field>
                </div> -->
          <!-- <div class="form-wrapper">
                    <label for="termsConditions">Terms & Conditions</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"></textarea>
                    </mat-form-field>
                </div>  -->
        </div>

      <!-- ADDITIONAL DISCOUNT -->
      <div class="form-container">
        <div  class="form-wrapper">
          <label >Additional Discount</label>
          <mat-form-field>
            <mat-label>Additional Discount</mat-label>
            <input matInput type="number" formControlName="additionalDiscount" (blur) = "calculateLogic()" />
          </mat-form-field>
        </div>
      </div>
      
    </div>
      <!-- <div class="amount-table-div">
        <div>
          <h6>
            Total Amount <span>: AED {{ getSubTotal().toFixed(2) | number:'1.2-2'  }}</span>
          </h6>
          <h6>
            Discount (-) <span>: AED {{ getDiscount().toFixed(2) | number:'1.2-2' }}</span>
          </h6>
          <h6>
            VAT (5%) <span>: AED {{ getVAT().toFixed(2) | number:'1.2-2'  }}</span>
          </h6>
        </div>
        <div>
          <h4>
            Grand Total <span>: AED {{ getGrandTotal().toFixed(2) | number:'1.2-2' }}</span>
          </h4>
        </div>
      </div> -->
      <div class="amount-table-div">
        <div>
          <h6>
            Total Amount <span>: {{ selectedCurrencyCode }} {{ subTotal.toFixed(2) | number:'1.2-2' }}</span>
          </h6>
          <h6>
            Discount (-) <span>: {{ selectedCurrencyCode }} {{(totalDiscount - additionalDiscount) | number:'1.2-2'}}</span>
          </h6>
          <h6>
            Additional Discount (-) <span>: {{ selectedCurrencyCode }} {{additionalDiscount | number:'1.2-2'}}</span>
          </h6>
          <h6>
            VAT (5%) <span>: {{ selectedCurrencyCode }} {{ totalVat.toFixed(2) | number:'1.2-2'}}</span>
          </h6>
        </div>
        <div>
          <h4>
            Grand Total <span>: {{ selectedCurrencyCode }} {{ grandTotal.toFixed(2) | number:'1.2-2'}}</span>
          </h4>
        </div>
      </div>



      <!-- <div class="amount-table-div">
        <div>
          <h6>
            Total Amount <span>: {{ selectedCurrencyCode }} {{ getSubTotal().toFixed(2) | number:'1.2-2' }}</span>
          </h6>
          <h6>
            Discount (-) <span>: {{ selectedCurrencyCode }} {{ getDiscount().toFixed(2) | number:'1.2-2' }}</span>
          </h6>
          <h6>
            VAT (5%) <span>: {{ selectedCurrencyCode }} {{ getVAT().toFixed(2) | number:'1.2-2'}}</span>
          </h6>
        </div>
        <div>
          <h4>
            Grand Total <span>: {{ selectedCurrencyCode }} {{ getGrandTotal().toFixed(2) | number:'1.2-2'}}</span>
          </h4>
        </div>
      </div> -->


    </div>

    <!-- <div class="payment-mode-wrapper"> -->
    <!-- Email Connection Section -->
    <!-- <div class="add-email-wrapper">
                <h6>Email Connection</h6>
                <div class="flex-wrapper-container">
                    <div class="email-account-div">
                        <div class="profile-image">
                            <img src="../../../assets/images/profile.png" alt="Profile Image">
                        </div>
                        <span>rikson&#64;gmail.com</span>
                        <div class="close-btn">
                            <img src="../../../assets/images/icons/icon-close.svg" alt="Remove Email">
                        </div>
                    </div>
                    <button>
                        <div>
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add Email">
                        </div>
                        <span>Add email</span>
                    </button>
                </div>
            </div>

        </div> -->

    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
        Cancel
      </button>
      <button class="cmn-draft-btn" [disabled]="loading" type="button" (click)="saveAsDraft()">
        <span *ngIf="!loading">Save As Draft</span>
        <span *ngIf="loading">Loading...</span>
       
      </button>
      <!-- <button class="cmn-next-btn" type="submit">Save and Send</button> -->
      <button class="cmn-next-btn" type="button" (click)="saveAndSend()" [disabled]="loading">
        <span *ngIf="!loading">Save and Send</span>
        <span *ngIf="loading">Loading...</span>
      </button>
    </div>
  </form>
</section>