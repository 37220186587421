<mat-dialog-content class="mat-typography">
    <section class="set-login-dialog-wrapper">
        <h2 class="cmn-popup-title">Set Login</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form>
            <div class="branch-form-wrapper">
                <div class="form-wrapper w-full">
                    <label for="">Username</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Username" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper w-full">
                    <label for="">Password</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Password" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper w-full">
                    <label for="">Profile <span class="red">*</span></label>
                    <mat-form-field>
                        <mat-select placeholder="Select Profile">
                            <mat-option value="Option1">Option 1</mat-option>
                            <mat-option value="Option2">Option 2</mat-option>
                            <mat-option value="Option3">Option 3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="check-wrapper w-full">
                    <h2>Branch</h2>
                    <div class="check-flex-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />All
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />Munawel Electromechanical Works LLC
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />Munawel Interiors LLC
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />Munawel Interiors LLC - Sharjah BR
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />Munawel Metal Industries LLC
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-wrapper">
                <button class="cmn-next-btn">
                    Submit
                </button>
            </div>
        </form>
    </section>
</mat-dialog-content>