import { Component, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { generatedNo } from '../../helpers/helper';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { PartnerTypesEnum } from '../../helpers/helper-file';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBillingAddressDialog } from '../invoice-new/dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { BillingAddressDialog } from '../invoice-new/dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { ListShippingAddressDialog } from '../invoice-new/dialog-components/list-shipping-address-dialog/list-shipping-address-dialog.component';
import { ShippingAddressDialog } from '../invoice-new/dialog-components/add-shipping-address-dialog/shipping-address-dialog.component';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PreferenceService } from '../../shared/services/preference.service';

@Component({
  selector: 'app-credit-note-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
  ],
  templateUrl: './credit-note-new.component.html',
  styleUrl: './credit-note-new.component.scss',
})
export class CreditNoteNewComponent {
  public arr = [1, 2, 3, 4, 5, 6, 7];

  readonly dialog = inject(MatDialog);
  private partnerTypes = PartnerTypesEnum;
  FormGroupItems: FormGroup;
  partnersList: any;
  customerSearchControl = new FormControl();
  itemData: any[] = [];
  accountData: any[] = [];
  public billingAddressList: any[];
  public shippingAddressList: any[];
  public selectedBillingAddress: any = {};
  public selectedShippingAddress: any = {};
  selectedCustomerId: any;
  VAT = '5%';
  public filteredItemData: any[] = [];
  NoteData: any;
  addedItems: any[] = [];
  private status: 'DRAFT' | 'SENT' = 'SENT';
  creditNoteNo: any;
  prefix: any;
  noteId: any;

  constructor(
    private fb: FormBuilder,
    private itemService: CreditDebitNoteService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private preferenceService: PreferenceService
  ) {
    this.initForm();
    this.fetchPartners();
    this.fetchItems();
    this.fetchAccounts();
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      if(id){
        this.noteId = parseInt(id);
        this.FormGroupItems.get('id')?.setValue(this.noteId);
        this.loadNoteData();
      }
    });

    this.addItem();
    
    if(!this.noteId){
      this.preferenceCode();
    }
    // const generatedId = generatedNo('CN');
    // this.FormGroupItems.get('creditNoteNo')?.setValue(generatedId);

    this.customerSearchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchText) => {
        this.fetchPartners(searchText);
      });

    this.FormGroupItems.get('customerName')?.valueChanges.subscribe(
      (partnerId) => {
        this.setAddresses(partnerId);
      }
    );

    
    this.items.valueChanges.subscribe(() => {
      this.getTotalAmount();
  });
  }

  openCreateClientDialog() {
    this.dialog.open(CreateClientDialog,{
      disableClose: true,
    });
  }

    preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Credit Note').subscribe({
      next: (response) => {
        console.log(response);
        
        response.forEach((prefix: any) => {
          this.creditNoteNo = prefix?.prefix + '-' + prefix?.sequenceCode  ;
        })
        this.prefix = `${this.creditNoteNo}`;
        this.FormGroupItems.patchValue({ creditNoteNo: this.prefix });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }


  initForm(): void {
    this.FormGroupItems = this.fb.group({
      id: [''],
      customerName: ['', Validators.required],
      creditNoteNo: ['',[ Validators.required,Validators.maxLength(10)]],
      reference: ['', [Validators.maxLength(10)]],
      creditNoteDate: [null, Validators.required],
      subject: ['', Validators.required],
      billingAddressId: [''],
      shippingAddressId: [''],
      items: this.fb.array([]),
      customerNote: [''],
      termsConditions: [''],
    });
  }

  
  loadNoteData(){
    if(this.noteId !== null ) {
      this.itemService.fetchNote(this.noteId).subscribe({
        next: (data) => {
          this.NoteData= data;          
          this.FormGroupItems.patchValue({
            id: data?.id,
            customerName: data?.partnerId,
            creditNoteNo: data?.noteNo,
            reference: data.referenceId,
            creditNoteDate: data?.noteDate ? new Date(data.noteDate) : null, 
            subject: data.subject,
            billingAddressId: data.billingAddressId,
            shippingAddressId: data.shippingAddressId,
            customerNote: data.customerNotes,
          });
          if (data.noteItems) {

            data.noteItems.forEach((item: any) => {
              console.log('itemData---', item);
              const itemForm = this.fb.group({
                id: [item.id || null],
                itemName: [item?.itemData?.itemName || 'Unknown Item'],
                selectItem: [item?.itemId, Validators.required],
                account: [item?.accountId, Validators.required],
                quantity: [item.quantity, Validators.required],
                rate: [item.rate, Validators.required],
                discount: [ item.discount, Validators.pattern(/^\d+(\.\d+)?$/), ],
                total: [ item.amount, [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)],],
                subTotal: [item.quantity*item.rate, Validators.required],
              });

              // this.items.push(itemForm);
              this.addedItems.push(itemForm);
              this.calculateAmount(itemForm);



              // Sync rate and quantity when itemId changes
              itemForm.get('itemId') ?.valueChanges.subscribe(() =>this.calculateAmount(itemForm));
              itemForm.get('rate')?.valueChanges.subscribe(() =>  this.calculateAmount(itemForm) );
              itemForm.get('quantity') ?.valueChanges.subscribe(() =>this.calculateAmount(itemForm));
              itemForm.get('discount')?.valueChanges.subscribe(() =>this.calculateAmount(itemForm) );
            });
          }

          
        },
       });
    }

  }
  
  onSubmit(): void {
    if (this.FormGroupItems.invalid) {
      this.FormGroupItems.markAllAsTouched();
      this.toastr.warning('Please fill in all required fields.');
      return;
    }
    else {
      const data = this.FormGroupItems.value;

      console.log(data);
  
      const subtotal = this.getSubTotal();
      const taxRate = 0.05; // 5%
      const tax = this.getVAT();
      const totalPrice = this.getGrandTotal();
      console.log("totalPrice",totalPrice);
      
  
      // const apiObjectArray = data?.items.map((item: any) => ({
      //   accountId: item?.account,
      //   itemId: item.selectItem,
      //   quantity: item.quantity,
      //   rate: Number(item.rate),
      //   discount: 0.0,
      //   discountPrice: 0.0,
      //   amount: item.total,
      // }));
  
      const createNoteInput = {
        noteType: 'Credit',
        partnerId: data?.customerName,
        noteNo: data?.creditNoteNo,
        referenceId: data?.reference,
        noteDate: data?.creditNoteDate,
  
        subject: data?.subject,
        customerNotes: data?.customerNote,
        termsAndConditions: data?.termsConditions,
        billingAddressId: data?.billingAddressId,
        shippingAddressId: data?.shippingAddressId,
  
        subTotal: subtotal,
        tax: tax,
        adjustments: 0.0,
        totalPrice: totalPrice,
        balance: totalPrice,
      };
  
      
      const noteItems = data?.items.map((item: any) => {
        if (item.selectItem) {
          // Item selected from dropdown
          return {
            itemId: item.selectItem,
            itemName: item.itemName,
            quantity: item.quantity,
            rate: Number(item.rate),
            accountId: item.account,
            discount: item.discount || 0,
            amount: item.total,
            // vat: item.vatAmount
          };
        } else {
          // Manually created item
          return {
            itemName: item.itemName,
            quantity: item.quantity,
            rate: Number(item.rate),
            accountId: item.account,
            discount: item.discount || 0,
            amount: item.total,
            // vat: item.vatAmount
          };
        }
      });

      const updateNoteInput = {
        id: this.noteId,
        ...createNoteInput
      }
  
      const updateItemInput = this.getUpdateNoteItemInput();


      if(this.noteId){
       this.itemService.updatenote(updateNoteInput,updateItemInput).subscribe({

        next: (data) => {
          console.log(data);
          this.toastr.success('Updated successfully.');
            this.router.navigate(['/debit-note']);
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to Update.');
        },
        
      });
        
      }
      else{
        this.itemService.createnote(createNoteInput, noteItems).subscribe({
        next: () => {
          this.toastr.success('Created successfully.');
          if (this.status === 'DRAFT') {
            this.router.navigate(['/credit-note/credit-note-details']);
          }
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to Create.');
        },
      });
      }


    }
  }

  getUpdateNoteItemInput(): any[] {
    return this.items.controls.map((itemGroup) => {
      const id = itemGroup.get('id')?.value; // The ID is for existing items
      const itemId = itemGroup.get('selectItem')?.value; // For the dropdown selected item
      const itemName = itemGroup.get('itemName')?.value; // For manual entry
      const quantity = itemGroup.get('quantity')?.value;
      const accountId = itemGroup.get('account')?.value;
      const rate = itemGroup.get('rate')?.value;
      const discount = itemGroup.get('discount')?.value;
      const amount = itemGroup.get('total')?.value;
  
      // Handle existing item
      if (id) {
        return {
          id, // Keep the existing item's ID
          quantity,
          itemName,
          rate,
          discount,
          amount,
          accountId
        };
      }
  
      // Handle new items, whether selected from dropdown or manually entered
      return itemId
        ? { // Item selected from dropdown
            itemId,
            quantity,
            accountId,
            itemName,
            rate,
            discount,
            amount,
        }
        : { // Manually created item (with itemName)
            itemName,
            quantity,
            rate,
            discount,
            amount,
            accountId
        };
    });
  }
  
  saveAsDraft(){
    this.status = 'DRAFT';
    this.onSubmit();
    this.router.navigate(['credit-note/credit-note-details']);
  }




  // private loadCreditNoteData(): void {
  //   const id = this.FormGroupItems.get('id')?.value;
  //   if (id) {
  //     this.itemService.fetchNote(parseInt(id)).subscribe({
  //       next: (data) => {
  //         console.log(data);

  //         this.FormGroupItems.patchValue({
  //           id: data?.id,
  //           customerName: data?.partnerId,
  //           creditNoteNo: data?.noteNo,
  //           reference: data.referenceId,
  //           creditNoteDate: new Date(Number(data?.noteDate)),
  //           subject: data.subject,
  //           billingAddressId: data.billingAddressId,
  //           shippingAddressId: data.shippingAddressId,
  //           customerNote: data.customerNotes,
  //         });
  //         if (data.noteItems) {

  //           data.noteItems.forEach((item: any) => {
  //             console.log('itemData---', item);
  //             const itemForm = this.fb.group({
  //               id: [item.id || null],
  //               itemName: [item?.itemData?.itemName || 'Unknown Item'],
  //               selectItem: [item?.itemId, Validators.required],
  //               account: [item?.accountId, Validators.required],
  //               isManual: [item.isManual || false],
  //               quantity: [item.quantity, Validators.required],
  //               rate: [item.rate, Validators.required],
  //               discount: [
  //                 item.discount,
  //                 Validators.pattern(/^\d+(\.\d+)?$/),
  //               ],
  //               total: [
  //                 item.amount,
  //                 [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)],
  //               ],
  //             });

  //             this.items.push(itemForm);
  //             this.calculateAmount(itemForm);

  //             // Handle dynamic validation based on `isManual`
  //             itemForm.get('isManual') ?. valueChanges.subscribe((isManual) => {
  //                 const itemIdControl = itemForm.get('selectItem');
  //                 if (isManual) {
  //                     itemIdControl ?. clearValidators();
  //                     itemIdControl ?. setValue(null);
  //                 } else {
  //                     itemIdControl ?. setValidators(Validators.required);
  //                 } itemIdControl ?. updateValueAndValidity();
  //             });

  //             // Sync rate and quantity when itemId changes
  //             itemForm.get('itemId') ?.valueChanges.subscribe(() =>this.calculateAmount(itemForm));
  //             itemForm.get('rate')?.valueChanges.subscribe(() =>  this.calculateAmount(itemForm) );
  //             itemForm.get('quantity') ?.valueChanges.subscribe(() =>this.calculateAmount(itemForm));
  //             itemForm.get('discount')?.valueChanges.subscribe(() =>this.calculateAmount(itemForm) );
  //           });
  //         }

          
  //       },
  //       error: (error) => console.error(error),
  //     });
  //   }
  // }

      // Method to disable future dates
    disableFutureDates = (date: Date | null): boolean => {
        const today = new Date();
        return date ? date <= today : false;
    };

  get items(): FormArray {
    return this.FormGroupItems.get('items') as FormArray;
  }

  onSelectItem(event: MatAutocompleteSelectedEvent): void {
    const selectedItem = this.itemData.find(
      (item) => item.id === event.option.value
    );

    console.log("selectedItem", selectedItem);
    
    if (selectedItem) {
      const itemGroup = this.items.at(this.items.length - 1);
      itemGroup.patchValue({
        selectItem: selectedItem.id,
        quantity: 1,
        itemName: selectedItem.itemName,
        isManual: false,
      });
    }
  }
  
  onItemSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;

    if (searchTerm) {
      this.fetchItems(searchTerm);
    } else {
      this.filteredItemData = this.itemData;
    }
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      selectItem: [null],
      itemName: [''], // Optional for manual entry
      account: ['', Validators.required],
      description: [''],
      quantity: [0, [Validators.required,Validators.min(1), Validators.pattern(/^(?!.*e)[0-9]+(\.[0-9]{1,2})?$/)]],
      rate: [0, [Validators.required, Validators.min(0),  Validators.pattern(/^(?!.*e)[0-9]+(\.[0-9])?$/),]],
      discount: [0, [Validators.min(0)]],
      total: [0, [Validators.required, Validators.min(0)]],
      subTotal: [0, [Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
      vatAmount: [0],
      netPrice: [''],
      
    });

    this.items.push(itemGroup);

    // Subscribe to changes in 'selectItem' to autofill other fields
    itemGroup.get('selectItem')?.valueChanges.subscribe((itemId) => {
      const selectedItem = this.itemData.find((item) => item.id === itemId);
      if (selectedItem) {
        itemGroup.patchValue({
          quantity: selectedItem.quantity,
          rate: selectedItem.sellingPrice,
          discount: selectedItem.discount,
          total: +selectedItem.quantity * +selectedItem.sellingPrice,
          subTotal: +selectedItem.quantity * +selectedItem.sellingPrice
        });
        this.calculateAmount(itemGroup);
      }
    });
    
    itemGroup.get('rate') ?. valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup.get('quantity') ?. valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup.get('discount') ?. valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  onCustomerSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.selectedCustomerId = selectedCustomerId;
  }

  private fetchPartners(search: string = '') {
    this.itemService
      .fetchPartners(search, this.partnerTypes.Customer)
      ?.subscribe({
        next: (partners: any) => {
          this.partnersList = partners;
          this.billingAddressList = partners
            .flatMap((partner: any) => partner?.addresses || [])
            .filter((address: any) => address?.addressType === 'BILLING');
          this.shippingAddressList = partners
            .flatMap((partner: any) => partner?.addresses || [])
            .filter((address: any) => address?.addressType === 'SHIPPING');
        },
        error: (error: any) => console.error(error),
      });
  }

  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isSalesActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.itemData = items;
        this.filteredItemData=items;
      },
      error: (error) => console.error(error),
    });
  }

  private fetchAccounts(search: string = '') {
    this.itemService.chartOfAccounts(null, search, null, null).subscribe({
      next: (accounts) => {
        this.accountData = accounts;
      },
      error: (error) => console.error(error),
    });
  }

  setAddresses(partnerId: number): void {
    const selectedPartner = this.partnersList.find(
      (p: any) => p.id === partnerId
    );

    if (selectedPartner) {
      if (
        Array.isArray(selectedPartner.addresses) &&
        selectedPartner.addresses.length > 0
      ) {
        this.selectedBillingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'BILLING'
          ) || null;
        this.selectedShippingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'SHIPPING'
          ) || null;

        this.FormGroupItems.patchValue({
          billingAddressId: this.selectedBillingAddress?.id || null,
          shippingAddressId: this.selectedShippingAddress?.id || null,
        });
      } else {
        console.error(
          `No addresses found or addresses is not an array for partnerId: ${partnerId}`
        );
      }
    } else {
      console.error(`Partner with ID ${partnerId} not found in partnersData.`);
    }
  }

  openSelectBillingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        console.log;
        this.selectedBillingAddress = address;
        this.FormGroupItems.patchValue({ shippingAddressId: address.id });
      }
    });
  }

  openBillingAddressDialog(type: any) {
    const selectedClientId = this.FormGroupItems.get('customerName')?.value;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openSelectShippingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'SHIPPING';

    const dialogRef = this.dialog.open(ListShippingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        console.log;
        this.selectedShippingAddress = address;
        this.FormGroupItems.patchValue({ shippingAddressId: address.id });
      }
    });
  }

  openShippingAddressDialog(type: any) {
    const selectedClientId = this.FormGroupItems.get('customerName')?.value;
    this.dialog.open(ShippingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }
  
  onRemoveInvoiceItem(index: number, itemId: number): void {
    if (itemId) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeItemFormLits(itemId, index);
        }
      });
    } else {
      console.error('Item ID is null or undefined');
      this.items.removeAt(index);
    }
  }

  
  private removeItemFormLits(itemId: number, index: number): void {
    this.itemService.removeNoteItem(itemId).subscribe({
      next: () => {
        this.toastr.success('Item removed successfully.');
        console.log('Item  item removed successfully');

      // Ensure `NoteData` and `noteItems` are replaced immutably
        this.NoteData = {
          ...this.NoteData, // Clone the parent object
          noteItems: [
            ...this.NoteData.noteItems.slice(0, index),
            ...this.NoteData.noteItems.slice(index + 1),
          ],
        };
      },
      error: () => {
        this.toastr.error('Failed to remove item.');
        console.log('Failed to remove item.');
      },
    });
  }

  getTotalAmount(): number {
    // Calculate total for newly added items
    const newItemsTotal = this.items.controls.reduce((total, itemGroup) => {
      const rate = parseFloat(itemGroup.get('rate')?.value || '0');
      const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
      const discount = parseFloat(itemGroup.get('discount')?.value || '0');
      const subTotal = rate * quantity;
      return total + (subTotal - discount); // Total after discount
    }, 0);
  
    // Calculate total for already added items in purchaseOrderData
    const existingItemsTotal = this.NoteData?.noteItems.reduce(
      (total: any, item: any) => {
        const rate = parseFloat(item.rate || '0');
        const quantity = parseFloat(item.quantity || '0');
        const discount = parseFloat(item.discount || '0');
        const subTotal = rate * quantity;
        return total + (subTotal - discount); // Total after discount
      },
      0
    );
  
    // Return combined total
    return newItemsTotal + (existingItemsTotal || 0);
  }
  
  getSubTotal(): number {
    // Calculate subtotal for newly added items
    const newItemsSubtotal = this.items.controls.reduce((subtotal, item) => {
      const quantity = parseFloat(item.get('quantity')?.value || '0');
      const rate = parseFloat(item.get('rate')?.value || '0');
      return subtotal + quantity * rate;
    }, 0);
  
    // Calculate subtotal for already added items in purchaseOrderData
    const existingItemsSubtotal = this.NoteData?.noteItems.reduce(
      (subtotal: any, item: any) => {
        const quantity = parseFloat(item.quantity || '0');
        const rate = parseFloat(item.rate || '0');
        return subtotal + quantity * rate;
      },
      0
    );
  
    // Return the total subtotal
    return newItemsSubtotal + (existingItemsSubtotal || 0);
  }
  
  getDiscount(): number {
    // Calculate discount for newly added items
    const newItemsDiscount = this.items.controls.reduce((discount, itemGroup) => {
      return discount + parseFloat(itemGroup.get('discount')?.value || '0');
    }, 0);
  
    // Calculate discount for already added items
    const existingItemsDiscount = this.NoteData?.noteItems.reduce(
      (discount: any, item: any) => {
        return discount + parseFloat(item.discount || '0');
      },
      0
    );
  
    // Return total discount
    return newItemsDiscount + (existingItemsDiscount || 0);
  }
  
  getVAT(): number {
    const totalAfterDiscount = this.getTotalAmount(); // Total after applying discounts
    return (totalAfterDiscount * 5) / 100; // VAT = Total after Discount * 5%
  }
  
  getGrandTotal(): number {
    const totalAfterDiscount = this.getTotalAmount();
    const vat = this.getVAT(); // VAT is calculated on the discounted total
    return totalAfterDiscount + vat; // Grand Total = Total after Discount + VAT
  }
   
  calculateAmount(itemGroup: FormGroup): void {
    const rate = parseFloat(itemGroup.get('rate')?.value || '0');
    const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
    const discount = parseFloat(itemGroup.get('discount')?.value || '0');
    
    const subTotal = rate * quantity; // Subtotal = rate * quantity
    let netPrice = subTotal - discount; // Total after discount
    
    const vatRate = 5; // VAT rate in percentage
    const vatAmount = (netPrice * 0.05).toFixed(2); // VAT = Total after discount * VAT rate
    
    const total = netPrice + Number(vatAmount); // Net Price = Total after discount + VAT
    
    // Patch values for subtotal, VAT amount, total, and net price
    itemGroup.patchValue({ subTotal, vatAmount, total, netPrice });
  }

  
onCancel(): void {
  this.FormGroupItems.reset();
  this.router.navigate(['/credit-note']);
}

}
