import { Component, EventEmitter, Inject, inject, Input, Output, SimpleChanges, ViewChild, viewChildren } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BankingService } from '../../features/banking/services/banking.service';

@Component({
  selector: 'app-update-banking-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    AgGridAngular,
    MatTabsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './update-transaction.component.html',
  styleUrl: './banking-details.component.scss'
})
export class UpdateBankingDetailsComponent {
  @Input() transactionDetail: any;
  @Output() transactionUpdated = new EventEmitter<any>();
  bankId: number;
  categorizeForm: FormGroup;
  flow: string;
  categories: any;
  selectedCategoryId: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['transactionDetail'] && changes['transactionDetail'].currentValue) {
      console.log('transactionDetail updated:', this.transactionDetail);
      this.patchValue();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private bankingService: BankingService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    console.log('transactionData', this.transactionDetail);
    this.fetchTrasactionCategories();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.bankId = Number(id);
      this.initForm();
    }
  }

  initForm() {
    this.categorizeForm = this.fb.group({
      category: [this.transactionDetail?.transactionCategoryId, Validators.required],
      date: [this.transactionDetail?.date || '', Validators.required],
      amount: [this.transactionDetail?.amount || 0, Validators.required],
      reference: [this.transactionDetail?.referenceNumber || ''],
      description: [this.transactionDetail?.description || ''],
    });

    console.log('this.categorizeForm ini', this.categorizeForm.value);
  }

  patchValue() {
    console.log('transactionDetail', this.transactionDetail);
    console.log('categories', this.categories);
    const selectedCategory = this.categories.find(
      (cat: any) => cat.id === this.transactionDetail?.transactionCategoryId
    );

    console.log('selectedCategory', selectedCategory);
    
    this.categorizeForm.patchValue({
      category: selectedCategory,
      date: new Date(parseInt(this.transactionDetail.date.toString(), 10)),
      amount: this.transactionDetail?.amount,
      reference: this.transactionDetail?.referenceNumber,
      description: this.transactionDetail?.description,
    });

    console.log('this.categorizeForm', this.categorizeForm.value);
  }

  fetchTrasactionCategories() {
    this.bankingService.transactionCategories().subscribe({
      next: (data) => {
        if (data) {
          this.categories = data;
          this.patchValue();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  selectionChangeCategory(event: any) {
    const selectedCategory = event.value; // This contains the selected category object
    console.log(selectedCategory);

    if (selectedCategory.categoryStatus === 'Out') {
      this.flow = 'Debit';
    } else if (selectedCategory.categoryStatus === 'In') {
      this.flow = 'Credit';
    }
    this.selectedCategoryId = selectedCategory.id,
      console.log('Selected category flow:', selectedCategory.categoryStatus);
    console.log('Selected category id:', selectedCategory.id);
  }

  onUpdate() {
    const transactionData = {
      id: this.transactionDetail?.id,
      date: this.datePipe.transform(this.categorizeForm.value.date, 'yyyy-MM-dd'),
      referenceNumber: this.categorizeForm.value.reference,
      amount: Number(this.categorizeForm.value.amount),
      description: this.categorizeForm.value.description,
      flow: this.flow || this.transactionDetail?.flow,
      offsetAccountId: this.bankId,
      transactionCategoryId: Number(this.selectedCategoryId) || this.transactionDetail?.transactionCategoryId,
    };

    console.log(transactionData);
    this.bankingService.updateTransaction(transactionData).subscribe({
      next: (data) => {
        if (data) {
          this.transactionUpdated.emit();
          this.toastr.success('Transaction updated successfully', 'Success');
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }
}