<mat-dialog-content class="mat-typography">
    <section class="activity-details-dialog-wrapper">
        <h2 class="cmn-popup-title">Activity Details</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <div class="activity-table-wrapper">
            <div class="request-box inner-box">
                <div class="head">
                    <h3>Request</h3>
                </div>
                <div class="content">
                    <div class="box">
                        <h4>Request :</h4>
                        <h5>Request Content</h5>
                    </div>
                    <div class="box">
                        <h4>Request :</h4>
                        <h5>Request Content</h5>
                    </div>
                </div>
            </div>
            <div class="reponse-box inner-box">
                <div class="head">
                    <h3>Response</h3>
                </div>
                <div class="content">
                    <div class="box">
                        <h4>Request :</h4>
                        <h5>Request Content</h5>
                    </div>
                    <div class="box">
                        <h4>Request :</h4>
                        <h5>Request Content</h5>
                    </div>
                </div>
            </div>
        </div>
    </section>
</mat-dialog-content>