import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private timeoutInterval = 10 * 60 * 1000;
  private activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
  private logoutTimeout: any;
  private activitySubject = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private zone: NgZone,
    private dialog: MatDialog
  ) {
    this.startMonitoring();
  }

  private resetTimer() {
    clearTimeout(this.logoutTimeout);
    this.logoutTimeout = setTimeout(() => {
      this.logoutUser();
    }, this.timeoutInterval);
  }

  private logoutUser() {
    this.zone.run(() => {
      this.dialog.closeAll();
      this.authService.logout();
    });
  }

  startMonitoring() {
    this.activityEvents.forEach((event) =>
      window.addEventListener(event, () => this.activitySubject.next())
    );

    this.activitySubject.subscribe(() => this.resetTimer());
    this.resetTimer(); // Start the timer initially
  }

  stopMonitoring() {
    clearTimeout(this.logoutTimeout);
    this.activityEvents.forEach((event) =>
      window.removeEventListener(event, () => this.activitySubject.next())
    );
  }
}
