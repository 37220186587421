import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { MatRadioModule } from '@angular/material/radio';
import { BankingService } from '../../features/banking/services/banking.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-create-transaction-dialog',
    standalone: true,
    imports: [
        CommonModule,
        LayoutComponent,
        MatInputModule,
        MatSelectModule,
        AgGridAngular,
        MatTabsModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatDialogModule,
        MatRadioModule
    ],
    templateUrl: './create-transaction-dialog.component.html',
    styleUrl: '../assets/assets.component.scss'
})
export class CreateTransactionDialog {
    accountType: any;
    search: any;
    parentAccountId: any;
    isSubAccount: any;
    chartOfAccounts: any;
    createTransactionForm: FormGroup;
    bankId: any;
    flow: any;
    categoryId: any;
    categories: any;
    expenseId: any;
    otherIncomeId: any;
    pendingAmount: any;

    constructor(
        private chartAccountService: ChartAccountsService,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private bankingService: BankingService,
        private router: Router,
        private toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CreateTransactionDialog>
    ) { }

    ngOnInit() {
        this.fetchChartAccounts();
        this.initForm();
        this.fetchTrasactionCategories();
    }

    initForm() {
        this.pendingAmount = this.data?.pendingAmount;
        this.createTransactionForm = this.fb.group({
            applyTo: [this.data?.applyTo || '', Validators.required],
            date: [new Date(parseInt(this.data.statementDate.toString(), 10)), Validators.required],
            // account: ['', Validators.required],
            amount: ['', Validators.required],
            reference: [''],
        })
    }

    fetchChartAccounts() {
        this.chartAccountService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.isSubAccount).subscribe({
            next: (data) => {
                this.chartOfAccounts = data;
                // this.creditCards = data.filter((account: any) => account.accountType === 'Credit');
            },
            error: (error) => {
                console.error('Error fetching chart accounts:', error);
            }
        });
    }

    fetchTrasactionCategories() {
        this.bankingService.transactionCategories().subscribe({
            next: (data) => {
                if (data) {
                    const otherIncomeCategory = data.find((category: any) => category.categoryName === 'other income');
                    this.otherIncomeId = otherIncomeCategory.id;
                    const expenseCategory = data.find((category: any) => category.categoryName === 'expense');
                    this.expenseId = expenseCategory.id;

                }
            },
            error: (error) => {
                this.toastr.error(error, 'Error')
            }
        })
    }

    onSave() {
        if (this.createTransactionForm.invalid) {
            this.createTransactionForm.markAllAsTouched();
            return;
        }

        if (this.createTransactionForm.value.applyTo === 'Deposits') {
            this.flow = 'Credit';
            this.categoryId = this.otherIncomeId;
        } else if (this.createTransactionForm.value.applyTo === 'Withdrawals') {
            this.flow = 'Debit';
            this.categoryId = this.expenseId;
        }
        const transactionData = {
            date: this.datePipe.transform(this.createTransactionForm.value.date, 'yyyy-MM-dd'),
            transactionStatus: 'Manual',
            referenceNumber: this.createTransactionForm.value.reference,
            amount: Number(this.createTransactionForm.value.amount),
            flow: this.flow,
            offsetAccountId: Number(this.data?.bankId),
            transactionCategoryId: Number(this.categoryId),
        }

        console.log('transactionData', transactionData);

        this.bankingService.createTransaction(transactionData).subscribe({
            next: (data) => {
                this.toastr.success('Transaction created successfully','Success');
                this.dialogRef.close();
                // this.router.navigate([`/banking/banking-details/${this.bankId}`]);
            },
            error: (error) => {
                this.toastr.error(error, 'Error')
            }
        })
    }
}