<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <form [formGroup]="vehicleFineForm" (ngSubmit)="onSubmit()" >
        <h2 class="cmn-popup-title">Add Fine</h2>
        <button class="cmn-close" mat-dialog-close></button>

        <h3> {{ data?.name }}</h3>
        <h4> {{ data?.number }}</h4>


        <div class="form-wrapper">
          <label for="assignee">Employee <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select Employee" formControlName="employeeId">
              <mat-option *ngFor="let employee of allEmployees" [value]="employee.id">
                {{ employee.firstName }}  {{ employee.lastName }} 
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-wrapper">
          <label for="">Amount <span class="red">*</span></label>
          <mat-form-field>
              <input matInput placeholder="Fine amount" formControlName="amount" type="number" />
            </mat-form-field>
            <mat-error *ngIf="vehicleFineForm.get('amount')?.touched && vehicleFineForm.get('amount')?.hasError('required')">
              Amount is required.
            </mat-error>
            <mat-error *ngIf="vehicleFineForm.get('amount')?.touched && vehicleFineForm.get('amount')?.hasError('min')">
              Amount must be greater than 0.
            </mat-error>
      </div>

        <div class="form-wrapper">
          <label for="">Date <span class="red">*</span></label>
          <mat-form-field>
              <input matInput [matDatepicker]="date" placeholder="Select date" formControlName="date" />
              <mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
              <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
      </div>
      <div class="form-wrapper">
          <label for="">Reason <span class="red">*</span> </label>
          <mat-form-field>
              <textarea matInput placeholder="Enter reason" formControlName="reason"></textarea>
          </mat-form-field>
      </div>


        <div class="btn-wrapper">
            <button class="cmn-next-btn ">
              <span *ngIf="!loading">Submit</span>
              <span *ngIf="loading">Loading...</span>
            </button>
        </div>
    </form>
    </section>
</mat-dialog-content>