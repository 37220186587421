import gql from 'graphql-tag';

export const CREATE_BANK = gql`
  mutation createBank($createBankInput: CreateBankInput!) {
    createBank(createBankInput: $createBankInput) {
    id
    bankType
    accountName
    accountCode
    accountNumber
    bankName
    routingNumber
    swiftCode
    description
    isPrimary
    currencyId
    currency {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      status
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
    status
  }
  }
`;

export const UPDATE_BANK = gql`
  mutation updateBank($updateBankInput: UpdateBankInput!) {
    updateBank(updateBankInput: $updateBankInput){
    id
    bankType
    accountName
    accountCode
    accountNumber
    bankName
    routingNumber
    swiftCode
    description
    currencyId
    createdAt
    updatedAt
    deletedAt
    status
  }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($createTransactionInput: CreateTransactionInput!) {
    createTransaction(createTransactionInput: $createTransactionInput) {
    id
    date
    referenceNumber
    transactionStatus
    amount
    flow
    description
    paidVia
    createdAt
    updatedAt
    deletedAt
    status
    offsetAccountId
    offsetAccount {
      id
      accountType
      accountName
      accountCode
      description
      amount
      parentAccountId
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
    }
    transactionCategoryId
    transactionCategory {
      id
      categoryName
      categoryStatus
      createdAt
      updatedAt
      deletedAt
      status
    }
    transactionReconcilations {
      id
      statementId
      createdAt
      updatedAt
      deletedAt
      status
    }
  }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction($updateTransactionInput: UpdateTransactionInput!) {
    updateTransaction(updateTransactionInput: $updateTransactionInput) {
    id
    date
    referenceNumber
    transactionStatus
    amount
    flow
    description
    paidVia
  }
  }
`;

export const REMOVE_TRANSACTION = gql`
  mutation removeTransaction(
    $id: Int!
    ) {
      removeTransaction(
      id: $id
      ) 
  }
`;

export const UNCATEGORIZE_TRANSACTION = gql`
  mutation categorizeTransactions(
    $statusType: String!
    $statementId: Int!
    $createTransactionInput: CreateTransactionInput!
    $transactionId: Int,
    $reconcilationId: Int
    ) {
    categorizeTransactions(
      statusType: $statusType
      statementId: $statementId
      createTransactionInput: $createTransactionInput
      transactionId: $transactionId
      reconcilationId: $reconcilationId
      ) 
  }
`;

export const MATCH_OR_UNMATCH_TRANSACTION = gql`
  mutation matchOrUnmatchStatementToTransactions(
    $statusType: String!
    $reconcilationIds: [Int]
    $statementId: Int!
    $transactionIds: [Int]
    $createTransactionInput: CreateTransactionInput
    ) {
    matchOrUnmatchStatementToTransactions(
      statusType: $statusType
      reconcilationIds: $reconcilationIds
      statementId: $statementId
      transactionIds: $transactionIds
      createTransactionInput: $createTransactionInput
      ) 
  }
`;