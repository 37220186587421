import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AssetService } from '../../../../features/assets/services/asset.service';

@Component({
  selector: 'app-assets-disposal',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './assets-disposal.html',
  styleUrl: '../../assets.component.scss'
})
export class AssetsDisposal implements OnInit, OnChanges {
  @Input() searchQuery: string = '';
  constructor(private assetService: AssetService,
    private datePipe: DatePipe,
  ){}
  

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchQuery']) {
      this.assetDisposals(this.searchQuery,null);
    }
    }
  ngOnInit(): void {
    this.assetDisposals();
  }
  public rowData: any[] | null = null;
  columnDefs = [

    {
      headerName: 'Asset Name',
      field: 'name',
      flex: 1,
    },
    // { headerName: 'ID', field: 'id', flex: 1 },
    { headerName: 'Disposal Method', field: 'disposalMethod', flex: 1 },
    { headerName: 'Disposal Date', field: 'disposalDate', flex: 1 },
    { headerName: 'Type', field: 'type', flex: 1 },
    // { headerName: 'Status', field: 'status', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === "On Site" ? 'green' : 'red'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';


  assetDisposals(search: string = '', assetId: number | null =null) {
    this.assetService.assetDisposals(search, assetId).subscribe({
      next: (response) => {
        this.rowData = response.map((asset: any) => {
          return {
            name: asset?.assetData?.assetName,
            type:  asset?.assetData?.assetType,
            status:  asset?.assetData?.assetStatus,
            disposalMethod: asset?.disposalMethod,
            disposalDate: asset?.disposalDate ?  this.datePipe.transform(asset?.disposalDate, 'dd/MM/yyyy') : null,
          }
        });
      }
    })
  }
}