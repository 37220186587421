import { EmployeeService } from './../../features/employees/services/employee.service';
import { InventoryItemService } from './../../features/inventory/services/inventory.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { QuotationService } from '../../features/quotation/services/quotation.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { BillingAddressDialog } from '../invoice-new/dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { ShippingAddressDialog } from '../invoice-new/dialog-components/add-shipping-address-dialog/shipping-address-dialog.component';
import { ListBillingAddressDialog } from '../invoice-new/dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { ListShippingAddressDialog } from '../invoice-new/dialog-components/list-shipping-address-dialog/list-shipping-address-dialog.component';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import {
  nonNegativeValidator,
  trnValidator,
} from '../../shared/services/validations';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PreferenceService } from '../../shared/services/preference.service';
import { PartnerTypesEnum, UNITTYPES } from '../../helpers/helper-file';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-quotation-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
  ],
  templateUrl: './quotation-new.component.html',
  styleUrl: './quotation-new.component.scss',
})
export class QuotationNewComponent implements OnInit {
  public arr = [1, 2, 3, 4, 5, 6, 7];
  public isEditMode = false;
  filter: {} = {};
  search: string = '';
  loading: boolean = false;
  quoteForm: FormGroup;
  workSplitForm: FormGroup;
  quoteData: any;
  quoteId: number | null = null;
  TRNNumber: number | null = null;
  subTotalValue: number;
  selectedBranchId: number;
  addedItems: any[] = [];
  isBranchReadOnly: boolean = false;
  private partnerTypes = PartnerTypesEnum;
  public selectedBillingAddress: any = {};
  public selectedShippingAddress: any = {};
  public billingAddressList: any[];
  public shippingAddressList: any[];
  public partnersData: any[] = [];
  public itemData: any[] = [];
  private searchSubject = new Subject<string>();
  public projectData: any[] = [];
  private projectSearchSubject = new Subject<string>();
  public branchData: any[] = [];
  private branchSearchSubject = new Subject<string>();
  selectedCustomerId: any;
  public filteredItemData: any[] = [];
  readonly dialog = inject(MatDialog);

  file: any = null;
  csvFile: File[] = [];
  url: string = '';
  fileDetailsArray: {
    fileName: string;
    fileUrl: string;
    id?: number;
    // isNew: boolean;
  }[] = [];
  unitTypes: any = UNITTYPES;

  @ViewChild('fileInput') fileInput!: ElementRef;
  currentFiles: File[] = [];
  private status: 'DRAFT' | 'SENT' = 'SENT';
  prefix: any;
  quoteNumber: any;
  hasShownWarning: boolean = false;

  constructor(
    private quoteService: QuotationService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private itemService: InventoryItemService,
    private documentUploadService: UploadFileService,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private preferenceService: PreferenceService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.selectedBranchId = this.authService.getSelectedBranchId();

    if (this.selectedBranchId == 0 || !this.selectedBranchId) {
      this.isBranchReadOnly = false;
    } else {
      this.isBranchReadOnly = true;
    }

    this.fetchPartners();
    this.initForm();
    this.preferenceCode();
    this.fetchItems();
    this.generateQuoteNumber();
    this.listBranches();

    this.quoteForm.get('projectId')?.valueChanges.subscribe((projectId) => {
      this.onProjectChange(projectId);
    });

    // Monitor changes to branchId in the quoteForm
    this.quoteForm.get('branchId')?.valueChanges.subscribe((selectedBranchId) => {
      this.updateBranchFields(selectedBranchId);
    });

    this.items.valueChanges.subscribe(() => {
      this.items.controls.forEach((control, index) => {
        this.updateItemTotal(index);
      });
    });

    const branches = this.quoteForm.get('branches') as FormArray;
    branches?.valueChanges.pipe(
      debounceTime(500) 
    ).subscribe((branchValues) => {
      branchValues.forEach((branchGroup: any, index: number) => {
        const branchControl = branches.at(index) as FormGroup;
        const amountControl = branchControl?.get('amount');

        if (amountControl) {
          amountControl.valueChanges.pipe(
            debounceTime(500)
          ).subscribe((value: any) => {

            const selectedBranchId = this.quoteForm.get('branchId')?.value;
            if (selectedBranchId) {
              this.updateBranchFields(selectedBranchId, false);  // Pass false to prevent valueChanges looping
            }
          });
        } else {
          console.warn(`Amount control is missing for branch at index ${index}`);
        }
      });
    });

    this.items.valueChanges.subscribe(() => {
      this.updateBranchFields(this.quoteForm.get('branchId')?.value);
    });

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.quoteId = +id;
        this.isEditMode = true;
        this.quoteForm.get('partnerId')?.disable();
        if (this.partnersData && this.partnersData.length) {
          this.loadQuoteData();
        } else {
          this.fetchPartners();
          setTimeout(() => this.loadQuoteData(), 500);
        }
      }
    });

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });

    this.projectSearchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
      });

    this.branchSearchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.listBranches(searchTerm);
      });

    this.items.valueChanges.subscribe(() => {
      this.getTotalAmount();
      this.getSubTotalAmount();
      this.getTotalDiscount();
    });

    if (!this.quoteId) {
      this.addItem();
      // this.addExclusion();
    }
    // this.addExclusion();
  }

  initForm(): void {
    this.quoteForm = this.fb.group({
      id: [this.quoteData?.id || ''],
      partnerId: [this.quoteData?.partnerId, Validators.required],
      shippingAddressId: this.selectedShippingAddress.id,
      billingAddressId: this.selectedBillingAddress.id,
      TRN: [this.TRNNumber, [Validators.required, trnValidator]],
      quoteCode: [this.quoteData?.quoteCode, Validators.required],
      tax: [this.quoteData?.tax],
      quoteTitle: [this.quoteData?.quoteTitle, Validators.required],
      refNumber: [this.quoteData?.refNumber],
      quoteDate: [this.quoteData?.quoteDate || new Date(), Validators.required],
      expiryDate: [this.quoteData?.expiryDate],
      projectId: [this.quoteData?.projectId],
      newProjectName: [this.quoteData?.newProjectName, Validators.required],
      branchId: [
        { value: Number(this.selectedBranchId), disabled: this.isBranchReadOnly },
        [Validators.required, this.branchValidator]
      ],
      SOW: [this.quoteData?.SOW, Validators.required],
      subject: [this.quoteData?.subject],
      paymentTerm: [this.quoteData?.paymentTerm],
      deliveryTerm: [this.quoteData?.deliveryTerm],
      validity: [this.quoteData?.validity],
      termsCondition: [this.quoteData?.termsCondition],
      items: this.fb.array([]),
      customerNote: [''],
      exclusions: this.fb.array([]),
      quoteAttachments: this.fb.array([]),
      branches: this.fb.array([]),
    });

    // Dynamically adjust validation for branchId
    const branchIdControl = this.quoteForm.get('branchId');
    if (this.selectedBranchId == 0) {
      branchIdControl?.setValidators([Validators.required, this.branchValidator]);
    } else {
      branchIdControl?.clearValidators();
    }
    branchIdControl?.updateValueAndValidity();

    // Monitor changes to branchId to dynamically adjust read-only status
    branchIdControl?.valueChanges.subscribe((value) => {
      if (value == 0) {
        branchIdControl.setValidators([Validators.required, this.branchValidator]);
      } else {
        branchIdControl.clearValidators();
      }
      branchIdControl.updateValueAndValidity();
    });
  }

  createBranchFormGroup(branch: any): FormGroup {
    return this.fb.group({
      id: [branch.id || null],
      branchId: [branch.branchId || '', Validators.required],
      branchName: [branch.branchData.branchName || ''],
      description: [branch.description, Validators.required],
      amount: [branch.amount || '', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
    });
  }

  branchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value === 0) {
      return { 'branchInvalid': true };
    }
    return null;
  }

  updateBranchFields(selectedBranchId: number, emitEvent: boolean = true): void {
    const branches = this.quoteForm.get('branches') as FormArray;
    const subTotalAmount = this.getSubTotalAmount();
    let sumAmount = 0;

    branches.controls.forEach((branchGroup) => {
      const branchId = branchGroup.get('branchId')?.value;
      const amountControl = branchGroup.get('amount');
      if (branchId !== selectedBranchId) {
        sumAmount += Number(amountControl?.value || 0);
      }
    });

    const balanceAmount = subTotalAmount - sumAmount;

    branches.controls.forEach((branchGroup) => {
      const branchId = branchGroup.get('branchId')?.value;
      const amountControl = branchGroup.get('amount');


      if (branchId === selectedBranchId) {
        branchGroup.get('amount')?.setValue(balanceAmount, { emitEvent });
        branchGroup.get('description')?.disable({ emitEvent });
        branchGroup.get('amount')?.disable({ emitEvent });
      } else {
        branchGroup.get('description')?.enable({ emitEvent });
        branchGroup.get('amount')?.enable({ emitEvent });

        if (amountControl && sumAmount > subTotalAmount && !this.hasShownWarning) {
          this.toastr.warning(
            'Sum of work split amounts should not exceed subtotal amount.'
          );
          this.hasShownWarning = true;
        }
      }
    });
    if (sumAmount <= subTotalAmount) {
      this.hasShownWarning = false;
    }
  }

  onProjectChange(projectId: number): void {
    const selectedProject = this.projectData.find(project => project.id === projectId);
    if (selectedProject) {
      this.quoteForm.patchValue({ newProjectName: selectedProject.projectName });
    }
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  onProjectSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.projectSearchSubject.next(searchTerm);
  }

  onBranchSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.branchSearchSubject.next(searchTerm);
  }

  onCustomerSelected(event: MatSelectChange): void {
    const selectedCustomerId = event.value;
    this.selectedCustomerId = event.value;
    const selectedPartner = this.partnersData.find(
      (partner) => partner.id === selectedCustomerId
    );

    if (selectedPartner) {
      // Update TRN field with selected partner's TRN number
      if (selectedPartner.trnNumber) {
        console.log(selectedPartner.trnNumber);
        this.quoteForm.patchValue({
          TRN: selectedPartner.trnNumber,
        });
      } else {
        this.quoteForm.patchValue({
          TRN: '', // Clear TRN if no trnNumber available
        });
      }
      if (selectedPartner.addresses.length > 0) {
        const address = selectedPartner.addresses.find((addr: any) => addr.addressType === 'BILLING');
        if (address) {
          this.selectedBillingAddress = address;
          this.quoteForm.patchValue({
            billingAddressId: this.selectedBillingAddress.id || null,
          });
        }
        const shippingAddress = selectedPartner.addresses.find((addr: any) => addr.addressType === 'SHIPPING');
        if (shippingAddress) {
          this.selectedShippingAddress = shippingAddress;
          this.quoteForm.patchValue({
            shippingAddressId: this.selectedShippingAddress.id || null,
          });
        }
      }
    }

    if (selectedPartner && selectedPartner.partnerProjects) {
      this.projectData = selectedPartner.partnerProjects.map(
        (project: any) => ({
          id: project.id,
          projectName: project.projectName,
          projectCode: project.projectCode,
          totalProjectCost: project.totalProjectCost,
          description: project.description,
        })
      );
    } else {
      this.projectData = [];
    }
  }

  loadQuoteData(): void {
    if (this.quoteId !== null) {
      this.quoteService.fetchQuoteById(this.quoteId).subscribe({
        next: (data) => {
          this.quoteData = data;

          // Fetch related partner projects first
          const selectedPartner = this.partnersData.find(
            (partner) => partner.id === this.quoteData.partnerId
          );
          if (selectedPartner && selectedPartner.partnerProjects) {
            this.projectData = selectedPartner.partnerProjects.map((project: any) => ({
              id: project.id,
              projectName: project.projectName,
              projectCode: project.projectCode,
              totalProjectCost: project.totalProjectCost,
              description: project.description,
            }));
          } else {
            this.projectData = [];
          }

          // After projectData is populated, patch the form
          this.quoteForm.patchValue({
            partnerId: this.quoteData.partnerId,
            shippingAddressId: this.quoteData.shippingAddressId,
            billingAddressId: this.quoteData.billingAddressId,
            TRN: this.quoteData.TRN,
            quoteCode: this.quoteData.quoteCode,
            quoteTitle: this.quoteData.quoteTitle,
            tax: this.quoteData.tax,
            refNumber: this.quoteData.refNumber,
            quoteDate: this.quoteData.quoteDate
              ? new Date(Number(this.quoteData.quoteDate))
              : null,
            expiryDate: this.quoteData.expiryDate
              ? new Date(Number(this.quoteData.expiryDate))
              : null,
            projectId: this.quoteData.projectId,
            newProjectName: this.quoteData.newProjectName,
            branchId: this.quoteData.branchId,
            SOW: this.quoteData.SOW,
            paymentTerm: this.quoteData.paymentTerm,
            deliveryTerm: this.quoteData.deliveryTerm,
            validity: this.quoteData.validity
              ? new Date(Number(this.quoteData.validity))
              : null,
            termsCondition: this.quoteData?.termsCondition,
            subject: this.quoteData.subject,
            customerNote: this.quoteData.customerNote,
          });

          if (data.quoteItems) {
            data.quoteItems.forEach((item: any) => {
              const itemForm = this.fb.group({
                id: [item.id || null],
                description: [item.description, Validators.required],
                quantity: [item.quantity, Validators.required],
                unit: [item.unit, Validators.required],
                rate: [item.rate, Validators.required],
                amount: [item.amount, Validators.required],
                discount: [item.discount, Validators.pattern(/^\d+(\.\d+)?$/)],
                tax: [item.tax],
                grossAmount: [item.grossAmount, Validators.pattern(/^\d+(\.\d+)?$/)],
              });
              // this.items.push(itemForm);

              this.addedItems.push(itemForm);
              this.calculateAmount(itemForm);
            });
          }

          this.setAddresses(this.quoteData.partnerId);
          this.setTRNNumber(this.quoteData.partnerId);
          this.selectedCustomerId = this.quoteData.partnerData.id;

          if (this.quoteData?.quoteExclusions) {
            this.quoteData.quoteExclusions.forEach((exclusion: any) => {
              const exclusionGroup = this.fb.group({
                exclusion: [exclusion.exclusion || ''],
              });
              // this.exclusions.push(exclusionGroup);
            });
          }

          // Populate branches FormArray
          const branchesArray = this.quoteForm.get('branches') as FormArray;
          branchesArray.clear(); // Clear existing controls, if any

          if (this.quoteData.quotesWorkData) {
            this.quoteData.quotesWorkData.forEach((workData: any) => {
              branchesArray.push(this.createBranchFormGroup(workData));
            });
          }

          if (data.quoteAttachment) {
            this.fileDetailsArray = []; // Clear array
            data.quoteAttachment.forEach((attachment: any) => {
              this.fileDetailsArray.push({
                id: attachment.id,
                fileName: attachment.fileName,
                fileUrl: attachment.fileUrl,
              });
            });
          }
          this.cdr.detectChanges();
        },
      });
    }
  }

  getUpdateQuoteItemInput(): any[] {
    return this.items.controls.map((itemGroup) => {
      const id = itemGroup.get('id')?.value;
      const description = itemGroup.get('description')?.value;
      const quantity = itemGroup.get('quantity')?.value;
      const unit = itemGroup.get('unit')?.value;
      const rate = parseFloat(itemGroup.get('rate')?.value);
      const amount = parseFloat(itemGroup.get('amount')?.value);
      const discount = itemGroup.get('discount')?.value;
      const taxControl = itemGroup.get('tax');
      const tax = parseFloat(taxControl?.value || '0');

      const existingQuoteItem = this.quoteData?.quoteItems?.find(
        (quoteItem: any) => quoteItem.id === id
      );

      return {
        id: existingQuoteItem ? existingQuoteItem.id : null,
        description,
        quantity,
        unit,
        rate,
        discount,
        amount,
        tax,
      };
    });
  }



  addItem(): void {
    const itemGroup = this.fb.group({
      // itemId: [null],
      description: ['', Validators.required],
      quantity: [
        '1',
        [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
          nonNegativeValidator,
        ],
      ],
      unit: ['', Validators.required],
      rate: ['', [Validators.required, nonNegativeValidator]],
      discount: [
        0,
        [Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
      amount: [0, [Validators.required, nonNegativeValidator]],
      tax: [0, [Validators.required, nonNegativeValidator]],
      grossAmount: [0, [Validators.required, nonNegativeValidator]]
    });

    this.items.push(itemGroup);

    // Trigger validation checks after adding the group
    itemGroup.updateValueAndValidity();
    this.items.updateValueAndValidity();
    
    itemGroup
      .get('rate')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('quantity')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('discount')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  }

  calculateAmount(itemGroup: FormGroup): void {
    const rate = parseFloat(itemGroup.get('rate')?.value || 0);
    const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
    const discount = itemGroup.get('discount')?.value || 0;
    let grossAmount = rate * quantity;
    if (discount > 0) {
      grossAmount = grossAmount - discount;
    }
    itemGroup.patchValue({ grossAmount });
    const tax = (grossAmount * 0.05).toFixed(2) || 0;
    itemGroup.patchValue({ tax });
    itemGroup.patchValue({ grossAmount });
    let amount = Number(grossAmount) + Number(tax);
    itemGroup.patchValue({ amount });
  }

  getSubTotalAmount(): number {
    const newItemsSubtotal = this.items.controls.reduce((subtotal, item) => {
      const quantity = parseFloat(item.get('quantity')?.value || '0');
      const rate = parseFloat(item.get('rate')?.value || '0');
      return subtotal + quantity * rate;
    }, 0);

    const existingItemsSubtotal = this.quoteData?.quoteItems.reduce(
      (subtotal: any, item: any) => {
        const quantity = parseFloat(item.quantity || '0');
        const rate = parseFloat(item.rate || '0');
        return subtotal + quantity * rate;
      },
      0
    )

    // Return the total subtotal
    return newItemsSubtotal + (existingItemsSubtotal || 0);
  }

  getTotalDiscount(): number {
    const newItemsDiscount = this.items.controls.reduce((totalDiscount, item) => {
      const totalAmount = this.getItemTotalAmount(item.value);
      const netAmount = item.get('grossAmount')?.value || 0;
      return totalDiscount + (totalAmount - netAmount);
    }, 0);

    const existingItemsDiscount = this.quoteData?.quoteItems.reduce(
      (totalDiscount: any, item: any) => {
        const discount = item.discount || 0;
        return totalDiscount + discount;
      },
      0
    );

    return newItemsDiscount + (existingItemsDiscount || 0);

  }

  getTotalVat(): number {
    const newItemVatAmount = this.items.controls.reduce((totalVatAmount, item) => {
      const vatAmount = item.get('tax')?.value || 0;
      return totalVatAmount + vatAmount;
    }, 0);

    const existingItemVatAmount = this.quoteData?.quoteItems.reduce(
      (totalVatAmount: number, item: any) => {
        const vat = item.tax || 0;
        return totalVatAmount + vat;
      },
      0
    );

    return newItemVatAmount + (existingItemVatAmount || 0);
  }

  getTotalAmount(): number {
    const subTotal = this.getSubTotalAmount();
    const totalDiscount = this.getTotalDiscount();
    // const vat = this.getVAT();
    const vat = this.getTotalVat();
    return subTotal - totalDiscount + vat;
  }

  updateItemTotal(index: number): void {
    const itemsControl = this.items.controls[index];
    const quantity = itemsControl.get('quantity')?.value || 0;
    const rate = itemsControl.get('rate')?.value || 0;
    const discount = itemsControl.get('discount')?.value || 0;

    const grossAmount = quantity * rate - discount;
    itemsControl.get('grossAmount')?.setValue(grossAmount, { emitEvent: false });

    const vatControl = itemsControl.get('tax');
    if (!vatControl?.dirty) {
      const vatValue = +(grossAmount * 0.05).toFixed(2);
      vatControl?.setValue(vatValue, { emitEvent: false });
    } else {
      const userEnteredVat = +vatControl?.value;
      const formattedVat = +userEnteredVat.toFixed(2);
      vatControl?.setValue(formattedVat, { emitEvent: false });
    }
    const vat = vatControl?.value || 0;
    const totalAmount = grossAmount + vat;
    itemsControl.get('amount')?.setValue(totalAmount, { emitEvent: false });
  }

  getItemTotalAmount(item: any): number {
    const quantity = parseFloat(item?.quantity || '0');
    const rate = parseFloat(item?.rate || '0');
    return quantity * rate;
  }

  get items(): FormArray {
    return this.quoteForm.get('items') as FormArray;
  }

  get branches(): FormArray {
    return this.quoteForm.get('branches') as FormArray;
  }

  get exclusions(): FormArray {
    return this.quoteForm.get('exclusions') as FormArray;
  }

  addExclusion(): void {
    const exclusionGroup = this.fb.group({
      exclusion: [''],
    });

    this.exclusions.push(exclusionGroup);
  }

  private populateBranches(): void {
    const branchValue = this.quoteForm.get('branchId')?.value;

    const filteredBranches = this.branchData.filter(
      branch => branch?.branchName !== 'All'
    );

    const branchArray = this.branches;
    filteredBranches.forEach(branch => {
      branchArray.push(
        this.fb.group({
          branchId: [branch?.id],
          branchName: [branch?.branchName],
          description: ['', Validators.required],
          amount: [0, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
        })
      );
    });

    // this.initializeAmountListeners();
  }

  setTRNNumber(partnerId: number): void {
    const selectedPartner = this.partnersData.find((p) => p.id === partnerId);
    this.TRNNumber = selectedPartner.trnNumber;
  }

  setAddresses(partnerId: number): void {
    const selectedPartner = this.partnersData.find((p) => p.id === partnerId);

    if (selectedPartner) {
      if (
        Array.isArray(selectedPartner.addresses) &&
        selectedPartner.addresses.length > 0
      ) {
        this.selectedBillingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'BILLING'
          ) || null;
        this.selectedShippingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'SHIPPING'
          ) || null;

        this.quoteForm.patchValue({
          billingAddressId: this.selectedBillingAddress?.id || null,
          shippingAddressId: this.selectedShippingAddress?.id || null,
        });
      } else {
        console.error(
          `No addresses found or addresses is not an array for partnerId: ${partnerId}`
        );
      }
    } else {
      console.error(`Partner with ID ${partnerId} not found in partnersData.`);
    }
  }

  saveAsDraft(): void {
    this.status = 'DRAFT';
    this.createQuote('quotation/quotation-details');
  }

  saveAndSend(): void {
    this.status = 'DRAFT';
    // this.createQuote('quotation/send-mail');
    this.createQuote('quotation/quotation-details/mail');
  }

  createQuote(navigateTo: string): void {
    if (this.quoteForm.invalid) {
      this.quoteForm.markAllAsTouched();
      this.toastr.warning('Please fill in all required fields.');
      return;
    }

    const billingAddressId = this.quoteForm.get('billingAddressId')?.value;
    const shippingAddressId = this.quoteForm.get('shippingAddressId')?.value;

    if (!billingAddressId || !shippingAddressId) {
      this.toastr.warning('Please select both billing and shipping addresses.');
      return;
    }

    if(!this.quoteId) {
      const items = this.quoteForm.value.items;
      if (!items || items.length === 0) {
        this.toastr.warning('Please add at least one item to the invoice.');
        return;
      }
    }

    else {
      const branches = this.quoteForm.get('branches') as FormArray;
      const selectedBranch = branches.controls.find(
        (branchGroup) => branchGroup.get('branchId')?.value === this.quoteForm.get('branchId')?.value
      );

      if (!selectedBranch) {
        this.toastr.error('Selected branch not found.');
        return;
      }

      const selectedBranchAmount = Number(selectedBranch.get('amount')?.value || 0);

      // Check if the selected branch amount is less than 0
      if (selectedBranchAmount < 0) {
        this.toastr.warning(
          'Please adjust the work split amount before proceeding.'
        );
        return; // Prevent form submission
      }

      const formValues = this.quoteForm.value;
      console.log('formValues', formValues);


      const loginEmployee: any = localStorage.getItem('loggedInUser');
      const employee = JSON.parse(loginEmployee);
      const employeeId = employee.employeeId;

      const subTotal = this.getSubTotalAmount(); // Calculates the subtotal
      const totalAmount = this.getTotalAmount();
      const tax = this.getTotalVat();

      const createQuoteInput = {
        partnerId: formValues.partnerId,
        billingAddressId: formValues.billingAddressId,
        shippingAddressId: formValues.shippingAddressId,
        quoteCode: formValues.quoteCode,
        quoteTitle: formValues.quoteTitle,
        tax,
        TRN: formValues.TRN,
        SOW: formValues.SOW,
        refNumber: formValues.refNumber,
        quoteDate: formValues.quoteDate,
        expiryDate: formValues.expiryDate,
        // approvalDate: formValues.approvalDate,
        projectId: formValues.projectId,
        newProjectName: formValues.newProjectName,
        branchId: this.quoteForm.get('branchId')?.value,
        paymentTerm: formValues.paymentTerm,
        deliveryTerm: formValues.deliveryTerm,
        validity: formValues.validity,
        termsCondition: formValues.termsCondition,
        subject: formValues.subject,
        customerNote: formValues.customerNote,
        createdBy: employeeId,
        subTotal,
        totalAmount
      };

      const createQuoteItemInput = this.quoteForm.getRawValue().items.map(
        (item: any) => {
          // if (item.isManual) {
          return {
            description: item.description,
            quantity: item.quantity,
            unit: item.unit,
            rate: parseFloat(item.rate),
            discount: item.discount,
            amount: parseFloat(item.amount),
            tax: Number(item.tax),
          };
        }
      );

      const quoteWorkSplitInput = this.branches.value.map(
        (branch: any) => ({
          branchId: branch.branchId,
          description: branch.description,
          amount: branch.amount,
        })
      );

      console.log("Update Work Split Input:", quoteWorkSplitInput);


      const createQuoteAttachmentInput = this.fileDetailsArray
        .filter((file) => !file.id)
        .map((file) => ({
          // id: null,
          fileName: file.fileName,
          fileUrl: file.fileUrl,
        }));

      const updateQuoteAttachmentInput = this.fileDetailsArray.map((file) => ({
        id: file.id || null,
        fileName: file.fileName,
        fileUrl: file.fileUrl,
      }));

      const createExclusionInput = this.exclusions.value.map(
        (exclusion: any) => ({
          exclusion: exclusion.exclusion,
        })
      );

      const updateExclusion = this.exclusions.value.map(
        (exclusion: any) => ({
          exclusion: exclusion.exclusion,
        })
      );

      const updateQuoteInput = {
        id: this.quoteId,
        ...createQuoteInput,
      };

      const updateWorkSplitInput = formValues.branches.map((branch: any) => ({
        id: branch.id, // Pass the ID if it exists
        branchId: branch.branchId,
        description: branch.description,
        amount: branch.amount,
        quoteId: this.quoteId,
      }));

      const quoteItemsInput = this.getUpdateQuoteItemInput();

      this.loading = true;
      if (this.quoteId) {
        this.quoteService
          .updateQuotation(
            updateQuoteInput,
            quoteItemsInput,
            updateQuoteAttachmentInput,
            updateExclusion
          )
          .subscribe({
            next: (response) => {
              // this.toastr.success('Quotation updated successfully.');
              const quoteId = response?.data?.updateQuote?.id;
              if (quoteId) {
                const parentQuoteId = response?.data?.updateQuote?.parentQuoteId;
                this.quoteService.updateQuoteWorkSplit(updateWorkSplitInput).subscribe({
                  next: (response) => {
                    // this.toastr.success('Work split updated successfully.');
                    this.quoteService.generateQuotePDF(quoteId).subscribe({
                      next: (pdfResponse) => {
                        this.router.navigate([`${navigateTo}`], {
                          // queryParams: { id: parentQuoteId },
                          queryParams: { id: parentQuoteId, quoteId: quoteId },
                        });
                        this.loading = false;
                        // this.toastr.success('PDF generated successfully.');
                        this.toastr.success('Quotation updated successfully.');
                      },
                      error: (pdfError) => {
                        console.error('Error generating PDF:', pdfError);
                        this.toastr.error(
                          pdfError.message || 'Failed to generate PDF.'
                        );
                      },
                    });
                  }, error: (error) => {
                    console.error('Error updating quote work split:', error);
                    this.toastr.error(
                      error.message || 'Failed to quote work split.'
                    );
                  }
                })
              } else {
                console.error('Quote ID is missing');
              }
            },
            error: (error) => {
              this.loading = false;
              console.error('Error updating Quotation:', error);
              this.toastr.error(error.message || 'Failed to update Quotation.');
            },
          });
      } else {
        this.quoteService
          .createQuote(
            createQuoteInput,
            createQuoteItemInput,
            createExclusionInput,
            createQuoteAttachmentInput
          )
          .subscribe({
            next: (response) => {
              // this.toastr.success('Quote created successfully');
              const quoteId = response?.data?.createQuotes?.id;
              if (quoteId) {
                const parentQuoteId = response?.data?.createQuotes?.parentQuoteId
                const createQuoteWorkSplitInput = this.branches.getRawValue().map((branch: any) => ({
                  quoteId: quoteId, // Ensure each object includes the quoteId
                  branchId: branch.branchId,
                  description: branch.description,
                  amount: branch.amount,
                }));

                this.quoteService.createQuotesWorkSplit(createQuoteWorkSplitInput).subscribe({
                  next: (response) => {
                    // this.toastr.success('Work Splits created successfully');
                    this.quoteService.generateQuotePDF(quoteId).subscribe({
                      next: (pdfResponse) => {
                        this.router.navigate([`${navigateTo}`], {
                          // queryParams: { id: parentQuoteId },
                          queryParams: { id: parentQuoteId, quoteId: quoteId },
                        });
                        this.loading = false;
                        // this.toastr.success('PDF generated successfully.');
                        this.toastr.success('Quote created successfully');
                      },
                      error: (pdfError) => {
                        console.error('Error generating PDF:', pdfError);
                        this.toastr.error(
                          pdfError.message || 'Failed to generate PDF.'
                        );
                        this.loading = false;
                      },
                    });
                  },
                  error: (error) => {
                    console.error('Error creating work splits:', error);
                    this.toastr.error(error.message || 'Failed to create work splits.');
                  },
                });
              }
            },
            error: (error) => {
              this.toastr.error(error.message || 'Failed to create Quote.');
              this.loading = false;
            },
          });
      }
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files);

      if (this.fileDetailsArray.length + newFiles.length > 10) {
        this.toastr.warning('You can only upload a maximum of 10 files.');
        return;
      }

      newFiles.forEach((newFile) => {
        const allowedExtensions = ['pdf', 'doc', 'jpg', 'jpeg', 'png'];
        const fileExtension = newFile.name.split('.').pop()?.toLowerCase();

        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          this.toastr.warning('Only PDF, DOC, and image files are allowed.');
          return;
        }

        if (
          !this.fileDetailsArray.some((file) => file.fileName === newFile.name)
        ) {
          this.fileDetailsArray.push({ fileName: newFile.name, fileUrl: '' });
        }
      });

      this.uploadDocuments(newFiles);
    }
  }

  uploadDocuments(files: File[]): void {
    files.forEach((file: File) => {
      this.documentUploadService.uploadDocument(file, 'items').subscribe({
        next: (response) => {
          const uploadedFile = this.fileDetailsArray.find(
            (f) => f.fileName === file.name
          );
          if (uploadedFile) {
            uploadedFile.fileUrl = response.url;
          }
        },
        error: (error) => {
          this.toastr.error(error, 'File upload failed');
        },
      });
    });
    this.fileInput.nativeElement.value = '';
  }

  removeFile(index: number): void {
    const file = this.fileDetailsArray[index];
    if (file.id) {
      this.onRemoveQuoteAttachment(file.id);
    } else {
      // New file - simply remove from array
      this.fileDetailsArray.splice(index, 1);
    }
  }

  onRemoveQuoteAttachment(quoteAttachmentId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((response) => {
      if (response === true) {
        this.quoteService
          .removeQuoteAttachmentById(quoteAttachmentId)
          .subscribe({
            next: () => {
              this.fileDetailsArray = this.fileDetailsArray.filter(
                (file) => file.id !== quoteAttachmentId
              );
            },
            error: () => {
              this.toastr.error('Failed to remove quote attachment.');
            },
          });
      }
    });
  }

  onViewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }

  onCancel(): void {
    this.quoteForm.reset();
    this.router.navigate(['/quotation']);
  }

  onRemoveQuoteItem(index: number, quoteItemId: number): void {
    if (quoteItemId) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeItem(quoteItemId, index);
        }
      });
    } else {
      console.error('Item ID is null or undefined');
      this.items.removeAt(index);
    }
  }

  private removeItem(quoteItemId: number, index: number): void {
    this.quoteService.removeQuoteItemById(quoteItemId).subscribe({
      next: () => {
        this.toastr.success('Quote item removed successfully.');
        // this.items.removeAt(index);
        this.quoteData = {
          ...this.quoteData,
          quoteItems: [
            ...this.quoteData.quoteItems.slice(0, index),
            ...this.quoteData.quoteItems.slice(index + 1)
          ]
        }
      },
      error: () => {
        this.toastr.error('Failed to remove quote item.');
        console.log('Failed to remove quote item.');
      },
    });
  }

  onRemoveNewlyAddedExclusions(index: number): void {
    this.exclusions.removeAt(index);
  }

  onRemoveQuoteExclusion(exclusionId: number, index: number): void {
    if (exclusionId) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeQuoteExclusionById(exclusionId, index);
        }
      });
    } else {
      console.error('Item ID is null or undefined');
    }
  }

  private removeQuoteExclusionById(exclusionId: number, index: number): void {
    this.quoteService.removeQuoteExclusion(exclusionId).subscribe({
      next: (response) => {
        // this.items.removeAt(index);
        this.toastr.success('Quote exclusion removed successfully.');
        this.quoteData = {
          ...this.quoteData,
          quoteExclusions: [
            ...this.quoteData.quoteExclusions.slice(0, index),
            ...this.quoteData.quoteExclusions.slice(index + 1),
          ]
        }
      },
      error: (err) => {
        this.toastr.error(err || 'Failed to remove quote exclusion.');
        console.log('Failed to remove quote exclusion.', err);
      },
    });
  }

  onRemoveNewlyAddedItems(index: number): void {
    this.items.removeAt(index);
  }

  generateQuoteNumber(): void {
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    const invoiceNo = `QT-${randomSuffix}`;
    this.quoteForm.patchValue({ invoiceNo });
  }

  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Quote').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.quoteNumber = prefix?.sequenceCode;
        });
        // const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        // this.prefix = `${this.quoteNumber + randomSuffix}`;
        this.quoteForm.patchValue({ quoteCode: this.quoteNumber });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  disableBeforeQuoteDate = (date: Date | null): boolean => {
    const quoteDate = this.quoteForm.get('quoteDate')?.value;
    if (!quoteDate || !date) {
      return true;
    }
    return date >= new Date(quoteDate);
  };

  private fetchPartners(search: string = '') {
    this.invoiceService.fetchCustomers(search, this.partnerTypes.Customer).subscribe({
      next: (partners: any) => {
        this.partnersData = partners
          // .filter((data: any) => data.partnerType === 'Customer')
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
            partnerProjects: data.partnerProjects || [],
            trnNumber: data.trnNumber || '',
          }));
        if (this.quoteId) {
          this.loadQuoteData(); // Ensure loadQuoteData is called after partnersData is ready
        }
        this.billingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'BILLING');
        this.shippingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'SHIPPING');
      },
      error: (error) => console.error(error),
    });
  }

  private listBranches(search: string = '') {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches
          .filter((data: any) => data.branchName !== 'All')
          .map((data: any) => ({
            id: data.id || '--',
            branchName: data.branchName || '--',
          }));
        this.populateBranches();
      },
      error: (error) => console.error(error),
    });
  }

  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isSalesActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.itemData = items;
        this.filteredItemData = items;
      },
      error: (error) => console.error(error),
    });
  }

  // openCreateClientDialog() {
  //   const dialogRef = this.dialog.open(CreateClientDialog, {
  //     disableClose: true,
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result === 'success') {
  //     }
  //     this.fetchPartners();
  //   });
  // }

  openCreateClientDialog() {
    const dialogRef = this.dialog.open(CreateClientDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.newCustomer) {
        this.partnersData.push(result.newCustomer);
        this.quoteForm.patchValue({ partnerId: result.newCustomer.id });
      }
      this.fetchPartners();
    });
  }


  openBillingAddressDialog(type: any) {
    const selectedClientId = this.quoteForm.get('partnerId')?.value;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openShippingAddressDialog(type: any) {
    const selectedClientId = this.quoteForm.get('partnerId')?.value;
    this.dialog.open(ShippingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openSelectBillingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedBillingAddress = address;
        this.quoteForm.patchValue({ billingAddressId: address.id });
      }
    });
  }

  openSelectShippingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'SHIPPING';

    const dialogRef = this.dialog.open(ListShippingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedShippingAddress = address;
        this.quoteForm.patchValue({ shippingAddressId: address.id });
      }
    });
  }

  calculateGrossAmount(rate: number, quantity: number): number {
    return rate * quantity;
  }

  calculateNetAmount(grossAmount: number, discount: number): number {
    return grossAmount - discount;
  }

  getCalculatedItems() {
    return this.quoteData?.quoteItems.map((item: any) => {
      const grossAmount = this.calculateGrossAmount(item.rate, item.quantity);
      const netAmount = this.calculateNetAmount(grossAmount, item.discount || 0);
      return {
        ...item,
        grossAmount,
        netAmount,
        // vat,
        vat: item.tax,
      };
    });
  }
}