<section class="cmn-innerpage-wrapper invoice-new">
    <h2 class="cmn-inner-heading">
        {{ noteId ? "Update Debit Note" : "New Debit Note"}}
    </h2>
    <form [formGroup]="FormGroupItems" (ngSubmit)="onSubmit()">
        <div class="invoice-new-form-wrapper">
            <div class="form-wrapper invoice-form-wrapper">
                <!-- Client Name Section -->
                <div class="cmn-Search-select-container">
                    <label for="clientName">Vendor Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Vendor Name" id="clientName" formControlName="customerName" [ngClass]="{
                'mat-select-error':
                  FormGroupItems.get('customerName')!.touched &&
                  FormGroupItems.get('customerName')!.hasError('required')
              }" (selectionChange)="onCustomerSelected($event)">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="customerSearchControl"
                                    placeholderLabel="Search Vendor"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partners of partnersList" [value]="partners.id">
                                {{ partners?.displayName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            FormGroupItems.get('customerName')!.touched &&
                            FormGroupItems.get('customerName')!.hasError('required')
                          ">Vendor Name is required.</mat-error>
                        </span>
                    </div>
                    <button (click)="openCreateVendorDialog()">
                        <div>
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
                        </div>
                        <span>Add Vendor</span>
                    </button>
                </div>

                <!-- Invoice Number Section -->
                <div>
                    <label for="invoiceNumber">Debit Note#</label>
                    <mat-form-field>
                        <input matInput placeholder="IN-000212" id="invoiceNumber" formControlName="creditNoteNo" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                                FormGroupItems.get('creditNoteNo')!.touched &&
                                FormGroupItems.get('creditNoteNo')!.hasError('required')
                            ">Debit Note is required.</mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="
                        FormGroupItems.get('creditNoteNo')!.touched &&
                        FormGroupItems.get('creditNoteNo')!.hasError('maxlength')
                        ">*Debit note number exceeds limit (max limit is 15)</mat-error>
                        </span>
                    </div>
                </div>

                <!-- Order Number Section -->
                <div>
                    <label for="reference">Reference#</label>
                    <mat-form-field>
                        <input matInput placeholder="Reference number" id="reference" formControlName="reference" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="FormGroupItems.get('reference')?.hasError('maxlength')">Reference cannot
                                exceed 10 characters</mat-error>
                        </span>
                    </div>
                </div>

                <!-- Invoice Date Section -->
                <div>
                    <label for="creditNoteDate">Debit Note Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date"
                            id="creditNoteDate" formControlName="creditNoteDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #invoiceDatePicker></mat-datepicker>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            FormGroupItems.get('creditNoteDate')!.touched &&
                            FormGroupItems.get('creditNoteDate')!.hasError('required')
                          ">Debit Note Date is required.</mat-error>
                        </span>
                    </div>
                </div>


                <div>
                    <label for="subject">Subject</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Subject" id="subject" formControlName="subject" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            FormGroupItems.get('subject')!.touched &&
                            FormGroupItems.get('subject')!.hasError('required')
                          ">Subject is required.</mat-error>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="billing-shipping-address-wrapper" *ngIf="selectedCustomerId">
            <div>
                <div class="header-wrapper">
                    <h5>Billing Address</h5>
                    <div (click)="openSelectBillingAddressDialog()">
                        <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
                    </div>
                </div>
                <div *ngIf="selectedBillingAddress">
                    <h6>{{ selectedBillingAddress.attention }}</h6>
                    <ul>
                        <li>{{ selectedBillingAddress.addressLine1 }}</li>
                        <!-- <li>{{ selectedBillingAddress.addressLine2 }}</li> -->
                        <li>{{ selectedBillingAddress.city }}</li>
                        <li>{{ selectedBillingAddress.state }}</li>
                        <li>{{ selectedBillingAddress.country }}</li>
                        <li>{{ selectedBillingAddress.phone }}</li>
                    </ul>
                </div>
                <button type="button" (click)="openBillingAddressDialog('add')">
                    <span>Add Address</span>
                </button>
            </div>

            <div>
                <div class="header-wrapper">
                    <h5>Shipping Address</h5>
                    <div (click)="openSelectShippingAddressDialog()">
                        <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
                    </div>
                </div>
                <div *ngIf="selectedShippingAddress">
                    <h6>{{ selectedShippingAddress.attention }}</h6>
                    <ul>
                        <li>{{ selectedShippingAddress.addressLine1 }}</li>
                        <!-- <li>{{ selectedShippingAddress.addressLine2 }}</li> -->
                        <li>{{ selectedShippingAddress.city }}</li>
                        <li>{{ selectedShippingAddress.state }}</li>
                        <li>{{ selectedShippingAddress.country }}</li>
                        <li>{{ selectedShippingAddress.phone }}</li>
                    </ul>
                </div>
                <button type="button" (click)="openShippingAddressDialog('add')">
                    <span>Add Address</span>
                </button>
            </div>
        </div>

        <div class="item-service-adding-table-wrapper">
            <table>
                <thead>
                    <tr>

                        <th>Item/Service</th>
                        <th>Account</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Gross Price</th>
                        <th>Discount</th>
                        <th>Net Price</th>
                        <th>VAT(5%)</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody formArrayName="items">
                    <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                        <td>
                            <div class="form-wrapper">
                                <div>
                                    <mat-form-field>
                                        <input matInput placeholder="Select or Type Item" [matAutocomplete]="auto"
                                            formControlName="itemName" (input)="onItemSearch($event)" />
                                        <mat-autocomplete #auto="matAutocomplete"
                                            (optionSelected)="onSelectItem($event)">
                                            <mat-option *ngFor="let item of filteredItemData" [value]="item.id">
                                                {{ item.itemName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <div class="custom-error">
                                        <span>
                                            <mat-error *ngIf="item.get('itemName')?.errors?.['required']">Item is
                                                required.</mat-error>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <mat-select placeholder="Enter Account" formControlName="account">
                                        <mat-option *ngFor="let acc of accountData" [value]="acc.id">
                                            {{ acc.accountName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="custom-error" *ngIf="item.get('account')!.touched">
                                    <span>
                                        <mat-error *ngIf="item.get('account')?.hasError('required')">Account is
                                            required</mat-error>
                                    </span>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Quantity" formControlName="quantity" />
                                </mat-form-field>
                                <div class="custom-error">
                                    <span>
                                        <mat-error *ngIf="
                                        item.get('quantity')?.touched &&
                                        item.get('quantity')?.hasError('required')
                                      ">*Quantity is required. </mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="item.get('quantity')?.hasError('nonNegative')">*Quantity
                                            cannot be negative.</mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="item.get('quantity')?.hasError('pattern')">*Quantity must be a
                                            valid number.</mat-error>
                                    </span>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Rate" formControlName="rate" />
                                </mat-form-field>
                                <div class="custom-error">
                                    <span>
                                        <mat-error *ngIf="
                                    item.get('rate')?.hasError('required')">Rate is required</mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="item.get('rate')?.hasError('min')">Rate cannot be
                                            negative</mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="item.get('rate')?.hasError('pattern')">Rate must be a valid
                                            number</mat-error>
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="0" formControlName="subTotal" type="number" />
                                </mat-form-field>
                                <div class="custom-error">
                                    <span>
                                        <mat-error *ngIf="item.get('subTotal')?.hasError('nonNegative')">*sub total
                                            cannot be negative.</mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="
                                    item.get('subTotal')?.hasError('pattern') &&
                                    item.get('subTotal')?.touched
                                  ">*Please enter a valid sub total.</mat-error>
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="discount" formControlName="discount" />
                                </mat-form-field>
                                <!-- <div class="custom-error">
                                    <span>
                                        <mat-error *ngIf="
                                    item.get('discount')?.hasError('required')">Rate is required</mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="item.get('discount')?.hasError('min')">Rate cannot be
                                            negative</mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="item.get('discount')?.hasError('pattern')">Rate must be a
                                            valid number</mat-error>
                                    </span>
                                </div> -->
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="netPrice" formControlName="netPrice" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="vat" formControlName="vatAmount" />
                                </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Total" formControlName="total" />
                                </mat-form-field>
                                <div class="custom-error">
                                    <span>
                                        <mat-error *ngIf="item.get('total')?.hasError('required')">Total is
                                            required</mat-error>
                                    </span>
                                    <span>
                                        <mat-error *ngIf="item.get('total')?.hasError('min')">Total cannot be
                                            negative</mat-error>
                                    </span>
                                </div>
                            </div>
                        </td>

                        <td>
                            <img src="../../../assets/images/icons/icon-close.svg" alt="Remove"
                                (click)="removeItem(i)" />
                        </td>
                    </tr>
                </tbody>

            </table>
            <button type="button" (click)="addItem()">
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" (click)="addItem()" />
                Add
            </button>
        </div>

        <div class="quotation-table-container" *ngIf="noteData?.noteItems.length > 0">

            <div>
                <table>

                    <thead>
                        <tr>
                            <th>SL No. </th>
                            <th>Item/Service</th>
                            <th>Account</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>VAT</th>
                            <th>Amount</th>
                            <th>Action </th>
                        </tr>
                    </thead>

                    <tbody *ngIf="itemData">

                        <tr *ngFor="let item of noteData.noteItems; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item?.itemData?.itemName }}</td>
                            <td>{{ item?.accountData?.accountName}}</td>
                            <td>{{ item?.quantity }}</td>
                            <td>{{ item?.rate }}</td>
                            <td>{{ item.discount }}</td>
                            <td>{{ ((item?.rate * item?.quantity - item?.discount) * 5) / 100 }}</td>

                            <td>{{ item?.amount }}</td>
                            <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                                (click)="onRemoveInvoiceItem(i, item?.id)" />
                        </tr>


                    </tbody>
                </table>
            </div>

        </div>

        <div class="notes-tatal-amount-container">
            <div class="form-container">
                <div class="form-wrapper">
                    <label for="customerNotes">Vendor Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter your notes here..."
                            formControlName="customerNote"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="termsConditions">Terms & Conditions</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"
                            formControlName="termsConditions"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="amount-table-div">
                <div>

                    <h6>Sub Total <span>: AED {{ getSubTotal().toFixed(2) | number:'1.2-2' }}</span></h6>

                    <h6>Discount <span>: AED {{ getDiscount().toFixed(2) | number:'1.2-2' }} </span></h6>
                    <h6>VAT amount <span>: AED {{ getVAT().toFixed(2) | number:'1.2-2' }} </span></h6>
                </div>
                <div>
                    <h4>Total(AED) <span>: {{ getGrandTotal().toFixed(2) | number:'1.2-2' }}</span></h4>
                </div>
            </div>
        </div>

        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="reset" (click)="onCancel()">Cancel</button>
            <button class="cmn-draft-btn" type="submit" (click)="saveAsDraft()">Save as Draft</button>
            <button class="cmn-next-btn" type="submit">Save as Open</button>
        </div>
    </form>
</section>