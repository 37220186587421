import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ASSETS_DROPDOWN, ASSET_DISPOSAL, ASSET_LOGS, ASSET_TRANSFER_LIST, ASSIGNEE_DROPDOWN, EMPLOYEE_DROPDOWN, FETCH_ALL_ASSETS, GET_ASSETS_FOR_EMPLOYEE, VEHICLETASKS, VEHICLE_FINES } from '../graphql/querries/assets.querries';
import { ADD_FINE_VEHICLE, ASSIGN_ASSET__TO_EMPOLOYEE, ASSIGN_VEHICLE, CREATE_ASSET, DISPOSE_ASSET, TRANSFER_ASSETS, UNASSIGN_ASSETS, UPDATE_ASSET, UPDATE_ASSET_TRANSFER } from '../graphql/mutations/assets.mutations';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private apollo: Apollo) {}

  private dataUpdatedSubject = new BehaviorSubject<boolean>(false);
  dataUpdated$ = this.dataUpdatedSubject.asObservable();

  notifyDataUpdated(updated: boolean) {
    this.dataUpdatedSubject.next(updated);
  }


  //queries


  getAssetsForEmployee(): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_ASSETS_FOR_EMPLOYEE,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data?.assetsForEmployee || [])
      );
  }

  getAllAssets(search: string, assetType: string): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: FETCH_ALL_ASSETS,
        variables: {
          search: search,
          assetType: assetType,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.assets || []));
  }

  listAssignees(search: string, status: number){
    return this.apollo
    .use('hrms')
    .watchQuery<any>({
      query: ASSIGNEE_DROPDOWN,
      variables:{
        search: search,
        status:status
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((response) => response || []));
  }


  assetsDropdown(search: string){
    return this.apollo
    .use('hrms')
    .watchQuery<any>({
      query: ASSETS_DROPDOWN,
      variables: {
        search: search
      },
      fetchPolicy: 'network-only'
    })
    .valueChanges.pipe(map((response) => response.data?.assetsDropDown || []));
  }

  listAssetTransfer(assetId?: number | null, branchId?: number | null, search?: string){
    return this.apollo
    .use('hrms')
    .watchQuery<any>({
      query: ASSET_TRANSFER_LIST,
      variables: {
        assetId: assetId,
        branchId: branchId,
        search: search
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((response) => response?.data?.assetTransfers || []));
  }

  assetDisposals(search? : string,assetId? : number | null){
    return this.apollo
    .use('hrms')
    .watchQuery<any>({
      query : ASSET_DISPOSAL,
      variables:{
        asserId: assetId,
        search: search
      },
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(map((response) => response?.data?.assetDisposals  || []));
  }


  vehicleTaskaLogs(assetId: number){
    return this.apollo
    .use('hrms')
    .watchQuery<any>({
      query: VEHICLETASKS,
      variables: {
        assetId: assetId
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((response) => response?.data?.vehicletasks || []));
  }


  assetlogs(filter: any){
    return this.apollo
    .use('hrms')
    .watchQuery<any>({
      query: ASSET_LOGS,
      variables:{
        filter: filter
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((response) => response?.data?.assetLogs || []));

  }

  listemployees(search: string){
    return this.apollo
    .use('hrms')
    .watchQuery<any>({
      query: EMPLOYEE_DROPDOWN,
      variables:{
        search: search
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((response) => response?.data?.employeesDropDown || []));
  }

  
  listFine(vehicleId: number, search: string){
    return this.apollo.use('hrms')
    .watchQuery<any>({
      query: VEHICLE_FINES,
      variables: {
        vehicleId: vehicleId,
        search: search
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((response) => response?.data?.vehicleFines || []));

  }

  //mutations

  assignVehicle(id: number, actionType: string, createVehicleLogInput? : any, createVehicletaskInput?: any, updateVehicletaskInput?: any) {
    return this.apollo.use('hrms').mutate<any>({
      mutation: ASSIGN_VEHICLE,
      variables : {
        id:id,
        actionType: actionType,
        createVehicleLogInput: createVehicleLogInput,
        createVehicletaskInput: createVehicletaskInput,
        updateVehicletaskInput: updateVehicletaskInput
      },
      fetchPolicy: 'no-cache'
    });
  }

  assignAssetToEmployee(assignedToId: number, assetId:  number){
    return this.apollo.use('hrms').mutate<any>({
      mutation: ASSIGN_ASSET__TO_EMPOLOYEE,
      variables:{
        assignedToId: assignedToId,
        assetId: assetId
      },
      fetchPolicy: 'no-cache'
    });
  }

  transferAssets(createAssetTransferInput: any){
    return this.apollo.use('hrms').mutate<any>({
      mutation:  TRANSFER_ASSETS,
      variables:{
        createAssetTransferInput: createAssetTransferInput
      },
      fetchPolicy: 'no-cache'
    });
  }

  createAsset(createAssetInput: any) {
    return this.apollo.use('hrms').mutate<any>({
      mutation: CREATE_ASSET,
      variables: {
        createAssetInput: createAssetInput,
      },
      fetchPolicy: 'no-cache',
    });
  }

  disposeAsset(createAssetDisposalInput: any){
    return this.apollo.use('hrms').mutate<any>({
      mutation:  DISPOSE_ASSET,
      variables:{
        createAssetDisposalInput: createAssetDisposalInput
      },
      fetchPolicy: 'no-cache'
    });
  }
  updateAsset(updateAssetInput: any){
    return this.apollo.use('hrms').mutate<any>({
    mutation: UPDATE_ASSET,
    variables: {
      updateAssetInput: updateAssetInput
    },
    fetchPolicy: 'no-cache'
    });
  }

  updateAssetTransfer(updateAssetTransfer: any){
    return this.apollo.use('hrms').mutate<any>({
      mutation: UPDATE_ASSET_TRANSFER,
      variables: {
        updateAssetTransferInput: updateAssetTransfer
      },
      fetchPolicy: 'no-cache'
    })
  }

  addFineToVehicle(createVehicleFineInput: any){
    return this.apollo.use('hrms')
    .mutate<any>({
      mutation: ADD_FINE_VEHICLE,
      variables: {
        createVehicleFineInput: createVehicleFineInput
      },
      fetchPolicy: 'no-cache'
    })
  }

  unAssignAsset(assetId: number){
    return this.apollo.use('hrms').mutate<any>({
      mutation: UNASSIGN_ASSETS,
      variables: {
        assetId: assetId
      },
      fetchPolicy: 'no-cache'
    })
  }

}
